import { Component, OnInit, Inject } from '@angular/core';
import { CrudService } from 'app/main/workflow/crud.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


// tslint:disable-next-line:no-empty-interface
export interface DialogData {
 
}


@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss']
})
export class EditFormComponent implements OnInit {
  udr_dtl: any;
  element: any;
  userlst: any;
  userForm: FormGroup;
  editdata: any;
  catList: any;
  sub_cat_list: any;
  imagesPrv;
  imageUrl;
  ShwQrcd;
  sveForm;
  preview;

  constructor(private apiCtrl: CrudService, private _formBuilder: FormBuilder, 
    public dialogRef: MatDialogRef<EditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.udr_dtl = JSON.parse(localStorage.getItem('usrDtls'));
    console.log(this.udr_dtl);
    console.log(data);
    this.editdata = data;
    console.log(this.editdata);
    // this.entryTktDtls();
    
   }


  ngOnInit() {

    this.apiCtrl.get('/ticket-system/req-stg-details/' + this.udr_dtl.usr_id + '/' + this.editdata.req_entry_id ).subscribe((res) => {
    this.userlst = res['data'];
    console.log(this.userlst);
  })

    this.userForm = this._formBuilder.group({

      req_usr_nm: [this.editdata.req_usr_nm, Validators.required],
      req_usr_mbl: [this.editdata.req_usr_mbl, Validators.required],
      req_usr_adrs: [this.editdata.req_usr_adrs, Validators.required],
      req_txt: [this.editdata.req_txt, Validators.required],
      city: [this.editdata.city, Validators.required],
      cat_id: [this.editdata.cat_id, Validators.required],
      sub_cat_id: [this.editdata.sub_cat_id, Validators.required],
      // date: ['', Validators.required],
      Date: [this.editdata.i_ts, Validators.required],
      shrt_desc: [this.editdata.shrt_desc, [Validators.required]],

    });
    this.getCatList();
   
  }
  getCatList() {
    console.log("getCats")
    this.apiCtrl.get('ticket-system/category-list').subscribe((res) => {
      this.catList = res['data'];
      console.log(this.catList)
      this.subcat(this.editdata.cat_id);
    }, (error) => {
      console.log(error)
    })
  }
  subcat(cat_id) {
    this.apiCtrl.get('ticket-system/sub-category-list/' + cat_id).subscribe((res) => {
      this.sub_cat_list = res['data'];
      console.log(this.sub_cat_list)
    }, (error) => {
      console.log(error)
    })
  }
  
 
  closeDialog(){
  this.dialogRef.close();

  }


  

}
