import { NgModule,Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { WasteGeneratorComponent } from './waste-generator.component';
import { AppartmentsComponent } from './appartments/appartments.component';
import { CommercialgatesComponent } from './commercialgates/commercialgates.component';
import { ResidentialgatesComponent } from './residentialgates/residentialgates.component';
import { MixedgatesComponent } from './mixedgates/mixedgates.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, 
         MatTableModule, MatPaginatorModule  , MatSortModule , MatButtonModule, MatIconModule,MatRadioModule,MatDatepickerModule   } from '@angular/material';
import { FuseSidebarModule } from '@fuse/components';
import {MatDividerModule} from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import { MatExpansionModule } from '@angular/material/expansion';
import {ConfirmDialog} from './waste-generator.component';

const wste_gntr_routes: Routes = [
    { path: 'appartments', component: AppartmentsComponent } ,
    { path: 'commercialgates', component: CommercialgatesComponent },
    { path: 'residentialgates', component: ResidentialgatesComponent },
    { path: 'mixedgates', component: MixedgatesComponent }
];

@NgModule({
  declarations: [
    WasteGeneratorComponent,
    AppartmentsComponent,
    CommercialgatesComponent,
    ResidentialgatesComponent,
    MixedgatesComponent,
    ConfirmDialog
  ],
  imports: [
    RouterModule.forChild(wste_gntr_routes),
    CommonModule,
    MatTabsModule,
    MatCardModule,
    MatFormFieldModule, MatSelectModule, MatInputModule, MatOptionModule, FlexLayoutModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatButtonModule,MatIconModule,MatRadioModule,MatDatepickerModule,
    MatExpansionModule,
    MatAutocompleteModule,
    FuseSidebarModule,
    MatDividerModule,
    MatToolbarModule,FormsModule, ReactiveFormsModule,
   
  ],
  entryComponents: [ConfirmDialog],
})
export class WasteGeneratorsModule { }
