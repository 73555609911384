import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../auth.service';
import { Route, Router } from '@angular/router';
import { UserService } from 'app/providers/user/user.serivce';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector: 'login-2',
    templateUrl: './login-2.component.html',
    styleUrls: ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class Login2Component implements OnInit {
    loginForm: FormGroup;
    userData: any = {};
    baseUrl = 'http://localhost:4200';
    err_msg = null;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _formBuilder: FormBuilder,
        public authService: AuthService,
        public userService: UserService,
        public router: Router
    ) {
        // console.log('In login');
        let userId = localStorage.getItem('usrDtls');
        let clnt = localStorage.getItem('clnts') ? JSON.parse(localStorage.getItem('clnts')) : {};
        if (userId) {
            this.router.navigate([clnt[0].tnt_hmdshb_pg]);
        }


        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required]
        });
    }

    doLogin() {
        this.err_msg = null;
        this.authService.doLogin(this.loginForm.value, (err, res) => {
            // console.log(res);
            if (err) {
                this.err_msg = 'Something went wrong. Please check your internet connection and try again.';
                return;
            }
            if (res && res.status == 200) {
                if (!res.data) {
                    this.err_msg = 'Incorrect username and/ or password.';
                    return;
                }
                localStorage.setItem('uid', res.data.user.usr_id);
                console.log(res.data.user.pwd_chngd_in)
                if (res.data.user.pwd_chngd_in) {
                    this.router.navigate(['/reset-password']);                    
                    return;
                }
                this.userData = res.data;
                this.userService.setUsrDta(res.data.user);
                localStorage.setItem('clnts', JSON.stringify(res.data.clnts));
                localStorage.setItem('ulbs', JSON.stringify(res.data.ulbs));

                if (res.data.clnts.length === 0) {
                    // console.log('Your are not linked with any clients. Please contact support team.');
                }

                if (res.data.clnts.length === 1 && res.data.clnts[0].tnts.length) {
                    localStorage.setItem('selectedClnt', JSON.stringify(res.data.clnts[0]));
                    this.UsrPrfles(res.data.user, res.data.clnts[0])
                }
                else {
                    this.router.navigate(['/']);
                }
            }
        });
    }


    UsrPrfles(user, clnt) {
        this.authService.getUsrPrfl((err, res) => {
            let d = {};
            if (res.data.length > 0) {
                d = res.data[0];
            }
            const a = Object.assign({}, user, d);

            localStorage.setItem('usrDtls', JSON.stringify(a));
            this.userService.setUsrDta(a);
            this.authService.getClntRprtStng((err, res) => {
                if (err) {
                    // console.log(err);
                    return;
                }
                let d = {};
                if (res.data.length > 0) {
                    d = res.data[0];
                } else {
                    d = undefined;
                }
                localStorage.setItem('reportStyle', JSON.stringify(d));
                this.authService.getAplctnPrfls((err, res) => {
                    if (err) {
                        // console.log(err);
                        return;
                    }
                    localStorage.setItem('appDtls', JSON.stringify(res.data));

                    this.authService.getAplctnMnuPrfls((err, res) => {
                        if (err) {
                            console.log(err);
                            return;
                        }
                        localStorage.setItem('mnuDtls', JSON.stringify(res.data));
                        this._fuseNavigationService.unregister('main');
                        // Register the navigation to the service
                        this._fuseNavigationService.register('main', res.data);

                        var lastAccessedUrl = window.sessionStorage.getItem(a.usr_id + '::lastAccessedUrl');
                        if (lastAccessedUrl)
                            this.router.navigate([lastAccessedUrl]);
                        else
                            this.router.navigate([clnt.tnt_hmdshb_pg]);
                    })
                })
            });
        })
    }
}
