import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { HttpModule } from '@angular/http';

import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { MatTabsModule, MatCardModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatTableModule, MatPaginatorModule,
  MatInputModule,MatSortModule,MatButtonModule, MatDialogModule ,MatDividerModule ,MatToolbarModule,MatDatepickerModule} from '@angular/material';
import { FuseWidgetModule } from '../../../@fuse/components/widget/widget.module';
import { FuseSidebarModule, FuseNavigationModule } from '@fuse/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeoLocationsComponent, ConfirmDialog } from './geo-locations.component';
import { UlbComponent } from './ulb/ulb.component';
import { WorkpackageComponent } from './workpackage/workpackage.component';
import { ClustersComponent } from './clusters/clusters.component';
import { MicropocketComponent } from './micropocket/micropocket.component';
import { WardsComponent } from './wards/wards.component';
import { StreetsComponent } from './streets/streets.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete'; 

import { DsDraggableComponent } from './../ds-draggable/ds-draggable.component';

import { GeoLocationsService } from './geo-locations.service';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { SharedModule } from 'app/shared/shared.module';
import { GatesComponent } from './gates/gates.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { ApprovalStatusComponent, ApprovalDialog } from '../approval-status/approval-status.component';
import { GroupsComponent } from './groups/groups.component';

const geo_routes: Routes = [
  { path: 'locations', component: GeoLocationsComponent },
  { path: 'locations/ulb', component: UlbComponent },
  { path: 'gates', component: GatesComponent },
  { path: 'vehicles', component: VehiclesComponent },
  { path: 'approval', component: ApprovalStatusComponent }
];


@NgModule({
  declarations: [
    GeoLocationsComponent,
    DsDraggableComponent,
    UlbComponent,
    WorkpackageComponent,
    ClustersComponent,
    MicropocketComponent, 
    WardsComponent,
    StreetsComponent,
    ConfirmDialog,
    ServiceProviderComponent,
    GatesComponent,
    VehiclesComponent,
    ApprovalStatusComponent,
    ApprovalDialog,
    GroupsComponent
  ],
  entryComponents: [ConfirmDialog, ApprovalDialog],
  imports: [
    RouterModule.forChild(geo_routes),
    FlexLayoutModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FuseWidgetModule,
    FuseSidebarModule, 
    FuseNavigationModule,
    MatAutocompleteModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    ScrollingModule,
    HttpModule,
    MatFormFieldModule, MatSelectModule, MatInputModule, MatOptionModule, FlexLayoutModule, MatDatepickerModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatButtonModule, MatDividerModule, MatToolbarModule, 
    SharedModule
  ],
  providers: [GeoLocationsService]
})
export class GeoLocationsModule {

}

