import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ulbgates } from './micropocket-level-filter.model';
import {Observable} from 'rxjs';
import {MatDatepickerModule, MatDatepickerInputEvent} from '@angular/material/datepicker';
import { map, startWith, filter } from 'rxjs/operators';

@Component({
  selector: 'app-micropocket-level-filter',
  templateUrl: './micropocket-level-filter.component.html',
  styleUrls: ['./micropocket-level-filter.component.scss']
})
export class MicropocketLevelFilterComponent implements OnInit {
  @Output() fltrEvent = new EventEmitter<string>();
  date = new Date();
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  frmevents: string[] = [];
  toevents: string[] = [];
  fltrdata;
  ulbLst: Ulbgates[] = [];
  ulbFltrOptions: Observable<Ulbgates[]>;
  gteLst: Ulbgates[] = [];
  gteFltrOptions: Observable<Ulbgates[]>;
  mcrptLst: Ulbgates[] = [];
  mpFltrOptions: Observable<Ulbgates[]>;
  empLst: Ulbgates[] = [];
  empFltrOptions: Observable<Ulbgates[]>;
  headerForm: FormGroup;
  ulb_id;
  wrk_pckge_id = 0;
  clsrt_id = 0;
  mcrpt_id;
  ulbsLst;
  gte_id;
  emp_id;
  @Input() fltrVsbleOptns;
  ulbfltr; mcrptFltr; gteFltr; empFltr; frmDt; toDt;
  constructor(public datepipe: DatePipe, private misreportsService: MisreportsService) { }

  ngOnInit() {
    if (this.fltrVsbleOptns === undefined){
      this.ulbfltr = true;
      this.mcrptFltr = true;
      this.gteFltr = true;
      this.frmDt = true;
      this.toDt = true;
    }else{
      this.ulbfltr = this.fltrVsbleOptns.isUlb;
      this.mcrptFltr = this.fltrVsbleOptns.isMcrpt;
      this.gteFltr = this.fltrVsbleOptns.isGte;
      this.empFltr = this.fltrVsbleOptns.isEmp;
      this.frmDt = this.fltrVsbleOptns.isFrmDt;
      this.toDt = this.fltrVsbleOptns.isToDt;
    }

    // this.sendFltrData() 
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required),
      selectedMp: new FormControl('', Validators.required),
      selectedGte: new FormControl('', Validators.required),
      selectedEmp: new FormControl('', Validators.required),
  }); 
  this.ulbFltrOptions = this.headerForm.get('selectedUlb').valueChanges
  .pipe(
    startWith(''),
    map(value => this._ulbfilter(value))
  );

  this.mpFltrOptions = this.headerForm.get('selectedMp').valueChanges
  .pipe(
    startWith(''),
    map(value => this._mpfilter(value))
  );

  this.gteFltrOptions = this.headerForm.get('selectedGte').valueChanges
  .pipe(
    startWith(''),
    map(value => this._gtefilter(value))
  );

  this.empFltrOptions = this.headerForm.get('selectedEmp').valueChanges
  .pipe(
    startWith(''),
    map(value => this._empfilter(value))
  );

  this.ulbsLst = JSON.parse(localStorage.getItem('ulbs'));
  for (let u of this.ulbsLst) {
        this.ulbLst.push(u);
      }
      this.headerForm.get('selectedUlb').setValue('');
  }
  ulbSelected(evt: any){
    this.ulb_id = evt.option.value.ulb_id;
    this.getMicropockets(this.ulb_id);
  }
  getMicropockets(ulb_id){
    this.mcrptLst = [];
    this.ulb_id = ulb_id;
    this.misreportsService.geMicroPockets(this.ulb_id, this.wrk_pckge_id, this.clsrt_id, (err, mcrptLst) => {
      for (let m of mcrptLst.data) {
        this.mcrptLst.push(m);
      }
      this.headerForm.get('selectedMp').setValue('');
    });
  }
  private _ulbfilter(value): Ulbgates[]{
    if (value) {
        return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }

  private _mpfilter(value): Ulbgates[]{
    if (value) {
        return this.mcrptLst.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
    }
    return this.mcrptLst;
  }
 
  autocompleteUlbDisplayFn(apprt?: Ulbgates): string  | undefined{
    return apprt ? apprt.ulb_nm : undefined;
  }
  autocompleteMpDisplayFn(apprt?: Ulbgates): string  | undefined{
    return apprt ? apprt.mcrpt_nm : undefined;
  }

  mpSelected (evt: any) {
    this.gteLst = [];
    this.mcrpt_id = evt.option.value.mcrpt_id;

    this.misreportsService.getGatesList(this.mcrpt_id, (err, gteLst) => {
      for (let g of gteLst.data) {
        this.gteLst.push(g);
      }
      this.headerForm.get('selectedGte').setValue('');
    });
    this.misreportsService.getEmpList(this.mcrpt_id, (err, empLst) => {
      for (let g of empLst.data) {
        this.empLst.push(g);
      }
      this.headerForm.get('selectedGte').setValue('');
    });
  }

  private _gtefilter(value): Ulbgates[]{
    if (value) {
        return this.gteLst.filter(item => ((typeof value === 'string') ? item.gte_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.gte_nm.toLowerCase().indexOf(value['gte_nm'].toLowerCase()) === 0));
    }
    return this.gteLst;
  }
  private _empfilter(value): Ulbgates[]{
    if (value) {
        return this.empLst.filter(item => ((typeof value === 'string') ? item.emp_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.emp_nm.toLowerCase().indexOf(value['emp_nm'].toLowerCase()) === 0));
    }
    return this.empLst;
  }
  gateSelected(evt: any){
    this.gte_id = evt.option.value.gte_id;
  }
  empSelected(evt: any){
    this.emp_id = evt.option.value.emp_id;
  }

  autocompleteGteDisplayFn(apprt?: Ulbgates): string  | undefined{
    return apprt ? apprt.gte_nm : undefined;
  }
  autocompleteEmpDisplayFn(apprt?: Ulbgates): string  | undefined{
    return apprt ? apprt.emp_nm : undefined;
  }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
    this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
    this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
  }
  sendFltrData(){
    if (this.frmevents[0] === undefined){
      this.frmevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }
    if (this.toevents[0] === undefined){
      this.toevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }
    this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');

    this.fltrdata = {ulb_id: this.ulb_id, mcrpt_id: this.mcrpt_id, gte_id: this.gte_id, emp_id: this.emp_id, from_date: this.from_date, to_date: this.to_date};
    this.fltrEvent.emit(this.fltrdata);
  }

}
