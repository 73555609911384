import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  uri = 'http://api.apsmartcity.com:4801/apiv1/';
  public usrLgnDtls: BehaviorSubject<any>;
  usrMnuDtls: BehaviorSubject<any>;
  constructor(private http: HttpClient) {
    if(localStorage.getItem('usrDtls'))
    {
      let jsonData = localStorage.getItem('usrDtls')
      this.usrLgnDtls = new BehaviorSubject(jsonData);
      console.log(this.usrLgnDtls)
    }
    else
    {
      this.usrLgnDtls = new BehaviorSubject({});
    }
    if(localStorage.getItem('mnuDtls'))
    {
      let jsonData = localStorage.getItem('mnuDtls')
      this.usrMnuDtls = new BehaviorSubject(jsonData);
      console.log(this.usrMnuDtls)
    }
    else
    {
      this.usrMnuDtls = new BehaviorSubject({});
    }
   }

  create(postdata, rte) {
    console.log(postdata)
    return this.http.post(`${this.uri}${rte}`, { data : postdata })
  }
  get(rte) {
    console.log(rte)
    return this.http.get(`${this.uri}${rte}`)
  }
  subget(cat_id,rte) {
    console.log(rte)
    return this.http.get(`${this.uri}${rte}`+ cat_id)
  }
  update(postdata, rte) {
    console.log(rte)
    return this.http.put(`${this.uri}${rte}`, { data : postdata })
  }

  delete(rte) {
    return this.http.delete(`${this.uri}${rte}`)
  }
  
  setUsrDtl(val){
    this.usrLgnDtls.next(val);
  }
  setMnuDtl(val){
    this.usrMnuDtls.next(val);
  }

  getUsrLgnDtls(){
    let jsonData = localStorage.getItem('usrDtls')
    this.usrLgnDtls.next(jsonData);
    return  this.usrLgnDtls;
  }
  getUsrMnuDtls(){
    console.log(this.usrMnuDtls)
    return  JSON.parse(this.usrMnuDtls.value)[0].children;
  }
  getMnuItmId(name){
    let item = _.filter(JSON.parse(this.usrMnuDtls.value)[0].children,function(i){
      if(i.mnu_itm_nm == name){
        return i
      }
    })
  return (item[0]['mnu_itm_id'])
  }
  getStpIn(name){
    let item = _.filter(JSON.parse(this.usrMnuDtls.value)[0].children,function(i){
      if(i.mnu_itm_nm == name){
        return i
      }
    })
  return (item[0]['stp_in'])
  }
}
