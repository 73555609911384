import { Component, OnInit } from '@angular/core';
import { FinesService } from 'app/main/fines.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { MatDatepickerInputEvent } from '@angular/material';

@Component({
  selector: 'app-user-fee-rpt',
  templateUrl: './user-fee-rpt.component.html',
  styleUrls: ['./user-fee-rpt.component.scss']
})
export class UserFeeRptComponent implements OnInit {
  headerForm: FormGroup;
  ulbLst: any;
  ulbid: any;
  ulbtopFltrOptions: any;
  displayfltrs: boolean;
  fltrs_lable: string;
  rowData: any = [];
  columnDefs: any = [];
  paginationPageSize = 10;
  pagination: boolean = true;
  fltrForm: FormGroup;
  namePattern: string | RegExp;
  mobnumPattern: string | RegExp;
  pdfData: any = [];
  isPdfDwnLd: boolean = false;
  pdfPagesize: string;
  PdfpageOrientation: string;
  pdfheaderRows: number;
  ReportHeader: string;
  pdftableData: any;
  tableHeadersWthDataValues: string[];
  fileName: string;
  ulbNM;
  curdate = new Date();
  pdf_date = this.datepipe.transform(this.curdate, 'dd-MM-yyyy');
  ulbId: any;
  ulbNm;
  fitToPage: boolean;
  autoresize: boolean;
  setFontSize: number;
  ofcrnameLst: any = [];
  date = new Date();
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  frmevents: any[] = [];
  toevents: any[] = [];
  usrfreRptData: any = [];
  fltrDta: any = [];
  ofcrlLst: any = [];
  ctgryfltrDta: any = [];
  ctgryLst: any = [];
  phnoLst: any[];
  fontSize;
  wardFltrdata: any[];
  WardLst: any = [];
  ctgry_nm: any;
  cstmr_ctgry_id: any = [];
  ph: any = [];
  ofcr_id: any;
  wrd_nu: any;
  wrd_nm: any;
  mp_ward_id: any;
  ward_id: any;
  mble_pn: any;
  ulbnm: any;
  ExcelData: any = [];
  isExcelDwnLd: boolean;
  excelFileNm: string;
  excelDataHeaders: any[];
  clctdamnt;
  totalamnts: any=[];
  constructor(private FinesService: FinesService, private route: ActivatedRoute, public datepipe: DatePipe, private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.ulbId = params['ulb_id'];
      this.ulbNm = params['ulb_nm'];
    });
  }

  ngOnInit(): void {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // })
    this.ulbid = this.ulbLst[0].ulb_id;
    this.ulbnm = this.ulbLst[0].ulb_nm;
    // console.log(this.ulbid);
    // console.log(this.ulbnm);
    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });
    this.getTableData();
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
    // this.getTableData();
    this.fltrForm = new FormGroup({
      ofcr_nm: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
      ctgry_nm: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
      mble_ph: new FormControl('', [Validators.required, Validators.pattern(this.mobnumPattern)]),
      wrd_nu: new FormControl('', Validators.required),
      from_date: new FormControl(''),
      to_date: new FormControl(''),
    });
    this.getFltrData();
    this.fltrForm.get('ofcr_nm').setValue(0);
    this.fltrForm.get('ctgry_nm').setValue(0);
    this.fltrForm.get('mble_ph').setValue(0);
    this.fltrForm.get('wrd_nu').setValue(0);
    this.getDtFltr();

  }

  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  public mble_ph: FormControl = new FormControl();

  onUlbChange(evt) {
    this.ulbid = evt.option.value.ulb_id;
    this.ulbNM = evt.option.value.ulb_nm;
    this.getFltrData();
    this.getTableData();
  }
  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
  }
  getDtFltr(): void {

    if (this.frmevents[0] === undefined) {
      this.frmevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }
    if (this.toevents[0] === undefined) {
      this.toevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }

    this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
    this.getTableData();
    this.getFltrData()
  }

  getFltrData(): void {
    this.FinesService.FltroptionsData((err, res) => {
      this.fltrDta = res.data;
      this.ofcrlLst = [{ usr_id: 0, fst_nm: 'ALL' }];
      for (let o of res.data) {
        this.ofcrlLst.push(o);
      }
    });
    this.FinesService.ctgryFltroptionsData((err, res) => {
      this.ctgryfltrDta = res.data;
      this.ctgryLst = [{ cstmr_ctgry_id: 0, cstmr_ctgry_nm: 'ALL' }];
      for (let c of res.data) {
        this.ctgryLst.push(c);
      }
      // console.log(this.ctgryLst);
    });
    this.FinesService.WrdFltroptionsData((err, res) => {
      this.wardFltrdata = res.data;
      this.WardLst = [{ mp_ward_id: 0, mp_ward_nm: 'ALL' }];
      for (let w of res.data) {
        this.WardLst.push(w);
      }
      // console.log(this.WardLst);
    });
  }

  getTableData(): void {

    // if (this.ofcr_id == undefined) {
    //   this.ofcr_id = [];
    // }
    // if (this.cstmr_ctgry_id.length == 0) {
    //   this.cstmr_ctgry_id = [];
    // }
    // if (this.mp_ward_id == undefined) {
    //   this.mp_ward_id = [];
    // }
    // if(this.mble_ph == undefined){
    //   this.mble_ph = null;
    // }

    this.isPdfDwnLd = false;
    this.pdfData = [];
    const data = [
      {
        'frmdt': this.from_date,
        'todt': this.to_date,
        'agnt': this.ofcr_id,
        'ctgry': this.cstmr_ctgry_id,
        'wrd': this.mp_ward_id,
        'phno': this.mble_ph

      }
    ];
    // console.log(data);
    this.FinesService.TotalCollctnData((err, res) => {
      this.totalamnts=res.data;
      this.clctdamnt=0;
      for(var i=0;i< this.totalamnts.length;i++)
      {
        this.clctdamnt += this.totalamnts[i].amnt;
      }
    });
 
    this.FinesService.userfeereportTblDta(data, (err, res) => {
      this.rowData = res.data;
      var index = 1;
      for (var i = 0; i < this.rowData.length; i++) {
        this.rowData[i].IncVl = index++;
      }
      this.pdfData = [];
      this.ExcelData = [];
      this.isExcelDwnLd = false;
      this.isExcelDwnLd = false
      // this.fltrForm.valueChanges
      // .pipe(takeUntil(this._onDestroy))
      // .subscribe(() => {
      //   this.filterBanks();
      // });

      this.columnDefs = [{ headerName: 'Sno', field: 'IncVl', width: 50, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Date', field: 'fn_date', width: 100, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Ward Number', field: 'wrd_nu', width: 100, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Micro Pocket No', field: 'micro_pkt', width: 120, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'House No', field: 'hse_nu', width: 150, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Officer Name', field: 'ofcr_nm', width: 150, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Receipt Number', field: 'recept_no', width: 100, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Name of Waste Generator', field: 'nme', width: 200, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Category', field: 'ctgry_nm', width: 200, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'User Fee Amount', field: 'amnt', width: 80, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Phone Number', field: 'mble_ph', width: 200, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Address', field: 'address', width: 120, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Challan No', field: 'chalana_no', width: 90, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Challan Date', field: 'chalana_dt', width: 90, cellStyle: { textAlign: 'center' },filter: true, },];

      for (var i = 0; i < this.rowData.length; i++) {
        this.pdfData.push({
          'Date': this.rowData[i].fn_date,
          'Officer Name': this.rowData[i].ofcr_nm,
          'Ward No': this.rowData[i].wrd_nu,
          'MicroPocket No': this.rowData[i].micro_pkt,
          'House no': this.rowData[i].hse_nu,
          'Receipt no': this.rowData[i].recept_no,
          'Name of Waste Generator': this.rowData[i].nme,
          'Category': this.rowData[i].ctgry_nm,
          'User Fee Amount': this.rowData[i].amnt,
          'Phone Number': this.rowData[i].mble_ph,
          'Address': this.rowData[i].address,
          'Challan No': this.rowData[i].chalana_no,
          'Challan Date': this.rowData[i].chalana_dt,

        });

      }
      // excell data
      this.ExcelData.push({
        'Date': "",
        'Officer Name': "",
        'Ward No': "",
        'MicroPocketNo': "",
        'House no': "",
        'Receipt no': "",
        'Name of Waste Generator': "",
        'Category': "",
        'UserFeeAmount': "",
        'Phone Number': "",
        'Address': "",
        'Challan No': "",
        'Challan Date': "",
      });
      this.ExcelData.push({

        'Date': "",
        'Officer Name': "",
        'Ward No': "",
        'MicroPocketNo': "",
        'House no': "",
        'Receipt no': "",
        'Name of Waste Generator': "User Fee report - ( " + this.from_date + ") - collected Amount - " +  + this.clctdamnt,
        'Category': "",
        'UserFeeAmount': "",
        'Phone Number': "",
        'Address': "",
        'Challan No': "",
        'Challan Date': "",
      });
      this.ExcelData.push({
        'Date': "Date",
        'Officer Name': "Officer Name",
        'Ward No': "Ward No",
        'MicroPocketNo': "MicroPocketNo",
        'House no': "House no",
        'Receipt no': "Receipt no",
        'Name of Waste Generator': "Name of Waste Generator",
        'Category': "Category",
        'UserFeeAmount': "UserFeeAmount",
        'Phone Number': "Phone Number",
        'Address': "Address",
        'Challan No': "Challan No",
        'Challan Date': "Challan Date",
      });
      for (let i = 0; i < this.rowData.length; i++) {
        this.ExcelData.push({
          'Date': this.rowData[i].fn_date,
          'Officer Name': this.rowData[i].ofcr_nm,
          'Ward No': this.rowData[i].wrd_nu,
          'MicroPocketNo': this.rowData[i].micro_pkt,
          'House no': this.rowData[i].hse_nu,
          'Receipt no': this.rowData[i].recept_no,
          'Name of Waste Generator': this.rowData[i].nme,
          'Category': this.rowData[i].ctgry_nm,
          'UserFeeAmount': this.rowData[i].amnt,
          'Phone Number': this.rowData[i].mble_ph,
          'Address': this.rowData[i].address,
          'Challan No': this.rowData[i].chalana_no,
          'Challan Date': this.rowData[i].chalana_dt,
        });
      }
      // console.log(this.pdfData);
    });

  }

  downloadPdf($event): void {
    if (this.pdfData.length > 0) {
      const date = (this.from_date).split("-").reverse().join("-");
      // const todate=(this.to_date).split("-").reverse().join("-");
      this.isPdfDwnLd = $event;
      this.pdfPagesize = 'A3';
      this.PdfpageOrientation = 'Landscape';
      this.pdfheaderRows = 1;
      this.ReportHeader = this.ulbnm + ' - User Fee Report ' + ' ' + '(' + date + ')';
      this.pdftableData = this.pdfData;
      this.tableHeadersWthDataValues = ['Date', 'Officer Name', 'Ward No', 'MicroPocket No', 'House no', 'Receipt no', 'Name of Waste Generator', 'Category', 'User Fee Amount', 'Phone Number', 'Address', 'Challan No', 'Challan Date'];
      this.fileName = 'User Fee report';
      this.autoresize = true;
      this.fitToPage = true;

    }
  }
  downloadExcel($event): void {
    if (this.ExcelData.length > 0) {
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['Date', 'Officer Name', 'Ward No', 'MicroPocketNo', 'House no', 'Receipt no', 'Name of Waste Generator', 'Category', 'UserFeeAmount', 'Phone Number', 'Address', 'Challan No', 'Challan Date'];
      this.excelFileNm = 'User fee rpt';
    }
  }
}
