import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) { }

  // get Hardware Rfid Tags
  public doLogin(data, cb) {
    data.app = 'web';
    this.http.post('/login', data).subscribe(data => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Rfid Tags
  public doLogout(cb) {
    this.http.get('/logout').subscribe(res => {
      // Read the result field from the JSON response.
      localStorage.clear();
      cb(false, res);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public getClntRprtStng(cb) {
    this.http.get('/client/report').subscribe(res => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, res);

    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public getAplctnPrfls(cb) {
    this.http.get('/user/appsLst').subscribe(res => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, res);

    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public getAplctnMnuPrfls(cb) {
    this.http.get('/user/menuLst').subscribe(data => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        console.log(err);
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public getUsrPrfl(cb) {
    this.http.get('/user/profile').subscribe(res => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, res);

    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Rfid Tags
  public forgetPassword(data, cb) {
    this.http.post('/forget_password', data).subscribe(res => {
      // Read the result field from the JSON response.
      localStorage.clear();
      cb(false, res);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Rfid Tags
  public resetPassword(data, cb) {
    this.http.post('/change_password', data).subscribe(res => {
      // Read the result field from the JSON response.
      localStorage.clear();
      cb(false, res);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public saveUsrDt(data, cb) {
    this.http.post('/user/profile', data).subscribe(res => {
      // Read the result field from the JSON response.
      localStorage.clear();
      cb(false, res);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
}
