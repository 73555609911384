import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { Cluster } from './cluster.model';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Ulb } from '../ulb/ulb.model';
import { ConfirmDialog } from '../geo-locations.component';
import { UserService } from 'app/providers/user/user.serivce';


@Component({
  selector: 'app-clusters',
  templateUrl: './clusters.component.html',
  styleUrls: ['./clusters.component.scss']
})
export class ClustersComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'clstr_nm', 'clustr_nu', 'ulb_nm', 'wrk_pckge_nm', 'actions'];
  dataSource = new MatTableDataSource<Cluster>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() ulbid;
  wp_id;
  clstrs: Cluster[];
  clusterForm: FormGroup;
  ulbLst: Ulb[] = [];
  wrkpckgsLst = [];
  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  userPerm: any = {};
  errMsg = false;
  shwLdr: boolean = false;
  noData;
  constructor(private geoService: GeoLocationsService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog, private userService:UserService) {
    // this.ulbid = 20;
  }

  ngOnInit() {
    // this.geoService.getUlbs((err, ulbLst) => {
      // this.ulbLst = ulbLst.data;
    // });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.clusterForm = new FormGroup({
      clstr_nm: new FormControl('', Validators.required),
      clustr_nu: new FormControl('', Validators.required),
      ulb_id: new FormControl('', Validators.required),
      wrk_pckge_id: new FormControl('', Validators.required)
    });
    this.clusterForm.get("ulb_id").setValue(this.ulbid);
    this.userPerm = this.userService.CURRENT_PERM;
    this.onUlbChange();
    this.getClusterDetails();
  }
  ngOnChanges(changes: any) {
    if (changes && changes.ulbid){
      this.getClusterDetails();
    }
  }
  onUlbChange() {
    this.geoService.workPackage(this.ulbid, (err, wrkPckg_res) => {
      this.wrkpckgsLst = wrkPckg_res.data;
    });
  }
  getClusterDetails() {













    this.dataSource.data = [];
    this.shwLdr = true;
    // console.log(this.wp_id);
    this.geoService.getClusterDetails(this.ulbid, 0, (err, clstrs_res) => {
      this.dataSource.data = clstrs_res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  saveData() {
    if (this.clusterForm.status == 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt = this.clusterForm.value;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if(val.clstr_nm == this.updtDt.clstr_nm){
        flag = true;
      }
    })
    if(flag){
      this.errMsg = true;
      return;
    }
    this.geoService.addCluster(this.updtDt, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation:"Created" }
        });
        this.getClusterDetails();
    }
    })
  }
  updateData() {
    if (this.clusterForm.status == 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt.clstr_nm = this.clusterForm.value.clstr_nm;
    this.updtDt.ulb_id = this.clusterForm.value.ulb_id;
    this.updtDt.wrk_pckge_id = this.clusterForm.value.wrk_pckge_id;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if(val.clstr_nm == this.updtDt.clstr_nm){
        if(val.clstr_id != this.updtDt.clstr_id){
          flag = true;
        }
      }
    })
    if(flag){
      this.errMsg = true;
      return;
    }
    // console.log(this.updtDt);
    this.geoService.updtCluster(this.updtDt, (err, res) => { 
      if (res.status == 200) {
       const dialogRef = this.dialog.open(ConfirmDialog, {
         width: '500px',
         data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
       });
       this.getClusterDetails();
   }
    })
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'cluster', cnfrmflag: true, successFlag: false,  operation:"Deleted",dtls:nm}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getClusterDetails();
    })
  }
  resetForm() {
    this.clusterForm.reset();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }
  opensideBar(key, updtData) {
    if (updtData) {
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.clusterForm.get('clstr_nm').setValue(updtData.clstr_nm);
      this.clusterForm.get('clustr_nu').setValue(updtData.clustr_nu);
      this.clusterForm.get('ulb_id').setValue(updtData.ulb_id);
      this.clusterForm.get('wrk_pckge_id').setValue(updtData.wrk_pckge_id);
    }
    else {
      this.clusterForm.get("ulb_id").setValue(this.ulbid);
      this.clusterForm.get('clstr_nm').setValue('');
      this.clusterForm.get('clustr_nu').setValue('');
      this.opLbl = "Add";
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
