import { Component, OnInit, ViewChild, Inject, ɵConsole } from '@angular/core';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { VehicleService } from '../vehicles.service';
import { MatSort, MatPaginator, MatTableDataSource, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ScheduleInterface } from './scheduleinterface.model';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ConfirmDialog } from '../vehicles.component';

import { UserService } from 'app/providers/user/user.serivce';
@Component({
  selector: 'app-scheduleinterface',
  templateUrl: './scheduleinterface.component.html',
  styleUrls: ['./scheduleinterface.component.scss']
})
export class ScheduleinterfaceComponent implements OnInit {
  ulbid;
  dataSource = new MatTableDataSource();
  empOpLbl: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tabGroup') tabGroup;
  private dialogRef: MatDialogRef<string>;
  strt_fnce_nm: any;
  end_fnce_nm: any;
  ulbLst: ScheduleInterface[] = [];
  clstLst: ScheduleInterface[] = [];
  clstRtsLst = [];
  AllMstrsData = [];
  trnsfrPntslst: ScheduleInterface[] = [];
  frmtplst: ScheduleInterface[] = [];
  fnceFltrForm: FormGroup;
  selectedulb = new FormControl();
  selectedClstr = new FormControl();
  ulbFltrOptns: Observable<ScheduleInterface[]>;
  clstrFltrOptns: Observable<ScheduleInterface[]>;
  frmfnceFltrOptns: Observable<ScheduleInterface[]>;
  tofnceFltrOptns: Observable<ScheduleInterface[]>;
  fnceFltrOptns: Observable<ScheduleInterface[]>[] = [];
  fncegrpFltrOptns: Observable<ScheduleInterface[]>;
  shwFnceFltr: boolean = false;
  shwrtDtls: boolean = false;
  routeForm: FormGroup;
  routLst = [];
  strt_fnce_id;
  end_fnce_id;
  routes: FormArray;
  routData;
  fncTypForm: FormGroup;
  addFncForm: FormGroup;
  dsblSvRtBtn: boolean = false;
  dsblgttrpdtlsBtn: boolean = false;
  shwTripDtls: any;
  tnt_id;
  updrtDtls;
  tpGrpLst: any;
  addfncLst: any;
  clsrt_id: any;
  dsbladdNwTrpBtn: boolean = true;
  shwErrMsg: boolean;
  errMsg: boolean;
  shwLdr: boolean;
  shwRouteMap: boolean;
  pushRslts;
  userPerm: { id: any; c_in: number; r_in: number; u_in: number; d_in: number; };
  strt_tm: Date = new Date();
  part:string;
  errFlag: boolean;
  index: any;
  tperrFlag: boolean;
  constructor(private geoService: GeoLocationsService, private vehicleService: VehicleService,
    private _fuseSidebarService: FuseSidebarService, private fb: FormBuilder,
    public dialog: MatDialog, private userService: UserService) {
  }
  ngOnInit() {
    // localStorage.setItem('usrDtls', JSON.stringify(data));
    // this.geoService.getUlbs((err, ulbLst) => {
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    
    // this.ulbLst = ulbLst.data;
    this.selectedulb.setValue("");
    // })
    this.fnceFltrForm = new FormGroup({
      fnce_id: new FormControl('', Validators.required),
      end_fnce_id: new FormControl('', Validators.required)
    });
    this.fncTypForm = new FormGroup({
      trp_typ_id: new FormControl('', Validators.required),
      trp_nu: new FormControl('', Validators.required),
      crw_id: new FormControl('', Validators.required),
      asrt_id: new FormControl('', Validators.required),
      strt_tm: new FormControl('', Validators.required),
      end_tm: new FormControl('', Validators.required),
      arvl_mrdn: new FormControl(''),
      dprtr_mrdn: new FormControl('')
    });

    this.addFncForm = new FormGroup({
      fnce_nm: new FormControl('', Validators.required),
      lat: new FormControl('', Validators.required),
      lng: new FormControl('', Validators.required),
      fnce_grp: new FormControl('', Validators.required)
    });
    this.routeForm = this.fb.group({
      routes: this.fb.array([], Validators.required)
    })
    this.ulbFltrOptns = this.selectedulb.valueChanges
      .pipe(
      startWith(''),
      map(value => this._topUlbfilter(value))
      );
    this.clstrFltrOptns = this.selectedClstr.valueChanges
      .pipe(
      startWith(''),
      map(value => this._topClstrfilter(value))
      );
    this.frmfnceFltrOptns = this.fnceFltrForm.get('fnce_id').valueChanges
      .pipe(
      startWith(''),
      map(value => this._strtFncfilter(value))
      );
    this.tofnceFltrOptns = this.fnceFltrForm.get('end_fnce_id').valueChanges
      .pipe(
      startWith(''),
      map(value => this._endFncfilter(value))
      );
    this.fncegrpFltrOptns = this.addFncForm.get('fnce_grp').valueChanges
      .pipe(
      startWith(''),
      map(value => this._fncegrpFncfilter(value))
      );
    this.userPerm = this.userService.CURRENT_PERM;
  }

  private _topUlbfilter(value): ScheduleInterface[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  private _topClstrfilter(value): ScheduleInterface[] {
    if (value) {
      return this.clstLst.filter(item => ((typeof value === 'string') ? item.clstr_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.clstr_nm.toLowerCase().indexOf(value['clstr_nm'].toLowerCase()) === 0));
    }
    return this.clstLst;
  }
  private _strtFncfilter(value): ScheduleInterface[] {
    if (value) {
      return this.frmtplst.filter(item => ((typeof value === 'string') ? item.fnce_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.fnce_nm.toLowerCase().indexOf(value['fnce_nm'].toLowerCase()) === 0));
    }
    return this.frmtplst;
  }
  private _endFncfilter(value): ScheduleInterface[] {
    if (value) {
      return this.frmtplst.filter(item => ((typeof value === 'string') ? item.fnce_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.fnce_nm.toLowerCase().indexOf(value['fnce_nm'].toLowerCase()) === 0));
    }
    return this.frmtplst;
  }
  private _Fncfilter(value): ScheduleInterface[] {
    if (value) {
      return this.trnsfrPntslst.filter(item => ((typeof value === 'string') ? item.fnce_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.fnce_nm.toLowerCase().indexOf(value['fnce_nm'].toLowerCase()) === 0));
    }
    return this.trnsfrPntslst;
  }
  private _fncegrpFncfilter(value): ScheduleInterface[] {
    if (value) {
      return this.tpGrpLst.filter(item => ((typeof value === 'string') ? item.fnce_grp_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.fnce_grp_nm.toLowerCase().indexOf(value['fnce_grp_nm'].toLowerCase()) === 0));
    }
    return this.tpGrpLst;
  }


  autocompleteUDisplayFn(ulb?: ScheduleInterface): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  autocompleteCDisplayFn(clster?: ScheduleInterface): string | undefined {
    return clster ? clster.clstr_nm : undefined;
  }
  frmFnceDisplayFn(fnce?: ScheduleInterface): string | undefined {
    return fnce ? fnce.fnce_nm : undefined;
  }
  toFnceDisplayFn(fnce?: ScheduleInterface): string | undefined {
    return fnce ? fnce.fnce_nm : undefined;
  }
  fnceGrpDisplayFn(fnce?: ScheduleInterface): string | undefined {
    return fnce ? fnce.fnce_grp_nm : undefined;
  }
  ulbSelected(evt) {
    this.tnt_id = evt.option.value.tnt_id;
    this.ulbid = evt.option.value.ulb_id
    this.vehicleService.clsrDtls(this.ulbid, (err, clstr_res) => {
      this.clstLst = clstr_res.data;
      this.selectedClstr.setValue("");
    });
  }
  clstrSelected(evt) {
    this.clsrt_id = evt.option.value.clsrt_id;
    this.dsbladdNwTrpBtn = false;
    this.getclstrWseRoutLst();
  }
  getclstrWseRoutLst() {
    this.vehicleService.clsrWseRtDtls(this.clsrt_id, (err, clstrRts_res) => {
      this.clstRtsLst = clstrRts_res.data;
      for (let r of this.clstRtsLst) {
        r.shwRtLbl = "Show Route";
      }
    });
  }
  frmfnceSelected(evt,indx) {
    this.pushRslts = [];
    this.strt_fnce_id = evt.option.value.fnce_id;
    this.strt_fnce_nm = evt.option.value.fnce_nm;
    if (indx == undefined)
      return;
    else{
      this.vehicleService.PcrRslts(this.strt_fnce_id, (err, pshRts_res) => {
        this.pushRslts = pshRts_res.data;
        let control = <FormArray>this.routeForm.controls.routes;
        control.controls[indx].get('fnce_id').value.pushRslts = this.pushRslts;
      });
    }
  }
  endfnceSelected(evt) {
    this.end_fnce_id = evt.option.value.fnce_id;
    this.end_fnce_nm = evt.option.value.fnce_nm;
    this.dsblgttrpdtlsBtn = false;
  }
  onUlbChange(ulb_id) {
    this.ulbid = ulb_id;
    this.vehicleService.clsrDtls(this.ulbid, (err, clstr_res) => {
      this.clstLst = clstr_res.data;
    });

  }
  onClsChange(cls_id) {
    this.clsrt_id = cls_id;
    this.getclstrWseRoutLst()
  }
  addNewTrp() {
    this.shwFnceFltr = true;
    this.dsblgttrpdtlsBtn = true;
    this.shwTripDtls = {};
    this.updrtDtls = {};
    this.routeForm.controls.routes = new FormArray([]);
    this.gettplst();
    if (this.clstRtsLst.length > 0) {
      for (let r of this.clstRtsLst) {
        r.shwRtLbl = "Show Route";
      }
    }
  }
  gettplst() {
    this.vehicleService.trnsfptsDtls(this.ulbid, (err, trns_res) => {
      this.trnsfrPntslst = [];
      this.frmtplst = [];
      for(let i of trns_res.data){
        if(i.fnce_ctgry_id == 32)
          this.trnsfrPntslst.push(i);
        else
          this.frmtplst.push(i);
      }
      // this.trnsfrPntslst = trns_res.data;
      this.fnceFltrForm.get('fnce_id').setValue('');
      this.fnceFltrForm.get('end_fnce_id').setValue('');
    });
  }
  getrtList(rtDtls) {
    this.routeForm.controls.routes = new FormArray([]);
    let control = <FormArray>this.routeForm.controls.routes;
    this.shwLdr = true;
    if (rtDtls.shwRtLbl == "Show Route") {
      this.shwrtDtls = true;
      this.shwFnceFltr = false;
    } else
      this.shwrtDtls = false;

    if (rtDtls.shwRtLbl == "Show Route") {
      for (let r of this.clstRtsLst) {
        if (r.trp_schde_id == rtDtls.trp_schde_id) {
          r.shwRtLbl = "Hide Route";
        } else {
          r.shwRtLbl = "Show Route";
        }
      }
      this.updrtDtls = rtDtls;
      this.getAllMstrData();
      this.gettplst();
      this.vehicleService.getTripDtls(rtDtls.trp_schde_id, (err, res) => {
        this.shwTripDtls = res.data;
        this.shwTripDtls.echSchdTrpFnceLst.forEach(t => {
          let arvl_ts = (t.fnce_arvl_tm.split(' '))[0];
          let arvl_mrdn;
          let dprtr_mrdn;
          if((t.fnce_arvl_tm.split(' '))[1])
          arvl_mrdn = (t.fnce_arvl_tm.split(' '))[1];
          else arvl_mrdn = '';
          let dprtr_ts = (t.fnce_dprte_tm.split(' '))[0];
          if((t.fnce_arvl_tm.split(' '))[1])
          dprtr_mrdn = (t.fnce_dprte_tm.split(' '))[1];
          else dprtr_mrdn = '';
        this.vehicleService.PcrRslts(t.fnce_id, (err, res) => {
          this.pushRslts = res.data;
          control.push(this.fb.group({
            fnce_id: { fnce_id: t.fnce_id, fnce_nm: t.fnce_nm,pushRslts: this.pushRslts},
            arvl_ts: arvl_ts,
            arvl_mrdn: arvl_mrdn,
            pcr: t.pcr,
            pushRslts: t.pushRslts,
            dprtr_ts: dprtr_ts,
            dprtr_mrdn: dprtr_mrdn,
          }))
        });
        })
        let arvl_ts = (this.shwTripDtls.echSchdTrpAssLst[0].strt_tm.split(' '))[0];
        let arvl_mrdn = (this.shwTripDtls.echSchdTrpAssLst[0].strt_tm.split(' '))[1];
        let dprtr_ts = (this.shwTripDtls.echSchdTrpAssLst[0].end_tm.split(' '))[0];
        let dprtr_mrdn = (this.shwTripDtls.echSchdTrpAssLst[0].end_tm.split(' '))[1];
        this.fncTypForm.get('trp_typ_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].trp_typId);
        this.fncTypForm.get('trp_nu').setValue(this.shwTripDtls.echSchdTrpAssLst[0].trips);
        this.fncTypForm.get('crw_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].crw_id);
        this.fncTypForm.get('asrt_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].asrt_id);
        this.fncTypForm.get('strt_tm').setValue(arvl_ts);
        this.fncTypForm.get('arvl_mrdn').setValue(arvl_mrdn);
        this.fncTypForm.get('end_tm').setValue(dprtr_ts);
        this.fncTypForm.get('dprtr_mrdn').setValue(dprtr_mrdn);
        this.shwLdr = false;
        this.dsblSvRtBtn = false;
        this.shwFnceFltr = false;
      });
      this.addfncLst = control.controls;
    } else {
      for (let r of this.clstRtsLst) {
        if (r.trp_schde_id == rtDtls.trp_schde_id)
          r.shwRtLbl = "Show Route";
      }
      this.shwLdr = false;
    }
  }


  delete(s) {
   console.log(s);
   const dialogRef = this.dialog.open(SecondaryConfirmDialog, {
    width: '500px',
    data: { id: s, entityname: 'Trip', cnfrmflag: true, successFlag: false, operation: "Deleted", dtls: s.trp_schde_id}
  });
  dialogRef.afterClosed().subscribe(() => {
    this.getclstrWseRoutLst();
  })
    // const dialogRef = this.dialog.open(ConfirmDialog, {
    //   width: '350px',
    //   data: "Do you confirm the deletion of this data?",
    // });
    // console.log("result");
    // this.vehicleService.dltsndry(s, (err, res) => {
    //   this. getclstrWseRoutLst();
    // })   
  }
  getmapRoute(trpDtls) {
    this.shwrtDtls = false;
    this.shwRouteMap = true;
  }
  opensideBar(key, event: any) {
    this.shwErrMsg = false;
    this.getAllMstrData();
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  getTripDetails() {
    this.shwrtDtls = true;
    this.dsblgttrpdtlsBtn = true;
    this.dsblSvRtBtn = false;
    this.getAllMstrData();
    this.routeForm.controls.routes = new FormArray([]);
    let control = <FormArray>this.routeForm.controls.routes;
    this.vehicleService.PcrRslts(this.strt_fnce_id, (err, pshRts_res) => {
      this.pushRslts = pshRts_res.data;
      control.push(
        this.fb.group({
          fnce_id: { fnce_id: this.strt_fnce_id, fnce_nm: this.strt_fnce_nm, pushRslts:this.pushRslts},
          arvl_ts: [''],
          arvl_mrdn: ['AM'],
          pcr:[[]],
          pushRslts:[[]],
          dprtr_ts: [''],
          dprtr_mrdn: ['AM'],
        })
      )
      this.ManageFenceControl(0);
    });
    this.vehicleService.PcrRslts(this.end_fnce_id, (err, pshRts_res) => {
      this.pushRslts = pshRts_res.data;
      control.push(
        this.fb.group({
          fnce_id: { fnce_id: this.end_fnce_id, fnce_nm: this.end_fnce_nm,pushRslts: this.pushRslts},
          arvl_ts: [''],
          arvl_mrdn: ['AM'],
          pcr:[[]],
          pushRslts:[[]],
          dprtr_ts: [''],
          dprtr_mrdn: ['AM'],
        })
      )
      this.ManageFenceControl(1);
      this.addfncLst = control.controls;
    });
    // this.fncTypForm.get('strt_tm').setValue('00:00');
    // this.fncTypForm.get('end_tm').setValue('00:00');
    this.fncTypForm.get('arvl_mrdn').setValue('AM');
    this.fncTypForm.get('dprtr_mrdn').setValue('AM');
  }
  getAllMstrData() {
    this.vehicleService.getAllMstrsData(this.ulbid, (err, allMstrs_res) => {
      this.AllMstrsData = allMstrs_res.data;
      this.tpGrpLst = allMstrs_res.data.transfrPointLst_rtms;
      this.addFncForm.get('fnce_grp').setValue('');
    });
  }
  addRoute(indx, controls): void {
    if (!this.dsblSvRtBtn) {
      this.addfncLst = controls.controls;
      let control = <FormArray>this.routeForm.controls.routes;
      control.controls.splice(indx + 1, 0,
        this.fb.group({
          fnce_id: [{}],
          arvl_ts: [''],
          arvl_mrdn: ['AM'],
          pcr:[[]],
          pushRslts:[[]],
          dprtr_ts: [''],
          dprtr_mrdn: ['AM'],
        }));
      this.ManageFenceControl(indx + 1);
    } else return;
  }
  deleteRoute(control, index) {
    control.removeAt(index);
    this.addfncLst = control.controls
  }
  savetripRoute() {
    if (this.routeForm.controls.routes.status == 'INVALID')
      this.shwErrMsg = true;
    if (this.fncTypForm.status == 'VALID' && this.routeForm.controls.routes.status == 'VALID') {
      this.errMsg = false;
      let flag = false;
      if(!this.updrtDtls.rte_id || !this.updrtDtls.trp_schde_id){
        this.clstRtsLst.forEach(val => {
          if (val.trp_nm == this.fncTypForm.value.trp_nu) {
            flag = true;
          }
        })
        if (flag) {
          this.errMsg = true;
          return;
        }
      }
      
      let strt_hour = parseInt((this.fncTypForm.value.strt_tm.split(':'))[0]);
      let strt_min = parseInt((this.fncTypForm.value.strt_tm.split(':'))[1]);
      let end_hour = parseInt((this.fncTypForm.value.end_tm.split(':'))[0]);
      let end_min = parseInt((this.fncTypForm.value.end_tm.split(':'))[1]);
      this.routData = {
        rteDtls: {
          "rte_id": "",
          "rte_ctgry_id": "",
          "rte_nm": this.strt_fnce_nm + "-" + this.end_fnce_nm,
          "strt_fnce_id": this.strt_fnce_id,
          "end_fnce_id": this.end_fnce_id,
          "tnt_id": this.tnt_id

        },
        asrtLst: {
          "asrt_id": this.fncTypForm.value.asrt_id,
          "crw_id": this.fncTypForm.value.crw_id,
          "strt_tm": this.fncTypForm.value.arvl_mrdn == "PM" ?(strt_hour+12+":"+((strt_min+'').length == 1 ? '0'+strt_min : strt_min)):this.fncTypForm.value.strt_tm,
          "end_tm": this.fncTypForm.value.dprtr_mrdn == "PM" ?(end_hour+12+":"+((end_min+'').length == 1 ? '0'+end_min : end_min)):this.fncTypForm.value.end_tm,
          "trips": this.fncTypForm.value.trp_nu,
          "trp_typId": this.fncTypForm.value.trp_typ_id,
          "trp_schde_id": this.updrtDtls.trp_schde_id,
          "tnt_id": this.tnt_id
        },
        fnscLst: []
      }
      var count = 0;
      for (let r of this.addfncLst) {
        count++;
        let arvl_hour = parseInt((r.value.arvl_ts.split(':'))[0]);
        let arvl_min = parseInt((r.value.arvl_ts.split(':'))[1]);
        let dprtr_hour = parseInt((r.value.dprtr_ts.split(':'))[0]);
        let dprtr_min = parseInt((r.value.dprtr_ts.split(':'))[1]);
        this.routData.fnscLst.push({
          "sqncNo": count,
          "fnce_nm": r.value.fnce_id.fnce_nm,
          "fnce_id": r.value.fnce_id.fnce_id,
          "arvlTm" : r.value.arvl_mrdn == "PM" ?(arvl_hour+12+":"+((arvl_min+'').length == 1 ? '0'+arvl_min : arvl_min)):r.value.arvl_ts,
          "DptrTm" : r.value.dprtr_mrdn == "PM" ?(dprtr_hour+12+":"+((dprtr_min+'').length == 1 ? '0'+dprtr_min : dprtr_min)):r.value.dprtr_ts,
          "cmtveTrvlTm": "",
          "avrgTrvlDstn": "",
          "avrgTrvlTm": "",
          "StpTm": "",
          "tnt_id": this.tnt_id,
          'pcr':r.value.pcr
        })
      }
      let operation;
      if (this.updrtDtls && this.updrtDtls.rte_id && this.updrtDtls.trp_schde_id) {
        operation = 'Updated';
        this.routData.rteDtls = {
          "rte_id": this.updrtDtls.rte_id,
          "rte_ctgry_id": 1,
          "rte_nm": this.updrtDtls.rte_nm,
          "strt_fnce_id": this.updrtDtls.strt_fnce_id,
          "end_fnce_id": this.updrtDtls.end_fnce_id,
          "tnt_id": this.tnt_id
        }
      } else {
        operation = 'Created';
        this.routData.rteDtls = {
          "rte_id": "",
          "rte_ctgry_id": "",
          "rte_nm": this.strt_fnce_nm + "-" + this.end_fnce_nm,
          "strt_fnce_id": this.strt_fnce_id,
          "end_fnce_id": this.end_fnce_id,
          "tnt_id": this.tnt_id
        }
      }
      console.log(this.routData);
      this.vehicleService.addTrips(this.routData, (err, res) => {
        if (res.status == 200) {
          this.dsblSvRtBtn = true;
          const dialogRef = this.dialog.open(SecondaryConfirmDialog, {
            width: '500px',
            data: { cnfrmflag: false, successFlag: true, operation: operation }
          });
          this.getclstrWseRoutLst();
        }
      })
    } else this.shwErrMsg = true;
  }
  resetAllForms() {
    this.fnceFltrForm.reset();
    this.fncTypForm.reset();
    this.routeForm.reset();
    this.shwrtDtls = false;
    this.dsblgttrpdtlsBtn = true;
    this.strt_fnce_id = null;
    this.strt_fnce_nm = '';
    this.end_fnce_id = null;
    this.end_fnce_nm = '';
  }
  resetrouteForm() {
    this.routeForm.reset();
    this.dsblSvRtBtn = false;
    this.routeForm.controls.routes = new FormArray([]);
    let control = <FormArray>this.routeForm.controls.routes;
    if (this.updrtDtls && this.updrtDtls.rte_id && this.updrtDtls.trp_schde_id) {
      this.shwTripDtls.echSchdTrpFnceLst.forEach(t => {
        this.vehicleService.PcrRslts(t.fnce_id, (err, res) => {
          this.pushRslts = res.data;
          control.push(this.fb.group({
            fnce_id: { fnce_id: t.fnce_id, fnce_nm: t.fnce_nm, pushRslts:this.pushRslts},
            arvl_ts: t.fnce_arvl_tm,
            pcr: t.pcr,
            pushRslts: t.pushRslts,
            dprtr_ts: t.fnce_dprte_tm,
          }))
        });
      })
      this.fncTypForm.get('trp_typ_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].trp_typId);
      this.fncTypForm.get('trp_nu').setValue(this.shwTripDtls.echSchdTrpAssLst[0].trips);
      this.fncTypForm.get('crw_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].crw_id);
      this.fncTypForm.get('asrt_id').setValue(this.shwTripDtls.echSchdTrpAssLst[0].asrt_id);
      this.fncTypForm.get('strt_tm').setValue(this.shwTripDtls.echSchdTrpAssLst[0].strt_tm);
      this.fncTypForm.get('end_tm').setValue(this.shwTripDtls.echSchdTrpAssLst[0].end_tm);
      this.dsblSvRtBtn = false;
      this.shwFnceFltr = false;
    }
    else {
  
      this.vehicleService.PcrRslts(this.strt_fnce_id, (err, pshRts_res) => {
        this.pushRslts = pshRts_res.data;
        control.push(
          this.fb.group({
            fnce_id: { fnce_id: this.strt_fnce_id, fnce_nm: this.strt_fnce_nm, pushRslts:this.pushRslts},
            arvl_ts: [''],
            arvl_mrdn: ['AM'],
            pcr:[[]],
            pushRslts:[[]],
            dprtr_ts: [''],
            dprtr_mrdn: ['AM'],
          })
        )
      });
      this.vehicleService.PcrRslts(this.end_fnce_id, (err, pshRts_res) => {
        this.pushRslts = pshRts_res.data;
        control.push(
          this.fb.group({
            fnce_id: { fnce_id: this.end_fnce_id, fnce_nm: this.end_fnce_nm,pushRslts: this.pushRslts},
            arvl_ts: [''],
            arvl_mrdn: ['AM'],
            pcr:[[]],
            pushRslts:[[]],
            dprtr_ts: [''],
            dprtr_mrdn: ['AM'],
          })
        )
      });
    }
    this.addfncLst = control.controls;
  }
  addNewFnc() {
    if (this.addFncForm.status == 'VALID') {
      let postData = {
        fncs_nm: this.addFncForm.value.fnce_nm,
        fncsGrpId: this.addFncForm.value.fnce_grp.fnce_grp_id,
        lat: this.addFncForm.value.lat,
        lng: this.addFncForm.value.lng,
        tnt_id: this.tnt_id
      }
      this.vehicleService.addFence(postData, (err, res) => {
        if (res.status == 200) {
          this.dsblSvRtBtn = true;
          const dialogRef = this.dialog.open(SecondaryConfirmDialog, {
            width: '500px',
            data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
          });
          this.gettplst();
        }
      })
    } else this.shwErrMsg = true;
  }
  MoveUp(indx, data) {
    let control = <FormArray>this.routeForm.controls.routes;
    control.removeAt(indx);
    control.controls.splice(indx - 1, 0,
      this.fb.group({
        fnce_id: data.value.fnce_id,
        arvl_ts: data.value.arvl_ts,
        pcr: data.value.pcr,
        pushRslts: data.value.pushRslts,
        dprtr_ts: data.value.dprtr_ts,
      }));
    this.addfncLst = control.controls;
  }
  MoveDown(indx, data) {
    let control = <FormArray>this.routeForm.controls.routes;
    control.removeAt(indx);
    control.controls.splice(indx + 1, 0,
      this.fb.group({
        fnce_id: data.value.fnce_id,
        arvl_ts: data.value.arvl_ts,
        pcr: data.value.pcr,
        pushRslts: data.value.pushRslts,
        dprtr_ts: data.value.dprtr_ts,
      }));
    this.addfncLst = control.controls;
  }
  ManageFenceControl(index: number) {
    var arrayControl = <FormArray>this.routeForm.controls.routes;
    this.fnceFltrOptns[index] = arrayControl.at(index).get('fnce_id').valueChanges
      .pipe(
      startWith(''),
      map(value => this._Fncfilter(value))
      );
  }
  validateTimeformate(time,indx,ind){
    let timeData ={h1:'', h2:'', m1:'', m2:'', mr1:'', mr2:''};
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    min = (min+'').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour+'').length == 1 ? `0${hour}` : hour;
    if(indx != undefined){
      this.index = indx;
      let control = <FormArray>this.routeForm.controls.routes;
      if(ind=='arvl'){
        control.controls[indx].get('arvl_ts').setValue(hour+":"+min);
      }else if(ind =='dprtr'){
        control.controls[indx].get('dprtr_ts').setValue(hour+":"+min);
      }
      timeData.h1 = (control.controls[indx].get('arvl_ts').value.split(':'))[0];
      timeData.h2 = (control.controls[indx].get('dprtr_ts').value.split(':'))[0];
      timeData.m1 = (control.controls[indx].get('arvl_ts').value.split(':'))[1];
      timeData.m2 = (control.controls[indx].get('dprtr_ts').value.split(':'))[1];
      timeData.mr1 = (control.controls[indx].get('arvl_mrdn').value);
      timeData.mr2 = (control.controls[indx].get('dprtr_mrdn').value);
    }else{
      if(ind=='arvl'){
        this.fncTypForm.get('strt_tm').setValue(hour+":"+min);
      }else if(ind =='dprtr'){
        this.fncTypForm.get('end_tm').setValue(hour+":"+min);
      }
      timeData.h1 = (this.fncTypForm.get('strt_tm').value.split(':'))[0];
      timeData.h2 = (this.fncTypForm.get('end_tm').value.split(':'))[0];
      timeData.m1 = (this.fncTypForm.get('strt_tm').value.split(':'))[1];
      timeData.m2 = (this.fncTypForm.get('end_tm').value.split(':'))[1];
      timeData.mr1 = (this.fncTypForm.get('arvl_mrdn').value);
      timeData.mr2 = (this.fncTypForm.get('dprtr_mrdn').value);
    }
    this.validMinMaxTimimgs(timeData,indx);
  }
  toggleMrdn(mrdn,indx,ind){
    let timeData ={h1:'', h2:'', m1:'', m2:'', mr1:'', mr2:''};
    let part = (mrdn == 'AM') ? 'PM' : 'AM'
    if(indx != undefined){
    this.index = indx;
    let control = <FormArray>this.routeForm.controls.routes;
    if(ind=='arvl')
    control.controls[indx].get('arvl_mrdn').setValue(part);
    else if(ind =='dprtr')
    control.controls[indx].get('dprtr_mrdn').setValue(part);  
    timeData.h1 = (control.controls[indx].get('arvl_ts').value.split(':'))[0];
    timeData.h2 = (control.controls[indx].get('dprtr_ts').value.split(':'))[0];
    timeData.m1 = (control.controls[indx].get('arvl_ts').value.split(':'))[1];
    timeData.m2 = (control.controls[indx].get('dprtr_ts').value.split(':'))[1];
    timeData.mr1 = (control.controls[indx].get('arvl_mrdn').value);
    timeData.mr2 = (control.controls[indx].get('dprtr_mrdn').value);
    }else{
      if(ind=='arvl'){
        this.fncTypForm.get('arvl_mrdn').setValue(part);
      }else if(ind =='dprtr'){
        this.fncTypForm.get('dprtr_mrdn').setValue(part);
      }
      timeData.h1 = (this.fncTypForm.get('strt_tm').value.split(':'))[0];
      timeData.h2 = (this.fncTypForm.get('end_tm').value.split(':'))[0];
      timeData.m1 = (this.fncTypForm.get('strt_tm').value.split(':'))[1];
      timeData.m2 = (this.fncTypForm.get('end_tm').value.split(':'))[1];
      timeData.mr1 = (this.fncTypForm.get('arvl_mrdn').value);
      timeData.mr2 = (this.fncTypForm.get('dprtr_mrdn').value);
    }
    this.validMinMaxTimimgs(timeData,indx);
  }
  validMinMaxTimimgs(timeData,indx){
    let errFlag = false;
    if(timeData.h1>timeData.h2){
      if(timeData.mr1 ==  timeData.mr2 || timeData.mr1=='PM' && timeData.mr2 == 'AM'){
        errFlag = true;
      }
      else if(timeData.mr1=='AM' && timeData.mr2 == 'PM')
      errFlag = false;
    }
    else if(timeData.h1<timeData.h2){
      if(timeData.mr1 ==  timeData.mr2 || timeData.mr1=='AM' && timeData.mr2 == 'PM')
      errFlag = false;
      else if(timeData.mr1=='PM' && timeData.mr2 == 'AM')
      errFlag = true;
    }else if(timeData.h1==timeData.h2){
      if(timeData.m1<timeData.m2){
        if(timeData.mr1 ==  timeData.mr2)
        errFlag = false;
        else if(timeData.mr1=='PM' && timeData.mr2 == 'AM')
          errFlag = true;
        else if(timeData.mr1=='AM' && timeData.mr2 == 'PM')
          errFlag = false;
      }else if(timeData.m1>timeData.m2){
        if(timeData.mr1 ==  timeData.mr2)
        errFlag = true;
        else if(timeData.mr1=='PM' && timeData.mr2 == 'AM')
          errFlag = true;
        else if(timeData.mr1=='AM' && timeData.mr2 == 'PM')
          errFlag = false;
      }
    }
    if(indx != undefined){ 
      this.errFlag = errFlag;
      this.tperrFlag = false;
    }else{
      this.tperrFlag = errFlag;
      this.errFlag = false;
    }
  }
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: '../../../main/confirm-dialog.html'
})
export class SecondaryConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<SecondaryConfirmDialog>,
    public vehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA) public data: { id: '', entityname: '', cnfrmflag: false, successFlag: false, operation: '', dtls: '' }) {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(id, entityname, cnfrmflag, successFlag, operation, dtls): void {
    switch (entityname) {
      case 'Trip': {
        this.vehicleService.dltsndry(id, (err, res) => {
          if (res.status === 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
    }

  }
  getColor(operation) {
    (2)
    switch (operation) {
      case 'Created':
        return 'green';
      case 'Assigned':
        return 'green';
      case 'Updated':
        return '#ffc107';
      case 'Disabled':
        return '#ffc107';
      case 'Removed':
        return '#red';
      case 'Deleted':
        return 'red';
    }
  }
}