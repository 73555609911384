import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GeoLocationsService } from '../geo-locations.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Groups } from './groups.model';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ulb } from '../ulb/ulb.model';
import { Workpackage } from '../workpackage/workpackage';
import { UserService } from 'app/providers/user/user.serivce';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  Micropockets: Groups[] = [];
  displayedColumns: string[] = ['sno','emp_grp_nm', 'ulb_nm','clstr_nm','emp_grp_ctgry_nm','actions'];
  dataSource = new MatTableDataSource<Groups>();

  @Input() ulbid: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  grpForm: FormGroup;

  ulb_id;
  wrk_pckge_id:number = 0;
  clsrt_id:number  = 0;
  emp_grp_ctgry_id:number  = 0;

  ulbLst: Ulb[] = [];
  wrkpckgsLst: Workpackage[];
  clstrList = null;
  grpctgryLst=null;

  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  userPerm: any = {};
  errMsg = false;
  shwLdr: boolean = false;
  noData;
  constructor(private geoService: GeoLocationsService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog, private userService:UserService) {

  }

  ngOnInit() {    
    this.ulb_id = this.ulbid;
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.grpForm = new FormGroup({
      emp_grp_nm: new FormControl('', Validators.required),
      ulb_id: new FormControl('', Validators.required),
      // clsrt_id: new FormControl('', Validators.required),
      emp_grp_ctgry_id: new FormControl('', Validators.required)
    });
    this.userPerm = this.userService.CURRENT_PERM;
    this.grpForm.get("ulb_id").setValue(this.ulbid);
    this.onUlbChange();
    this.geoLocationGroup();
  }
  ngOnChanges(changes: any) {
    if (changes && changes.ulbid){
      this.ulb_id=changes.ulbid.currentValue;
      this.geoLocationGroup();
      this.onUlbChange();
    }
  }
  onUlbChange() {
    this.geoService.getEmpGropCtgry(this.ulb_id,(err, res) => {
      this.grpctgryLst = res.data;
    });
    this.geoService.getClusterDetails(this.ulb_id, this.wrk_pckge_id, (err, clstr_res) => {
      this.clstrList = clstr_res.data;
    });
  }
  geoLocationGroup() {
    this.dataSource.data = [];
    this.shwLdr = true;
    this.geoService.getGropsData(this.ulbid, this.clsrt_id, (err, grp_res) => {
      this.dataSource.data = grp_res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }

  saveData() {
    if (this.grpForm.status == 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt = this.grpForm.value;
    console.log(this.updtDt);
    let flag = false;
    this.dataSource.data.forEach(val => {
      if(val.emp_grp_nm == this.updtDt.emp_grp_nm){
        flag = true;
      }
    })
    if(flag){
      this.errMsg = true;
      return;
    }
    this.geoService.addEmployeeGroup(this.updtDt, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation:"Created" }
        });
        this.clsrt_id=0;
        this.geoLocationGroup();
    }
    })
  }
  updateData() {
    if (this.grpForm.status == 'INVALID') {
      return false;
    }
    console.log(this.grpForm.value.emp_grp_ctgry_id);
    this.errMsg = false;
    this.updtDt.ulb_id = this.grpForm.value.ulb_id;
    // this.updtDt.clsrt_id = this.grpForm.value.clsrt_id;
    this.updtDt.emp_grp_ctgry_id = this.grpForm.value.emp_grp_ctgry_id;

    let flag = false;
    this.dataSource.data.forEach(val => {
      if(val.emp_grp_nm == this.updtDt.emp_grp_nm){
        if(val.emp_grp_id != this.updtDt.emp_grp_id){
          flag = true;
        }
      }
    })
    if(flag){
      this.errMsg = true;
      return;
    }
    console.log(this.updtDt);
    this.geoService.updtEmployeeGroup(this.updtDt, (err, res) => {
      if (res.status == 200) {
       const dialogRef = this.dialog.open(ConfirmDialog, {
         width: '500px',
         data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
       });
       this.clsrt_id=0;
       this.geoLocationGroup();
   }
    })
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'EmployeeGroup', cnfrmflag: true, successFlag: false,  operation:"Deleted",dtls:nm}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.clsrt_id=0;
      this.geoLocationGroup();
    })
  }

  resetForm() {
    this.grpForm.reset();
  }
  opensideBar(key, updtData, event: any) {
    if (updtData) {
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.grpForm.get('emp_grp_nm').setValue(updtData.emp_grp_nm);
      this.grpForm.get('ulb_id').setValue(updtData.ulb_id);
      // this.grpForm.get('clsrt_id').setValue(updtData.clsrt_id);
      this.grpForm.get('emp_grp_ctgry_id').setValue(updtData.emp_grp_ctgry_id);

      this.ulb_id = updtData.ulb_id;
      this.emp_grp_ctgry_id = updtData.emp_grp_ctgry_id;

      this.onUlbChange();
    }
    else {
      this.grpForm.get('ulb_id').setValue(this.ulbid);
      this.onUlbChange();
      this.grpForm.get('emp_grp_nm').setValue('');
      // this.grpForm.get('clsrt_id').setValue('');
      this.grpForm.get('emp_grp_ctgry_id').setValue('');

      this.opLbl = "Add";
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

}


