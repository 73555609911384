
import { Component, OnInit, ViewChild, Inject, ɵConsole } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Employees } from './employees.model';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { EmployeesService } from 'app/main/employees/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GeoLocationsService } from '../geo-locations/geo-locations.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { UserService } from 'app/providers/user/user.serivce';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'emp_nm', 'emp_nu', 'mble_nu', 'adhr_nu', 'dsgn_nm', 'emp_ctgry_nm', 'empnt_type_nm', 'ulb_nm', 'clstr_nm', 'mcrpt_nm','emp_grp_ctgry_nm','emp_grp_nm', 'actions'];
  sbstitEmpColumns: string[] = ['sno', 'sbste_emp_nm', 'mble_nu', 'eff_dt', 'exp_dt', 'actions'];
  sbstitEmpLst = new MatTableDataSource<Employees>(); mble_nu: any;
  sEmpOpsLbl: string;
  shwLdr: boolean = false;
  userPerm;
  dataSource = new MatTableDataSource<Employees>();
  empOpLbl: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tabGroup') tabGroup;
  private dialogRef: MatDialogRef<string>;
  empForm: FormGroup;
  sempForm: FormGroup;
  srvcPrvdrs: Employees[];
  dsbladdNwTrpBtn: boolean = true;
  ulbLst = [];
  wrkpckgsLst = [];
  clstrLst = [];
  mcrptLst = [];
  empdsgns = [];
  empCtgries = [];
  empTypes = [];
  ulb_id = 0;
  wrk_pckge_id = 0;
  clsrt_id = 0;
  mcrpt_id = 0;
  slctdTypId;
  slctdDsgnId;
  adhr_num;
  hideClstr: boolean = false;
  hidemp: boolean = false;
  assignedEmpTab: boolean = false;
  unassignedEmpTab: boolean = false;
  unapprovedEmpTab: boolean = false;
  emplist: Employees[];
  bnkLst;
  isExcelDwnLd: boolean = false;
  excelDataHeaders;
  excelFileNm;
  pndngEnrolmentTab: boolean = false;
  pndngEnrolment: any = [];

  headerForm: FormGroup;
  ulbs = [];
  wrkpckgs = [];
  clstrs = [];
  mcrpts = [];
  ulbid;
  dobvents: string[] = [];
  dob_dt;
  eff_dt = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  exp_dt;
  emp_id: number;
  emp_nm: string;
  errMsg: boolean;
  pattern: string | RegExp
  maxlength: string;
  namePattern = "^[a-zA-Z0-9_ ]{3,50}$";
  mobnumPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  adhrnumPattern = "^((\\+91-?)|0)?[0-9]{12}$";
  bnkacnumPattern = "^((\\+91-?)|0)?[0-9]{6,18}$";
  ifscPattern = "^[A-Za-z]{4}[a-zA-Z0-9]{7}$";
  exstsSEmpSts: string;
  showAsgnSemp: boolean;
  expdterrMsg;
  noData;
  unaprvdEmplist = [];
  curdate = new Date();
  curdateFrmt = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
  ulbtopFltrOptions: Observable<Employees[]>;
  mptopFltrOptions: Observable<Employees[]>;
  mpFltrOptions: Observable<Employees[]>;
  selectedmp: any;
  ulbAttndCnts: any;
  asgnLst: any;
  smleunasgnd: any[];
  EmployeeExclData: any = [];
  ulb_nm;
  ulbNm;
  exclData: any = [];
  sts: any;
  empNM;
  empID;
  empGrpLst: any = [];
  empGrpctgryLst: any = [];
  constructor(private EmployeesService: EmployeesService, private _fuseSidebarService: FuseSidebarService, private userService: UserService,
    private locationService: GeoLocationsService, public dialog: MatDialog, public datepipe: DatePipe) { }

  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulbid = this.ulbLst[0].ulb_id;
    this.ulbNm = this.ulbLst[0].ulb_nm;
    // console.log(this.ulbNm);

    // console.log(this.ulbLst[0]);
    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });

    this.empForm = new FormGroup({
      emp_id: new FormControl(),
      emp_nm: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
      fthr_nm: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
      emp_nu: new FormControl(''),
      mble_nu: new FormControl('', [Validators.required, Validators.pattern(this.mobnumPattern)]),
      adhr_nu: new FormControl('', [Validators.required, Validators.pattern(this.adhrnumPattern)]),
      dsgn_id: new FormControl('', Validators.required),
      gndr_nm: new FormControl('', Validators.required),
      dob_dt: new FormControl(''),
      emp_ctgry_id: new FormControl('', Validators.required),
      empnt_type_id: new FormControl('', Validators.required),
      // ulb_id: new FormControl('', Validators.required),
      wrk_pckge_id: new FormControl('', Validators.required),
      clsrt_id: new FormControl(''),
      mcrpt_id: new FormControl(''),
      emp_grp_ctgry_id: new FormControl(''),
      emp_grp_id: new FormControl(''),
      bnk_acnt_nu: new FormControl('', [Validators.required, Validators.pattern(this.bnkacnumPattern)]),
      ifsc_cd: new FormControl('', [Validators.required, Validators.pattern(this.ifscPattern)]),
      bnk_id: new FormControl('', Validators.required),
      brnch_nm: new FormControl('', Validators.required),
      pf_nu: new FormControl('')
    });
    // *************************filter dropdowns form***********************
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required),
      selectedWP: new FormControl('', Validators.required),
      selectedClstr: new FormControl('', Validators.required),
      selectedMP: new FormControl('', Validators.required)
    });
    this.wrkpckgs = [{ sno: 0, wrk_pckge_id: 0, wrk_pckge_nm: "ALL" }];
    this.clstrs = [{ sno: 0, clsrt_id: 0, clstr_nm: "ALL" }];
    this.mcrpts = [{ sno: 0, mcrpt_id: 0, mcrpt_nm: "ALL" }];
    this.ulbs = [];
    this.headerForm.get("selectedUlb").setValue(this.ulb_id);
    this.headerForm.get("selectedWP").setValue(0);
    this.headerForm.get("selectedClstr").setValue(0);
    this.headerForm.get("selectedMP").setValue(0);


    // *************************Substitution Employee form***********************
    this.sempForm = new FormGroup({
      sbste_emp_id: new FormControl(),
      sbste_emp_nm: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
      mble_nu: new FormControl('', [Validators.required, Validators.pattern(this.mobnumPattern)]),
      eff_dt: new FormControl('', Validators.required),
      exp_dt: new FormControl('')
    });
    this.userPerm = this.userService.CURRENT_PERM;
    this.geoLocationulbs();
    this.getEmpData();

    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );


    this.mptopFltrOptions = this.headerForm.get('selectedMP').valueChanges
      .pipe(
        startWith(''),
        map(value => this._mptopfilter(value))
      );
    this.mpFltrOptions = this.empForm.get('mcrpt_id').valueChanges
      .pipe(
        startWith(''),
        map(value => this._mpfilter(value))
      );
  }

  actopudisplayFn(ulb?: Employees): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Employees[] {
    if (value) {
      return this.ulbs.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbs;
  }
  topulbSelected(evt) {
    this.ulb_id = evt.option.value.ulb_id;
    this.geoLocationWorkPackage(this.ulb_id);
  }

  actopmpdisplayFn(ulb?: Employees): string | undefined {
    return ulb ? ulb.mcrpt_nm : undefined;
  }
  private _mptopfilter(value): Employees[] {
    if (value) {
      return this.mcrpts.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
    }
    return this.mcrpts;
  }
  private _mpfilter(value): Employees[] {
    if (value) {
      return this.mcrptLst.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
    }
    return this.mcrptLst;
  }
  getEmpData() {
    this.dataSource.data = [];
    this.shwLdr = true;
    // let postData = {ulb_id:this.ulb_id,wrk_pckge_id:this.wrk_pckge_id,clsrt_id:this.clsrt_id,mcrpt_id:this.mcrpt_id}

    let postData = {
      ulb_id: this.headerForm.value.selectedUlb.ulb_id,
      wrk_pckge_id: this.headerForm.value.selectedWP,
      clsrt_id: this.headerForm.value.selectedClstr,
      mcrpt_id: this.headerForm.value.selectedMP,
      sts: 1
    }

    this.EmployeesService.getasgndEmployees(postData, (err, emp_res) => {
      this.dataSource.filter = '';
      this.shwLdr = false;
      this.emplist = emp_res.data;
      this.getCrddataLst();
      this.getAsgnedEmpLst();
      this.tabChanged();
    });



  }

  public tabChanged(): void {
    // this.exclData=[];

    if (this.tabGroup.selectedIndex === 0) {
      this.assignedEmpTab = true; this.unassignedEmpTab = false; this.unapprovedEmpTab = false; this.pndngEnrolmentTab = false;
      this.getAsgnedEmpLst();
      this.exclData = this.emplist;
      this.sts = "Assigned employees";
    } else if (this.tabGroup.selectedIndex === 1) {
      this.unassignedEmpTab = false; this.assignedEmpTab = true; this.unapprovedEmpTab = false; this.pndngEnrolmentTab = false;
      this.getUnasgnedEmpLst();
      this.exclData = this.dataSource.data;
      this.sts = "Unassigned employees";

    }
    else if (this.tabGroup.selectedIndex === 2) {
      this.unapprovedEmpTab = true; this.assignedEmpTab = false; this.unassignedEmpTab = false; this.pndngEnrolmentTab = false;
      this.getUnapprovedEmpLst();
      this.exclData = this.unaprvdEmplist;
      this.sts = "Unapproved employees";

    }
    else if (this.tabGroup.selectedIndex === 3) {
      this.unapprovedEmpTab = false; this.assignedEmpTab = false; this.unassignedEmpTab = false; this.pndngEnrolmentTab = true;
      this.getapprovedntEnrolled();
      this.exclData = this.pndngEnrolment;
      this.sts = "Pending Enrollment employees";

    }
    this.isExcelDwnLd = false;
    this.EmployeeExclData = [];
    this.EmployeeExclData.push({
      'Sno': "",
      'Employee Name': "",
      'Employee No.': "",
      'Mobile No': "",
      'Aadhar No': "",
      'Designation': "Employees Report - " + this.ulbNm + "-" + this.sts,
      'Category': "",
      'Type': "",
      'ULB': "",
      'Cluster': "",
      'Micropocket': "",
    });
    this.EmployeeExclData.push({
      'Sno': "Sno",
      'Employee Name': "Employee Name",
      'Employee No.': "Employee No.",
      'Mobile No': "Mobile No",
      'Aadhar No': "Aadhar No",
      'Designation': "Designation",
      'Category': "Category",
      'Type': "Type",
      'ULB': "ULB",
      'Cluster': "Cluster",
      'Micropocket': "Micropocket",
    });
    for (let i = 0; i < this.exclData.length; i++) {
      this.EmployeeExclData.push({
        'Sno': this.exclData[i].sno,
        'Employee Name': this.exclData[i].emp_nm,
        'Employee No.': this.exclData[i].emp_nu,
        'Mobile No': this.exclData[i].mble_nu,
        'Aadhar No': this.exclData[i].adhr_nu,
        'Designation': this.exclData[i].dsgn_nm,
        'Category': this.exclData[i].emp_ctgry_nm,
        'Type': this.exclData[i].empnt_type_nm,
        'ULB': this.exclData[i].ulb_nm,
        'Cluster': this.exclData[i].clstr_nm,
        'Micropocket': this.exclData[i].mcrpt_nm,
      });
    }

  }

  downloadExcel($event): void {
    if (this.emplist.length > 0) {
      this.isExcelDwnLd = $event;

      this.excelDataHeaders = ['Sno', 'Employee Name', 'Employee No.', 'Mobile No', 'Aadhar No',
        'Designation', 'Category', 'Type',
        'ULB', 'Cluster', 'Micropocket',];
      this.excelFileNm = 'Employees Data';
    }
  }

  getCrddataLst() {
    this.shwLdr = true;
    let postData = {
      ulb_id: this.headerForm.value.selectedUlb.ulb_id,
      from_dt: this.curdateFrmt,
      to_dt: this.curdateFrmt,
    }
    this.EmployeesService.getEmployeesAttndnce(postData, (err, res) => {
      this.ulbAttndCnts = res.data[0];
      // console.log(this.ulbAttndCnts);
      // // console.log(this.ulbAttndCnts[0].ph_prsnt);
    });
  }

  getAsgnedEmpLst() {

    this.shwLdr = true;
    this.dataSource.data = [];
    let count = 0;
    // console.log(this.emplist);
    for (let i of this.emplist) {
      if ((i.assigned_status == 'assigned') || (i.empnt_type_nm == 'Reserved' && i.assigned_status != 'assigned') || (i.clsrt_id != null && i.assigned_status != 'assigned')) {
        count++;
        i.sno = count;
        this.dataSource.data.push(i);
      }
    }
    // console.log(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.shwLdr = false;
  }

  getUnasgnedEmpLst() {
    this.shwLdr = true;
    this.dataSource.data = [];
    this.smleunasgnd = [];
    let count = 0;
    let cnt = 0
    for (let i of this.emplist) {
      if (i.assigned_status != 'assigned') {
        count++;
        i.ssno = count;
        this.smleunasgnd.push(i);
      }
    }
    for (let m of this.smleunasgnd) {
      if (m.empnt_type_nm != 'Reserved' && m.clsrt_id == null) {
        cnt++;
        m.sno = cnt;
        this.dataSource.data.push(m);
      }
    }

    // console.log(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.shwLdr = false;
  }
  getUnapprovedEmpLst() {
    this.shwLdr = true;
    let postData = {
      ulb_id: this.headerForm.value.selectedUlb.ulb_id,
      wrk_pckge_id: this.headerForm.value.selectedWP,
      clsrt_id: this.headerForm.value.selectedClstr,
      mcrpt_id: this.headerForm.value.selectedMP,
      sts: 2
    }
    this.EmployeesService.getasgndEmployees(postData, (err, res) => {
      this.dataSource.filter = '';
      this.shwLdr = false;
      this.unaprvdEmplist = res.data;

      this.dataSource.data = [];
      let count = 0;
      for (let i of this.unaprvdEmplist) {
        if (i.sts == '2') {
          count++;
          i.sno = count;
          this.dataSource.data.push(i);
        }
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }


  getapprovedntEnrolled() {
    this.shwLdr = true;
    let postData = {
      ulb_id: this.headerForm.value.selectedUlb.ulb_id,
      wrk_pckge_id: this.headerForm.value.selectedWP,
      clsrt_id: this.headerForm.value.selectedClstr,
      mcrpt_id: this.headerForm.value.selectedMP,
      sts: 3
    }
    this.EmployeesService.getasgndEmployees(postData, (err, res) => {
      this.dataSource.filter = '';
      this.shwLdr = false;
      this.pndngEnrolment = res.data;

      this.dataSource.data = [];
      let count = 0;
      for (let i of this.pndngEnrolment) {
        if (i.sts == '3') {
          count++;
          i.sno = count;
          this.dataSource.data.push(i);
        }
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }

  opensideBar(key, empUpdtData, event: any) {
    this.dsbladdNwTrpBtn = false;
    this.BankLst();
    if (empUpdtData) {
      this.empOpLbl = 'Edit';
      this.empForm.get('emp_id').setValue(empUpdtData.emp_id);
      this.empForm.get('emp_nm').setValue(empUpdtData.emp_nm);
      this.empForm.get('fthr_nm').setValue(empUpdtData.fthr_nm);
      this.empForm.get('emp_nu').setValue(empUpdtData.emp_nu);
      this.empForm.get('mble_nu').setValue(empUpdtData.mble_nu);
      this.empForm.get('adhr_nu').setValue(empUpdtData.adhr_nu);
      this.empForm.get('gndr_nm').setValue(empUpdtData.gndr_nm);
      this.empForm.get('dob_dt').setValue(empUpdtData.dob_dt);
      this.empForm.get('dsgn_id').setValue(empUpdtData.dsgn_id);
      this.empForm.get('emp_ctgry_id').setValue(empUpdtData.empnt_ctgry_id);
      this.empForm.get('empnt_type_id').setValue(empUpdtData.empnt_type_id);
      // this.empForm.get('ulb_id').setValue(empUpdtData.ulb_id);
      this.geoLocationWorkPackage(empUpdtData.ulb_id);
      this.empForm.get('wrk_pckge_id').setValue(empUpdtData.wrk_pckge_id);
      this.geoLocationCluster(empUpdtData.wrk_pckge_id);
      this.empForm.get('clsrt_id').setValue(empUpdtData.clsrt_id);
      this.geoLocationMicroPocket(empUpdtData.clsrt_id);
      this.getEmpGroupCtgries(empUpdtData.ulb_id);
      this.empForm.get('mcrpt_id').setValue(empUpdtData.mcrpt_id);
      this.empForm.get('emp_grp_ctgry_id').setValue(empUpdtData.emp_grp_ctgry_id);
      this.getEmpctgrygrps(empUpdtData.emp_grp_ctgry_id);
      this.empForm.get('emp_grp_id').setValue(empUpdtData.emp_grp_id);
      this.empForm.get('bnk_acnt_nu').setValue(empUpdtData.bnk_acnt_nu);
      this.empForm.get('ifsc_cd').setValue(empUpdtData.ifsc_cd);
      this.empForm.get('bnk_id').setValue(empUpdtData.bnk_id);
      this.empForm.get('brnch_nm').setValue(empUpdtData.brnch_nm);
      this.empForm.get('pf_nu').setValue(empUpdtData.pf_nu);
    }
    else {
      this.empOpLbl = 'Add';
      this.empForm.get('emp_id').setValue('');
      this.empForm.get('emp_nm').setValue('');
      this.empForm.get('fthr_nm').setValue('');
      this.empForm.get('emp_nu').setValue('');
      this.empForm.get('mble_nu').setValue('');
      this.empForm.get('adhr_nu').setValue('');
      this.empForm.get('gndr_nm').setValue('');
      this.empForm.get('dob_dt').setValue('');
      this.empForm.get('dsgn_id').setValue('');
      this.empForm.get('emp_ctgry_id').setValue('');
      this.empForm.get('empnt_type_id').setValue('');
      // this.empForm.get('ulb_id').setValue('');
      this.empForm.get('wrk_pckge_id').setValue('');
      this.empForm.get('clsrt_id').setValue('');
      this.empForm.get('mcrpt_id').setValue('');
      this.empForm.get('emp_grp_ctgry_id').setValue('');
      this.empForm.get('emp_grp_id').setValue('');
      this.empForm.get('bnk_acnt_nu').setValue('');
      this.empForm.get('ifsc_cd').setValue('');
      this.empForm.get('bnk_id').setValue('');
      this.empForm.get('brnch_nm').setValue('');
      this.empForm.get('pf_nu').setValue('');
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
    this.getDsgns();
    this.getempCtgries();
    this.getempTypes();
    this.getEmpGroupCtgries(this.ulb_id);
  }
  getDsgns() {
    this.EmployeesService.getEmpDsgns((err, dsgn_res) => {
      this.empdsgns = dsgn_res.data;
    });
  }
  getempCtgries() {
    this.EmployeesService.getEmpCtgries((err, ctgry_res) => {
      this.empCtgries = ctgry_res.data;
    });
  }
  getempTypes() {
    this.EmployeesService.getEmpTypes((err, typ_res) => {
      this.empTypes = typ_res.data;
    });
  }
  geoLocationWorkPackage(ulb) {
    this.wrkpckgs = [{ sno: 0, wrk_pckge_id: 0, wrk_pckge_nm: "ALL" }];
    this.clstrs = [{ sno: 0, clsrt_id: 0, clstr_nm: "ALL" }];
    this.mcrpts = [{ sno: 0, mcrpt_id: 0, mcrpt_nm: "ALL" }];
    this.wrkpckgsLst = [{ sno: 0, wrk_pckge_id: 0, wrk_pckge_nm: "None" }];
    this.headerForm.get('selectedWP').setValue(0);
    this.headerForm.get('selectedClstr').setValue(0);
    this.headerForm.get('selectedMP').setValue(this.mcrpts[0]);
    this.ulb_id = ulb;
    // this.empForm.get('wrk_pckge_id').setValue('');
    this.locationService.workPackage(this.ulb_id, (err, wrkPckg_res) => {
      for (let w of wrkPckg_res.data) {
        this.wrkpckgsLst.push(w);
        this.wrkpckgs.push(w);
      }
      this.geoLocationCluster(this.wrkpckgsLst[1].wrk_pckge_id);
    });
  }
  geoLocationulbs() {
    this.ulbLst = [{ sno: 0, ulb_id: 0, ulb_nm: "None" }];
    this.ulbs = [];
    let ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    for (let u of ulbLst) {
      this.ulbLst.push(u);
      this.ulbs.push(u);
    }
    this.ulb_id = ulbLst[0].ulb_id;

    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[1].ulb_id, ulb_nm: this.ulbLst[1].ulb_nm })
    this.geoLocationWorkPackage(this.ulb_id);
  }
  geoLocationCluster(wrk_pckge_id) {
    this.clstrs = [{ sno: 0, clsrt_id: 0, clstr_nm: "ALL" }];
    this.mcrpts = [{ sno: 0, mcrpt_id: 0, mcrpt_nm: "ALL" }];
    this.clstrLst = [{ sno: 0, clsrt_id: 0, clstr_nm: "None" }];
    this.headerForm.get('selectedClstr').setValue(0);
    this.headerForm.get('selectedMP').setValue(this.mcrpts[0]);
    this.wrk_pckge_id = wrk_pckge_id;
    // this.empForm.get('clsrt_id').setValue('');
    this.locationService.getClusterDetails(this.ulb_id, this.wrk_pckge_id, (err, clstr_res) => {
      for (let c of clstr_res.data) {
        this.clstrLst.push(c);
        this.clstrs.push(c);
      }
      this.geoLocationMicroPocket(this.clstrLst[1].clsrt_id);
      this.getEmpGroupCtgries(this.ulb_id);
    });
  }
  geoLocationMicroPocket(clsrt_id) {

    // this.mcrpts = [{ sno: 0, mcrpt_id: 0, mcrpt_nm: "ALL" }];
    this.mcrptLst = [{ sno: 0, mcrpt_id: 0, mcrpt_nm: "None" }];
    this.headerForm.get('selectedMP').setValue(this.mcrpts[0]);
    // this.empForm.get('mcrpt_id').setValue('');
    this.clsrt_id = clsrt_id;
    this.locationService.getMicroPocket(this.ulb_id, this.wrk_pckge_id, this.clsrt_id, (err, mcrpt_res) => {
      for (let m of mcrpt_res.data) {
        this.mcrptLst.push(m);
        this.mcrpts.push(m);
        // this.mcrpts = mcrpt_res;
      }
      // console.log(this.mcrptLst);
      // console.log(this.mcrpts);
    });
    this.getEmpGroupCtgries(this.ulb_id);
  }
  // getmpdata()
  // {
  //   this.selectedmp=[];
  //   this.selectedmp=this.mcrpt_id;
  // }

  slctdDsgn(dsgn) {
    this.slctdDsgnId = dsgn.dsgn_id;
    if (this.slctdDsgnId == 1 && (this.slctdTypId == 1 || this.slctdTypId == 3)) {
      this.hideClstr = false;
      this.hidemp = false;
    } else if ((this.slctdDsgnId > 1 && this.slctdTypId == 2) || this.slctdTypId == 2) {
      this.hideClstr = true;
      this.hidemp = true;
    } else if ((this.slctdDsgnId > 1 && this.slctdTypId != 2) || this.slctdDsgnId > 1) {
      this.hideClstr = false;
      this.hidemp = true;
    } else {
      this.hideClstr = false;
      this.hidemp = false;
    }
  }
  slctdCtgry(ctgry) {
    if (ctgry.emp_ctgry_id === 1)
      this.empForm.get('empnt_type_id').setValue(1);
  }
  slctdTyp(type) {
    this.slctdTypId = type.empnt_type_id;
    if (this.slctdDsgnId == 1 && (this.slctdTypId == 1 || this.slctdTypId == 3)) {
      this.hideClstr = false;
      this.hidemp = false;
    } else if ((this.slctdDsgnId > 1 && this.slctdTypId == 2) || this.slctdTypId == 2) {
      this.hideClstr = true;
      this.hidemp = true;
    } else if ((this.slctdDsgnId > 1 && this.slctdTypId != 2) || this.slctdDsgnId > 1) {
      this.hideClstr = false;
      this.hidemp = true;
    } else {
      this.hideClstr = false;
      this.hidemp = false;
    }
  }
  getEmpGroupCtgries(ulb_id) {
    this.ulb_id=ulb_id;
    this.EmployeesService.getEmpGropCtgry(this.ulb_id,(err, typ_res) => {
      if(typ_res.data!= undefined){
        this.empGrpctgryLst = typ_res.data;
      }
      console.log(this.empGrpctgryLst)
        this.getEmpctgrygrps(0);
    });
  }
  getEmpctgrygrps(emp_ctgry_id) {
    console.log(this.ulb_id, emp_ctgry_id)
    this.EmployeesService.getEmpGropforCtgry(this.ulb_id, emp_ctgry_id, (err, grp_res) => {
      if(grp_res.data!= undefined){
        this.empGrpLst = grp_res.data;
      }
    });
  }
  onSubmitcmform() {
    // console.log(this.empForm.value.empnt_type_id);
    // console.log(this.empForm.value.clsrt_id);
    if (this.empForm.value.empnt_type_id == 1 || this.empForm.value.empnt_type_id == 3 || this.empForm.value.empnt_type_id == 4) {
      // console.log(this.empForm.value.empnt_type_id);
      if (this.empForm.value.clsrt_id == null || this.empForm.value.clsrt_id == 'undefined' || this.empForm.value.clsrt_id == "") {
        // console.log("return");
        return;
      }
    }
    if (this.empForm.value.dsgn_id == null || this.empForm.value.dsgn_id == 'undefined' || this.empForm.value.dsgn_id == "") {
      // console.log("return");
      return;
    }
    if (this.empForm.value.empnt_type_id == null || this.empForm.value.empnt_type_id == 'undefined' || this.empForm.value.empnt_type_id == "") {
      // console.log("return");
      return;
    }
    // console.log("submittingggggggggg");
    this.dsbladdNwTrpBtn = true;
    this.empForm.value.ulb_id = this.ulb_id;
    // if (this.empForm.status == 'INVALID') {
    //   console.log("In if 1");
    //   return false;
    // }
    if (this.empForm.get("emp_id").value == '' || this.empForm.get("emp_id").value == null) {
      this.addEmployee(this.empForm.value);
    } else {
      this.updtEmployee(this.empForm.value);
    }


  }
  onBlurMethod(adhr_nu) {
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.adhr_nu == adhr_nu && adhr_nu != null && adhr_nu != '') {
        flag = true;
        this.empNM = val.emp_nm;
        this.empID = val.emp_nu;
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }
    else {
      this.errMsg = false;
    }
  }
  addEmployee(data) {
    console.log(data);
    // console.log(data.dsgn_id.length);
    this.dsbladdNwTrpBtn = true;
    this.errMsg = false;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.adhr_nu == data.adhr_nu && data.adhr_nu != null && data.adhr_nu != '') {
        flag = true;
        // this.empNM=val.emp_nm;
        // console.log(this.empNM);
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }
    else {
      this.errMsg = false;
    }
    if (data.empnt_type_id === 2) {
      data.rsrve_in = 1;
    }
    else {
      data.rsrve_in = 0;
    }
    if (data.ulb_id === 0) { data.ulb_id = null; }
    if (data.wrk_pckge_id == 0) { data.wrk_pckge_id = null; }
    if (data.clsrt_id == 0) data.clsrt_id = null;
    if (data.mcrpt_id.mcrpt_id == 0 || !data.mcrpt_id) data.mcrpt_id = 0;
    // console.log(data.dsgn_id);

    this.EmployeesService.addEmployee(data, (err, add_res) => {
      if (add_res.status == 200) {

        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: "Created" }

        });
        this.empForm.reset();
        this.dsbladdNwTrpBtn = false;
        this.getEmpData();
        this.tabChanged();
      }

    });

  }

  updtEmployee(data) {
    this.dsbladdNwTrpBtn = true;

    if (data.empnt_type_id == 2) { data.rsrve_in = 1; }

    else { data.rsrve_in = 0; }
    if (data.ulb_id == 0) { data.ulb_id = null; }
    if (data.wrk_pckge_id == 0) { data.wrk_pckge_id = null; }
    if (data.clsrt_id == 0) { data.clsrt_id = null; }
    if (data.mcrpt_id == null) { data.mcrpt_id = null; }
    // console.log(data);
    this.EmployeesService.updtEmployee(data, (err, updt_res) => {

      if (updt_res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: "Updated" }
        });
        this.empForm.reset();
        // console.log(data);
        this.dsbladdNwTrpBtn = false;
        this.getEmpData();
        this.tabChanged();
      }
    });
  }
  delete(empData) {
    // console.log(empData);
    // console.log("deletedeletedeletedeletedeletedeletedeletedeletedeletedeletedelete");
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: empData.emp_id, entityname: 'Employee', cnfrmflag: true, successFlag: false, operation: "Deleted", dtls: empData.emp_nu }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (empData.sts == 2) {
        this.getUnapprovedEmpLst();
      } else if (empData.sts == 1) {
        // console.log(empData.assigned_status);
        if (empData.assigned_status == null) {
          // this.getUnasgnedEmpLst();
          this.dataSource.data = [];
          this.shwLdr = true;
          // let postData = {ulb_id:this.ulb_id,wrk_pckge_id:this.wrk_pckge_id,clsrt_id:this.clsrt_id,mcrpt_id:this.mcrpt_id}

          let postData = {
            ulb_id: this.headerForm.value.selectedUlb.ulb_id,
            wrk_pckge_id: this.headerForm.value.selectedWP,
            clsrt_id: this.headerForm.value.selectedClstr,
            mcrpt_id: this.headerForm.value.selectedMP,
            sts: 1
          }

          this.EmployeesService.getasgndEmployees(postData, (err, emp_res) => {
            this.dataSource.filter = '';
            this.shwLdr = false;
            this.emplist = emp_res.data;
            // console.log(this.emplist);

            this.getCrddataLst();
            // console.log(this.emplist);
            this.getUnasgnedEmpLst();

          });
        }
        else {
          this.getEmpData();
        }
      }

    })
  }
  getMicropocketId(evt) {
    this.mcrpt_id = evt.option.value.mcrpt_id;
  }


  dobtEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dobvents.push(`${type}: ${event.value}`);
    this.dob_dt = this.datepipe.transform(this.dobvents[0], 'yyyy-MM-dd');
  }
  efctDtEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dobvents.push(`${type}: ${event.value}`);
    this.eff_dt = this.datepipe.transform(this.dobvents[0], 'yyyy-MM-dd');
  }
  expDtEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dobvents.push(`${type}: ${event.value}`);
    this.exp_dt = this.datepipe.transform(this.dobvents[0], 'yyyy-MM-dd');
  }
  openSbstnsideBar(key, emp, event: any) {
    this.sEmpOpsLbl = 'Add';
    this.getSbEmpLst();
    this.emp_id = emp.emp_id;
    this.emp_nm = emp.emp_nm;
    this.mble_nu = emp.mble_nu;
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  getSbEmpLst() {
    this.sbstitEmpLst.data = [];
    this.EmployeesService.getsbstitempLst(this.emp_id, (err, res) => {
      let count = 0
      for (let i of res.data) {
        count++;
        i.sno = count;
        this.sbstitEmpLst.data.push(i)
      }
      this.sbstitEmpLst.paginator = this.paginator;
      this.sbstitEmpLst.sort = this.sort;
    });
  }
  onSubmitSbstFrm() {
    this.sempForm.get("eff_dt").setValue(this.eff_dt)
    if (this.sempForm.status == 'INVALID') {
      return false;
    }
    if (this.sempForm.get("sbste_emp_id").value == '' || this.sempForm.get("sbste_emp_id").value == null) {
      this.addSbEmployee(this.sempForm.value);
    } else {
      this.updtSbEmployee(this.sempForm.value);
    }
  }
  BankLst() {
    this.EmployeesService.getBankLst((err, res) => {
      this.bnkLst = res.data;
    });
  }
  isSubStituteExist() {
    this.EmployeesService.chcksbstitemp(this.sempForm.value.mble_nu, (err, res) => {
      if (res.data.length > 0) {
        this.errMsg = true;
        this.exstsSEmpSts = res.data[0].status;
        this.sempForm.get("sbste_emp_id").setValue(res.data[0].sbste_emp_nm);
        this.sempForm.get("sbste_emp_nm").setValue(res.data[0].sbste_emp_nm);
        this.sempForm.get("mble_nu").setValue(res.data[0].mble_nu);
        if (!res.data[0].exp_dt) {
          this.sEmpOpsLbl = 'Disable';
          this.sempForm.get("eff_dt").setValue(this.datepipe.transform(res.data[0].eff_dt, 'yyyy-MM-dd'))
        }
        else {
          this.sEmpOpsLbl = 'Assign';
        }
      }
    });
  }
  getdisableEmp(sEmpData) {
    this.sEmpOpsLbl = 'Disable';
    this.sempForm.get("sbste_emp_id").setValue(sEmpData.sbste_emp_id);
    this.sempForm.get("sbste_emp_nm").setValue(sEmpData.sbste_emp_nm);
    this.sempForm.get("mble_nu").setValue(sEmpData.mble_nu);
    this.sempForm.get("eff_dt").setValue(this.datepipe.transform(sEmpData.eff_dt, 'yyyy-MM-dd'));
  }

  getassignSbEmp(sEmpData) {
    this.sEmpOpsLbl = 'Assign';
    this.sempForm.get("sbste_emp_id").setValue(sEmpData.sbste_emp_id);
    this.sempForm.get("sbste_emp_nm").setValue(sEmpData.sbste_emp_nm);
    this.sempForm.get("mble_nu").setValue(sEmpData.mble_nu);
  }
  addSbEmployee(sEmpData) {
    sEmpData.emp_id = this.emp_id;
    this.sEmpOpsLbl = 'Add';
    this.EmployeesService.addsbstiteEmployee(sEmpData, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: "Created" }
        });
        this.sbstitEmpLst.data.push(sEmpData);
      }
    });
  }
  updtSbEmployee(sEmpData) {
    this.sEmpOpsLbl = 'Edit';
    this.EmployeesService.updtSbEmp(sEmpData, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: "Updated" }
        });
        this.sbstitEmpLst.data.push(sEmpData);
      }
    });
  }

  assignSEmp() {
    this.sEmpOpsLbl = 'Assign';
    let postData = {
      sbste_emp_id: this.sempForm.get("sbste_emp_id").value,
      emp_id: this.emp_id,
      eff_dt: this.sempForm.get("eff_dt").value
    }
    this.EmployeesService.assignsbstitemp(postData, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: "Assigned" }
        });
      }
    });
  }
  disablesEmp() {
    this.sEmpOpsLbl = 'Disable';
    if (this.exp_dt) {
      this.expdterrMsg = ''
      this.EmployeesService.disablesbstitemp(this.sempForm.get("sbste_emp_id").value, this.exp_dt, (err, res) => {
        if (res.status == 200) {
          const dialogRef = this.dialog.open(ConfirmDialog, {
            width: '500px',
            data: { cnfrmflag: false, successFlag: true, operation: "Disabled" }
          });
        }
      });
    } else {
      this.expdterrMsg = "Please select Expire date before Disable"
    }
  }
  deleteSbEmp(empData) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: empData.sbste_emp_id, entityname: 'Substitute Employee', cnfrmflag: true, successFlag: false, operation: "Deleted", dtls: empData.sbste_emp_nm }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getSbEmpLst();
    })
  }
  editSbEmp(sEmpData) {
    this.sEmpOpsLbl = 'Edit';
    this.sempForm.get("sbste_emp_id").setValue(sEmpData.sbste_emp_id);
    this.sempForm.get("sbste_emp_nm").setValue(sEmpData.sbste_emp_nm);
    this.sempForm.get("mble_nu").setValue(sEmpData.mble_nu);
    this.sempForm.get("eff_dt").setValue(this.datepipe.transform(sEmpData.eff_dt, 'yyyy-MM-dd'));
  }


  // downloadExcel($event): void{
  //     this.isExcelDwnLd = $event;
  //     this.excelDataHeaders = [ 'Sno', 'Employee Name', 'Employee No.', 'Mobile No.', ' Adhar No.', 
  //     'Designation', 'Category', 'Type',
  //     'ULB', 'Cluster', 'Micropocket',];
  //     this.excelFileNm = 'employees_rpt';
  // }



}


@Component({
  selector: 'confirm-dialog',
  templateUrl: '../../main/confirm-dialog.html'
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    public EmployeesService: EmployeesService,
    @Inject(MAT_DIALOG_DATA) public data: { id: '', entityname: '', cnfrmflag: false, successFlag: false, operation: '', dtls: '' }) {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(id, entityname, cnfrmflag, successFlag, operation, dtls): void {
    switch (entityname) {
      case 'Employee': {
        // console.log("addEmployee");
        // console.log(id);
        this.EmployeesService.deleteEmployee(id, (err, res) => {
          if (res.status === 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'Substitute Employee': {
        this.EmployeesService.deleteSbEmp(id, (err, res) => {
          if (res.status === 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
    }

  }

  getColor(operation) {
    (2)
    switch (operation) {
      case 'Created':
        return 'green';
      case 'Assigned':
        return 'green';
      case 'Updated':
        return '#ffc107';
      case 'Disabled':
        return '#ffc107';
      case 'Removed':
        return '#red';
      case 'Deleted':
        return 'red';
    }
  }
}
