

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HardwareCertificationComponent} from './hardware-certification.component';
import { WeighingMachinesComponent } from './weighing-machines/weighing-machines.component';
import { ScannersComponent } from './scanners/scanners.component';
import { TrackersComponent } from './trackers/trackers.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule, MatCardModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatTableModule, MatPaginatorModule,
 MatSortModule,MatButtonModule, MatToolbarModule, MatInputModule} from '@angular/material';
 import { SharedModule } from 'app/shared/shared.module';
 import {MatAutocompleteModule} from '@angular/material/autocomplete'; 

const hc_routes: Routes = [
  { path: 'hardwareCertification', component: HardwareCertificationComponent}
];

@NgModule({
  declarations: [HardwareCertificationComponent, WeighingMachinesComponent, ScannersComponent, TrackersComponent],
  imports: [
    RouterModule.forChild(hc_routes),
    CommonModule,
    FlexLayoutModule,
    FormsModule,ReactiveFormsModule, 
    MatTabsModule, MatCardModule, MatIconModule,
    MatFormFieldModule, MatSelectModule, MatOptionModule, FlexLayoutModule,MatInputModule,
    MatTableModule,MatPaginatorModule,MatSortModule,MatButtonModule,MatToolbarModule,
    MatAutocompleteModule,
    SharedModule
  ]
})
export class HardwareCertificationModule { }

