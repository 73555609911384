import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
    MatButtonModule, MatIconModule, MatTabsModule, MatCardModule, MatDividerModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatTableModule, MatGridListModule, MatCheckboxModule, MatProgressSpinnerModule,
    MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatDialogModule, MatPaginatorModule, MatToolbarModule, MatListModule, MatRadioModule, MatSortModule,
    MAT_DATE_LOCALE, MatProgressBarModule, MatButtonToggleModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';

import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';

import { AppComponent } from 'app/app.component';
import { ScannersComponent } from './main/hardware/scanners/scanners.component';

import { LayoutModule } from 'app/layout/layout.module';
import { GeoLocationsModule } from 'app/main/geo-locations/geo-locations.module';
import { HardwareModule } from './main/hardware/hardware.module';
import { DashboardModule } from './main/State-dashboard/dashboard.module';

import { WasteGeneratorsModule } from './main/waste-generators/waste-generators.module';
import { VehiclesComponent } from './main/vehicles/vehicles.component';
import { VehiclesModule } from './main/vehicles/vehicles.module';
import { PrimaryComponent, PrimaryConfirmDialog } from './main/vehicles/primary/primary.component';
import { ScheduleinterfaceComponent, SecondaryConfirmDialog} from './main/vehicles/scheduleinterface/scheduleinterface.component'

import { SecondaryComponent } from './main/vehicles/secondary/secondary.component';
import { MisreportsComponent } from './main/misreports/misreports.component';
import { ExceptionalReportsComponent } from './main/exceptional-reports/exceptional-reports.component';
import { AuthModule } from './main/auth/auth.module';
import { KpiComponent } from './main/kpi/kpi.component';
import { KpiModule } from 'app/main/kpi/kpi.module';
import { MisreportsModule } from './main/misreports/misreports.module';
import { ExceptionalReportsModule } from './main/exceptional-reports/exceptional-reports.module';
import { AgGridModule } from 'ag-grid-angular';
import { SessionPopup } from './providers/http/sessionPopups';
import { HttpConfigInterceptor } from './providers/http/http.interceptor';
import { EmployeesComponent, ConfirmDialog } from './main/employees/employees.component';
import { EmployeesService } from './main/employees/employees.service';
import { VehicleService } from './main/vehicles/vehicles.service';
import { UserService } from './providers/user/user.serivce';
import { DatePipe } from '@angular/common';
import { Rtms2Component } from './main/rtms2/rtms2.component';
import { Rtms2Module } from './main/rtms2/rtms2.module';
import { SharedModule } from 'app/shared/shared.module';
import { HardwareCertificationModule } from './main/hardware-certification/hardware-certification.module';
import { UlbIncentiveReportComponent } from './main/ulb-incentive-report/ulb-incentive-report.component';
import { UlbIncentiveReportService } from './main/ulb-incentive-report/ulb-incentive-report.service';
import { UlbWiseIncentiveReportComponent } from './main/ulb-wise-incentive-report/ulb-wise-incentive-report.component';
import { UserProfileComponent } from './main/auth/user-profile/user-profile.component';
import { UlbDashboardComponent } from './main/ulb-dashboard/ulb-dashboard.component';
import { RequestEntryListComponent } from './main/workflow/request-entry-list/request-entry-list.component';
import { RequestFormComponent } from './main/workflow/request-form/request-form.component';
import { ApAgrofedDashboardComponent, apapagrodashboarddialoge } from './main/ap-agrofed-dashboard/ap-agrofed-dashboard.component';
import { AssignUserListComponent, UserListDialog } from './main/workflow/assign-user-list/assign-user-list.component';
import { ChatService } from './main/workflow/assign-user-list/chat.serve';
import { UserWiseReportComponent } from './main/ap-agro-reports/user-wise-report/user-wise-report.component';


import { MainServiceService } from './main/main-service.service';
import { NgxQRCodeModule } from 'ngx-qrcode2';
// import { NgxPrintModule } from 'ngx-print';
import { PrintDetailsComponent } from './main/workflow/print-details/print-details.component';
import { MandalWiseReportComponent } from './main/ap-agro-reports/mandal-wise-report/mandal-wise-report.component';
import { LoginDetailsReportComponent } from './main/ap-agro-reports/login-details-report/login-details-report.component';
import { ModalGalleryModule } from 'angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';
//   import {AppComponent}       from './app.component';
import { EditFormComponent } from './main/workflow/edit-form/edit-form.component';
import { TimelineDetailsComponent } from './main/workflow/timeline-details/timeline-details.component';
import { PdfDownloadComponent } from './main/workflow/pdf-download/pdf-download.component';
import { ReportsComponent } from './main/workflow/reports/reports.component';
import { ExcelService } from './main/workflow/eexcelservice';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AcceptReportsComponent } from './main/ap-agro-reports/accept-reports/accept-reports.component';
import { RejectedReportsComponent } from './main/ap-agro-reports/rejected-reports/rejected-reports.component';
import { DifferedReportsComponent } from './main/ap-agro-reports/differed-reports/differed-reports.component';
import { PublicPortalComponent } from './main/workflow/public-portal/public-portal.component';
import { TimelinePublicComponent } from './main/workflow/timeline-public/timeline-public.component';
import { TicketDashbaordComponent } from './main/workflow/ticket-dashbaord/ticket-dashbaord.component';
import { MyDialogComponent } from './main/workflow/my-dialog/my-dialog.component';
import { FinesDashbordComponent } from './main/fines/fines-dashbord/fines-dashbord.component';
import { UserChargesDashBoardComponent } from './main/fines/user-charges-dash-board/user-charges-dash-board.component';
import { UserFeeRptComponent } from './main/fines/user-fee-rpt/user-fee-rpt.component';
import { FinesRptComponent } from './main/fines/fines-rpt/fines-rpt.component';
import { MctCollectrptComponent } from './main/fines/mct-collectrpt/mct-collectrpt.component';
import { UserReportComponent } from './main/fines/user-report/user-report.component';
// import { RptBuilderComponent } from './main/rpt-builder/rpt-builder.component';
import { MessgeDialougComponent } from './main/rpt-builder/messge-dialoug/messge-dialoug.component';
import { TransferPointComponent } from './main/transfer-point/transfer-point.component';
// import { CustomReportModule } from './main/custom-report/custom-report.module';
// import { ReportComponent } from './main/report/report.component';

const routes: Routes = [
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'sample', redirectTo: 'locations' },
    { path: 'logout', redirectTo: 'pages' },
    { path: 'auth', loadChildren: './main/auth/auth.module#AuthModule' },
    { path: 'locations', loadChildren: './main/geo-locations/geo-locations.module#GeoLocationsModule' },
    { path: 'hardware', loadChildren: './main/hardware/hardware.module#HardwareModule' },
    { path: 'households', loadChildren: './main/waste-generators/waste-generators.module#WasteGeneratorsModule' },
    { path: 'reports', loadChildren: './main/report/report.module#ReportModule' },
    { path: 'misreports', loadChildren: './main/misreports/misreports.module#MisreportsModule' },
    { path: 'excepreports', component: ExceptionalReportsComponent },
    { path: 'employees', component: EmployeesComponent },
    { path: 'hardwareCertification', loadChildren: './main/hardware-certification/hardware-certification.module#HardwareCertificationModule' },
    { path: 'ulbincentivereport', component: UlbIncentiveReportComponent },
    { path: 'ulbwiseincentivereport', component: UlbWiseIncentiveReportComponent },
    { path: 'userprofile', component: UserProfileComponent },
    { path: 'ulbDasbrd', component: UlbDashboardComponent },
    { path: 'rqstform', component: RequestFormComponent },
    { path: 'rqsten', component: RequestEntryListComponent },
    { path: 'apagrofed', component: ApAgrofedDashboardComponent },
    { path: 'userwise', component: UserWiseReportComponent },
    { path: 'asgnusr', component: AssignUserListComponent },
    { path: 'prntqr', component: PrintDetailsComponent },
    { path: 'mandalreport', component: MandalWiseReportComponent },
    { path: 'logindetails', component: LoginDetailsReportComponent },
    { path: 'editfrm', component: EditFormComponent },
    { path: 'tmlnfrm', component: TimelineDetailsComponent },
    { path: 'pdfdwn', component: PdfDownloadComponent },
    { path: 'report', component: ReportsComponent },
    { path: 'accepted', component: AcceptReportsComponent },
    { path: 'rejected', component: RejectedReportsComponent },
    { path: 'differed', component: DifferedReportsComponent },
    { path: 'public', component: PublicPortalComponent },
    { path: 'publicTmln', component: TimelinePublicComponent },
    { path: 'tktdshbrd', component: TicketDashbaordComponent },
    { path: 'FinesDshBrd', component: FinesDashbordComponent },
    { path: 'usrchrgDBrd', component: UserChargesDashBoardComponent },
    { path: 'usrfeerpt', component: UserFeeRptComponent },
    { path: 'usrpt', component:  UserReportComponent },
    { path: 'mctrpt', component:  MctCollectrptComponent },
    { path: 'fnsrpt', component:  FinesRptComponent },
    { path: 'TransferPoint', component: TransferPointComponent },

    // { path: 'cstmrptbldr', component:  RptBuilderComponent },
    // { path: 'cstmrpts', component:  ReportComponent },

];

@NgModule({
    declarations: [
        AppComponent,
        VehiclesComponent,
        ExceptionalReportsComponent,
        KpiComponent,
        SessionPopup,
        EmployeesComponent,
        ConfirmDialog,
        PrimaryConfirmDialog,
        SecondaryConfirmDialog,
        Rtms2Component,
        UlbIncentiveReportComponent,
        UlbWiseIncentiveReportComponent,
        UserProfileComponent,
        UlbDashboardComponent,
        RequestEntryListComponent,
        RequestFormComponent,
        ApAgrofedDashboardComponent,
        apapagrodashboarddialoge,
        AssignUserListComponent,
        UserWiseReportComponent,
        PrintDetailsComponent,
        MandalWiseReportComponent,
        LoginDetailsReportComponent,
        AppComponent,
        EditFormComponent,
        TimelineDetailsComponent,
        PdfDownloadComponent, UserListDialog, ReportsComponent, AcceptReportsComponent, RejectedReportsComponent, DifferedReportsComponent, PublicPortalComponent, TimelinePublicComponent,
        ReportsComponent, AcceptReportsComponent, RejectedReportsComponent, DifferedReportsComponent, TicketDashbaordComponent, MyDialogComponent,
        FinesDashbordComponent,
        UserChargesDashBoardComponent,
        UserFeeRptComponent,
        FinesRptComponent,
        MctCollectrptComponent,
        UserReportComponent,
        // RptBuilderComponent,
        MessgeDialougComponent,
        TransferPointComponent,
        // ReportComponent,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // NgxPrintModule,
        RouterModule.forRoot(routes, { useHash: true }),
        //    RouterModule.forRoot( appRoutes , { useHash: true }),
        TranslateModule.forRoot(),
        LayoutModule,
        FormsModule,
        FlexLayoutModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatMenuModule,
        NgxQRCodeModule,

        // Material
        MatMomentDateModule, MatButtonModule, MatIconModule, MatTabsModule, MatCardModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatDividerModule, MatProgressSpinnerModule,
        MatRippleModule, MatGridListModule, MatCheckboxModule, MatAutocompleteModule, MatSortModule,
        MatDatepickerModule, MatDialogModule, MatPaginatorModule, MatToolbarModule, MatListModule, MatProgressBarModule,
        MatNativeDateModule, MatButtonToggleModule, MatSidenavModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules 
        AuthModule,
        GeoLocationsModule,
        HardwareModule,
        WasteGeneratorsModule,
        VehiclesModule,
        Rtms2Module,
        DashboardModule,
        MatTableModule,
        KpiModule,
        ExceptionalReportsModule,
        AgGridModule.withComponents([]),
        MatRadioModule,
        SharedModule,
        HardwareCertificationModule,
        ModalGalleryModule.forRoot(),
        MatSnackBarModule,
        DateRangePickerModule


    ],
    entryComponents: [
        SessionPopup,
        ConfirmDialog,PrimaryConfirmDialog,SecondaryConfirmDialog,
        apapagrodashboarddialoge, UserListDialog, MyDialogComponent
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [ChatService, MainServiceService, ExcelService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        UserService,
        EmployeesService, VehicleService, UlbIncentiveReportService, DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
