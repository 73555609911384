import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { CrudService } from '../crud.service';
let ELEMENT_DATA = []
@Component({
  selector: 'app-timeline-public',
  templateUrl: './timeline-public.component.html',
  styleUrls: ['./timeline-public.component.scss']
})

export class TimelinePublicComponent implements OnInit {
  displayedColumns: string[] = ['fileid', 'remarks', 'asgndby', 'asgndt','frwdto','frwddt'];
  dataSource =  new MatTableDataSource(ELEMENT_DATA);
timelinePubData;
timelineColumns;

  constructor(public crdsrv: CrudService) { }

  ngOnInit() {
  }

  
pubTimeline(){
this.crdsrv.get('ticket-system/').subscribe((res)=>{

},
(error) => {
      console.log(error)
    })
}
}
