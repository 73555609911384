import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { MainServiceService } from '../main-service.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { Router, NavigationExtras } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Image, ImageModalEvent } from 'angular-modal-gallery';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-ap-agrofed-dashboard',
  templateUrl: './ap-agrofed-dashboard.component.html',
  styleUrls: ['./ap-agrofed-dashboard.component.scss']
})
export class ApAgrofedDashboardComponent implements OnInit {
  overallCounts = [];
  district;
  private chart: am4charts.XYChart;
  columnDefs = [];
  rowData = [];
  pagination: boolean = true;
  paginationPageSize = 10;
  isExcelDwnLd;
  excelFileNm;
  excelDataHeaders = [];
  rowData_inspctOffcr = [];
  columnDefs_inspctOffcr = [];
  pagination_inspctOffcr: boolean = true;
  paginationPageSize_inspctOffcr = 10;
  isExcelDwnLd_inspctOffcr;
  excelFileNm_inspctOffcr;
  excelDataHeaders_inspctOffcr = [];
  mandal;
  mndlExcelData = [];

  bnfsCnts;
  bnfsCnts_dstrct;
  curdate = new Date();
  frmevents;
  toevents;
  // inspctOffcr_date = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
  curdate_bckendFrmt = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
  date = new Date();
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  router: any;
  rowClicked: boolean = false;
  eqmntData;
  shwBck: boolean = false;
  shwbtton:boolean=false;
  mndleExcelData = [];
  inspectExcelData = [];
  fnlExcelData;
  fnlInsptExcelData;
  letructor() { }

  constructor(private dataService: MainServiceService, public datepipe: DatePipe, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getMandalWiseData();

    const bnfsCnts_rte = 'beneficiary/counts';
    this.dataService.get(bnfsCnts_rte, (err, res) => {
      this.bnfsCnts = res.data[0];

      this.overallCounts.push(
        {
          lbl: 'Total Beneficiaries',
          cnt: this.bnfsCnts.tot,
          cnt_clr: '#03a9f4'
        },
        {
          lbl: 'Visited Beneficiaries',
          cnt: this.bnfsCnts.cmpltd,
          cnt_clr: '#00c853',
          shwIssu: true
        },
        {
          lbl: 'In Progess Beneficiaries',
          cnt: this.bnfsCnts.on_gng,
          cnt_clr: '#ff9800',
        },
        {
          lbl: 'Not Started Beneficiaries',
          cnt: this.bnfsCnts.not_cmpltd,
          cnt_clr: '#607d8b',
        }
      );
    });



    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);

    // Themes end

    // Create chart instance
    var chart = am4core.create("chartdiv", am4charts.XYChart);

    const dstrctBnfsCnts_rte = 'beneficiary/counts/districtwise';
    // Add data
    this.dataService.get(dstrctBnfsCnts_rte, (err, res) => {
      chart.data = res.data;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "dstrct_nm";
      categoryAxis.renderer.grid.template.location = 0;


      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.disabled = true;
      // valueAxis.renderer.labels.template.disabled = false;
      valueAxis.min = 0;
      // valueAxis.strictMinMax = true;
      // valueAxis.calculateTotals = true;
      // valueAxis.renderer.minWidth = 50;
      // // Create series
      function createSeries(field, name) {

        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;

        series.dataFields.categoryX = "dstrct_nm";
        series.sequencedInterpolation = true;
        // categoryAxis.renderer.labels.template.rotation = -70;
        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;

        return series;
      }

      createSeries("cmpltd", "Completed");
      createSeries("on_gng", "In Progess");
      createSeries("not_cmpltd", "Not Started");

      // Legend
      chart.legend = new am4charts.Legend();
      chart.scrollbarX = new am4core.Scrollbar();
    });




    // Create chart instance
    // let statuschart = am4core.create("statuschartdiv", am4charts.XYChart);

    // Add data
    // statuschart.data = [{
    //   "year": "Not Matched Chassis Number",
    //   "europe": 130,
    // }, {
    //   "year": "Not Matched Beneficiaries",
    //   "europe": 100,
    // }, {
    //   "year": "Vehicle in Different Location",
    //   "europe": 80,
    // },
    // {
    //   "year": "Vehicle Not Available",
    //   "europe": 240,
    // }];




    // statuschart.legend = new am4charts.Legend();
    // statuschart.legend.position = "right";

    // Create axes
    // let statuscategoryAxis = statuschart.yAxes.push(new am4charts.CategoryAxis());
    // statuscategoryAxis.dataFields.category = "year";
    // statuscategoryAxis.renderer.grid.template.opacity = 0;

    // let statusvalueAxis = statuschart.xAxes.push(new am4charts.ValueAxis());
    // statusvalueAxis.min = 0;
    // statusvalueAxis.renderer.grid.template.opacity = 0;
    // statusvalueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    // statusvalueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    // statusvalueAxis.renderer.ticks.template.length = 10;
    // statusvalueAxis.renderer.line.strokeOpacity = 0.5;
    // statusvalueAxis.renderer.baseGrid.disabled = true;
    // statusvalueAxis.renderer.minGridDistance = 40;

    // // Create series
    // function createSeries(field, name) {
    //   let series = statuschart.series.push(new am4charts.ColumnSeries());
    //   series.dataFields.valueX = field;
    //   series.dataFields.categoryY = "year";
    //   series.stacked = true;
    //   series.name = name;

    //   let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    //   labelBullet.locationX = 0.5;
    //   labelBullet.label.text = "{valueX}";
    //   labelBullet.label.fill = am4core.color("#fff");
    // }

    // createSeries("europe", "Europe");
    this.getInspctOffcrData();
  }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
    // this.getInspctOffcrData();
  }
  addtoEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
    // this.getInspctOffcrData();
  }
  getInspctOffcrData(): void {
    let inspct_backendDate;
    this.fnlInsptExcelData = [];
    this.isExcelDwnLd_inspctOffcr = false;
    this.shwbtton = false;
    if (this.frmevents === undefined){
      this.from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }else{
      this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    }
    if (this.toevents === undefined){
      this.to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }else{
      this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
    }

    // if (this.frmevents === undefined) {
    //   inspct_backendDate = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
    // } else {
    //   inspct_backendDate = this.frmevents[0];
    // }

    // this.frm_dt = this.datepipe.transform(inspct_backendDate, 'yyyy-MM-dd');
    const inspctOffcrData = {
      frm_dt: this.from_date,
      to_dt: this.to_date
    };

    const inspctOfcrsBnfsCnts_rte = 'beneficiary/counts/inspectionofficerwise';
    // Add data
    this.dataService.getByData(inspctOfcrsBnfsCnts_rte, inspctOffcrData, (err, res) => {

      this.rowData_inspctOffcr = res.data;

      for (let i = 0; i < this.rowData_inspctOffcr.length; i++) {
        this.inspectExcelData.push({
          dstrct_nm: this.rowData_inspctOffcr[i].dstrct_nm,
           mndle_nm: this.rowData_inspctOffcr[i].mndle_nm,
           user_nm : this.rowData_inspctOffcr[i].user_nm ,
           tot : this.rowData_inspctOffcr[i].tot , 
           on_gng : this.rowData_inspctOffcr[i].on_gng,
           cmpltd: this.rowData_inspctOffcr[i].cmpltd,
           not_cmpltd: this.rowData_inspctOffcr[i].not_cmpltd
        });
      }
      this.excelDataHeaders_inspctOffcr.push('District', 'Mandal', 'Inspection  Officer', ' Total Inspections ',  'In Progess', 'Completed', 'Not Started');
      

      this.columnDefs_inspctOffcr = [
        {
          headerName: 'District',
          field: 'dstrct_nm',
          sortable: true,
          filter: true,
          width:250
        
        },
        {
          headerName: 'Mandal',
          field: 'mndle_nm',
          sortable: true,
          filter: true,
          width:250
        },
        {
          headerName: 'Inspection Officer',
          field: 'user_nm',
          sortable: true,
          filter: true,
        
        },
        {
          headerName: 'Total Inspections',
          field: 'tot',
          sortable: true,
          filter: true,
          
        },
        {
          headerName: 'In Progess',
          field: 'on_gng',
          sortable: true,
          filter: true,
          cellStyle: {
            cursor: 'pointer' 
          }
        },
        {
          headerName: 'Completed',
          field: 'cmpltd',
          sortable: true,
          filter: true,
          cellStyle: {
           cursor: 'pointer' 
        }
        },
        {
          headerName: 'Not Started',
          field: 'not_cmpltd',
          sortable: true,
          filter: true,
        //   cellStyle: {
        //   backgroundColor: '#e9eef0  ' 
        // }
        cellStyle: {
          cursor: 'pointer'
          }
    
        },
      ];
    });
  }

  downloadExcel($event, data): void {
    this.fnlExcelData = [];
    this.isExcelDwnLd = $event;
    this.fnlExcelData = data;
    this.excelDataHeaders = this.excelDataHeaders;
    this.excelFileNm = 'Mandal_Wise_rpt';
  }

  inspectionOffcr_downloadExcel($event, data): void {
    // if (this.rowData.length > 0) {
      this.fnlInsptExcelData = [];
      this.isExcelDwnLd_inspctOffcr = $event;
      this.fnlInsptExcelData = data;
      this.excelDataHeaders_inspctOffcr = this.excelDataHeaders_inspctOffcr;
      // ['District', 'Mandal', 'Inspection Officer', 'Total Inspections', 'In Progess', 'Completed', 'Not Started'];
      this.excelFileNm_inspctOffcr = 'Inspection_Officer_Wise_rpt';
    // }
  }

  getMandalWiseData(): void {
    // Mandal data table
    this.shwBck = false;
    this.excelDataHeaders = [];
    this.mndleExcelData = [];
    this.fnlExcelData = [];
    this.excelFileNm = '';
    this.isExcelDwnLd = false;
    const mandalBnfsCnts_rte = 'beneficiary/counts/mandelwise';
    const mndlData = {
      frm_dt: this.curdate_bckendFrmt,
      to_dt: this.curdate_bckendFrmt
    };
    // Add data
    this.dataService.getByData(mandalBnfsCnts_rte, mndlData, (err, res) => {

      this.rowData = res.data; 

      for (let i = 0; i < this.rowData.length; i++) {
        this.mndleExcelData.push({
          dstrct_nm: this.rowData[i].dstrct_nm, mndle_nm: this.rowData[i].mndle_nm, tot: this.rowData[i].tot,
          on_gng: this.rowData[i].on_gng, cmpltd: this.rowData[i].cmpltd, not_cmpltd: this.rowData[i].not_cmpltd
        });
      }
      this.excelDataHeaders.push('District', 'Mandal', 'Total Equiments', 'In Progess', 'Visited', 'Not Started');

      this.columnDefs = [
        {
          headerName: 'District',
          field: 'dstrct_nm',
          sortable: true,
          filter: true,
          width: 250
        },
        {
          headerName: 'Mandal',
          field: 'mndle_nm',
          sortable: true,
          filter: true,
          width: 250
        },
        {
          headerName: 'Total Equipments',
          field: 'tot',
          sortable: true,
          filter: true,
          width: 250,
          cellStyle: {
              cursor: 'pointer' 
          }
        },
        {
          headerName: 'In Progess',
          field: 'on_gng',
          sortable: true,
          filter: true,
          width: 250,
          cellStyle: {
            cursor: 'pointer' 
        }
        },
        {
          headerName: 'Visited/completed',
          field: 'cmpltd',
          sortable: true,
          filter: true,
          width: 250,
          cellStyle: {
            cursor: 'pointer' 
        }
        },
        {
          headerName: 'Not Started',
          field: 'not_cmpltd',
          sortable: true,
          filter: true,
          width: 250,
          cellStyle: {
            cursor: 'pointer' 
        }
        },
      ];
    });
  }


  getRowClicked(slctdData): void {
    this.excelDataHeaders = [];
    this.mndleExcelData = [];
    this.fnlExcelData = [];
    this.excelFileNm = '';
    this.isExcelDwnLd = false;

    if (slctdData.colDef.headerName === 'Total Equipments') {
      this.rowClicked = true;
      const dashbrd_data = {
        dstrct_id: slctdData.data.dstrct_id,
        mndle_id: slctdData.data.mndle_id,
      };

      const inspctOfcrsBnfsCnts_rte = 'beneficiary/counts/mandelwise/equiments';

      this.dataService.getByData(inspctOfcrsBnfsCnts_rte, dashbrd_data, (err, res) => {
        this.rowData = res.data;
        this.shwBck = true;

        
        for (let i = 0; i < this.rowData.length; i++) {
          this.mndleExcelData.push({
            dstrct_nm: this.rowData[i].dstrct_nm, mndle_nm: this.rowData[i].mndle_nm, bnfcry_nm: this.rowData[i].bnfcry_nm,
            mble_nu: this.rowData[i].mble_nu, addrs_txt: this.rowData[i].addrs_txt, schm_nm: this.rowData[i].schm_nm,
             eqpmnt_chss_nu: this.rowData[i].eqpmnt_chss_nu
          });
        }
        this.excelDataHeaders.push('District', 'Mandal', 'Benificary Name', 'Mobile Number', 'Addrress', 'Scheme Name',  'Chassis Number');

        this.columnDefs = [
          {
            headerName: 'District ',
            field: 'dstrct_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Benificary Name',
            field: 'bnfcry_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mobile Number ',
            field: 'mble_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Addrress',
            field: 'addrs_txt',
            sortable: true,
            filter: true,
           
          },
          {
            headerName: 'Scheme Name',
            field: 'schm_nm',
            sortable: true,
            filter: true
          },
          // {
          //   headerName: 'Equipment Type',
          //   field: 'eqpmnt_type_nm',
          //   sortable: true,
          //   filter: true
          // },
          {
            headerName: 'Chassis Number',
            field: 'eqpmnt_chss_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'View Details',
            cellStyle: { textAlign: 'center' },
            width: 100,
            cellRenderer: function (param) {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = `<span class="my-css-class">
                <button class="btn-simple-history" style="background-color: #3190a6;  
                box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
                color: #fff;cursor: pointer;border: none;line-height: 17px;border-radius: 3px;">View</button>
                </span>`;
              const eButton = eDiv.querySelectorAll('.btn-simple-history')[0];
              eButton.addEventListener('click', function (param) {
                // console.log(param.value);
              });
              return eDiv;
            },
          },
        ];
      });
    }
    if (slctdData.colDef.headerName === 'Visited/completed') {
      console.log("clicked visited")
      this.rowClicked = true;
      const dashbrd_data = {
        dstrct_id: slctdData.data.dstrct_id,
        mndle_id: slctdData.data.mndle_id,
      };

      const inspctOfcrsBnfsCnts_rte = '/mndallist/detiallist/visited';

      this.dataService.getByData(inspctOfcrsBnfsCnts_rte, dashbrd_data, (err, res) => {
        this.rowData = res.data;
        this.shwBck = true;

        for (let i = 0; i < this.rowData.length; i++) {
          this.mndleExcelData.push({
            dstrct_nm: this.rowData[i].dstrct_nm, mndle_nm: this.rowData[i].mndle_nm, bnfcry_nm: this.rowData[i].bnfcry_nm,
            mble_nu: this.rowData[i].mble_nu, addrs_txt: this.rowData[i].addrs_txt, schm_nm: this.rowData[i].schm_nm,
             eqpmnt_chss_nu: this.rowData[i].eqpmnt_chss_nu
          });
        }
       
        this.excelDataHeaders.push('District', 'Mandal', 'Benificary Name', 'Mobile Number', 'Addrress', 'Scheme Name',  'Chassis Number');

        this.columnDefs = [
          {
            headerName: 'District ',
            field: 'dstrct_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Benificary Name',
            field: 'bnfcry_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mobile Number ',
            field: 'mble_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Addrress',
            field: 'addrs_txt',
            sortable: true,
            filter: true,
           
          },
          {
            headerName: 'Scheme Name',
            field: 'schm_nm',
            sortable: true,
            filter: true
          },
          
          {
            headerName: 'Chassis Number',
            field: 'eqpmnt_chss_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'View Details',
            cellStyle: { textAlign: 'center' },
            width: 100,
            cellRenderer: function (param) {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = `<span class="my-css-class">
                <button class="btn-simple-history" style="background-color: #3190a6;  
                box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
                color: #fff;cursor: pointer;border: none;line-height: 17px;border-radius: 3px;">View</button>
                </span>`;
              const eButton = eDiv.querySelectorAll('.btn-simple-history')[0];
              eButton.addEventListener('click', function (param) {
                // console.log(param.value);
              });
              return eDiv;
            },
          },
        ];
      });
    }
    if (slctdData.colDef.headerName === 'In Progess') {
      console.log("clicked visited")
      this.rowClicked = true;
      const dashbrd_data = {
        dstrct_id: slctdData.data.dstrct_id,
        mndle_id: slctdData.data.mndle_id,
      };

      const inspctOfcrsBnfsCnts_rte = '/mndallist/detiallist/inprogress';

      this.dataService.getByData(inspctOfcrsBnfsCnts_rte, dashbrd_data, (err, res) => {
        this.rowData = res.data;
        this.shwBck = true;

        for (let i = 0; i < this.rowData.length; i++) {
          this.mndleExcelData.push({
            dstrct_nm: this.rowData[i].dstrct_nm, mndle_nm: this.rowData[i].mndle_nm, bnfcry_nm: this.rowData[i].bnfcry_nm,
            mble_nu: this.rowData[i].mble_nu, addrs_txt: this.rowData[i].addrs_txt, schm_nm: this.rowData[i].schm_nm,
             eqpmnt_chss_nu: this.rowData[i].eqpmnt_chss_nu
          });
        }
       
        this.excelDataHeaders.push('District', 'Mandal', 'Benificary Name', 'Mobile Number', 'Addrress', 'Scheme Name',  'Chassis Number');

        this.columnDefs = [
          {
            headerName: 'District ',
            field: 'dstrct_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Benificary Name',
            field: 'bnfcry_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mobile Number ',
            field: 'mble_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Addrress',
            field: 'addrs_txt',
            sortable: true,
            filter: true,
           
          },
          {
            headerName: 'Scheme Name',
            field: 'schm_nm',
            sortable: true,
            filter: true
          },
          
          {
            headerName: 'Chassis Number',
            field: 'eqpmnt_chss_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'View Details',
            cellStyle: { textAlign: 'center' },
            width: 100,
            cellRenderer: function (param) {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = `<span class="my-css-class">
                <button class="btn-simple-history" style="background-color: #3190a6;  
                box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
                color: #fff;cursor: pointer;border: none;line-height: 17px;border-radius: 3px;">View</button>
                </span>`;
              const eButton = eDiv.querySelectorAll('.btn-simple-history')[0];
              eButton.addEventListener('click', function (param) {
                // console.log(param.value);
              });
              return eDiv;
            },
          },
        ];
      });
    }
    if (slctdData.colDef.headerName === 'Not Started') {
      console.log("clicked visited")
      this.rowClicked = true;
      const dashbrd_data = {
        dstrct_id: slctdData.data.dstrct_id,
        mndle_id: slctdData.data.mndle_id,
      };

      const inspctOfcrsBnfsCnts_rte = '/mndallist/detiallist/notstarted';

      this.dataService.getByData(inspctOfcrsBnfsCnts_rte, dashbrd_data, (err, res) => {
        this.rowData = res.data;
        this.shwBck = true;

        for (let i = 0; i < this.rowData.length; i++) {
          this.mndleExcelData.push({
            dstrct_nm: this.rowData[i].dstrct_nm, mndle_nm: this.rowData[i].mndle_nm, bnfcry_nm: this.rowData[i].bnfcry_nm,
            mble_nu: this.rowData[i].mble_nu, addrs_txt: this.rowData[i].addrs_txt, schm_nm: this.rowData[i].schm_nm,
             eqpmnt_chss_nu: this.rowData[i].eqpmnt_chss_nu
          });
        }
       
        this.excelDataHeaders.push('District', 'Mandal', 'Benificary Name', 'Mobile Number', 'Addrress', 'Scheme Name',  'Chassis Number');

        this.columnDefs = [
          {
            headerName: 'District ',
            field: 'dstrct_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Benificary Name',
            field: 'bnfcry_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mobile Number ',
            field: 'mble_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Addrress',
            field: 'addrs_txt',
            sortable: true,
            filter: true,
           
          },
          {
            headerName: 'Scheme Name',
            field: 'schm_nm',
            sortable: true,
            filter: true
          },
          
          {
            headerName: 'Chassis Number',
            field: 'eqpmnt_chss_nu',
            sortable: true,
            filter: true
          },
         
        ];
      });
    }
    if (slctdData.colDef.headerName === 'View Details') {
      const vwDtls_rte = 'beneficiary/counts/mandelwise/equiments/' + slctdData.data.eqpmnt_id;
      this.dataService.get(vwDtls_rte, (err, res) => {
        this.eqmntData = res.data;
        const dialogRef = this.dialog.open(apapagrodashboarddialoge, {
          width: '1000px',
          panelClass: 'equiments-dialog',
          data: { action: this.eqmntData }
        });
      });
    }




    
  }
  goBack(): void {
    this.getMandalWiseData();
  }



  getinspectionclicked(slctdData1): void {
    this.excelDataHeaders_inspctOffcr = [];
    this.inspectExcelData = [];
    this.fnlInsptExcelData = [];
    this.excelFileNm_inspctOffcr = '';
    this.isExcelDwnLd_inspctOffcr = false;
console.log(slctdData1);

    if (slctdData1.colDef.headerName === 'Not Started') {
      this.rowClicked = true;
      const dashbrd_data = {
        frm_dt: this.from_date,
        to_dt: this.to_date,
        usr_id: slctdData1.data.usr_id,
      };
      console.log(dashbrd_data);
      console.log("row clicked");
      console.log("notsatted");
      const inspctOfcrsBnfsCnts_reports = '/inspection/detiallist/notstart';

      this.dataService.getByData(inspctOfcrsBnfsCnts_reports, dashbrd_data,(err, res) => {
        this.rowData_inspctOffcr = res.data;
        this.shwbtton = true;

       
        for (let i = 0; i < this.rowData_inspctOffcr.length; i++) {
          this.inspectExcelData.push({
            dstrct_nm: this.rowData_inspctOffcr[i].dstrct_nm, mndle_nm: this.rowData_inspctOffcr[i].mndle_nm, bnfcry_nm: this.rowData_inspctOffcr[i].bnfcry_nm,
            mble_nu: this.rowData_inspctOffcr[i].mble_nu, addrs_txt: this.rowData_inspctOffcr[i].addrs_txt, schm_nm: this.rowData_inspctOffcr[i].schm_nm,
             eqpmnt_chss_nu: this.rowData_inspctOffcr[i].eqpmnt_chss_nu
          });
        }
        this.excelDataHeaders_inspctOffcr.push('District', 'Mandal', 'Benificary Name', 'Mobile Number', 'Addrress', 'Scheme Name',  'Chassis Number');

        this.columnDefs_inspctOffcr = [
          {
            headerName: 'District ',
            field: 'dstrct_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Benificary Name',
            field: 'bnfcry_nm',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Mobile Number ',
            field: 'mble_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Addrress',
            field: 'addrs_txt',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Scheme Name',
            field: 'schm_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Chassis Number',
            field: 'eqpmnt_chss_nu',
            sortable: true,
            filter: true
          },
         
        ];
      });
    }

    if (slctdData1.colDef.headerName === 'In Progess') {
      this.rowClicked = true;
      const dashbrd_data = {
        frm_dt: this.from_date,
        to_dt: this.to_date,
        usr_id:slctdData1.data.usr_id,
      };
      console.log(dashbrd_data);
      console.log("row clicked");
      const inspctOfcrsBnfsCnts_reports = '/inspection/detiallist/inprog';
      console.log("in progress");
      this.dataService.getByData(inspctOfcrsBnfsCnts_reports, dashbrd_data, (err, res) => {
        this.rowData_inspctOffcr = res.data;
        this.shwbtton = true;

       
        for (let i = 0; i < this.rowData_inspctOffcr.length; i++) {
          this.inspectExcelData.push({
            dstrct_nm: this.rowData_inspctOffcr[i].dstrct_nm, mndle_nm: this.rowData_inspctOffcr[i].mndle_nm, bnfcry_nm: this.rowData_inspctOffcr[i].bnfcry_nm,
            mble_nu: this.rowData_inspctOffcr[i].mble_nu, addrs_txt: this.rowData_inspctOffcr[i].addrs_txt, schm_nm: this.rowData_inspctOffcr[i].schm_nm,
             eqpmnt_chss_nu: this.rowData_inspctOffcr[i].eqpmnt_chss_nu
          });
        }
        this.excelDataHeaders_inspctOffcr.push('District', 'Mandal', 'Benificary Name', 'Mobile Number', 'Addrress', 'Scheme Name',  'Chassis Number');

        this.columnDefs_inspctOffcr = [
          {
            headerName: 'District ',
            field: 'dstrct_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Benificary Name',
            field: 'bnfcry_nm',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Mobile Number ',
            field: 'mble_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Addrress',
            field: 'addrs_txt',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Scheme Name',
            field: 'schm_nm',
            sortable: true,
            filter: true
          },
          
          {
            headerName: 'Chassis Number',
            field: 'eqpmnt_chss_nu',
            sortable: true,
            filter: true
          },
         
        ];
      });
    }

    if (slctdData1.colDef.headerName === 'Completed') {
      this.rowClicked = true;
      const dashbrd_data = {
        frm_dt: this.from_date,
        to_dt: this.to_date,
        usr_id: slctdData1.data.usr_id,
      };
      console.log(dashbrd_data);
      console.log("row clicked");
      const inspctOfcrsBnfsCnts_reports = '/inspection/detiallist/complet';
      console.log("complete");
      this.dataService.getByData(inspctOfcrsBnfsCnts_reports, dashbrd_data, (err, res) => {
        this.rowData_inspctOffcr = res.data;
        this.shwbtton = true;

       
        for (let i = 0; i < this.rowData_inspctOffcr.length; i++) {
          this.inspectExcelData.push({
            dstrct_nm: this.rowData_inspctOffcr[i].dstrct_nm, mndle_nm: this.rowData_inspctOffcr[i].mndle_nm, bnfcry_nm: this.rowData_inspctOffcr[i].bnfcry_nm,
            mble_nu: this.rowData_inspctOffcr[i].mble_nu, addrs_txt: this.rowData_inspctOffcr[i].addrs_txt, schm_nm: this.rowData_inspctOffcr[i].schm_nm,
             eqpmnt_chss_nu: this.rowData_inspctOffcr[i].eqpmnt_chss_nu
          });
        }
        this.excelDataHeaders_inspctOffcr.push('District', 'Mandal', 'Benificary Name', 'Mobile Number', 'Addrress', 'Scheme Name',  'Chassis Number');

        this.columnDefs_inspctOffcr = [
          {
            headerName: 'District ',
            field: 'dstrct_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Benificary Name',
            field: 'bnfcry_nm',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Mobile Number ',
            field: 'mble_nu',
            sortable: true,
            filter: true
          },
          {
            headerName: 'Addrress',
            field: 'addrs_txt',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Scheme Name',
            field: 'schm_nm',
            sortable: true,
            filter: true
          },
          
          {
            headerName: 'Chassis Number',
            field: 'eqpmnt_chss_nu',
            sortable: true,
            filter: true
          },
         
        ];
      });
    }



  }
  goBackinspect(): void {
    this.getInspctOffcrData();
  } 

}

@Component({
  selector: 'ap-agrofed-dasboard-dialoge',
  templateUrl: 'ap-agrofed-dasboard-dialoge.html',
  styleUrls: ['./ap-agrofed-dashboard.component.scss']
})
export class apapagrodashboarddialoge {
  eqmntDtls;
  openModalWindow: boolean = false;
  images: Image[] = [];

  // imagePointer;
  // openModalWindow: boolean = false;
  imagesArray = [];
  imagePointer: number = 0;
  shwDataDiv: boolean;
  // router: any;
  dashbrd_data;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private router: Router, public dialogRef: MatDialogRef<ApAgrofedDashboardComponent>, 
  private dataService: MainServiceService,
  private _snackBar: MatSnackBar ) {
    this.eqmntDtls = this.data.action;
    console.log(this.data);
    if (this.eqmntDtls.length === 0) {
      this.shwDataDiv = false;
    } else {
      this.shwDataDiv = true;
    }
    // this.imagesArray = this.eqmntDtls[0].imgCtgryLst
    var counter = 0;
    this.eqmntDtls.filter((k) => {
      k.imgCtgryLst.filter((k2) => {

        k2.imgLst.filter((img) => {
          k2['admn_in']=img.admn_in
          img['mdlGlryImg'] = [new Image(
            ++counter,
            { // modal
              img: img.img_bse_txt
            },
            { // plain
              img: img.img_bse_txt
            }
          )]
        })
      })
    })
  }
  openImageModal(image: Image) {
    this.imagePointer = this.imagesArray.push(image);
    this.openModalWindow = true;
  }

  onCloseImageModal(event: ImageModalEvent) {
    this.openModalWindow = false;
  }
  closedlog() {
    console.log("close button");
    this.dialogRef.close();
  }
  // console.log(this.data);
  accept(id, ic) {
    console.log("accept button");
    console.log(ic);
    console.log(id);
    console.log(this.data);
    const dashbrd_data = {
      eqpmnt_id: this.data.action[0].eqpmnt_id,
      img_ctgry_id: ic.img_ctgry_id,
      admn_in: id
    };
    const btnstatus_id = '/buttonstatus/:' + id;
    this.dataService.getByData(btnstatus_id, dashbrd_data, (err, res) => {
    })
    console.log(dashbrd_data);
    this._snackBar.open( "You are Accepted this picture"," ",{
      duration: 2000,
    });
  }
  Reject(id, ic) {
    console.log("reject button");
    console.log(ic);
    console.log(id);
    const dashbrd_data = {
      eqpmnt_id: this.data.action[0].eqpmnt_id,
      img_ctgry_id: ic.img_ctgry_id,
      admn_in: id
    };
    const btnstatus_id = '/buttonstatus/:' + id;
    this.dataService.getByData(btnstatus_id, dashbrd_data, (err, res) => {
    })
    console.log(dashbrd_data);

    this._snackBar.open( "You are Rejectd this picture","",{
      duration: 2000,
    });
}
}
// You are Rejectd this picture 
