import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class HardwareCertificationService {

  constructor(public httpCtrl: Http, private http: HttpClient) { }

  public wmsWrkngStatus(ulb_id, cb) {
    this.http.get('/hwCertiification/weighingmachines/'+ulb_id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public scnrsWrkngStatus(ulb_id, cb) {
    this.http.get('/hwCertiification/scanners/'+ulb_id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public trckrsWrkngStatus(ulb_id, cb) {
    this.http.get('/hwCertiification/trackers/'+ulb_id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
}
