import { Component, OnInit, ViewChild, Input, Inject} from '@angular/core';
import { GeoLocationsService } from '../geo-locations/geo-locations.service';
import { Ulb } from './approval-status.model';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColDef, GridApi, ColumnApi } from 'ag-grid-community';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-approval-status',
  templateUrl: './approval-status.component.html',
  styleUrls: ['./approval-status.component.scss']
})
export class ApprovalStatusComponent implements OnInit {
  ulbLst: Ulb[] = [];
  headerForm: FormGroup;
  columnDefs = [];
  rowData =  [];
  pagination:boolean = true;
  paginationPageSize = 10;
  constructor(private locationService: GeoLocationsService,  public dialog: MatDialog) { }
  shwLdr;
  noData;
  ulbId;
  ulb;
  slctdEmps = [];
  rowSelection;
  params;
  columnApi;
  public gridApi: GridApi;
  shwSveBtn;
  emp;
  clstr;
  mcrpt;
  private dialogRef: MatDialogRef<string>;
  @ViewChild('tabGroup') tabGroup;

  ngOnInit(): void {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    // this.geoService.getUlbs((err, ulbLst) => {
    // this.ulbLst = ulbLst.data;
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    if (this.ulbLst.length === 1){
      this.ulbId = this.ulbLst[0].ulb_id;
    }
    this.ulb = {
      ulb_id: this.ulbLst[0].ulb_id,
      ulb_nm: this.ulbLst[0].ulb_nm
    };
    const toSelect = this.ulbLst.find(c => c.ulb_id === this.ulb.ulb_id);
    this.headerForm.get('selectedUlb').setValue(toSelect);
    this.getUlbUnaprvdEmpLst();
  }

  public tabChanged(): void {
    if (this.tabGroup.selectedIndex === 0) {
      this.getUlbUnaprvdEmpLst();
    } else if (this.tabGroup.selectedIndex === 1) {
      this.getUlbUnaprvdClstrLst();
    }
    else if (this.tabGroup.selectedIndex === 2) {
      this.getUlbUnaprvdMcrptLst();
    }
  }

  onUlbChange(ulb_id){
    this.ulbId = ulb_id;
  }

  // Employee list
  getUlbUnaprvdEmpLst(): void{
    this.rowData = [];
    this.columnDefs = [];
    this.clstr = 0;
    this.mcrpt = 0;
      this.shwLdr = true;
    if (this.ulbId !== undefined){
      this.locationService.getUnapprovedEmps(this.ulbId, (err, ulbUnaprvd) => {
        this.rowData = ulbUnaprvd.data;

        if (this.rowData.length !== 0){
          this.noData = false;
        }else{
          this.noData = true;
        }
        this.shwLdr = false;
        this.rowSelection = 'multiple';
        this.columnDefs = [
          {
            headerName: 'Sno',
            field: 'sno', 
            sortable: true, 
            cellStyle: {textAlign: 'center'}, 
            filter: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            width: 100
          },
          {
            headerName: 'Employee Number',
            field: 'emp_nu', 
            sortable: true, 
            cellStyle: {textAlign: 'center'}, 
            filter: true,
            
          },
          {
            headerName: 'Employee Name',
            field: 'emp_nm', 
            cellStyle: {textAlign: 'center'}, 
            sortable: true, 
            filter: true,
            
          },
          {
            headerName: 'Mobile',
            field: 'mble_nu', 
            cellStyle: {textAlign: 'center'}, 
            sortable: true, 
            filter: true,
            
          },
          {
            headerName: 'Adhar Number',
            field: 'adhr_nu', 
            cellStyle: {textAlign: 'center'}, 
            sortable: true, 
            filter: true,
            
          },
          {
            headerName: 'Designation',
            field: 'dsgn_nm', 
            cellStyle: {textAlign: 'center'}, 
            sortable: true, 
            filter: true,
            
          },
          {
            headerName: 'Category',
            field: 'emp_ctgry_nm', 
            cellStyle: {textAlign: 'center'}, 
            sortable: true, 
            filter: true,
            
          },
          {
            headerName: 'Type',
            field: 'empnt_type_nm', 
            cellStyle: {textAlign: 'center'}, 
            sortable: true, 
            filter: true,
            
          }
        ];
      });
    }
  }

  getUlbUnaprvdClstrLst(): void{
    this.rowData = [];
    this.columnDefs = [];
    this.emp = 0;
    this.mcrpt = 0;
    this.shwLdr = true;
  if (this.ulbId !== undefined){
    this.locationService.getUnapprovedClstrs(this.ulbId, (err, ulbUnaprvdClstrs) => {
      this.rowData = ulbUnaprvdClstrs.data;
      if (this.rowData.length !== 0){
        this.noData = false;
      }else{
        this.noData = true;
      }
      this.shwLdr = false;
      this.rowSelection = 'multiple';
      this.columnDefs = [
        {
          headerName: 'Sno',
          field: 'sno', 
          sortable: true, 
          cellStyle: {textAlign: 'center'}, 
          filter: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          width: 100
        },
        {
          headerName: 'Cluster No.',
          field: 'clustr_nu', 
          sortable: true, 
          cellStyle: {textAlign: 'center'}, 
          filter: true
        },
        {
          headerName: 'Cluster',
          field: 'clstr_nm', 
          cellStyle: {textAlign: 'center'}, 
          sortable: true, 
          filter: true
        },
        {
          headerName: 'Workpackage',
          field: 'wrk_pckge_nm', 
          cellStyle: {textAlign: 'center'}, 
          sortable: true, 
          filter: true
        }
      ];
    });
  }
}

getUlbUnaprvdMcrptLst(): void{
  this.rowData = [];
  this.columnDefs = [];
  this.emp = 0;
  this.clstr = 0;
  this.shwLdr = true;
if (this.ulbId !== undefined){
  this.locationService.getUnapprovedMcrpts(this.ulbId, (err, ulbUnaprvdMcrpt) => {
    this.rowData = ulbUnaprvdMcrpt.data;
    if (this.rowData.length !== 0){
      this.noData = false;
    }else{
      this.noData = true;
    }
    this.shwLdr = false;
    this.rowSelection = 'multiple';
    this.columnDefs = [
      {
        headerName: 'Sno',
        field: 'sno', 
        sortable: true, 
        cellStyle: {textAlign: 'center'}, 
        filter: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        width: 100
      },
      {
        headerName: 'Micropocket No.',
        field: 'mcrpt_nu', 
        sortable: true, 
        cellStyle: {textAlign: 'center'}, 
        filter: true
      },
      {
        headerName: 'Micropocket',
        field: 'mcrpt_nm', 
        sortable: true, 
        cellStyle: {textAlign: 'center'}, 
        filter: true
      },
      {
        headerName: 'Cluster',
        field: 'clstr_nm', 
        cellStyle: {textAlign: 'center'}, 
        sortable: true, 
        filter: true
      },
      {
        headerName: 'Workpackage',
        field: 'wrk_pckge_nm', 
        cellStyle: {textAlign: 'center'}, 
        sortable: true, 
        filter: true
      }
    ];
  });
}
}

  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.params = params;
  }

  getSlctdRows(): void {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length >= 1){
      this.shwSveBtn = true;
    }else{
      this.shwSveBtn = false;
    }
    
    if (selectedRows[0].emp_id){
      this.emp = 0;
      for (let i = 0; i < selectedRows.length; i++) {
        this.emp = this.emp + ',' + selectedRows[i].emp_id;
      }
    }
    else if (selectedRows[0].clustr_nu){
      this.clstr = 0;
      for (let i = 0; i < selectedRows.length; i++) {
        this.clstr = this.clstr + ',' + selectedRows[i].clsrt_id;
      }
    }
    else if (selectedRows[0].mcrpt_nu){
      this.mcrpt = 0;
      for (let i = 0; i < selectedRows.length; i++) {
        this.mcrpt = this.mcrpt + ',' + selectedRows[i].mcrpt_id;
      }
    }
  }

  approveEmp(): void{
    if (this.emp !== 0 ){
      const aprdEmpData = {
        'ulb_id': this.ulbId,
        'emp': this.emp
      };
      this.locationService.approvedUlbEmps(aprdEmpData, (err, ulbApprvd) => {
        if (ulbApprvd.status === 200){
          const dialogRef = this.dialog.open(ApprovalDialog, {
            width: '500px',
            data: { cnfrmflag: false, successFlag: true, operation: 'Approved' }
          });
          this.getUlbUnaprvdEmpLst();
        }
      });
    }
    
    if (this.clstr !== 0 ){
      const aprdClstrData = {
        'ulb_id': this.ulbId,
        'clstr': this.clstr
      };
      this.locationService.approvedUlbClstrs(aprdClstrData, (err, ulbApprvdClstrs) => {
        if (ulbApprvdClstrs.status === 200){
          const dialogRef = this.dialog.open(ApprovalDialog, {
            width: '500px',
            data: { cnfrmflag: false, successFlag: true, operation: 'Approved' }
          });
          this.getUlbUnaprvdClstrLst();
        }
      });
    }

    if (this.mcrpt !== 0 ){
      const aprdMcrptData = {
        'ulb_id': this.ulbId,
        'mcrpt': this.mcrpt
      };
      this.locationService.approvedUlbMcrpt(aprdMcrptData, (err, ulbApprvdMcrpts) => {
        
        if (ulbApprvdMcrpts.status === 200){
          const dialogRef = this.dialog.open(ApprovalDialog, {
            width: '500px',
            data: { cnfrmflag: false, successFlag: true, operation: 'Approved' }
          });
          this.getUlbUnaprvdMcrptLst();
        }
      });
    }

  }
}


@Component({
  selector: 'approve-dialog',
  templateUrl: '../confirm-dialog.html'
})
export class ApprovalDialog {
  constructor(
    public dialogRef: MatDialogRef<ApprovalDialog>, 
    @Inject(MAT_DIALOG_DATA) public data: {id: '', entityname: '', cnfrmflag: false, successFlag: false, operation: '', dtls: ''}) {
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(id, entityname, cnfrmflag, successFlag, operation, dtls): void {
  }
  getColor(operation) { (2)
    switch (operation) {
      case 'Created':
        return 'green';
      case 'Assigned':
        return 'green';
      case 'Updated':
        return '#ffc107';
      case 'Disabled':
        return '#ffc107';
      case 'Removed':
        return '#red';
      case 'Deleted':
        return 'red';
    }
  }
}
