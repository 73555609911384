import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { GeoLocationsService } from '../geo-locations.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Street } from './streets.model';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ulb } from '../ulb/ulb.model';
import { Workpackage } from '../workpackage/workpackage';
import { UserService } from 'app/providers/user/user.serivce';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';



@Component({
  selector: 'app-streets',
  templateUrl: './streets.component.html',
  styleUrls: ['./streets.component.scss']
})
export class StreetsComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'strt_nm','wrd_nm', 'mcrpt_nm', 'clstr_nm', 'wrk_pckge_nm', 'strt_gte_nu', 'end_gte_nu', 'gts_ct', 'hse_hlds_ct', 'lndmrk_nm', 'actions'];
  dataSource = new MatTableDataSource<Street>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  streetForm: FormGroup;
  selectedwrd = new FormControl();
  selectedMp = new FormControl();
  @Input() ulb;
  ulb_id;
  wrk_pckge_id = 0;
  mcrpt_id: 0;
  clsrt_id = 0;
  ulbLst;
  wrkpckgsLst: Workpackage[];
  clstrList = null;
  mcrptList = [];

  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  userPerm: any = {};
  errMsg = false;
  shwLdr: boolean = false;
  wrdtopFltrOptions: Observable<Ulb[]>;
  mptopFltrOptions: Observable<Ulb[]>;
  mpFltrOptions: Observable<Ulb[]>;
  ulbFltrOptions: Observable<Ulb[]>;
  isExcelDwnLd: any;
  excelFileNm: string;
  excelDataHeaders: string[];
  streetsData = [];
  data;
  ulb_nm: any;
  noData;
  wrd_id = 0;
  wrdList: any;
  constructor(private geoService: GeoLocationsService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog, private userService: UserService) {

  }

  ngOnInit() {
    this.ulb_id = this.ulb.ulb_id;
    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.streetForm = new FormGroup({
      strt_nm: new FormControl('', Validators.required),
      strt_gte_nu: new FormControl('', Validators.required),
      end_gte_nu: new FormControl('', Validators.required),
      hse_hlds_ct: new FormControl('', Validators.required),
      ulb_id: new FormControl('', Validators.required),
      // wrk_pckge_id: new FormControl('', Validators.required),
      // clsrt_id: new FormControl('', Validators.required),
      mcrpt_id: new FormControl('', Validators.required),
      lndmrk_nm: new FormControl(''),
    });
    // this.streetForm.get("ulb_id").setValue(this.ulb.ulb_id);
    this.userPerm = this.userService.CURRENT_PERM;
    this.onUlbChange();
    this.streetLst();
    
    this.wrdtopFltrOptions = this.selectedwrd.valueChanges
      .pipe(
        startWith(''),
        map(value => this._wrdfilter(value))
      );
    this.mptopFltrOptions = this.selectedMp.valueChanges
      .pipe(
        startWith(''),
        map(value => this._mpfilter(value))
      );

    this.ulbFltrOptions = this.streetForm.get('ulb_id').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
    this.mpFltrOptions = this.streetForm.get('mcrpt_id').valueChanges
      .pipe(
        startWith(''),
        map(value => this._mpfilter(value))
      );
  }

  private _wrdfilter(value): Ulb[] {
    if (value) {
      return this.wrdList.filter(item => ((typeof value === 'string') ? item.wrd_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.wrd_nm.toLowerCase().indexOf(value['wrd_nm'].toLowerCase()) === 0));
    }
    return this.wrdList;
  }
  private _mpfilter(value): Ulb[] {
    if (value) {
      return this.mcrptList.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
    }
    return this.mcrptList;
  }

  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  autocompletemdisplayFn(mp?: Ulb): string | undefined {
    return mp ? mp.mcrpt_nm : undefined;
  }
  autocompletewrddisplayFn(wrd?: Ulb): string | undefined {
    return wrd ? wrd.wrd_nm : undefined;
  }


  topwrdSelected(evt) {
    this.wrd_id = evt.option.value.wrd_id;
    this.getMicropockets();
  }
  topmpSelected(evt) {
    this.mcrpt_id = evt.option.value.mcrpt_id;
    this.streetLst();
  }
  ulbSelected(evt) {
    this.ulb_id = evt.option.value.ulb_id;
    this.onUlbChange();
  }
  streetLst() {
    this.shwLdr = true;
    let postData = { ulb_id: this.ulb.ulb_id, mcrpt_id: this.mcrpt_id }
    this.geoService.streetLst(postData, (err, res) => {
      this.dataSource.filter = '';
      this.dataSource.data = [];
      this.data = res.data;
      this.dataSource.data = res.data;
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    })
  }
  ngOnChanges(changes: any) {
    if (changes && changes.ulb) {
      this.selectedMp.setValue('');
      this.ulb_id = changes.ulb.currentValue.ulb_id;
      this.ulb_nm = changes.ulb.currentValue.ulb_nm;
      this.onUlbChange();
      this.mcrpt_id = 0;
      this.streetLst();
    }
  }
  onUlbChange() {
    // this.geoService.workPackage(this.ulb_id, (err, res) => {
    //   this.wrkpckgsLst = res.data;
    // }); 
    this.getWards();
    this.getMicropockets();
    // this.geoService.getMicroPocket(this.ulb_id, this.wrk_pckge_id, this.clsrt_id, (err, res) => {
    //   this.mcrptList = res.data;
    //   if (this.opLbl != 'Edit' && this.opLbl != 'Add')
    //     this.selectedMp.setValue('');
    //     if(this.opLbl != 'Edit')
    //     this.streetForm.get('mcrpt_id').setValue('');
    // });
  }
  getWards(){
    let postData = {
      ulb_id : this.ulb_id
    }
    this.geoService.wardsLst(postData, (err, res) => {
      this.wrdList = res.data;
      console.log(res);
      if (this.opLbl != 'Edit' && this.opLbl != 'Add')
        this.selectedwrd.setValue('');
    });
  }
  getMicropockets(){

    let postData = {
      ulb_id : this.ulb_id, 
      wrk_pckge_id : this.wrk_pckge_id, 
      clsrt_id:this.clsrt_id,
      wrd_id : this.wrd_id
    }
    
    this.geoService.getMicroPocketMstr(postData, (err, res) => {
      this.mcrptList = res.data;
      if (this.opLbl != 'Edit' && this.opLbl != 'Add')
        this.selectedMp.setValue('');
        if(this.opLbl != 'Edit')
        this.streetForm.get('mcrpt_id').setValue('');
    });
  }
  // onWpChange() {
  //   this.geoService.getClusterDetails(this.ulb_id, this.wrk_pckge_id, (err, res) => {
  //     this.clstrList = res.data;
  //   });
  // }
  // onClstrChange() {
  //   console.log(this.ulb_id, this.wrk_pckge_id, this.clsrt_id);
  //   this.geoService.getMicroPocket(this.ulb_id, this.wrk_pckge_id, this.clsrt_id, (err, res) => {
  //     this.mcrptList = res.data;
  //   });
  // }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }

  saveData() {
    if (this.streetForm.status == 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt = this.streetForm.value;
    this.updtDt.ulb_id = this.streetForm.value.ulb_id.ulb_id;
    this.updtDt.mcrpt_id = this.streetForm.value.mcrpt_id.mcrpt_id;
    // let flag = false;
    // this.dataSource.data.forEach(val => {
    //   if (val.strt_nm == this.updtDt.strt_nm) {
    //     flag = true;
    //   }
    // })
    // if (flag) {
    //   this.errMsg = true;
    //   return;
    // }
    if (this.updtDt.end_gte_nu > this.updtDt.strt_gte_nu)
      this.updtDt.gts_ct = (this.updtDt.end_gte_nu - this.updtDt.strt_gte_nu) + 1;
    else if (this.updtDt.strt_gte_nu > this.updtDt.end_gte_nu)
      this.updtDt.gts_ct = (this.updtDt.strt_gte_nu - this.updtDt.end_gte_nu) + 1;
    this.geoService.addStreet(this.updtDt, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: "Created" }
        });
        this.streetForm.reset();
        this.streetLst();
      }
    })
  }
  updateData() {
    if (this.streetForm.status == 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt.strt_nm = this.streetForm.value.strt_nm;
    this.updtDt.ulb_id = this.streetForm.value.ulb_id.ulb_id;
    this.updtDt.strt_gte_nu = this.streetForm.value.strt_gte_nu;
    this.updtDt.end_gte_nu = this.streetForm.value.end_gte_nu;
    this.updtDt.hse_hlds_ct = this.streetForm.value.hse_hlds_ct;
    // this.updtDt.wrk_pckge_id = this.streetForm.value.wrk_pckge_id;
    // this.updtDt.clsrt_id = this.streetForm.value.clsrt_id;
    this.updtDt.mcrpt_id = this.streetForm.value.mcrpt_id.mcrpt_id;
    this.updtDt.lndmrk_nm = this.streetForm.value.lndmrk_nm;
    if (this.updtDt.end_gte_nu > this.updtDt.strt_gte_nu)
      this.updtDt.gts_ct = (this.updtDt.end_gte_nu - this.updtDt.strt_gte_nu) + 1;
    else if (this.updtDt.strt_gte_nu > this.updtDt.end_gte_nu)
      this.updtDt.gts_ct = (this.updtDt.strt_gte_nu - this.updtDt.end_gte_nu) + 1;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.strt_nm == this.updtDt.strt_nm) {
        if (val.strt_id != this.updtDt.strt_id) {
          flag = true;
        }
      }
    })
    if (flag) {
      this.errMsg = true;
      return;
    }
    this.geoService.updtStreet(this.updtDt, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
        });
        this.streetLst();
      }
    })
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'Street', cnfrmflag: true, successFlag: false, operation: "Deleted", dtls: nm }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.streetLst();
    })
  }

  resetForm() {
    this.streetForm.reset();
  }
  opensideBar(key, updtData, event: any) {
    this.onUlbChange();
    if (updtData) {
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.streetForm.get('strt_nm').setValue(updtData.strt_nm);
      this.streetForm.get('ulb_id').setValue({ ulb_id: updtData.ulb_id, ulb_nm: updtData.ulb_nm });
      this.streetForm.get('strt_gte_nu').setValue(updtData.strt_gte_nu);
      this.streetForm.get('end_gte_nu').setValue(updtData.end_gte_nu);
      this.streetForm.get('hse_hlds_ct').setValue(updtData.hse_hlds_ct);
      this.streetForm.get('lndmrk_nm').setValue(updtData.lndmrk_nm);
      // this.streetForm.get('wrk_pckge_id').setValue(updtData.wrk_pckge_id);
      // this.streetForm.get('clsrt_id').setValue(updtData.clsrt_id);
      this.streetForm.get('mcrpt_id').setValue({mcrpt_id:updtData.mcrpt_id,mcrpt_nm:updtData.mcrpt_nm});
      this.ulb_id = updtData.ulb_id;
      // this.wrk_pckge_id = updtData.wrk_pckge_id;
      // this.clsrt_id = updtData.clsrt_id;
      // this.onWpChange();
      // this.onClstrChange();
    }
    else {
      this.streetForm.get("ulb_id").setValue('');
      this.streetForm.get('strt_nm').setValue('');
      this.streetForm.get('strt_gte_nu').setValue('');
      this.streetForm.get('end_gte_nu').setValue('');
      this.streetForm.get('hse_hlds_ct').setValue('');
      this.streetForm.get('lndmrk_nm').setValue('');
      this.streetForm.get('mcrpt_id').setValue('');
      this.streetForm.value
      this.opLbl = "Add";
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  downloadExcel($event): void {
    this.streetsData = [];
    if (this.data.length > 0) {
      for (let i of this.data) {
        this.streetsData.push({
          'sno': i.sno ? i.sno : '', 'street': i.strt_nm ? i.strt_nm : '', 'Micropocket': i.mcrpt_nm ? i.mcrpt_nm : '',
          'Cluster': i.clstr_nm ? i.clstr_nm : '', 'Work Package': i.wrk_pckge_nm ? i.wrk_pckge_nm : '',
          'Start Gate No.': i.strt_gte_nu ? i.strt_gte_nu : '', 'End Gate No.': i.end_gte_nu ? i.end_gte_nu : '', 'No. of Gates': i.gts_ct ? i.gts_ct : '',
          'No. of House Holds': i.hse_hlds_ct ? i.hse_hlds_ct : '', 'Land Mark': i.lndmrk_nm ? i.lndmrk_nm : ''
        });
      }
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['sno', 'street', 'Micropocket', 'Cluster', 'Work Package', 'Start Gate No.', 'End Gate No.', 'No. of Gates', 'No. of House Holds', 'Land Mark'];
        this.excelFileNm = 'streets_data_'+this.ulb_nm;
    }
  }
}
