import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimaryComponent, PrimaryConfirmDialog } from './primary/primary.component';
import { SecondaryComponent} from './secondary/secondary.component';
import { MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, 
         MatTableModule, MatPaginatorModule  , MatSortModule , MatButtonModule, MatIconModule } from '@angular/material';
import { FuseSidebarModule } from '@fuse/components';

import { VehicleService } from './vehicles.service';
import { ScheduleinterfaceComponent, SecondaryConfirmDialog} from './scheduleinterface/scheduleinterface.component'
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import {MatToolbarModule} from '@angular/material/toolbar';
import {ConfirmDialog} from './vehicles.component';

const vehicles_mstr_routes: Routes = [
  { path: 'PCREntry', component: PrimaryComponent } ,
  { path: 'secondaryvehicles', component: SecondaryComponent },
  { path: 'scheduleinterface', component: ScheduleinterfaceComponent }
];

@NgModule({
  declarations: [
    PrimaryComponent,
    SecondaryComponent,
    ScheduleinterfaceComponent,
    ConfirmDialog
  ],
  imports: [

    RouterModule.forChild(vehicles_mstr_routes),

    FlexLayoutModule,
    CommonModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatCardModule, 
    MatInputModule, 
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule, 
    MatTableModule, 
    MatPaginatorModule  ,
    MatSortModule , 
    MatButtonModule, 
    MatIconModule,
    FuseSidebarModule,  
    MatAutocompleteModule,
    MatToolbarModule
  ],
   providers: [VehicleService],
   entryComponents: [ConfirmDialog, PrimaryConfirmDialog, SecondaryConfirmDialog],
})
export class VehiclesModule { }
