import { Component, OnInit } from '@angular/core';
import { MainServiceService } from '../../main-service.service';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-mandal-wise-report',
  templateUrl: './mandal-wise-report.component.html',
  styleUrls: ['./mandal-wise-report.component.scss']
})
export class MandalWiseReportComponent implements OnInit {
  rowData = [];
  columnDefs = [];
  pagination: boolean = true;
  paginationPageSize = 10;
  date = new Date();
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  shwLdr = true;
  frmevents;
  toevents;
  isExcelDwnLd;
  excelFileNm;
  excelDataHeaders;
  noData;
  mandalExcelData=[];
  constructor(public datepipe: DatePipe, private dataService: MainServiceService) { }

  ngOnInit(): void {
    this.getmandalwise();
  }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
  }

  getmandalwise(): void{

    if (this.frmevents === undefined){
      this.from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }else{
      this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    }

    if (this.toevents === undefined){
      this.to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }else{
      this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
    }

      const inspctOffcrData = {
        'frm_dt': this.from_date,
        'to_dt': this.to_date
      };
      
      const inspctOfcrsBnfsCnts_rte = 'beneficiary/counts/mandelwise';

      this.dataService.getByData(inspctOfcrsBnfsCnts_rte, inspctOffcrData, (err, res) => {
        this.rowData = res.data;
        this.shwLdr = false;

        this.columnDefs = [
          {
            headerName: 'District',
            field: 'dstrct_nm',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Total Equiments',
            field: 'tot',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'},
            width:250
          },
          {
            headerName: 'In Progess',
            field: 'on_gng',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'},
            width:250
          },
          {
            headerName: 'Visited',
            field: 'cmpltd',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'},
            width:250
          },
          {
            headerName: 'Not Started',
            field: 'not_cmpltd',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'},width:250
          },
        ];
      });
    }
    
    downloadExcel($event): void {
      // if (this.rowData.length > 0) {
        for (let i = 0; i < this.rowData.length; i++) {
     
          this.mandalExcelData.push({dstrct_nm:this.rowData[i].dstrct_nm, 
            mndle_nm:this.rowData[i].mndle_nm, 
            tot :this.rowData[i].tot,
            on_gng :this.rowData[i].on_gng, 
            cmpltd:this.rowData[i].cmpltd, 
            not_cmpltd:this.rowData[i].not_cmpltd });
  
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['District', 'Mandal', 'Total Equiments', 'In Progess', 'Visited', 'Not Started'];
        this.excelFileNm = 'Mandal_Wise_rpt';
      }
    }
}
