import { Component, OnInit } from '@angular/core';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { Rtms2Service } from '../rtms2.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { rfidTags } from 'app/main/hardware/rfidtags/rfidtags.model';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserService } from 'app/providers/user/user.serivce';
import { ConfirmDialog } from '../rtms2.component';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-search-by-rfid-tag',
  templateUrl: './search-by-rfid-tag.component.html',
  styleUrls: ['./search-by-rfid-tag.component.scss']
})
export class SearchByRfidTagComponent implements OnInit {
  headerForm: FormGroup;
  tagForm: FormGroup;
  editcodeForm: FormGroup;
  ulbLst: any;
  ulb_id: any;
  ulb_nm: any;
  ulbtopFltrOptions: Observable<rfidTags[]>;
  userPerm: { id: any; c_in: number; r_in: number; u_in: number; d_in: number; };
  show: boolean;
  tgInfo: boolean;
  tag_no: any;
  tagData: any;
  gateData: any;
  nwTagData: any;
  mpMonthlyCvrg: any;
  isEdit: boolean = false;
  private dialogRef: MatDialogRef<string>;
  rfidCode: any;
  noData: boolean = false;
  msg: string;
  isEditperm: boolean;
  noTgData: boolean = false;

  constructor(private geoService: GeoLocationsService, private Rtms2Service: Rtms2Service, private userService: UserService, public dialog: MatDialog,) { }

  ngOnInit() {
    this.userPerm = this.userService.CURRENT_PERM;
    // console.log(this.userPerm);
    if (this.userPerm.u_in == 1) {
      this.isEditperm = true;
    } else {
      this.isEditperm = false;
    }
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );

    this.tagForm = new FormGroup({
      tag_no: new FormControl(''),
    });

  }

  autocompleteudisplayFn(ulb?: rfidTags): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): rfidTags[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  ulbSelected(evt) {
    this.ulb_id = evt.option.value.ulb_id;
    this.ulb_nm = evt.option.value.ulb_nm;

  }


  getTagInfo() {
    this.tag_no = this.tagForm.value.tag_no,
      this.tgInfo = false; this.show = false; this.isEdit = false;

    this.Rtms2Service.getTagInfo(this.tag_no, 1, (err, res) => {
      this.tgInfo = true;
      if (err || !res.data) {
        // this.tagData = null;
        // this.gateData = null;
        // this.noTgData=true;
        return;
      }
      // console.log(res.data);
      this.tagData = res.data.tagInfo[0];
      this.gateData = res.data.gateInfo[0];
      // console.log(this.tagData);
      // console.log(this.gateData);
      // console.log("length:" + res.data.gateInfo.length);
      if (this.gateData && res.data.gateInfo.length > 0) {
        // console.log('In IF');
        this.noData = false;
      }
      else if (this.gateData == undefined) {
        this.noData = true;
        this.msg = "Not Assigned to Gate"
      }
      else if (!this.tagData) {
        this.noTgData = true;

      }

      // console.log(this.userPerm)
      if (this.userPerm.u_in == 1) {
        this.isEditperm = true;
      } else {
        this.isEditperm = false;
      }

    })
  }
  forEdit() {
    this.editcodeForm = new FormGroup({
      rfid_tge_scn_cd: new FormControl(this.tagData.rfid_tge_scn_cd),
    });
  }

  requestEdit() {
    this.isEdit = true;
    this.forEdit();
  }

  saveTagCode() {
    var postData = {
      ulb_id: this.tagData.ulb_id,
      rfid_tge_scn_cd: this.editcodeForm.value.rfid_tge_scn_cd,
      tge_id: this.tagData.rfid_tge_id
    }
    console.log(postData);
    this.Rtms2Service.updtTagCode(postData, (err, res) => {
      // console.log(res);
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
        });
        this.show = false;
        this.getTagInfo();
      }
    })


  }
  onReset() {
    this.isEdit = false;
    // this.getTagInfo();
  }

}
