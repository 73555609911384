import { Component, OnInit, ViewChild, Input, NgZone } from '@angular/core';

import { ulbdashboard } from './ulb-dashboard.model';
import { ActivatedRoute } from '@angular/router';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/providers/user/user.serivce';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ulbdashboardService } from './ulbdashboard.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-ulb-dashboard',
  templateUrl: './ulb-dashboard.component.html',
  styleUrls: ['./ulb-dashboard.component.scss']
})
export class UlbDashboardComponent implements OnInit {
  private chart: am4charts.XYChart;
  gatesCrdRcvdData:any=[];
  gatesCrdCuntData:any;
  attndnceCrdCuntData:any;
  wghmntCrdCuntData:any;
  vhclsCrdCuntData:any;
  attndnceCrdRcvdData:any=[];
  vhclsCrdRcvdData:any=[];
  ttlGtesScnedCountUlbWiseDta:any;
  ttlGtesTotlCountUlbWiseDta:any;
  GatesPrcntageCaluclatn:any;
  attndncePrcntageCaluclatn:any;
  transprtPrcntageCaluclatn:any;
  ttlAttndncePrsntCountUlbWiseDta:any;
  ttlAttndnceTotlCountUlbWiseDta:any;
  scnrsData:any=[];
  id = 3;
  ulb_id: number;
  ulb_nm: string;
  ulbLst: ulbdashboard[] = [];
  ulb;
  ulbname;
  headerForm: FormGroup;
  ulbtopFltrOptions: Observable<ulbdashboard[]>;
  date = new Date();
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  ulbid;
  getAttndnceChrtData;
  getGatesChrtDta;
  getWtChrtDta;
  getTranChrtDta;
  wgmntCrdRcvdData:any=[];


  constructor(public datepipe: DatePipe, private ulbdashboardService: ulbdashboardService, private zone: NgZone) {
  }

  ngOnInit(): void {


    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulbid = this.ulbLst[0].ulb_id;
    this.ulbname = this.ulbLst[0].ulb_nm;
    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });


    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );

    this.getGatesCards();
    this.getAttendanceCards();
    this.getWghmntCards();
    this.getTrpsCards();
    this.getScnrs();
  }


  autocompletetpudisplayFn(ulb?: ulbdashboard): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }


  private _ulbfilter(value): ulbdashboard[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }



  onUlbChange(evt) {
    this.ulbid = evt.option.value.ulb_id;
    this.ulbname = evt.option.value.ulb_nm;
    this.getGatesCards();
    this.getAttendanceCards();
    this.getWghmntCards();
    this.getTrpsCards();
    this.getScnrs();

  }

  getGatesCards() {
    const getgtsCrds = {
      'ulb_id': this.ulbid,
      'from_dt': this.from_date,
      'to_dt': this.to_date,
    };
    // Add data for gates

    this.ulbdashboardService.getGateChartData(this.ulbid, (err, res) => {
      this.getGatesChrtDta = res.data;

      this.getGatesChrtDta.forEach(element => {
        element['Dates'] = element.dt;
        element['scanned Gates'] = element.scnd_gts;
        element['Total Gates'] = element.tot_gts;
        element['totalPercent'] = element.percentage;


      });
      gatechart.data = this.getGatesChrtDta;
      console.log(gatechart.data);
    });
    this.ulbdashboardService.PrtUlbGatesCrd(getgtsCrds, (err, gatesCrdRcvdDat) => {
      this.gatesCrdRcvdData = gatesCrdRcvdDat.data;
      this.ttlGtesScnedCountUlbWiseDta = this.gatesCrdRcvdData[0].hh_scnd + this.gatesCrdRcvdData[0].rc_scnd + this.gatesCrdRcvdData[0].a_scnd + this.gatesCrdRcvdData[0].bc_scnd;
      this.ttlGtesTotlCountUlbWiseDta = this.gatesCrdRcvdData[0].hh_tot + this.gatesCrdRcvdData[0].rc_tot + this.gatesCrdRcvdData[0].a_tot + this.gatesCrdRcvdData[0].bc_tot;
      this.GatesPrcntageCaluclatn = Math.round((this.ttlGtesScnedCountUlbWiseDta / this.ttlGtesTotlCountUlbWiseDta) * 100);
    });
    let gatechart = am4core.create('gateschartdiv', am4charts.XYChart);

    // Create axes
    let categoryAxisGate = gatechart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxisGate.dataFields.category = 'Dates';
    categoryAxisGate.title.text = 'Dates';

    // categoryAxisGate.renderer.grid.template.location = 0;
    // categoryAxisGate.renderer.minGridDistance = 20;

    categoryAxisGate.renderer.grid.template.location = 0;
    categoryAxisGate.renderer.minGridDistance = 30;
    categoryAxisGate.renderer.labels.template.rotation = -90;


    let valueAxisGate = gatechart.yAxes.push(new am4charts.ValueAxis());
    valueAxisGate.title.text = 'No.of Gates Scanned';
    valueAxisGate.calculateTotals = true;
    valueAxisGate.strictMinMax = true;
    valueAxisGate.renderer.labels.template.adapter.add('text', function (text) {
      return text + "";
    });

    // Create series
    // Total Gates
    let seriesGate = gatechart.series.push(new am4charts.ColumnSeries());
    seriesGate.dataFields.valueY = 'scanned Gates';
    //  seriesGate.dataFields.valueYShow = 'totalPercent';
    seriesGate.dataFields.categoryX = 'Dates';
    seriesGate.name = 'Scanned Gates';

    seriesGate.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
    seriesGate.stacked = true;
    seriesGate.strokeWidth = 3;
    seriesGate.strokeOpacity = 4;
    // seriesGate.columns.template.fillOpacity = .8;

    // Gates Scanned
    let seriesGt2 = gatechart.series.push(new am4charts.ColumnSeries());
    seriesGt2.dataFields.valueY = 'Total Gates';
    //  seriesGt2.dataFields.valueYShow = 'totalPercent';
    seriesGt2.dataFields.categoryX = 'Dates';
    seriesGt2.name = 'Total Gates';
    seriesGt2.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
    seriesGt2.stacked = true;
    // Add legend
    gatechart.legend = new am4charts.Legend();



  }
  getAttendanceCards() {
    const getAttndnceCrds = {
      'ulb_id': this.ulbid,
      'from_dt': this.from_date,
      'to_dt': this.to_date,
    };
    this.ulbdashboardService.getAttndnceChartData(this.ulbid, (err, res) => {
      this.getAttndnceChrtData = res.data;
      this.getAttndnceChrtData.forEach(element => {
        element['Dates'] = element.dt;
        element['supervisors'] = element.act_Supervisor;
        element['phworkers'] = element.act_PHWorker;
        element['drivers'] = element.act_Driver;
        element['loaders'] = element.act_Loader;

      });

      chart.data = this.getAttndnceChrtData;
      console.log(chart.data);
    });

    this.ulbdashboardService.PrtUlbAttndnceCrd(getAttndnceCrds, (err, attndnceCrdRcvdData) => {
      this.attndnceCrdRcvdData = attndnceCrdRcvdData.data;
      this.ttlAttndncePrsntCountUlbWiseDta = this.attndnceCrdRcvdData[0].ph_prsnt + this.attndnceCrdRcvdData[0].dr_prsnt + this.attndnceCrdRcvdData[0].ld_prsnt + this.attndnceCrdRcvdData[0].sp_prsnt;
      this.ttlAttndnceTotlCountUlbWiseDta = this.attndnceCrdRcvdData[0].ph_tot + this.attndnceCrdRcvdData[0].dr_tot + this.attndnceCrdRcvdData[0].ld_tot + this.attndnceCrdRcvdData[0].sp_tot;
      this.attndncePrcntageCaluclatn = Math.round((this.ttlAttndncePrsntCountUlbWiseDta / this.ttlAttndnceTotlCountUlbWiseDta) * 100);
    });
    let chart = am4core.create('chartdiv', am4charts.XYChart);
    // Create axes

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'Dates';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.title.text = 'Dates';

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'No.of Members attended';
    valueAxis.calculateTotals = true;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.adapter.add('text', function (text) {
      return text;
    });


    // Create series
    // supervisors
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'supervisors';
    // series.dataFields.valueYShow = 'total';
    series.dataFields.categoryX = 'Dates';
    series.name = 'Supervisors';
    series.strokeWidth = 3;
    series.tensionX = 0.8;
    series.bullets.push(new am4charts.CircleBullet());
    series.stroke = am4core.color("#fcd202");
    series.fill = am4core.color("#5f56bf");

    // PH workers
    let serieswrk = chart.series.push(new am4charts.LineSeries());
    serieswrk.dataFields.valueY = 'phworkers';
    serieswrk.dataFields.categoryX = 'Dates';
    serieswrk.name = 'PH Workers';
    serieswrk.strokeWidth = 3;
    serieswrk.tensionX = 0.8;
    serieswrk.bullets.push(new am4charts.CircleBullet());
    serieswrk.stroke = am4core.color("#b0de09");
    serieswrk.fill = am4core.color("#5f56bf");
    // serieswrk.tooltipText = '{categoryX}:[bold]{valueY}[/]';
    // Drivers
    let seriesdr = chart.series.push(new am4charts.LineSeries());
    seriesdr.dataFields.valueY = 'drivers';
    seriesdr.dataFields.categoryX = 'Dates';
    seriesdr.name = 'Drivers';
    seriesdr.strokeWidth = 3;
    seriesdr.tensionX = 0.8;
    seriesdr.bullets.push(new am4charts.CircleBullet());
    seriesdr.stroke = am4core.color("#41abb3");
    seriesdr.fill = am4core.color("#5f56bf");
    // seriesdr.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    //  Loaders

    let seriesld = chart.series.push(new am4charts.LineSeries());
    seriesld.dataFields.valueY = 'loaders';
    seriesld.dataFields.categoryX = 'Dates';
    seriesld.name = 'Loaders';
    seriesld.strokeWidth = 3;
    seriesld.tensionX = 0.8;
    seriesld.bullets.push(new am4charts.CircleBullet());
    seriesld.stroke = am4core.color("#0d8ecf");
    seriesld.fill = am4core.color("#5f56bf");
    // seriesld.tooltipText = '{categoryX}: [bold]{valueY}[/]';

    // Add legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }
  getWghmntCards() {
    const getWgmntCrds = {
      'ulb_id': this.ulbid,
      'from_dt': this.from_date,
      'to_dt': this.to_date,
    };
    console.log(this.ulbid);
    // Add data for weight
    this.ulbdashboardService.getWtChartData(this.ulbid, (err, res) => {
      this.getWtChrtDta = res.data;

      this.getWtChrtDta.forEach(element => {
        element['Dates'] = element.dt;
        element['wetweight'] = element.wet;
        element['dryweight'] = element.dry;
        element['otherweight'] = element.othrs;


      });

      console.log(this.getWtChrtDta);
      weightchart.data = this.getWtChrtDta;
      console.log(weightchart.data);
    });

    this.ulbdashboardService.PrtUlbWgmntCrd(getWgmntCrds, (err, wgmntCrdRcvdDatas) => {
      this.wgmntCrdRcvdData = wgmntCrdRcvdDatas.data;
      this.wghmntCrdCuntData = this.wgmntCrdRcvdData[0].dry + this.wgmntCrdRcvdData[0].wet + this.wgmntCrdRcvdData[0].othrs;
    });


    let weightchart = am4core.create('wtchartdiv', am4charts.XYChart);

    // Create axes

    let categoryAxisWt = weightchart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxisWt.dataFields.category = 'Dates';
    categoryAxisWt.renderer.grid.template.location = 0;
    categoryAxisWt.renderer.minGridDistance = 30;
    categoryAxisWt.renderer.labels.template.rotation = -90;
    categoryAxisWt.title.text = 'Dates';


    let valueAxisWt = weightchart.yAxes.push(new am4charts.ValueAxis());
    valueAxisWt.title.text = 'Waste weight in Tons';
    valueAxisWt.calculateTotals = true;
    valueAxisWt.renderer.labels.template.adapter.add('text', function (text) {
      return text;
    });
    // Create series
    // Wet Weight
    let seriesWt = weightchart.series.push(new am4charts.LineSeries());
    seriesWt.dataFields.valueY = 'wetweight';
    seriesWt.dataFields.categoryX = 'Dates';
    // seriesWt.tooltipText = '{valueY.value}';
    // seriesWt.tooltipText = '{name}: [bold]{valueY}[/]';
    seriesWt.name = 'Wet weight';
    seriesWt.stroke = am4core.color("#9b6cb0");
    seriesWt.strokeWidth = 3;
    seriesWt.tensionX = 0.8;
    seriesWt.bullets.push(new am4charts.CircleBullet());
    seriesWt.fill = am4core.color("#974eb7");
    // Dry Weight
    let seriesDWt = weightchart.series.push(new am4charts.LineSeries());
    seriesDWt.dataFields.valueY = 'dryweight';
    seriesDWt.dataFields.categoryX = 'Dates';
    // seriesDWt.tooltipText = '{valueY.value}';
    seriesDWt.name = 'Dry Weight';
    seriesDWt.stroke = am4core.color("#41abb3");
    seriesDWt.strokeWidth = 3;
    seriesDWt.tensionX = 0.8;
    seriesDWt.bullets.push(new am4charts.CircleBullet());

    seriesDWt.fill = am4core.color("#974eb7");
    // Other Weight
    let seriesOWt = weightchart.series.push(new am4charts.LineSeries());
    seriesOWt.dataFields.valueY = 'otherweight';
    seriesOWt.dataFields.categoryX = 'Dates';
    // seriesOWt.tooltipText = '{valueY.value}';
    seriesOWt.name = 'Other weight';
    seriesOWt.stroke = am4core.color("#b0de09");
    seriesOWt.strokeWidth = 3;
    seriesOWt.tensionX = 0.8;
    seriesOWt.bullets.push(new am4charts.CircleBullet());
    // seriesOWt.tooltipText = 'hi';
    seriesOWt.fill = am4core.color("#974eb7");
    // seriesOWt. = new am4charts.XYCursor();

    // Add legend
    weightchart.legend = new am4charts.Legend();

    // Add cursor
    weightchart.cursor = new am4charts.XYCursor();


  }

  getTrpsCards() {
    console.log(this.id);
    const getVhclsCrds = {
      'ulb_id': this.ulbid,
      'from_dt': this.from_date,
      'to_dt': this.to_date,

    };
    console.log(getVhclsCrds);
    console.log(this.ulbid);
    // add data for Transportation
    this.ulbdashboardService.getTranspChartData(this.ulbid, this.id, (err, res) => {
      this.getTranChrtDta = res.data;
      this.getTranChrtDta.forEach(element => {
        element['Dates'] = element.dt;
        element['traveled trps'] = element.covered_tp;
        element['Total trips'] = element.tot_tp;
        element['totalPercent'] = element.percentage;

      });
      console.log(this.getTranChrtDta);
      transchart.data = this.getTranChrtDta;
      console.log(transchart.data);
    });
    this.ulbdashboardService.PrtUlbVhclCrd(getVhclsCrds, (err, vhclsCrdRcvdDatas) => {
      this.vhclsCrdRcvdData = vhclsCrdRcvdDatas.data;
      console.log(this.vhclsCrdRcvdData);
      this.transprtPrcntageCaluclatn = Math.round((this.vhclsCrdRcvdData[0].travlld / this.vhclsCrdRcvdData[0].trips) * 100);
    });
    let transchart = am4core.create('trchartdiv', am4charts.XYChart);
    // Create axes
    let categoryAxisTr = transchart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxisTr.dataFields.category = 'Dates';
    categoryAxisTr.title.text = 'Dates';

    // categoryAxisGate.renderer.grid.template.location = 0;
    // categoryAxisGate.renderer.minGridDistance = 20;

    categoryAxisTr.renderer.grid.template.location = 0;
    categoryAxisTr.renderer.minGridDistance = 30;
    categoryAxisTr.renderer.labels.template.rotation = -90;


    let valueAxisTr = transchart.yAxes.push(new am4charts.ValueAxis());
    valueAxisTr.title.text = 'No.of trips vs Target trips ';
    //  valueAxisTr.calculateTotals = true;
    //  valueAxisTr.min = 0;
    //  valueAxisTr.max = 100;
    valueAxisTr.strictMinMax = true;
    valueAxisTr.renderer.labels.template.adapter.add('text', function (text) {
      return text;
    });

    // Create series
    // Total Trips
    let seriesTr = transchart.series.push(new am4charts.ColumnSeries());
    seriesTr.dataFields.valueY = 'traveled trps';
    //  seriesTr.dataFields.valueYShow = 'totalPercent';
    seriesTr.dataFields.categoryX = 'Dates';
    seriesTr.name = 'Travelled trips';
    seriesTr.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
    seriesTr.stacked = true;
    seriesTr.strokeWidth = 3;
    seriesTr.strokeOpacity = 1;


    // Travelled Trips
    let seriesT = transchart.series.push(new am4charts.ColumnSeries());
    seriesT.dataFields.valueY = 'Total trips';
    //  seriesT.dataFields.valueYShow = 'totalPercent';
    seriesT.dataFields.categoryX = 'Dates';
    seriesT.name = 'Total trips';
    seriesT.columns.template.tooltipText = '{name}: {valueY}';
    seriesT.stacked = true;

    // Add legend
    transchart.legend = new am4charts.Legend();

  }
  getScnrs() {

    const getScnrs = {
      'ulb_id': this.ulbid,
      'from_dt': this.from_date,
      'to_dt': this.to_date,
    };
    this.ulbdashboardService.PrtUlbScnrsCrd(getScnrs, (err, scnrsUlbData) => {
      this.scnrsData = scnrsUlbData.data;
    });
  }
  getAWdth(awdth) {
    return { 'width': awdth + '%' };
  }
  getWdth(wdth) {
    return { 'width': wdth + '%' };
  }

  getTWdth(twdth) {
    return { 'width': twdth + '%' };
  }
}
