import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MisreportsService } from 'app/main/misreports/misreports.service';




@Component({
  selector: 'app-exceptional-reports',
  templateUrl: './exceptional-reports.component.html',
  styleUrls: ['./exceptional-reports.component.scss']
})
export class ExceptionalReportsComponent implements OnInit {

  excprptLst = [];
  
  constructor(private router: Router, private misreportsService: MisreportsService) { }

  ngOnInit(): void{
    this.reportLst();
  }


  reportLst(): void{
    this.misreportsService.getReportList(8, (err, res) => {
      this.excprptLst = res.data;
      console.log(this.excprptLst);
    });
  }

  gotoRpt(data): void{
    // console.log(data);
    if (data.rpt_id === 54){
      this.router.navigate(['/gatesnotscanned']);
    }
    if (data.rpt_id === 58){
      this.router.navigate(['/unassignedemployee']);
    }
    if (data.rpt_id === 59){
      this.router.navigate(['/wronglyscnd']);
    }
    if (data.rpt_id === 53){
      this.router.navigate(['/employeeabsentreport']);
    }
    if (data.rpt_id === 60){
      this.router.navigate(['/scannersnotworking']);
    }
    if (data.rpt_id === 62){
      this.router.navigate(['/trackersnotworking']);
    }
    if (data.rpt_id === 61 ){
      this.router.navigate(['/vehiclesnotcoveredtrips']);
    }
    if (data.rpt_id === 55){
      this.router.navigate(['/transperpointsnotscanned']);
    }
    if (data.rpt_id === 57){
      this.router.navigate(['/weighmentnottaken']);
    }
  
}
}
