import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ScoreCardService {


  constructor(private http: HttpClient) { }

  // get Trackers list for Hardware in Master Data 
  public getKpiUlbs(cb) {
    this.http.get('/Kpiulbs').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public getUlbs(cb) {
    this.http.get('/ulb').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public getsrvPrvdrs(uid, cb) {
    this.http.get('/kpi/card/' + uid).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  public getsbctgryWseData(kid, cb) {
    this.http.get('/kpi/table/' + kid).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
}
