import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VehicleService } from './vehicles.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: '../../main/confirm-dialog.html'
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>, 
    public VehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA) public data: {id: '', entityname: '', cnfrmflag: false, successFlag: false, operation: '',dtls:''}) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(id, entityname, cnfrmflag, successFlag, operation,dtls): void {
    switch(entityname) { 
      case 'Route': { 
        // this.VehicleService.deleteRoute(id, (err, res) => {
        //   if(!err){
        //     this.data.cnfrmflag = false;
        //   }
        // });
        break; 
      } 
   } 

  }
  getColor(operation) { (2)
    switch (operation) {
      case 'Created':
        return 'green';
      case 'Updated':
        return '#ffc107';
      case 'Removed':
        return '#red';
      case 'Deleted':
        return 'red';
    }
  }
}
