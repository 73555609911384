import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FinesService } from 'app/main/fines.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { MatDatepickerInputEvent } from '@angular/material';
@Component({
  selector: 'app-fines-rpt',
  templateUrl: './fines-rpt.component.html',
  styleUrls: ['./fines-rpt.component.scss']
})
export class FinesRptComponent implements OnInit {
  ulbNm;
  ulbId;
  headerForm: any;
  ulbLst: any;
  ulbid: any;
  ulbnm: any;
  ulbtopFltrOptions: any;
  ulbNM: any;
  rowData: any = [];
  columnDefs: any = [];
  paginationPageSize = 10;
  pagination: boolean = true;
  pdfData: any = [];
  isPdfDwnLd: any;
  pdfPagesize: string;
  PdfpageOrientation: string;
  pdfheaderRows: number;
  ReportHeader: string;
  pdftableData: any;
  tableHeadersWthDataValues: string[];
  fileName: string;
  autoresize: boolean;
  fitToPage: boolean;
  date = new Date();
  namePattern: string | RegExp;
  mobnumPattern: string | RegExp;
  frmevents: any = [];
  toevents: any = [];
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  fltrDta: any = [];
  ctgryfltrDta: any = [];
  ofcrlLst: any = [];
  ctgryLst: any = [];
  wardFltrdata: any = [];
  WardLst: any = [];
  ofcr_id: any;
  ctgry_id: any;
  mp_ward_id: any;
  fltrForm: FormGroup;
  ph: any = [];
  excelDataHeaders: string[];
  isExcelDwnLd: boolean;
  excelFileNm: string;
  ExcelData: any = [];
  amnt = 0;
  clctdamnt;
  constructor(private FinesService: FinesService, private route: ActivatedRoute, public datepipe: DatePipe, private router: Router) {
  }

  ngOnInit(): void {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulbid = this.ulbLst[0].ulb_id;
    this.ulbnm = this.ulbLst[0].ulb_nm;

    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });
    this.getfinetabledata();
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
    this.getDtFltr();
    this.fltrForm = new FormGroup({
      ofcr_nm: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
      ctgry_nm: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
      mble_ph: new FormControl('', [Validators.required, Validators.pattern(this.mobnumPattern)]),
      wrd_nu: new FormControl('', Validators.required),
      from_date: new FormControl(''),
      to_date: new FormControl(''),
    });
    this.getFltrData();
    this.fltrForm.get('ofcr_nm').setValue(0);
    this.fltrForm.get('ctgry_nm').setValue(0);
    this.fltrForm.get('mble_ph').setValue(0);
    this.fltrForm.get('wrd_nu').setValue(0);
    this.getDtFltr();
  }
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
  }
  getDtFltr(): void {

    if (this.frmevents[0] === undefined) {
      this.frmevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }
    if (this.toevents[0] === undefined) {
      this.toevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }

    this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
    this.getFltrData();
    this.getfinetabledata();
  }
  public mble_ph: FormControl = new FormControl();
  onUlbChange(evt) {
    this.ulbid = evt.option.value.ulb_id;
    this.ulbNM = evt.option.value.ulb_nm;
    this.getfinetabledata();
  }

  getFltrData(): void {
    this.FinesService.FinerptFltroptionsData((err, res) => {
      this.fltrDta = res.data;
      this.ofcrlLst = [{ usr_id: 0, fst_nm: 'ALL' }];
      for (let o of res.data) {
        this.ofcrlLst.push(o);
      }
    });
    this.FinesService.FinerptctgryFltroptionsData((err, res) => {
      this.ctgryfltrDta = res.data;
      this.ctgryLst = [{ ctgry_id: 0, ctgry_nm: 'ALL' }];
      for (let c of res.data) {
        this.ctgryLst.push(c);
      }
    });
    this.FinesService.FinerptWrdFltroptionsData((err, res) => {
      this.wardFltrdata = res.data;
      this.WardLst = [{ mp_ward_id: 0, mp_ward_nm: 'ALL' }];
      for (let w of res.data) {
        this.WardLst.push(w);
      }
    });
  }
  getfinetabledata() {
    this.isPdfDwnLd = false;
    this.pdfData = [];
    this.isExcelDwnLd = false;
    this.ExcelData = [];
    const data = {
      'frmdt': this.from_date,
      'todt': this.to_date,
      'agnt': this.ofcr_id,
      'ctgry': this.ctgry_id,
      'wrd': this.mp_ward_id,
      'phno': this.mble_ph,
    }
    this.FinesService.fineReportTblDta(data, (err, res) => {
      this.rowData = res.data;
      var index = 1;
      this.clctdamnt = 0;
      for (var i = 0; i < this.rowData.length; i++) {
        this.rowData[i].IncVl = index++;
        this.clctdamnt += this.rowData[i].amnt;
      }
      this.pdfData = [];
      this.ExcelData = [];
      this.columnDefs = [{ headerName: 'Sno', field: 'IncVl', width: 50, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Date', field: 'fn_date', width: 100, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Officer Name', field: 'ofcr_nm', width: 120, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Ward Number', field: 'wrd_nu', width: 120, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Receipt Number', field: 'recept_no', width: 150, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Name of Offender', field: 'nme', width: 150, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Category', field: 'ctgry_nm', width: 150, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Fine Amount', field: 'amnt', width: 100, cellStyle: { textAlign: 'center' } ,filter: true,},
      { headerName: 'Phone Number', field: 'mble_ph', width: 200, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Location', field: 'lctn_txt', width: 250, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Challan No', field: 'chalana_no', width: 90, cellStyle: { textAlign: 'center' },filter: true, },
      { headerName: 'Challan Date', field: 'chalana_dt', width: 100, cellStyle: { textAlign: 'center' } ,filter: true,},];

      for (var i = 0; i < this.rowData.length; i++) {
        this.pdfData.push({
          'Date': this.rowData[i].fn_date,
          'Officer Name': this.rowData[i].ofcr_nm,
          'Ward No': this.rowData[i].wrd_nu,
          'Receipt no': this.rowData[i].recept_no,
          'Name of Offender': this.rowData[i].nme,
          'Category': this.rowData[i].ctgry_nm,
          'Fine Amount': this.rowData[i].amnt,
          'Phone Number': this.rowData[i].mble_ph,
          'Location': this.rowData[i].lctn_txt,
          'Challan No': this.rowData[i].chalana_no,
          'Challan Date': this.rowData[i].chalana_dt,

        });
      }
      // excell data

      this.ExcelData.push({
        'Date': "",
        'Officer Name': "",
        'Ward No': "",
        // 'Offender': "",
        'Receipt no': "",
        'Name of Offender': "",
        'Category Name': "",
        'Fine Amount:"",                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           Amount': "",
        'Phone Number': "",
        'Location': "",
        'Challan No': "",
        'Challan Date': "",
      });
      this.ExcelData.push({

        'Date': "",
        'Officer Name': "",
        'Ward No': "",
        'Receipt no': "",
        'Name of Offender': " Fines report ( " + this.from_date + " ) collected amount - " + this.clctdamnt,
        'Category Name': "",
        'Fine Amount': "",
        'Phone Number': "",
        'Location': "",
        'Challan No': "",
        'Challan Date': "",
      });
      this.ExcelData.push({
        'Date': "Date",
        'Officer Name': "Officer Name",
        'Ward No': "Ward No",
        'Receipt no': "Receipt no",
        'Name of Offender': "Name of Offender",
        'Category Name': "Category Name",
        'Fine Amount': "Fine Amount",
        'Phone Number': "Phone Number",
        'Location': "Location",
        'Challan No': "Challan No",
        'Challan Date': "Challan Date",
      });
      for (let i = 0; i < this.rowData.length; i++) {
        this.ExcelData.push({
          'Date': this.rowData[i].fn_date,
          'Officer Name': this.rowData[i].ofcr_nm,
          'Ward No': this.rowData[i].wrd_nu,
          'Receipt no': this.rowData[i].recept_no,
          'Name of Offender': this.rowData[i].nme,
          'Category Name': this.rowData[i].ctgry_nm,
          'Fine Amount': this.rowData[i].amnt,
          'Phone Number': this.rowData[i].mble_ph,
          'Location': this.rowData[i].lctn_txt,
          'Challan No': this.rowData[i].chalana_no,
          'Challan Date': this.rowData[i].chalana_dt,
        });
      }
    });
  }
  downloadPdf($event): void {
    if (this.pdfData.length > 0) {
      const date = (this.from_date).split("-").reverse().join("-");
      // const todate=(this.to_date).split("-").reverse().join("-");
      this.isPdfDwnLd = $event;
      this.pdfPagesize = 'A3';
      this.PdfpageOrientation = 'Landscape';
      this.pdfheaderRows = 1;
      this.ReportHeader = this.ulbnm + ' - User Fee Report ' + ' ' + '(' + date + ')';
      this.pdftableData = this.pdfData;
      this.tableHeadersWthDataValues = ['Date', 'Officer Name', 'Ward No', 'Receipt no', 'Name of Offender', 'Category', 'Fine Amount', 'Phone Number', 'Location', 'Challan No', 'Challan Date'];
      this.fileName = 'Fines report';
      this.autoresize = true;
      this.fitToPage = true;

    }
  }
  downloadExcel($event): void {
    if (this.ExcelData.length > 0) {
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['Date', 'Officer Name', 'Ward No', 'Receipt no', 'Name of Offender', 'Category Name', 'Fine Amount', 'Phone Number', 'Location', 'Challan No', 'Challan Date'];
      this.excelFileNm = 'Fines rpt';
    }
  }


}
