
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { MatTabsModule, MatIconModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatTableModule, MatPaginatorModule } from '@angular/material';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { DashboardComponent } from './dashboard.component';
import { DsWidgetGraphComponent } from './../ds-widget-graph/ds-widget-graph.component';
// import { UlbDashboardComponent } from './../ulb-dashboard/ulb-dashboard.component';

const hw_routes: Routes = [
  {
    path: 'dashboard', component: DashboardComponent
  }    
];

@NgModule({
  declarations: [
    DashboardComponent,
    DsWidgetGraphComponent,
    // UlbDashboardComponent
  ],
  imports: [

    RouterModule.forChild(hw_routes),

    MatTabsModule,
    MatCardModule,
    MatFormFieldModule, MatSelectModule, MatInputModule, MatOptionModule, FlexLayoutModule,
    MatTableModule,MatPaginatorModule,MatIconModule,
    CommonModule,
    NgxChartsModule,
    FuseWidgetModule,
  ],
  exports: [
    DashboardComponent
  ]
  
})
export class DashboardModule { }
