import { Component, OnInit } from '@angular/core';
import { ulbFilterModel } from 'app/main/misreports/transportation_reports/day-wise-trans-status/day-wise-trans-status.model';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { map, startWith } from 'rxjs/operators';
import { UlbIncentiveReportService } from 'app/main/ulb-incentive-report/ulb-incentive-report.service';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-ulb-wise-incentive-report',
  templateUrl: './ulb-wise-incentive-report.component.html',
  styleUrls: ['./ulb-wise-incentive-report.component.scss']
})
export class UlbWiseIncentiveReportComponent implements OnInit {
  position: number;
  weight: number;
  symbol: string;
  dataSource;
  displayedColumns;
  date = new Date();
  ystrdy = this.date.setDate(this.date.getDate() - 1);
  from_date = this.datepipe.transform(this.ystrdy, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.ystrdy, 'yyyy-MM-dd');
  frmevents: string[] = [];
  toevents: string[] = [];
  ulbLst: ulbFilterModel[] = [];
  ulbFltrOptions: Observable<ulbFilterModel[]>;
  fltrForm: FormGroup;
  ulbId: number = 0;
  ulbsLst;
  noData: boolean;
  ulbIncntvData = [];
  totlScr = 0;
  frmDtFrmt;
  toDtFrmt;
  constructor(public datepipe: DatePipe, private ulbIncentiveReportService: UlbIncentiveReportService) { }

  ngOnInit(): void {
    this.fltrForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required),
    });

    this.ulbFltrOptions = this.fltrForm.get('selectedUlb').valueChanges
      .pipe(
      startWith(''),
      map(value => this._ulbfilter(value))
      );

    // this.misreportsService.getUlbLst((err, ulbLst) => {
    //   for (let u of ulbLst.data) {
    //     this.ulbLst.push(u);
    //   }
    //   this.fltrForm.get('selectedUlb').setValue('');
    // });
    this.ulbsLst = JSON.parse(localStorage.getItem('ulbs'));
    for (let u of this.ulbsLst) {
      this.ulbLst.push(u);
    }
    this.fltrForm.get('selectedUlb').setValue('');
    this.displayedColumns = ['position', 'name', 'weight', 'symbol', 'symbol1'];
    this.getDtFltr();
  }
  ulbSelected(evt: any) {
    this.ulbId = evt.option.value.ulb_id;
  }

  private _ulbfilter(value): ulbFilterModel[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  autocompleteUlbDisplayFn(apprt?: ulbFilterModel): string | undefined {
    return apprt ? apprt.ulb_nm : undefined;
  }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
  }

  getDtFltr(): void {
    if (this.ulbId === 0) {
      this.noData = true;
    } else {
      this.noData = false;

      if (this.frmevents[0] === undefined) {
        this.frmevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
      }
      if (this.toevents[0] === undefined) {
        this.toevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
      }
  
      this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
      this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');

      this.frmDtFrmt = this.datepipe.transform(this.from_date, 'dd-MM-yyyy'); 
      this.toDtFrmt = this.datepipe.transform(this.to_date, 'dd-MM-yyyy'); 

      const ulbIncntveFltrData = {
        'strt_dt': this.from_date,
        'end_dt': this.to_date,
        'ulb_id': this.ulbId
      };

      this.ulbIncentiveReportService.insentivesData(ulbIncntveFltrData, (err, ulbIncntveData) => {
        this.ulbIncntvData = ulbIncntveData.data;
        if (this.ulbIncntvData[0].frs_reg_per === undefined){
          this.ulbIncntvData[0].frs_reg_per = 0;
        }
        this.totlScr = 0;
        this.totlScr =  this.totlScr +  +this.ulbIncntvData[0].fnce_dly_achvd + +this.ulbIncntvData[0].frs_dly_achvd + 
                        +this.ulbIncntvData[0].frs_reg_achvd + +this.ulbIncntvData[0].scn_dly_achvd + +this.ulbIncntvData[0].wght_dly_achvd;
      });
    }
  }
  getPdf(): void{
    const data = document.getElementById('contentToConvert');  
    html2canvas(data, null).then(canvas => {  
      // Few necessary setting options  
      const imgWidth = 210;   
      const pageHeight = 295;    
      const imgHeight = canvas.height * imgWidth / canvas.width;  
      const heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      const position = 10;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('Ulb_Incentive_Calculator.pdf'); // Generated PDF   
    }); 

  }
}
