import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HardwareService } from './../hardware.service';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Tracker } from './trackers.model'
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { GeoLocationsService } from './../../geo-locations/geo-locations.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { UserService } from 'app/providers/user/user.serivce';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';

@Component({
  selector: 'app-trackers',
  templateUrl: './trackers.component.html',
  styleUrls: ['./trackers.component.scss']
})
export class TrackersComponent implements OnInit {

  Tracker: Tracker[] = [];
  headerForm: FormGroup;
  trackerForm: FormGroup;
  ulbLst: Ulb[] = [];
  tckrCtgryLst: any = [];
  tckrTypLst: any = [];
  ulbid;
  userPerm: any = {};
  noData;
  displayedColumns: string[] = ['sno', 'dvce_nm', 'imei_nu', 'mble_nu', 'assigned/unassigned', 'asrt_nm', 'asrt_sts_nm','device_received_ts', 'actions'];
  dataSource = new MatTableDataSource<Tracker>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  errMsg = false;
  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  shwLdr: boolean;
  lists;
  mobnumPattern = '^((\\+91-?)|0)?[0-9]{10,13}$';
  imeiumPattern = '^((\\+91-?)|0)?[0-9]{15}$';
  ulbtopFltrOptions: Observable<Ulb[]>;
  tracstat;
  asrtstsid;
  constructor(private geoService: GeoLocationsService, private hardwareService: HardwareService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog,
    private userService: UserService) { }


  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });

    // this.hardwareService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // })
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulbid = this.ulbLst[0].ulb_id;
    this.trackerForm = new FormGroup({
      dvce_nm: new FormControl('', Validators.required),
      imei_nu: new FormControl('', [Validators.required, Validators.pattern(this.imeiumPattern)]),
      mble_nu: new FormControl('', [Validators.required, Validators.pattern(this.mobnumPattern)]),
      ulb_id: new FormControl('', Validators.required),
      asrt_sts_id: new FormControl('', Validators.required),
    });

    this.trackerForm.get('ulb_id').setValue(this.ulbLst[0].ulb_id);

    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });
    this.getTrackers();

    // get Trackers status for sidebar
    this.hardwareService.getTrackerStatus(this.ulbid, (err, trckerstat_res) => {
      this.lists = trckerstat_res.data;
    });

    this.userPerm = this.userService.CURRENT_PERM;
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
  }
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }


  onUlbChange(evt) {
    this.ulbid = evt.option.value.ulb_id;
    this.getTrackers();
  }
  // onUlbChange(uid){
  //   this.ulbid = uid;
  //   this.getTrackers();
  // }
  getTrackers() {
    this.dataSource.data = [];
    this.shwLdr = true;
    this.hardwareService.hardwareTrackers(this.ulbid, (err, trcker_res) => {
      this.dataSource.data = trcker_res.data;
      console.log(this.dataSource.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  // getTrcrStatus(data) {
  //   this.tracstat = data.value;
  // }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }
  saveData() {
    // console.log(this.trackerForm);
    this.errMsg = false;
    if (this.trackerForm.status === 'INVALID') {
      return false;
    }
    // console.log(this.trackerForm.value);
    this.updtDt = this.trackerForm.value;
    // console.log(this.updtDt);
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.dvce_nm === this.updtDt.dvce_nm) {
        flag = true;
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }

   
    const ulb = this.ulbLst.find(c => c.ulb_id === this.ulbid);
    this.updtDt.ulb_id = this.ulbid;
    this.updtDt.clnt_id = ulb['clnt_id'];
    this.updtDt.tnt_id = ulb['tnt_id'];
    this.updtDt.dvce_ctgry_id = 3;
    this.updtDt.dvce_type_id = 1;
    this.updtDt.asrt_sts_id = this.asrtstsid;

    this.hardwareService.addTracker(this.updtDt, (err, res) => {
      if (res.status === 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
        });
        this.getTrackers();
      }
    });
  }
  updateData() {
    if (this.trackerForm.status === 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt.dvce_nm = this.trackerForm.value.dvce_nm;
    this.updtDt.imei_nu = this.trackerForm.value.imei_nu;
    this.updtDt.mble_nu = this.trackerForm.value.mble_nu;
    this.updtDt.dvce_ctgry_id = 3;
    this.updtDt.dvce_type_id = 1;
    this.updtDt.ulb_id = this.trackerForm.value.ulb_id;
    this.updtDt.asrt_sts_id = this.trackerForm.value.asrt_sts_id;


    const ulb = this.ulbLst.find(c => c.ulb_id === this.ulbid);
    this.updtDt.clnt_id = ulb['clnt_id'];
    this.updtDt.tnt_id = ulb['tnt_id'];
    // console.log(this.updtDt.clnt_id);
    // console.log(this.updtDt.tnt_id);
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.dvce_nm === this.updtDt.dvce_nm) {
        if (val.dvce_id !== this.updtDt.dvce_id) {
          flag = true;
        }
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }
    // console.log(this.updtDt);
    this.hardwareService.updtTracker(this.updtDt, (err, res) => {
      if (res.status === 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
        });
        this.getTrackers();
      }
    });
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'Tracker', cnfrmflag: true, successFlag: false, operation: 'Deleted', dtls: nm }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getTrackers();
    });
  }

  resetForm() {
    this.trackerForm.reset();
  }
  opensideBar(key, updtData) {
    if (updtData) {
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.trackerForm.get('dvce_nm').setValue(updtData.dvce_nm);
      this.trackerForm.get('imei_nu').setValue(updtData.imei_nu);
      this.trackerForm.get('mble_nu').setValue(updtData.mble_nu);
      this.trackerForm.get('ulb_id').setValue(updtData.ulb_id);
      this.trackerForm.get('asrt_sts_id').setValue(updtData.asrt_sts_id);
    }
    else {
      this.trackerForm.get('dvce_nm').setValue('');
      this.trackerForm.get('imei_nu').setValue('');
      this.trackerForm.get('mble_nu').setValue('');
      this.trackerForm.get('asrt_sts_id').setValue('');
      this.opLbl = 'Add';
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
