import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { GeoLocationsService } from '../geo-locations.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Micropocket } from './../../geo-locations/micropocket/micropocket.model';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ulb } from '../ulb/ulb.model';
import { Workpackage } from '../workpackage/workpackage';
import { UserService } from 'app/providers/user/user.serivce';

@Component({
  selector: 'app-micropocket',
  templateUrl: './micropocket.component.html',
  styleUrls: ['./micropocket.component.scss']
})
export class MicropocketComponent implements OnInit {

  Micropockets: Micropocket[] = [];
  displayedColumns: string[] = ['sno', 'mcrpt_nm', 'ulb_nm', 'wrk_pckge_nm', 'clstr_nm','wrd_nm','actions'];
  dataSource = new MatTableDataSource<Micropocket>();

  @Input() ulbid: number;
  @Input() selectedTabIndex: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  mpForm: FormGroup;

  ulb_id;
  wrk_pckge_id:number = 0;
  clsrt_id:number  = 0;
  wrd_id:number  = 0;

  ulbLst: Ulb[] = [];
  wrkpckgsLst: Workpackage[];
  clstrList = null;
  wardList=null;

  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  userPerm: any = {};
  errMsg = false;
  shwLdr: boolean = false;
  noData;
  constructor(private geoService: GeoLocationsService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog, private userService:UserService) {

  }

  ngOnInit() {    
    this.ulb_id = this.ulbid;
    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.mpForm = new FormGroup({
      mcrpt_nm: new FormControl('', Validators.required),
      mcrpt_nu: new FormControl('', Validators.required),
      ulb_id: new FormControl('', Validators.required),
      wrk_pckge_id: new FormControl('', Validators.required),
      clsrt_id: new FormControl('', Validators.required),
      wrd_id: new FormControl('', Validators.required)
    });
    this.userPerm = this.userService.CURRENT_PERM;
    this.mpForm.get("ulb_id").setValue(this.ulbid);
    this.onUlbChange();
    this.geoLocationMicropocket();
  }
  ngOnChanges(changes: any) {
    if (changes && changes.ulbid){
      this.geoLocationMicropocket();
    }
  }
  // ngOnChanges(changes: any) {
  //   if(changes && changes.ulbid)
  //   this.geoLocationMicropocket();
  // }
  onUlbChange() {
    this.geoService.workPackage(this.ulb_id, (err, wrkPckg_res) => {
      this.wrkpckgsLst = wrkPckg_res.data;
    });

    let postData = {
      ulb_id : this.ulb_id
    }
    this.geoService.wardsLst(postData, (err, res) => {
      this.wardList = res.data;
      console.log(res);
      // if (this.opLbl != 'Edit' && this.opLbl != 'Add')
      //   this.selectedwrd.setValue('');
    });

  }
  onWpChange() {
    this.geoService.getClusterDetails(this.ulb_id, this.wrk_pckge_id, (err, clstr_res) => {
      this.clstrList = clstr_res.data;
    });
  }
  geoLocationMicropocket() {
    this.dataSource.data = [];
    this.shwLdr = true;
    this.geoService.getMicroPocket(this.ulbid, this.wrk_pckge_id, this.clsrt_id, (err, micropckt_res) => {
      this.dataSource.data = micropckt_res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }

  saveData() {
    if (this.mpForm.status == 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt = this.mpForm.value;
    console.log(this.updtDt);
    let flag = false;
    this.dataSource.data.forEach(val => {
      if(val.mcrpt_nm == this.updtDt.mcrpt_nm){
        flag = true;
      }
    })
    if(flag){
      this.errMsg = true;
      return;
    }
    this.geoService.addMicropocket(this.updtDt, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation:"Created" }
        });
        this.geoLocationMicropocket();
    }
    })
  }
  updateData() {
    if (this.mpForm.status == 'INVALID') {
      return false;
    }
    console.log(this.mpForm.value.wrd_id);
    this.errMsg = false;
    this.updtDt.mcrpt_nm = this.mpForm.value.mcrpt_nm;
    this.updtDt.ulb_id = this.mpForm.value.ulb_id;
    this.updtDt.wrk_pckge_id = this.mpForm.value.wrk_pckge_id;
    this.updtDt.clsrt_id = this.mpForm.value.clsrt_id;
    this.updtDt.wrd_id = this.mpForm.value.wrd_id;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if(val.mcrpt_nm == this.updtDt.mcrpt_nm){
        if(val.mcrpt_id != this.updtDt.mcrpt_id){
          flag = true;
        }
      }
    })
    if(flag){
      this.errMsg = true;
      return;
    }
    console.log(this.updtDt);
    this.geoService.updtMicropocket(this.updtDt, (err, res) => {
      if (res.status == 200) {
       const dialogRef = this.dialog.open(ConfirmDialog, {
         width: '500px',
         data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
       });
       this.geoLocationMicropocket();
   }
    })
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'Micropocket', cnfrmflag: true, successFlag: false,  operation:"Deleted",dtls:nm}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.geoLocationMicropocket();
    })
  }

  resetForm() {
    this.mpForm.reset();
  }
  opensideBar(key, updtData, event: any) {
    if (updtData) {
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.mpForm.get('mcrpt_nm').setValue(updtData.mcrpt_nm);
      this.mpForm.get('mcrpt_nu').setValue(updtData.mcrpt_nu);
      this.mpForm.get('ulb_id').setValue(updtData.ulb_id);
      this.mpForm.get('wrk_pckge_id').setValue(updtData.wrk_pckge_id);
      this.mpForm.get('clsrt_id').setValue(updtData.clsrt_id);
      this.mpForm.get('wrd_id').setValue(updtData.wrd_id);

      this.ulb_id = updtData.ulb_id;
      this.wrk_pckge_id = updtData.wrk_pckge_id;
      this.wrd_id = updtData.wrd_id;

      this.onWpChange();
    }
    else {
      this.mpForm.get("ulb_id").setValue(this.ulbid);
      this.mpForm.get('mcrpt_nm').setValue('');
      this.mpForm.get('mcrpt_nu').setValue('');
      this.opLbl = "Add";
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

}



