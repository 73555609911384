import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation, Inject, Output, EventEmitter } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgForm } from '@angular/forms';

import { CrudService } from 'app/main/workflow/crud.service';

import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';

import { fuseAnimations } from '@fuse/animations';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';

import * as _ from 'lodash';

import { ChatService } from 'app/main/workflow/assign-user-list/chat.serve';
import { array } from '@amcharts/amcharts4/core';
import { MatSidenav, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DialogData } from 'app/providers/http/sessionPopups';

@Component({
    selector: 'app-assign-user-list',
    templateUrl: './assign-user-list.component.html',
    styleUrls: ['./assign-user-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AssignUserListComponent implements OnInit, OnDestroy {
    chats: any[];
    chatSearch: any;
    contacts: any[];
    searchText: string;
    user: any;
    chat: any;
    dialog: any;
    contact: any;
    replyInput: any;
    selectedChat: any;
    labelPosition = 'after';
    ShwFrwdDrpDw: any;
    asgndLst = [];
    tmlneLst: any;
    cmntdata = [];

    @ViewChild(FusePerfectScrollbarDirective)
    directiveScroll: FusePerfectScrollbarDirective;

    @ViewChildren('replyInput')
    replyInputField;

    @ViewChild('replyForm')
    replyForm: NgForm;

    // Private
    private _unsubscribeAll: Subject<any>;
    imagePath: any;
    Imgsrc: any;
    imagesPrv: any = [];
    images: any = [];
    udr_dtl: any;
    cmmnt: any;
    selectedTabIndex: number = 0;
    sts_id: any;
    pndngLst: any;
    req_Entry_dtls = [];
    cmnt_txt: any;
    postData: { entry_id: any; stg_id: any; usr_id: any; msg: any; };
    ShowNav: boolean;

    @ViewChild(MatSidenav)
    sidenav: MatSidenav;
    showFiller: boolean;
    panelOpenState = false;
    imgdata: { entry_id: any; stg_id: any; usr_id: any; upldData: any; };
    shwBnfcryDtls: boolean;
    shwFrwdBnfcryDtls: boolean;
    usr_lst: any;
    tmlCrd: any;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     * @param {FuseMatSidenavHelperService} _fuseMatSidenavHelperService
     * @param {MediaObserver} _mediaObserver
     */
    constructor(
        private _chatService: ChatService,
        private _fuseMatSidenavHelperService: FuseMatSidenavHelperService,
        public _mediaObserver: MediaObserver, private apiCtrl: CrudService, public usrdialog: MatDialog,
    ) {
        this.showFiller = false;

        // Set the defaults
        this.chatSearch = {
            name: ''
        };
        this.searchText = '';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.udr_dtl = JSON.parse(localStorage.getItem("usrDtls"));
        console.log(this.udr_dtl);
        this.getAsgndPndngData();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.user = this._chatService.user;
        this.chats = this._chatService.chats;
        this.contacts = this._chatService.contacts;

        this._chatService.onChatsUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(updatedChats => {
                this.chats = updatedChats;
            });

        this._chatService.onUserUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(updatedUser => {
                this.user = updatedUser;
            });


        this._chatService.onChatSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatData => {
                if (chatData) {
                    this.selectedChat = chatData;
                    this.contact = chatData.contact;
                    this.dialog = chatData.dialog;
                    this.readyToReply();
                }
            });
        this.getfrwrdusrslst()

    }


    toggle() {
        this.sidenav.toggle();
    }

    selectedIndexChange(index: number) {
        this.selectedTabIndex = index;
        console.log(this.selectedTabIndex)
        this.getAsgndPndngData();
    }

    getAsgndPndngData() {
        this.asgndLst = [];
        this.apiCtrl.get('ticket-system/req-list/' + this.udr_dtl.usr_id).subscribe((res) => {
            console.log(this.selectedTabIndex);
            if (this.selectedTabIndex == 0) {
                this.asgndLst = res['data'][0];
                console.log(this.asgndLst);
                this.tmlneLst = [];

            } else {
                this.pndngLst = res['data'][1];
                console.log(this.pndngLst);
                this.tmlneLst = [];
            }

        })
    }

    reqEntryTmlne(aspn) {
        this.tmlCrd = aspn;
        this.shwBnfcryDtls = true;
        this.shwFrwdBnfcryDtls = true;
        this.req_Entry_dtls = [];
        console.log(aspn)
        this.apiCtrl.get('ticket-system/req-stg-details/' + this.udr_dtl.usr_id + '/' + aspn.req_entry_id).subscribe((res) => {
            console.log(res);
            // this.tmlneLst = res['data'];
            this.req_Entry_dtls.push(res['data'][0].req_dtls);
            this.tmlneLst = res['data'][0].stg_dtls;
            console.log(this.req_Entry_dtls);
            console.log(this.tmlneLst);
        })
    }

    entryTktDtls(tmln, ind) {
        let cmnt = {
            comment: tmln
        }

        this.postData = {
            entry_id: tmln.req_entry_id,
            stg_id: tmln.stg_id,
            usr_id: tmln.usr_id,
            msg: cmnt.comment.comment
        }
        console.log(this.postData)
        this.apiCtrl.create(this.postData, 'ticket-system/stage-chat').subscribe((res: any) => {
            console.log(res);
            this.reqEntryTmlne(tmln);
        })
    }
    tmln(tmln: any) {
        throw new Error("Method not implemented.");
    }

    ngAfterViewInit(): void {
        this.replyInput = this.replyInputField.first.nativeElement;
        this.readyToReply();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chat
     *
     * @param contact
     */
    getChat(contact): void {
        this._chatService.getChat(contact);

        if (!this._mediaObserver.isActive('gt-md')) {
            this._fuseMatSidenavHelperService.getSidenav('chat-left-sidenav').toggle();
        }
    }



    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void {
        this._chatService.setUserStatus(status);
    }

    /**
     * Change left sidenav view
     *
     * @param view
     */
    changeLeftSidenavView(view): void {
        this._chatService.onLeftSidenavViewChanged.next(view);
    }

    /**
     * Logout
     */
    logout(): void {
        console.log('logout triggered');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {
        return (
            message.who === this.contact.id &&
            ((this.dialog[i + 1] && this.dialog[i + 1].who !== this.contact.id) || !this.dialog[i + 1])
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {
        return (i === 0 || this.dialog[i - 1] && this.dialog[i - 1].who !== message.who);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.dialog.length - 1 || this.dialog[i + 1] && this.dialog[i + 1].who !== message.who);
    }

    /**
     * Select contact
     */
    selectContact(): void {
        this._chatService.selectContact(this.contact);
    }

    /**
     * Ready to reply
     */
    readyToReply(): void {
        setTimeout(() => {
            this.focusReplyInput();
            this.scrollToBottom();
        });
    }

    /**
     * Focus to the reply input
     */
    focusReplyInput(): void {
        setTimeout(() => {
            this.replyInput.focus();
        });
    }

    /**
     * Scroll to the bottom
     *
     * @param {number} speed
     */
    scrollToBottom(speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                this.directiveScroll.scrollToBottom(0, speed);
            });
        }
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();

        if (!this.replyForm.form.value.message) {
            return;
        }

        // Message
        const message = {
            who: this.user.id,
            message: this.replyForm.form.value.message,
            time: new Date().toISOString()
        };

        // Add the message to the chat
        this.dialog.push(message);

        // Reset the reply form
        this.replyForm.reset();

        // Update the server
        this._chatService.updateDialog(this.selectedChat.chatId, this.dialog).then(response => {
            this.readyToReply();
        });
    }
    userlst: any;
    fwddrpbtn() {

    }
    // getCmntLngth(arr) {
    //     return arr.length
    // }
    getfrwrdusrslst() {
        this.ShwFrwdDrpDw = true;

        this.apiCtrl.get('ticket-system/users-lst').subscribe((res) => {
            this.userlst = res['data'];
            console.log(this.userlst);
        })
    }
    timeline() {
        this.ShowNav = true;
    }

    preview(files) {
        if (files.length === 0)
            return;

        var mimeType = files[0].type;
        // if (mimeType.match(/image\/*/) == null) {
        //   console.log("Only images are supported.");
        //   return;
        // }

        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event: any) => {
            this.Imgsrc = _event.target.result;
            if ((mimeType.match(/image\/*/) == null)) {
                this.Imgsrc = 'assets/images/file.png'
            }
            this.imagesPrv.push(this.Imgsrc)
            this.images.push(_event.target.result)
        }
    }

    svimg(tmln) {
        console.log(tmln);
        this.imgdata = {
            entry_id: tmln.req_entry_id,
            stg_id: tmln.stg_id,
            usr_id: tmln.usr_id,
            upldData: this.images,
        }
        this.apiCtrl.create(this.imgdata, 'ticket-system/atchmnt-lst').subscribe((res: any) => {
            console.log(res);
            if (res['status'] == 200) {
                this.reqEntryTmlne(tmln);
            }
        }, (err) => {
            console.log(err)
        })
    }

    delImage(img: any) {
        let index = this.imagesPrv.indexOf(img);
        if (index != -1) {
            this.imagesPrv.splice(index, 1)
            this.images.splice(index, 1)
        }
    }


    openDialog(tmln): void {
        this.apiCtrl.get('ticket-system/users-lst').subscribe((res: any) => {
            if (res['status'] == 200) {
                this.usr_lst = res;
                console.log(this.usr_lst);
                const dialogRef = this.usrdialog.open(UserListDialog, {
                    width: '250px',
                    data: { usr_data: this.usr_lst, timeline: tmln }
                });

                dialogRef.afterClosed().subscribe(result => {
                    console.log(result)
                    console.log('The dialog was closed');
                    this.reqEntryTmlne(result)
                });
            }
        }, (err) => {
            console.log(err)
        })

    }

    clseCmplnt(tmln) {
        console.log(tmln);
        tmln['req_in'] = 0;
        let frwdData = {
            req_entry_id: tmln.req_entry_id,
            asgnd_usr_id: tmln.asgnd_usr_id,
            a_in: tmln.a_in,
            frwd_usr_id: tmln.usr_id,
            dt: tmln.isue_dt,
            app_in: 1,
            usr_id: this.udr_dtl.usr_id
        }
        console.log(frwdData)
        this.apiCtrl.create(tmln, 'ticket-system/close-request').subscribe((res: any) => {
            console.log(res);
           this.getAsgndPndngData();
        }, (err) => {
            console.log(err)
        })
    }
}

@Component({
    selector: 'user-list-dialog',
    templateUrl: 'user-list-dialog.html',
})
export class UserListDialog {
    @Output() myEvent = new EventEmitter();
    postData: { asgnd_usr: any; lg_usr: any; };
    usr_dtls: any;
    udr_dtl: any;
    tmln: any;

    constructor(
        public dialogRef: MatDialogRef<UserListDialog>,
        @Inject(MAT_DIALOG_DATA) private data: DialogData, private apiCtrl: CrudService) {
        this.tmln = data.timeline;
        this.usr_dtls = data.usr_data.data;
        this.udr_dtl = JSON.parse(localStorage.getItem("usrDtls"));
    }

    onNoClick(): void {        

    }
    forward(usr_data) {
        console.log(usr_data);
        let req_data = []
        req_data.push({
            req_entry_id: this.tmln.req_entry_id,
            status_id: this.tmln.status_id,
            frwd_usr_id: this.tmln.frwd_usr_id
        })
      
        const data = {
            chckd: req_data,
            asgnd_usr_id: usr_data.usr_id,
            usr_id: this.udr_dtl.usr_id
        };
        console.log(data)
        this.apiCtrl.create(data, 'ticket-system/forward-chcek-list').subscribe((res) => {          
            console.log(res);
            this.dialogRef.close(this.tmln);            
        })
    }
}


