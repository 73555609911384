import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { SessionPopup } from './sessionPopups';
import { MatDialog } from '@angular/material';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { UserService } from '../user/user.serivce';

export const environment = {
    origin: 'http://api.apsmartcity.com:4801/apiv1'
};

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(public dialog: MatDialog, private userService: UserService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user: string = localStorage.getItem('usrDtls') || '';

        req = req.clone({ url: req.url.startsWith('http') ? req.url : environment.origin + req.url });
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

        if (localStorage.getItem('usrDtls')) {
            req = req.clone({ headers: req.headers.set('usr_id', JSON.parse(user).usr_id || '') });
            req = req.clone({ headers: req.headers.set('app', 'web') });
            req = req.clone({ headers: req.headers.set('access_id', this.userService.CURRENT_PERM.id || '') });
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                    if (event.status == 257 || event.status == 258 || event.status == 259) {
                        this.dialog.closeAll();
                        const dialogRef = this.dialog.open(SessionPopup, {
                            width: '250px', disableClose: true
                        });
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // console.log(error);
                let data = {};
                data = {
                    reason: error && error.error ? error.error.reason : '',
                    status: error.status
                };
                // console.log(data);
                // this.errorDialogService.openDialog(data);
                return throwError(error);
            }));
    }
    public internetError() {
        // return this.toastCtrl.create({
        //     message: 'Something went wrong. Please check your internet connectivity',
        //     duration: 3000
        // }).present();
    }
}