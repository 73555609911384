import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { Router, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrackersNotWorking } from './trackers-notworking.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-trackers-notworking',
  templateUrl: './trackers-notworking.component.html',
  styleUrls: ['./trackers-notworking.component.scss']
})
export class TrackersNotworkingComponent implements OnInit {
  columnDefs = [];
  rowData =  [];
  pagination:boolean = true;
  paginationPageSize = 10;
  fltrdata;
  isExcelDwnLd:boolean = false;
  excelDataHeaders;
  excelFileNm;
  ulbId;
  ulbNm;
  shwLdr = true;
  frmevents: string[] = [];
  toevents: string[] = [];
  curdate = new Date();
  curdateFrmt = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
  lst10DyDte = new Date().setDate(new Date().getDate() - 10);
  from_date = '';
  to_date = '';
  ulbGatesData = [];
  rpt_data_id;
  rpt_data_ky;
  rpt_nm;
  mcrptId; mcrptNm;
  noData;
  ulbsLst = [];
  ulb_id;
  wrk_pckge_id = 0;
  clsrt_id = 0;
  mcrpt_id;
  mcrpt_nm;
  mcrptGteLst = [];
  emp_id;
  fltrVsbleOptns;
  trackrsNtWrkngExclData = [];
  rptFrmDt;
  rptToDt;
  ulbLst: TrackersNotWorking[] = [];
  ulbtopFltrOptions: Observable<TrackersNotWorking[]>;
  headerForm: FormGroup;
  ulb;
  constructor(private misreportsService: MisreportsService, public datepipe: DatePipe, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });

    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulbId = this.ulbLst[0].ulb_id;
    this.ulb = {
      ulb_id: this.ulbLst[0].ulb_id,
      ulb_nm: this.ulbLst[0].ulb_nm
    };
    const toSelect = this.ulbLst.find(c => c.ulb_id === this.ulb.ulb_id);
    this.headerForm.get('selectedUlb').setValue(toSelect);

    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
    .pipe(
      startWith(''),
      map(value => this._ulbfilter(value))
    );
    this.fltrVsbleOptns = {
      'isUlb':  true,
      'isMcrpt': false,
      'isGte': false,
      'isEmp': false,
      'isFrmDt': false,
      'isToDt': false
    };
    this.getUlbTrackersNotWorkingStatus();
  }

  autocompleteudisplayFn(ulb?: TrackersNotWorking): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): TrackersNotWorking[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }

  onUlbChange(evt): void {
    this.ulbId = evt.option.value.ulb_id;
    this.getUlbTrackersNotWorkingStatus();
  }

  getUlbTrackersNotWorkingStatus(): void{
    this.trackrsNtWrkngExclData =  [];
    if (this.ulbId === undefined){
      this.noData = true;
    }else{
      this.noData = false;
      const trackrsNtWrkngData = {
        'ulb_id': this.ulbId,
      };
      this.misreportsService.trackersNtWrkngUlbWse(trackrsNtWrkngData, (err, trackersNtWrkng_res) => {
        this.rowData = trackersNtWrkng_res.data;
        if (this.rowData.length){
          this.noData = false;
        }else{
          this.noData = true;
        }
  
        this.shwLdr = false;
  
        for (let i = 0; i < this.rowData.length; i++){
          this.trackrsNtWrkngExclData.push({'sno': this.rowData[i].sno, 'ulb_nm': this.rowData[i].ulb_nm,
                                      'dvce_nm': this.rowData[i].dvce_nm, 'imei_nu': this.rowData[i].imei_nu,
                                       'mble_nu': this.rowData[i].mble_nu,  'trkstatus': this.rowData[i].trkstatus,
                                     });
        }
        this.columnDefs = [
          {headerName: 'SNo.', field: 'sno', width: 200, cellStyle: {textAlign: 'center'}},
          {headerName: 'Ulb', field: 'ulb_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 270},
          {headerName: 'Device Name', field: 'dvce_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 270},
          {headerName: 'Imei Number', field: 'imei_nu', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 270},
          {headerName: 'Mobile Number', field: 'mble_nu', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 270},
          {headerName: 'Trackers Status', field: 'trkstatus', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 270},
      ];
      });
    }
    this.isExcelDwnLd = false;
    this.shwLdr = true;
  }
  downloadExcel($event): void{
    if (this.rowData.length > 0){
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['SNo', 'Ulb', 'Device Name', 'Imei Number' ,'Mobile Number','Trackers Status'
       ];
      this.excelFileNm = 'trackers_notworking_rpt';
    }
  }

}
