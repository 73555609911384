import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { Router, NavigationExtras } from '@angular/router';
import { map, startWith, filter } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import {MatDatepickerModule, MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-long-term-transperpoints-notcovered-report',
  templateUrl: './long-term-transperpoints-notcovered-report.component.html',
  styleUrls: ['./long-term-transperpoints-notcovered-report.component.scss']
})
export class LongTermTransperpointsNotcoveredReportComponent implements OnInit {
  columnDefs = [];
  rowData =  [];
  pagination:boolean = true;
  paginationPageSize = 10;
  fltrdata;
  isExcelDwnLd:boolean = false;
  excelDataHeaders;
  excelFileNm;
  ulbId;
  ulbNm;
  shwLdr = true;
  frmevents: string[] = [];
  toevents: string[] = [];
  curdate = new Date();
  curdateFrmt = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
  lst10DyDte = new Date().setDate(new Date().getDate() - 10);
  from_date = '';
  to_date = '';
  ulbGatesData = [];
  rpt_data_id;
  rpt_data_ky;
  rpt_nm;
  mcrptId; mcrptNm;
  noData;
  ulbsLst = [];
  ulb_id;
  wrk_pckge_id = 0;
  clsrt_id = 0;
  mcrpt_id;
  mcrpt_nm;
  mcrptGteLst = [];
  emp_id;
  fltrVsbleOptns;
  trnsprPntsNtCvrdExclData = [];
  rptFrmDt;
  rptToDt;
  constructor(private misreportsService: MisreportsService, public datepipe: DatePipe, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.fltrVsbleOptns = {
      'isUlb':  true,
      'isMcrpt': false,
      'isGte': false,
      'isEmp': false,
      'isFrmDt': true,
      'isToDt': true
    };
    this.getUlbGtehistoricalTransprPointsNtCvrdStatus();
  }
  receivefltrData(data): void{
    this.ulbId = data.ulb_id;
    this.mcrptId = data.mcrpt_id;
    this.ulbNm = data.ulb_nm;
    this.mcrptNm = data.mcrpt_nm;
    this.from_date = data.from_date;
    this.to_date = data.to_date;
    this.getUlbGtehistoricalTransprPointsNtCvrdStatus();
  }

  getUlbGtehistoricalTransprPointsNtCvrdStatus(): void{
    this.rptFrmDt = '';
    this.rptToDt = '';
    if (this.ulbId === undefined){
      this.noData = true;
    }else{
      this.noData = false;
      
     
      if (this.from_date === this.curdateFrmt){
        this.from_date = this.datepipe.transform(this.lst10DyDte, 'yyyy-MM-dd');
      }
      this.rptFrmDt = this.datepipe.transform(this.from_date, 'dd-MM-yyyy');
      this.rptToDt =  this.datepipe.transform(this.to_date, 'dd-MM-yyyy');
      const scnrsNtWrkngData = {
        'from_dt': this.from_date,
        'to_dt': this.to_date,
        'ulb_id': this.ulbId,
      };
      this.misreportsService.transprPontsNtCvrd(scnrsNtWrkngData, (err, transprPntsNtCvrd_res) => {
        this.rowData = transprPntsNtCvrd_res.data;
        console.log(this.rowData);
        if (this.rowData.length){
          this.noData = false;
        }else{
          this.noData = true;
        }
  
        this.shwLdr = false;
  
        for (let i = 0; i < this.rowData.length; i++){
          this.trnsprPntsNtCvrdExclData.push({'sno': this.rowData[i].sno,
                                      'scnr_cd': this.rowData[i].scnr_cd, 'ulb_nm': this.rowData[i].ulb_nm, 'mcrpt_nm': this.rowData[i].mcrpt_nm,
                                     });
        }
        this.columnDefs = [
          {headerName: 'SNo.', field: 'sno', width: 390, cellStyle: {textAlign: 'center'}},
          {headerName: 'Scanner Code', field: 'scnr_cd', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 390},
          {headerName: 'Ulb', field: 'ulb_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 390},
          {headerName: 'Micropocket ', field: 'mcrpt_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 390},
      ];
      });
    }
    this.isExcelDwnLd = false;
    this.shwLdr = true;
  }

}
