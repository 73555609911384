import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  msg: string;
  btnLst: [];
}

@Component({
  selector: 'app-messge-dialoug',
  templateUrl: './messge-dialoug.component.html',
  styleUrls: ['./messge-dialoug.component.scss']
})
export class MessgeDialougComponent implements OnInit {
  
  flag2: any;
  data;
  
  constructor(public dialogRef: MatDialogRef<MessgeDialougComponent>,
    @Inject(MAT_DIALOG_DATA) public msgData: DialogData) {
    this.data = msgData;
  }

  ngOnInit() {
    // console.log(this.data);

  }

  onBtnClick(res): void {
    this.dialogRef.close(res);
  }
}
