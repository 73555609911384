import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { number } from '@amcharts/amcharts4/core';
import { keyValuesToMap } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { ThrowStmt } from '@angular/compiler';


export interface UserData {
  SNO:number
  name:string
  Days5: number
  Days15:number
  Days45:number
  Days90:number
  Morethan90days:number
  ParKingFiles:number
  interimFiles:number
  Total:number
}






@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})


export class ReportsComponent {
  displayedColumns: string[] = ['S.NO', 'officer Name', '1-5 Days', '6-15 days','16-45 days','46-90 days','Morethan90 days','ParKing Files','interim Files','Total'];
  // dataSource:MatTableDataSource<UserData> 

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
   dataSource:MatTableDataSource<UserData>
   element:UserData[]=[{
    'SNO':1,
    'name':'dhanraj varma',
    'Days5':2, 
    'Days15':4,
    'Days45':16,
    'Days90':3,
    'Morethan90days':2,
    'ParKingFiles':4,
    'interimFiles':5,
    'Total':55
   },
   {
    'SNO':2,
    'name':'pradeep b',
    'Days5':21, 
    'Days15':4,
    'Days45':6,
    'Days90':32,
    'Morethan90days':2,
    'ParKingFiles':42,
    'interimFiles':5,
    'Total':96
   },
   {
    'SNO':3,
    'name':'laxmanredyy',
    'Days5':41, 
    'Days15':5,
    'Days45':6,
    'Days90':32,
    'Morethan90days':12,
    'ParKingFiles':2,
    'interimFiles':5,
    'Total':86
   },
   {
    'SNO':4,
    'name':'ravikanth',
    'Days5':31, 
    'Days15':15,
    'Days45':16,
    'Days90':42,
    'Morethan90days':12,
    'ParKingFiles':5,
    'interimFiles':6, 
    'Total':46
   }]
  constructor() {
    // Create 100 users
    //  const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
   
 
  }
a:any
  ngOnInit() {
    this.dataSource =new MatTableDataSource(this.element);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource,'this is data')
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

/** Builds and returns a new User. */
// function createNewUser(id: number): UserData {
//   const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
//       NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

//   return {
//     id: id.toString(),
//     name: name,
//     progress: Math.round(Math.random() * 100).toString(),
//     color: COLORS[Math.round(Math.random() * (COLORS.length - 1))]
//   };
// }
