import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { fuseAnimations } from '@fuse/animations';
import { DashboardService } from 'app/main/State-dashboard/dashboard.service';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: fuseAnimations
})

export class DashboardComponent implements OnInit {

  colorschema = [];
  colorschema1 = [];
  colorschema2 = [];
  colorschema3 = [];
  attndanceData;
  attdLabel: string = "ATTENDANCE";
  attndanceMatIcon = "assignment_turned_in";

  scannersData;
  scannersLabel: string = "SCANNERS";
  scannerMatIcon = "settings_input_antenna";

  weighmentData;
  weighmentLabel: string = "WEIGHMENT";
  weighmentMatIcon = "view_day";

  transData;
  transLabel: string = "TRANSPORTATION";
  transMatIcon = "commute";

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    this.getAttendance();
    this.getScanners();
    this.getWeighment();
    this.getTransportation();
  }

  getAttendance() {
    this.dashboardService.getAtdPrcntge((err, res) => {
      for (let rec in res.data) {
        this.attndanceData = res.data;
        this.colorschema.push(res.data[rec].color);
      }
    });
  }

  getScanners() {
    this.dashboardService.getScannersPrcntge((err, res) => {
      for (let rec in res.data) {
        this.scannersData = res.data;

        this.colorschema1.push(res.data[rec].color);
      }
    });
  }

  getWeighment() {
    this.dashboardService.getWeighmentPrcntge((err, res) => {
      for (let rec in res.data) {
        this.weighmentData = res.data;
        this.colorschema2.push(res.data[rec].color);
      }
    });
  }

  getTransportation() {
    this.dashboardService.getTransportationPrcntge((err, res) => {
      for (let rec in res.data) {
        this.transData = res.data;
        this.colorschema3.push(res.data[rec].color);
      }
    });
  }

}
