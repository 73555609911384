import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { Ulbgates } from './unassigned-employee.model';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-unassigned-employees-report',
  templateUrl: './unassigned-employees-report.component.html',
  styleUrls: ['./unassigned-employees-report.component.scss']
})
export class UnassignedEmployeesReportComponent implements OnInit {
  ntAssgndEmplyLst = [];
  noData;

  dataSource = new MatTableDataSource<Ulbgates>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  shwLdr = true;
  displayedColumns: string[] = ['Employee id', 'Employee Name', 'Mobile', 'Designation', 'Ulb'];

  columnDefs = [];
  rowData = [];
  pagination: boolean = true;
  paginationPageSize = 25;
  ulbLst;
  fltrData;
  constructor(private route: ActivatedRoute, private misreportsService: MisreportsService, private router: Router) { }

  ngOnInit(): void {
    this.getEmpData();
  }
  getEmpData(){

    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    
    let ulb = '0';
    for (let i = 0; i < this.ulbLst.length; i++) {
      ulb = ulb + ',' + this.ulbLst[i].ulb_id;
    }

    this.fltrData = {
      ulb_lst : ulb
    };

    this.misreportsService.getUnassigndEmpList(this.fltrData, (err, res) => {
      this.ntAssgndEmplyLst = res.data;
      this.shwLdr = false;
      if (this.ntAssgndEmplyLst.length === 0){
        this.noData = true;
      }
      this.rowData = res.data;
      this.columnDefs = [
        {
          headerName: 'Employee id',
          field: 'emp_nu', 
          sortable: true, 
          cellStyle: {textAlign: 'center'},
          filter: true,
          width:220
        },
        {
          headerName: 'Employee Name',
          field: 'emp_nm', 
          sortable: true, 
          cellStyle: {textAlign: 'center'},
          filter: true,
          width:240
        },
        {
          headerName: 'Mobile',
          field: 'mble_nu', 
          sortable: true, 
          cellStyle: {textAlign: 'center'},
          filter: true,
          width:200
        },
        {
          headerName: 'Designation',
          field: 'dsgn_nm', 
          sortable: true, 
          cellStyle: {textAlign: 'center'},
          filter: true,
          width:240
        },
        {
          headerName: 'ULB',
          field: 'ulb_nm', 
          sortable: true, 
          filter: true,
          width:240
        },
      ];
    });
  }
}
