import { Component, OnInit, Inject } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas, { Options } from 'html2canvas';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pdf-download',
  templateUrl: './pdf-download.component.html',
  styleUrls: ['./pdf-download.component.scss']
})
export class PdfDownloadComponent implements OnInit {
  printData: any;
  qrcd: any;
  // dialogRef: any;

  // constructor() { }

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private _data: any, public matDialogRef: MatDialogRef<PdfDownloadComponent>, public dialogRef: MatDialogRef<PdfDownloadComponent>
  ) {
    this.printData = _data;
    // console.log(this.printData)
    // this.qrcd = _data.req_qr_cd;
    console.log(_data);
    let data = {
      req_qr_cd: this.printData.req_qr_cd,
      dt: this.printData.dt,
      req_txt: this.printData.req_txt,
      status_nm: this.printData.status_nm
    }
    this.qrcd = JSON.stringify(data);
  }

  ngOnInit() {
  }
  // print(){
  // let printContents, popupWin;
  // printContents = document.getElementById('print-section').innerHTML;
  // popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  // popupWin.document.open();
  // popupWin.document.write(`
  //   <html>
  //     <head>
  //       <title>Print tab</title>
  //       <style>
  //       //........Customized style.......
  //       </style>
  //     </head>
  // <body onload="window.print();window.close()">${printContents}</body>
  //   </html>`
  // );
  // popupWin.document.close();
  // }
  public captureScreen() {
    var data = document.getElementById('print-section');
    const opts: any = {};
    html2canvas(data, opts as Options).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('NewTkt.pdf'); // Generated PDF   
    });


  }
  closeDialog() {
    this.dialogRef.close();

  }
}
