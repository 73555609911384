import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FinesService } from 'app/main/fines.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss']
})
export class UserReportComponent implements OnInit {
  headerForm: any;
  ulbLst: any;
  ulbid: any;
  ulbnm: any;
  ulbtopFltrOptions: any;
  rowData: any = [];
  columnDefs: any = [];
  paginationPageSize = 10;
  pagination: boolean = true;
  constructor(private FinesService: FinesService, private route: ActivatedRoute, public datepipe: DatePipe, private router: Router) { }

  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulbid = this.ulbLst[0].ulb_id;
    this.ulbnm = this.ulbLst[0].ulb_nm;
    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
    this.getTableData();
  }
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  onUlbChange(evt) {
    this.ulbid = evt.option.value.ulb_id;
    this.ulbnm = evt.option.value.ulb_nm;
    this.getTableData();
  }

  getTableData(): void {
    this.FinesService.UserRptTablData((err, res) => {
      this.rowData = res.data;
      // console.log(this.rowData);
    });
    this.columnDefs = [{ headerName: 'Sno', field: 'sno', width: 50, cellStyle: { textAlign: 'center' } },
    { headerName: 'Officer Name', field: 'name', width: 200, cellStyle: { textAlign: 'center' } },
    { headerName: 'Designation', field: 'desg', width: 150, cellStyle: { textAlign: 'center' } },
    { headerName: 'Phone Number', field: 'ph', width: 200, cellStyle: { textAlign: 'center' } },
    { headerName: 'Email Id', field: 'eml_tx', width: 120, cellStyle: { textAlign: 'center' } },];

  }
}