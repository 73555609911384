import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'app/providers/user/user.serivce';
import { Vehicles } from 'app/main/geo-locations/vehicles/vehicles.model';
import { Rtms2Service } from '../rtms2.service';
import { HardwareService } from 'app/main/hardware/hardware.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-vehicle-tracker-assignment',
  templateUrl: './vehicle-tracker-assignment.component.html',
  styleUrls: ['./vehicle-tracker-assignment.component.scss']
})
export class VehicleTrackerAssignmentComponent implements OnInit {
  vehicles: Vehicles[] = [];
  displayedColumns: string[] = ['sno', 'asrt_nm', 'wrk_pckge_nm', 'clstr_nm', 'asrt_ctgry_nm','asgnd_sts','dvce_nm','dev_imei_nu','vehstatus','lastData','actions'];
  dataSource = new MatTableDataSource<Vehicles>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  headerForm: FormGroup;
  topCtgryFltrForm: FormGroup;
  ulbLst: any;
  ulb_id: number;
  ulb_nm: string;
  wrkpckgsLst: any;
  wrk_pckge_id: number = 0;
  clstrLst: any;
  userPerm: { id: any; c_in: number; r_in: number; u_in: number; d_in: number; };
  vhclOpLbl: string;
  asgnTrckrForm: FormGroup;
  tnt_id: any;
  clnt_id: any;
  vehiclCtgries: any;
  vehiclGrps: any;
  shwLdr: boolean;
  clstrs: any;
  vehiclCtgrylst: any;
  data: any;
  excelData: any;
  isExcelDwnLd: any;
  excelDataHeaders: string[];
  excelFileNm: string;
  devices: any;
  noData;
  ulbtopFltrOptions: Observable<Vehicles[]>;
  constructor(private geoService: GeoLocationsService,
    private _fuseSidebarService: FuseSidebarService,
    public dialog: MatDialog, private userService: UserService,
    private Rtms2Service: Rtms2Service, private hardwareService: HardwareService) { }

  ngOnInit() {
    this.userPerm = this.userService.CURRENT_PERM;
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.topCtgryFltrForm = new FormGroup({
      selectedClstr: new FormControl(''),
      selectedwp: new FormControl(''),
      selectedCtgry: new FormControl('')
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulb_id = this.ulbLst[0].ulb_id;
    this.ulb_nm = this.ulbLst[0].ulb_nm;
    this.clnt_id = this.ulbLst[0].clnt_id;
    this.tnt_id = this.ulbLst[0].tnt_id;
    this.headerForm.get('selectedUlb').setValue({ulb_id:this.ulbLst[0].ulb_id,ulb_nm:this.ulbLst[0].ulb_nm});
    this.topCtgryFltrForm.get("selectedwp").setValue(0);
    this.topCtgryFltrForm.get("selectedClstr").setValue(0);
    this.topCtgryFltrForm.get("selectedCtgry").setValue(0);
    this.geoLocationWorkPackage(this.ulb_id);
    this.geoLocationCluster();
    this.asgnTrckrForm = new FormGroup({
      asrt_nm: new FormControl('', Validators.required),
      dvce: new FormControl('', Validators.required),
      asrt_id: new FormControl(''),
    });
    this.getVehicleCtgries();
    this.wrkpckgsLst = [{ wrk_pckge_id: 0, wrk_pckge_nm: 'ALL' }];
    this.clstrLst = [{ clsrt_id: 0, clsrt_nm: 'ALL' }];
    this.vehiclCtgries = [{ asrt_ctgry_id: 0, asrt_ctgry_nm: 'ALL' }];
    this.getVehiclData()
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
  }

  autocompleteudisplayFn(ulb?: Vehicles): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Vehicles[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  ulbSelected(evt) {
    this.clnt_id = evt.option.value.clnt_id
    this.tnt_id = evt.option.value.tnt_id
    this.ulb_id = evt.option.value.ulb_id;
    this.ulb_nm = evt.option.value.ulb_nm;
    this.topCtgryFltrForm.get("selectedwp").setValue(0);
    this.geoLocationWorkPackage(this.ulb_id);
    this.geoLocationCluster();
    this.getVehicleCtgries();
  }
  geoLocationWorkPackage(ulb) {
    this.ulb_id = ulb;
    this.geoService.workPackage(this.ulb_id, (err, wrkPckg_res) => {
      this.wrkpckgsLst = [{ wrk_pckge_id: 0, wrk_pckge_nm: 'ALL' }];
      for (let w of wrkPckg_res.data) {
        this.wrkpckgsLst.push(w)
      }
      this.topCtgryFltrForm.get("selectedClstr").setValue(0);
    });
  }
  geoLocationCluster() {
    this.geoService.getClusterDetails(this.ulb_id, this.wrk_pckge_id, (err, res) => {
      this.clstrLst = [{ clsrt_id: 0, clstr_nm: 'ALL' }];
      for (let c of res.data) {
        this.clstrLst.push(c)
      }
      this.clstrs = res.data;
      if (this.vhclOpLbl != 'Assign')
        this.topCtgryFltrForm.get("selectedClstr").setValue(0);
    });
  }
  getVehicleCtgries() {
    this.geoService.getVehicleCtgries(this.clnt_id, this.tnt_id, (err, res) => {
      this.vehiclCtgries = [{ asrt_ctgry_id: 0, asrt_ctgry_nm: 'ALL' }];
      for (let c of res.data) {
        this.vehiclCtgries.push(c)
      }
      this.vehiclCtgrylst = res.data;
      this.topCtgryFltrForm.get("selectedCtgry").setValue(0);
    });
  }
  getVehiclData() {
    this.shwLdr = true;
    var postData = {
      clnt_id: this.clnt_id,
      tnt_id: this.tnt_id,
      wrk_pckge_id: this.topCtgryFltrForm.value.selectedwp,
      clsrt_id: this.topCtgryFltrForm.value.selectedClstr,
      asrt_ctgry_id: this.topCtgryFltrForm.value.selectedCtgry
    }
    this.Rtms2Service.getVehclAsgnmntData(postData, (err, clstr_res) => {
      this.dataSource.data = [];
      this.dataSource.filter = '';
      this.dataSource.data = clstr_res.data;
      this.data = clstr_res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
    
  }
  opensideBar(key, vehUpdtData, event: any) {
    if(vehUpdtData){
      this.vhclOpLbl = 'Assign';
      this.asgnTrckrForm.get('asrt_nm').setValue(vehUpdtData.asrt_nm);
      this.asgnTrckrForm.get('dvce').setValue(vehUpdtData.dvce);
      this.asgnTrckrForm.get('asrt_id').setValue(vehUpdtData.asrt_id);
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
    this.getDevices();
  }
  getDevices(){
    this.hardwareService.hardwareTrackers(this.ulb_id, (err, res) => {
      this.devices = res.data;
      console.log(this.devices);
    })
  }
  assgnTrckr() {
    if (this.asgnTrckrForm.status == 'INVALID') {
      return false;
    }
    this.asgnTrckrForm.value.clnt_id = this.clnt_id;
    this.asgnTrckrForm.value.tnt_id = this.tnt_id;;
    this.asgnTrckrForm.value.dvce_id = this.asgnTrckrForm.value.dvce.dvce_id;
    this.asgnTrckrForm.value.imei_nu = this.asgnTrckrForm.value.dvce.imei_nu;
    this.Rtms2Service.assgnTrckr(this.asgnTrckrForm.value, (err, res) => {
      console.log(res);
      if (res.status == 200 && res.data.cmntx=='asgnd' ) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: " assigned to new vehicle" }
        });
        this.getVehiclData();
      }
      else 
      if(res.status == 200){
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: "Assigned" }
        });
        this.getVehiclData();
      }
    });
  }
  downloadExcel($event): void {
    this.excelData = [];
    if (this.data.length > 0) {
      for (let i of this.data) {
        this.excelData.push({
          'sno': i.sno ? i.sno : '', 'Vehicle': i.asrt_nm ? i.asrt_nm : '', 'Cluster': i.clstr_nm ? i.clstr_nm : '',
          'Workpackage':i.wrk_pckge_nm ? i.wrk_pckge_nm:'',
          'Category': i.asrt_ctgry_nm ? i.asrt_ctgry_nm : '',
          'Tracker Assigned': i.asgnd_sts ? i.asgnd_sts : '',
          'Tracker': i.dvce_nm ? i.dvce_nm : '',
          'Status': i.vehstatus ? i.vehstatus : '',
          'Last Data Received On': i.lastData ? i.lastData : ''});
      }
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['sno', 'Vehicle', 'Cluster', 'Workpackage', 'Category','Tracker Assigned','Tracker','Status','Last Data Received On'];
        this.excelFileNm = 'vehicles_data_'+this.ulb_nm;
    }
  }
}
