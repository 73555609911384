import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

import {
  MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule,
  MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatButtonModule, MatTooltipModule, MatSlideToggleModule
  , MatDialogModule, MatDividerModule, MatToolbarModule,
} from '@angular/material';

import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseSidebarModule, FuseNavigationModule } from '@fuse/components';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';

import { HardwareComponent } from './hardware.component';
import { RFIDTagsComponent } from './rfidtags/rfidtags.component';
import { ScannersComponent } from './scanners/scanners.component';
import { TrackersComponent } from './trackers/trackers.component';
import { WeighingMachineComponent } from './weighing-machine/weighing-machine.component';
import { SharedModule } from 'app/shared/shared.module';
import { StreetsMasterComponent } from './streets-master/streets-master.component';



const hw_routes: Routes = [
  { path: 'rfidtags', component: RFIDTagsComponent },
  { path: 'scanners', component: ScannersComponent },
  { path: 'trackers', component: TrackersComponent },
  { path: 'weighingmachines', component: WeighingMachineComponent },
  { path: 'streets', component: StreetsMasterComponent }
];

@NgModule({
  declarations: [
    HardwareComponent,
    RFIDTagsComponent,
    ScannersComponent,
    TrackersComponent,
    WeighingMachineComponent,
    StreetsMasterComponent
  ],
  imports: [

    RouterModule.forChild(hw_routes),

    MatTabsModule,
    MatCardModule,
    MatFormFieldModule, MatSelectModule, MatInputModule, MatOptionModule, FlexLayoutModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatButtonModule, MatTooltipModule
    , MatDialogModule, MatDividerModule, MatToolbarModule,
    FormsModule, ReactiveFormsModule, MatSlideToggleModule, FuseSidebarModule, FuseNavigationModule, NavbarModule,
    CommonModule,
    HttpModule,
    MatAutocompleteModule,
    SharedModule
  ],
  exports: [
    ScannersComponent
  ]

})
export class HardwareModule { }
