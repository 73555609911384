import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { gateFixModel } from './gate-fixation-status.model';
import { MatDatepickerModule, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { map, startWith, filter } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { DatePipe } from '@angular/common';
import { Rtms2Service } from 'app/main/rtms2/rtms2.service';
import { MatSort, MatPaginator, MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
@Component({
  selector: 'app-gate-fixation-status',
  templateUrl: './gate-fixation-status.component.html',
  styleUrls: ['./gate-fixation-status.component.scss']
})
export class GateFixationStatusComponent implements OnInit {
  ulbLst: gateFixModel[] = [];
  ulbFltrOptions: Observable<gateFixModel[]>;
  fltrForm: FormGroup;
  shwLdr = true;
  columnDefs = [];
  rowData = [];
  pagination: boolean = true;
  paginationPageSize = 20;
  isExcelDwnLd:boolean = false;
  excelDataHeaders;
  excelFileNm;
  fltrdata;
  ulb_id;
  noData:boolean = false;
  date = new Date();
  frmevents: string[] = [];
  toevents: string[] = [];
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');

  displayedColumns: string[] = ['dt', 'ulb_nm', 'frst_nm', 'mbl_nu', 'mcrpt_nu', 'gates_assigned'];
  dataSource = new MatTableDataSource<gateFixModel>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  frm_dt;
  to_dt;
  ulbsLst;
  ulb;
  constructor(private misreportsService: MisreportsService, public datepipe: DatePipe, private rtms2Service: Rtms2Service) { }

  ngOnInit(): void {

    this.fltrForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });

    this.ulbFltrOptions = this.fltrForm.get('selectedUlb').valueChanges
    .pipe(
      startWith(''),
      map(value => this._ulbfilter(value))
    );

    
    this.getUlbList();
    this.getGteFxSts();
  }

  getUlbList(){
    // this.ulbLst = [{ulb_id: 0, ulb_nm: 'All'}];
    
        // this.misreportsService.getUlbLst((err, ulbLst) => {
        //   for (let u of ulbLst.data) {
        //     this.ulbLst.push(u);
        //   }
        //   this.fltrForm.get('selectedUlb').setValue('');
        // });
        if (this.ulbLst.length > 1){
          this.ulbLst = [{ulb_id: 0, ulb_nm: 'All'}];
        }
        this.ulbsLst = JSON.parse(localStorage.getItem('ulbs'));
        for (let u of this.ulbsLst) {
              this.ulbLst.push(u);
        }

        if (this.ulbLst.length > 1){
          this.ulbLst.push({ulb_id: 0, ulb_nm: 'All'});
        }
        this.ulbLst.sort();
        this.ulbLst.reverse();
        this.fltrForm.get('selectedUlb').setValue('');
        
  }
  

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
  }

  ulbSelected(evt: any){
    this.ulb_id = evt.option.value.ulb_id;
  }

  private _ulbfilter(value): gateFixModel[]{
    if (value) {
        return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }

  autocompleteUlbDisplayFn(apprt?: gateFixModel): string  | undefined{
    return apprt ? apprt.ulb_nm : undefined;
  }

  getDtFltr(): void{
    if (this.frmevents[0] === undefined){
      this.frmevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }
    if (this.toevents[0] === undefined){
      this.toevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }
    this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
    this.getGteFxSts();
  }

  getGteFxSts(): void{
    this.shwLdr = true;
    this.isExcelDwnLd = false;
    this.noData = false;

      if (this.from_date === null){
        this.frm_dt = this.datepipe.transform(this.date, 'yyyy-MM-dd');
      }else{
        this.frm_dt = this.from_date;
      }
      if (this.to_date === null){
        this.to_dt = this.datepipe.transform(this.date, 'yyyy-MM-dd');
      }else{
        this.to_dt = this.to_date;
      }
      if (this.ulbLst.length === 1){
        this.ulb = this.ulbLst[0].ulb_id;
      }
      else if (this.ulbLst.length > 1){

        if (this.ulb_id !== undefined){
          this.ulb = this.ulb_id;
        }
        else{
          let ulb = '0';
          for (let i = 0; i < this.ulbLst.length; i++) {
            ulb = ulb + ',' + this.ulbLst[i].ulb_id;
          }
          this.ulb = ulb;
        }
      }else{
        this.ulb = this.ulb_id;
      }
      const gtsFxFltrData = {
        ulb_id : this.ulb,
        frm_dt: this.frm_dt,
        to_dt: this.to_dt
      };
      this.rtms2Service.ulbGteFxtnStsData(gtsFxFltrData, (err, gtes_res) => {
        this.rowData = gtes_res.data;
        this.shwLdr = false;
        if (this.rowData.length === 0){
          this.noData = true;
        }else{
          this.noData = false;
        }

        this.columnDefs = [
        {
            headerName: 'Date',
            field: 'dt',
            sortable: true, 
            cellStyle: {textAlign: 'center'},
            filter: true,
            width: 100
          },
          {
            headerName: 'ULB',
            field: 'ulb_nm', 
            sortable: true, 
            filter: true,
            cellStyle: {textAlign: 'center'},
          },
          {
            headerName: 'User Name',
            field: 'frst_nm', 
            sortable: true, 
            cellStyle: {textAlign: 'center'},
            filter: true
          },
          {
            headerName: 'Mobile',
            field: 'mbl_nu',
            sortable: true, 
            cellStyle: {textAlign: 'center'},
            filter: true
          },
          {
            headerName: 'Micropocket',
            field: 'mcrpt_nu',
            sortable: true, 
            cellStyle: { textAlign: 'center'},
            filter: true,
            width : 150
          },
          {
            headerName: 'Gates Fixed',
            field: 'gates_assigned',
            sortable: true, 
            cellStyle: {textAlign: 'center'},
            filter: true,
            width: 200
          },
        ];
      });
  }

  downloadExcel($event): void{
    if (this.rowData.length > 0 && $event === true){
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = [ 'dt', 'ulb_nm', 'frst_nm', 'mbl_nu', 'mcrpt_nu', 'gates_assigned'];
      this.excelFileNm = 'employee_gates_fixation_status_rpt';
    }
  }
  
}
