import { Component, OnInit,ViewChild } from '@angular/core';
import { GeoLocationsService } from '../geo-locations/geo-locations.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { Ulb } from '../geo-locations/ulb/ulb.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import { Rtms2Service } from 'rtms2.service';
import { CrudService } from 'app/main/workflow/crud.service';
import { ColDef, GridApi, ColumnApi } from 'ag-grid-community';

@Component({
  selector: 'app-transfer-point',
  templateUrl: './transfer-point.component.html',
  styleUrls: ['./transfer-point.component.scss']
})
export class TransferPointComponent implements OnInit {
  ulbLst: Ulb[] = [];
  headerForm: FormGroup;
  // ulbid: number;
  @ViewChild(MatSort) sort: MatSort;

  selectedTabIndex: number = 0;
  wmstab: boolean = false;
  scannerstab: boolean = false;
  trackerstab: boolean = false;
  ulb;
  ulbtopFltrOptions: Observable<Ulb[]>;
  trnsfrpntsLst: any;
  displayedColumns: string[] = ['sno','fnce_nm', 'lat', 'lng', 'rfid_tge_nu','mcrpt_nm'];
  dataSource = new MatTableDataSource();
  columnDefs = [];
  rowData =  [];
  shwLdr;
  noData;
  pagination:boolean = true;
  paginationPageSize = 10;
  params;
  columnApi;
  public gridApi: GridApi;
  @ViewChild(MatPaginator) paginator: MatPaginator; 
  constructor(private geoService: GeoLocationsService,private CrudService: CrudService, private locationsService: GeoLocationsService) {
  }

  ngOnInit() {
  this.dataSource.paginator = this.paginator;
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulb = {
      ulb_id: this.ulbLst[0].ulb_id,
      ulb_nm: this.ulbLst[0].ulb_nm
    };
    const toSelect = this.ulbLst.find(c => c.ulb_id == this.ulb.ulb_id);
    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });
    this.getTagInfo(this.ulbLst[0].ulb_id)
    this.wmstab = true; this.scannerstab = false; this.trackerstab = false;
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
    console.log(this.ulbtopFltrOptions)
  }
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  onUlbChange(evt) {
    this.ulb = evt.option.value;
    console.log(this.ulb.ulb_id)
    this.getTagInfo(this.ulb.ulb_id)
  }
  getTagInfo(ulb) {
    this.rowData = [];
    this.columnDefs = [];
    this.CrudService.get('/ops/TrnsfrPntByulbId/' + ulb).subscribe(res => {
      console.log(res)
      this.rowData = res['data'];
      this.dataSource.data=this.rowData
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (this.rowData.length !== 0){
        this.noData = false;
      }else{
        this.noData = true;
      }
     
    })
  }
  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filteredData)
    if (this.dataSource.filteredData.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    console.log(this.dataSource.filter)
  }
  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.params = params;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }

}
