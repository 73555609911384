import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatDatepickerModule,
         MatTableModule, MatPaginatorModule  , MatSortModule , MatButtonModule, MatIconModule, MatCheckboxModule  } from '@angular/material';
import { FuseSidebarModule } from '@fuse/components';

import { Rtms2Service } from './rtms2.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import {MatToolbarModule} from '@angular/material/toolbar';
import {ConfirmDialog} from './rtms2.component';
import { AddFeilddUsersComponent } from './add-feildd-users/add-feildd-users.component';
import { GateFixationStatusComponent } from './gate-fixation-status/gate-fixation-status.component';
import { VehicleTrackerAssignmentComponent } from './vehicle-tracker-assignment/vehicle-tracker-assignment.component';
import { SharedModule } from 'app/shared/shared.module';
import { SearchByRfidTagComponent } from './search-by-rfid-tag/search-by-rfid-tag.component';
const rtms2_routes: Routes = [
  { path: 'fielduser', component: AddFeilddUsersComponent },
  { path: 'gatesfxdsts', component: GateFixationStatusComponent },
  { path: 'trackerAssignment', component: VehicleTrackerAssignmentComponent },
  { path: 'rfidtagsearch', component: SearchByRfidTagComponent },

];

@NgModule({
  declarations: [
    ConfirmDialog,
    AddFeilddUsersComponent,
    GateFixationStatusComponent,
    VehicleTrackerAssignmentComponent,
    SearchByRfidTagComponent
  ],
    imports: [
      RouterModule.forChild(rtms2_routes),
      FlexLayoutModule,
      CommonModule,
      HttpModule,
      FormsModule,
      ReactiveFormsModule,
      MatTabsModule,
      MatCardModule, 
      MatInputModule, 
      MatFormFieldModule,
      MatSelectModule,
      MatOptionModule, 
      MatTableModule, 
      MatPaginatorModule  ,
      MatSortModule , 
      MatButtonModule, 
      MatIconModule,
      FuseSidebarModule,  
      MatAutocompleteModule,
      MatToolbarModule,
      MatCheckboxModule,
      MatDatepickerModule,
      SharedModule
    ],
     providers: [Rtms2Service],
  entryComponents: [ConfirmDialog],
})
export class Rtms2Module { }
