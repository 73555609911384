import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Rtms2Service } from './rtms2.service';


@Component({
  selector: 'app-rtms2',
  templateUrl: './rtms2.component.html',
  styleUrls: ['./rtms2.component.scss']
})
export class Rtms2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
@Component({
  selector: 'confirm-dialog',
  templateUrl: '../../main/confirm-dialog.html'
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>, 
    public Rtms2Service: Rtms2Service,
    @Inject(MAT_DIALOG_DATA) public data: {id: '', entityname: '', cnfrmflag: false, successFlag: false, operation: '',dtls:''}) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(id, entityname, cnfrmflag, successFlag, operation,dtls): void {
    switch(entityname) { 
      case 'Route': { 
        // this.VehicleService.deleteRoute(id, (err, res) => {
        //   if(!err){
        //     this.data.cnfrmflag = false;
        //   }
        // });
        break; 
      } 
   } 

  }
  getColor(operation) { (2)
    switch (operation) {
      case 'Created':
        return 'green';
      case 'Updated':
        return '#ffc107';
      case 'Assigned':
        return '#ffc107';
      case 'Removed':
        return '#red';
      case 'Deleted':
        return 'red';
    }
  }
}