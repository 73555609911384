import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HardwareService } from 'app/main/hardware/hardware.service';
import { WeighMachines } from 'app/main/hardware/weighing-machine/weighing-machine.model';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { GeoLocationsService } from './../../geo-locations/geo-locations.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { UserService } from 'app/providers/user/user.serivce';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-weighing-machine',
  templateUrl: './weighing-machine.component.html',
  styleUrls: ['./weighing-machine.component.scss']
})
export class WeighingMachineComponent implements OnInit {
  mpid = [];



  constructor(private geoService: GeoLocationsService, private hardwareService: HardwareService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog,
    private userService: UserService) { }

  WeighMachines: WeighMachines[] = [];
  headerForm: FormGroup;
  wghMchForm: FormGroup;
  ulbLst: Ulb[] = [];
  ulbid: number;
  userPerm: any = {};
  wgmsts: number;
  displayedColumns: string[] = ['sno', 'id', 'name', 'ulb_nm', 'sts', 'mcrpts', 'actions'];
  dataSource = new MatTableDataSource<WeighMachines>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  errMsg = false;
  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  shwLdr: boolean;
  noData;
  ulbtopFltrOptions: Observable<Ulb[]>;
  listItems = [];
  data;
  values = [];
  mcrpklst;
  mpsel;
  WMstatus;
  lists;
  food;
  lst;



  ngOnInit() {





    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });

    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // })
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulbid = this.ulbLst[0].ulb_id;
    this.wghMchForm = new FormGroup({

      name: new FormControl('', Validators.required),
      ulb_id: new FormControl('', Validators.required),
      wghm_sts_id: new FormControl('', Validators.required),
      mcrpt_id: new FormControl(''),

    });

    this.wghMchForm.get('ulb_id').setValue(this.ulbid);
    this.wghMchForm.get('wghm_sts_id').setValue(this.wgmsts);
    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });
    this.getWeighingMachines();

    // getting MICROPOCKETS for ULB in sidebar
    this.geoService.getMicroPocket(this.ulbid, 0, 0, (err, micropckt_res) => {
      this.mcrpklst = micropckt_res.data;
      this.getWeighingMachines();
    });

    this.hardwareService.getWeighMchnStatus((err, wghm_sts_nm) => {
      this.lists = wghm_sts_nm.data;
    });

    this.userPerm = this.userService.CURRENT_PERM;
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
  }
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }




  onUlbChange(evt) {
    this.ulbid = evt.option.value.ulb_id;
    this.getWeighingMachines();
  }

  getSelMP(data) {
    this.mpsel = [];
    this.mpsel = data.value;
  }
  getStatus(data) {
    this.wgmsts = data.value;

  }


  getWeighingMachines() {
    this.dataSource.data = [];
    this.shwLdr = true;
    this.hardwareService.hardwareWeighingMachines(this.ulbid, (err, trcker_res) => {
      //  console.log(trcker_res.data);
      this.dataSource.data = trcker_res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
      
      
    });
  }

  saveData() {
   
    this.errMsg = false;
    if (this.wghMchForm.status === 'INVALID') {
      return false;
    }
    this.updtDt = this.wghMchForm.value;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.name === this.updtDt.name) {
        flag = true;
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }
  
    const getNwWegngDtls = {
      'mcrpt_id': this.mpsel,
      'wghng_mchne_id': this.updtDt.name,
      'ulb_id': this.ulbid,
      'sts': this.wgmsts,
    };

    this.hardwareService.addWeighingMachine(getNwWegngDtls, (err, res) => {
      if (res.status === 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
        });
        this.getWeighingMachines();

      }
    });
  }
  updateData() {
    if (this.wghMchForm.status === 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt.name = this.wghMchForm.value.name;
    this.updtDt.ulb_id = this.wghMchForm.value.ulb_id;
    this.updtDt.mcrpt_id = this.wghMchForm.value.mpid;
    this.updtDt.wghm_sts_id = this.wghMchForm.value.wghm_sts_id;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.name === this.updtDt.name) {
        if (val.id !== this.updtDt.id) {
          if (val.mcrpt_id === this.updtDt.mcrpt_id) {
            if (val.wghm_sts_id === this.updtDt.wghm_sts_id) {
              flag = true;
            }
          }
        }
      }
    });

    if (flag) {
      this.errMsg = true;
      return;
    }
  
    if (this.mpsel == null) {
      this.updtDt.mcrpt_id = this.mpid;
    }
    else {
      this.updtDt.mcrpt_id = this.mpsel;
    }

    this.hardwareService.updtWeighingMachine(this.updtDt, (err, res) => {
      if (res.status === 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
        });
        this.getWeighingMachines();


      }
    });
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'WeighingMachine', cnfrmflag: true, successFlag: false, operation: 'Deleted', dtls: nm }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getWeighingMachines();

    });
  }

  resetForm() {
    this.wghMchForm.reset();
  }
  opensideBar(key, updtData) {
    this.mpid = [];

    if (updtData) {
      if (updtData.mcrpt_id) {
        var res = updtData.mcrpt_id.split(",");
        res.forEach(element => {
         
          // tslint:disable-next-line:radix
          this.mpid.push(parseInt(element));
        });
      
      }
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.wghMchForm.get('name').setValue(updtData.name);
      this.wghMchForm.get('ulb_id').setValue(updtData.ulb_id);
      this.wghMchForm.get('mcrpt_id').setValue(this.mpid);
      this.wghMchForm.get('wghm_sts_id').setValue(updtData.wghm_sts_id);

    }
    else {
      this.wghMchForm.get('name').setValue('');
      this.opLbl = 'Add';
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }

}
