import { Component, OnInit, ViewChild } from '@angular/core';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { addFeildUsers } from './add-feild-users.model';
import { MatSort, MatPaginator, MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from 'app/providers/user/user.serivce';
import { Rtms2Service } from '../rtms2.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialog } from '../rtms2.component';
@Component({
  selector: 'app-add-feildd-users',
  templateUrl: './add-feildd-users.component.html',
  styleUrls: ['./add-feildd-users.component.scss']
})
export class AddFeilddUsersComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'ulb_nm', 'frst_nm', 'mbl_nu', 'a_in'];
  dataSource = new MatTableDataSource<addFeildUsers>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ulb_id;
  wp_id;
  mcrpt_id;
  clstrs: addFeildUsers[];
  feildUsrForm: FormGroup;
  ulbLst: addFeildUsers[] = [];
  wrkpckgsLst = [];
  opLbl = '';
  updtDt: any = {};
  userPerm: any = {};
  errMsg = false;
  headerForm: FormGroup;
  ulb_nm: string;
  usrPrfls: any;
  prflFltrOptions: Observable<addFeildUsers[]>;
  ulbFltrOptions: Observable<addFeildUsers[]>;
  selectedprfl = new FormControl();

  private dialogRef: MatDialogRef<string>;
  mobnumPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  namePattern = "^[a-z0-9_-]{3,50}$";
  shwLdr: boolean = false;
  noData;
  constructor(private geoService: GeoLocationsService, private Rtms2Service: Rtms2Service, private _fuseSidebarService: FuseSidebarService,
     public dialog: MatDialog, private userService: UserService) {
  }
  ngOnInit() {
    this.ulb_id = 20;
    this.ulb_nm = 'Tirupathi';
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.geoService.getUlbs((err, ulbLst) => {
      this.ulbLst = ulbLst.data;
    });
    this.feildUsrForm = new FormGroup({
      usr_id: new FormControl(),
      ulb: new FormControl('', Validators.required),
      usr_nm: new FormControl('', Validators.required),
      mbl_nu: new FormControl('', [Validators.required, Validators.pattern(this.mobnumPattern)]),
      mnu_prfle: new FormControl('', Validators.required)
    });
    this.userPerm = this.userService.CURRENT_PERM;
    this.getFeildUsrLst();
    this.prflFltrOptions = this.feildUsrForm.get('mnu_prfle').valueChanges
      .pipe(
        startWith(''),
        map(value => this._prflfilter(value))
      );
    this.ulbFltrOptions = this.feildUsrForm.get('ulb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
  }

  private _prflfilter(value): addFeildUsers[] {
    if (value) {
      return this.usrPrfls.filter(item => ((typeof value === 'string') ? item.mnu_prfle_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.mnu_prfle_nm.toLowerCase().indexOf(value['mnu_prfle_nm'].toLowerCase()) === 0));
    }
    return this.usrPrfls;
  }
  private _ulbfilter(value): addFeildUsers[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  onUlbChange(ulb) {
    this.ulb_id = ulb.ulb_id;
    this.ulb_nm = ulb.ulb_nm;
    this.feildUsrForm.get('ulb').setValue({ ulb_id: this.ulb_id, ulb_nm: this.ulb_nm });
  }
  ulbSelected(evt) {
    this.ulb_id = evt.option.value.ulb_id;
    this.ulb_nm = evt.option.value.ulb_id;
  }
  getFeildUsrLst() {
    this.shwLdr = true;
    this.Rtms2Service.getFeildUsrLst((err, res) => {
      for (let u of res.data) {
        if (u.sts == 1) u.a_in = true;
        else u.a_in = false;
      }
      this.dataSource.data = res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  opensideBar(key, updtData) {
    this.getUserProfiles();
    if (updtData) {
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.feildUsrForm.get('ulb').setValue({ ulb_id: updtData.ulb_id, ulb_nm: updtData.ulb_nm });
      this.feildUsrForm.get('usr_nm').setValue(updtData.usr_nm);
      this.feildUsrForm.get('mbl_nu').setValue(updtData.mbl_nu);
      this.feildUsrForm.get('mnu_prfle').setValue({ mnu_prfle_id: updtData.mnu_prfle_id, mnu_prfle_nm: updtData.mnu_prfle_nm });
    }
    else {
      //this.feildUsrForm.get('ulb').setValue({ ulb_id: this.ulb_id, ulb_nm: this.ulb_nm });
      this.feildUsrForm.get('usr_nm').setValue('');
      this.feildUsrForm.get('mbl_nu').setValue('');
      this.feildUsrForm.get('mnu_prfle').setValue('');
      this.opLbl = "Add";
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  getUserProfiles() {
    this.Rtms2Service.getUserProfiles((err, res) => {
      this.usrPrfls = res.data;
      this.feildUsrForm.get('mnu_prfle').setValue("");
    });
  }

  autocompletepDisplayFn(prfl?: addFeildUsers): string | undefined {
    return prfl ? prfl.mnu_prfle_nm : undefined;
  }
  autocompleteuisplayFn(ulb?: addFeildUsers): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  saveData() {
    if (this.feildUsrForm.status == 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt = this.feildUsrForm.value;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.mbl_nu == this.updtDt.mbl_nu) {
        flag = true;
      }
    })
    if (flag) {
      this.errMsg = true;
      return;
    }
    this.feildUsrForm.value.ulb_id = this.ulb_id;
    this.feildUsrForm.value.prfle_id = this.feildUsrForm.value.mnu_prfle.mnu_prfle_id;
    delete this.feildUsrForm.value.mnu_prfle;
    if (this.feildUsrForm.get("usr_id").value == '' || this.feildUsrForm.get("usr_id").value == null) {
      this.addUser(this.feildUsrForm.value);
    } else {
      this.updtUser(this.feildUsrForm.value);
    }
  }
  addUser(data) {
    this.Rtms2Service.addfeildUser(data, (err, res) => {
      if (res.status == 200) {
        if (res.data == 'User Already Exisits') {
          this.errMsg = true;
        } else {
          const dialogRef = this.dialog.open(ConfirmDialog, {
            width: '500px',
            data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
          });
          this.getFeildUsrLst();
        }
      }
    })
  }
  updtUser(data) {
  }
  deleteData(id, nm) {
    // const dialogRef = this.dialog.open(ConfirmDialog, {
    //   width: '500px',
    //   data: { id: id, nm: nm, entityname: 'cluster', flag: false }
    // });
    // dialogRef.afterClosed().subscribe(() => {
    //   this.getFeildUsrLst();
    // })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }
  chngActvUsr(usr) {
    let postData = { a_in: null, usr_id: usr.usr_id };
    if (usr.a_in) postData.a_in = 1;
    else postData.a_in = 0;
    this.Rtms2Service.actiInactivFldUsr(postData, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
        });
        this.getFeildUsrLst();
      }
    })
  }
}
