import { Component, Input , OnInit } from '@angular/core';
import {CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-ds-draggable', // parameters: id startswith - as it is single page application, json
  templateUrl: './ds-draggable.component.html',
  styleUrls: ['./ds-draggable.component.scss']
})
export class DsDraggableComponent implements OnInit {

  @Input() idtxt: string;;
  @Input() results;
  @Input() subitemsnm: string;

  constructor() { }

  ngOnInit() { }
  
  getList(index) {
    var temp = [];
    for (var i=0; i <= this.results.length-1; i++) {
        if (index != i ) {
          temp.push(this.idtxt+''+i) ;
        }
    }
    return temp;
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data[this.subitemsnm], event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data[this.subitemsnm],
                          event.container.data[this.subitemsnm],
                          event.previousIndex,
                          event.currentIndex);
    }
  }
}
