import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { Ulbgates } from './wrong-scnd.model';
@Component({
  selector: 'app-wrong-scan-report',
  templateUrl: './wrong-scan-report.component.html',
  styleUrls: ['./wrong-scan-report.component.scss']
})
export class WrongScanReportComponent implements OnInit {
  wrnglyScndLst = [];
  noData;
  displayedColumns: string[] = ['Ulb', 'Scanner', 'Scanner Code', 'Assigned Micropocket', 'Scanned Micropocket'];
  dataSource = new MatTableDataSource<Ulbgates>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  shwLdr = true;

  constructor(private misreportsService: MisreportsService) { }

  ngOnInit() {
    this.getWrnglyScndData();
  }
  getWrnglyScndData(){
    this.misreportsService.getWrnglyScndList((err, res) => {
      this.wrnglyScndLst = res.data;
      this.shwLdr = false;
      if (this.wrnglyScndLst.length === 0){
        this.noData = true;
      }
      this.dataSource.data = this.wrnglyScndLst;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
