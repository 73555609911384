import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { Router, NavigationExtras } from '@angular/router';
import { map, startWith, filter } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import {MatDatepickerModule, MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-long-term-gates-notscanned-report',
  templateUrl: './long-term-gates-notscanned-report.component.html',
  styleUrls: ['./long-term-gates-notscanned-report.component.scss']
})
export class LongTermGatesNotscannedReportComponent implements OnInit {
  columnDefs = [];
  rowData =  [];
  pagination:boolean = true;
  paginationPageSize = 10;
  fltrdata;
  isExcelDwnLd:boolean = false;
  excelDataHeaders;
  excelFileNm;
  ulbId;
  ulbNm;
  shwLdr = true;
  frmevents: string[] = [];
  toevents: string[] = [];
  curdate = new Date();
  curdateFrmt = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
  lst10DyDte = new Date().setDate(new Date().getDate() - 10);
  from_date = '';
  to_date = '';
  ulbGatesData = [];
  rpt_data_id;
  rpt_data_ky;
  rpt_nm;
  mcrptId; mcrptNm;
  noData;
  ulbsLst = [];
  ulb_id;
  wrk_pckge_id = 0;
  clsrt_id = 0;
  mcrpt_id;
  mcrpt_nm;
  mcrptGteLst = [];
  emp_id;
  fltrVsbleOptns;
  gatentscndExclData = [];
  rptFrmDt;
  rptToDt;
  constructor(private misreportsService: MisreportsService, public datepipe: DatePipe, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.fltrVsbleOptns = {
      'isUlb':  true,
      'isMcrpt': true,
      'isGte': false,
      'isEmp': false,
      'isFrmDt': true,
      'isToDt': true
    };
    this.getUlbGtehistoricalGateStatus();
  }

  receivefltrData(data): void{
    this.ulbId = data.ulb_id;
    this.mcrptId = data.mcrpt_id;
    this.ulbNm = data.ulb_nm;
    this.mcrptNm = data.mcrpt_nm;
    this.from_date = data.from_date;
    this.to_date = data.to_date;
    this.getUlbGtehistoricalGateStatus();
  }

  getUlbGtehistoricalGateStatus(): void{
    this.gatentscndExclData=[];
    this.rptFrmDt = '';
    this.rptToDt = '';
    if (this.ulbId === undefined){
      this.noData = true;
    }else{
      this.noData = false;
    }
    this.isExcelDwnLd = false;
    this.shwLdr = true;

    if (this.mcrptId === undefined){
      this.noData = true;
    }else{
      this.noData = false;
      if (this.from_date === this.curdateFrmt){
        this.from_date = this.datepipe.transform(this.lst10DyDte, 'yyyy-MM-dd');
      }
      this.rptFrmDt = this.datepipe.transform(this.from_date, 'dd-MM-yyyy');
      this.rptToDt =  this.datepipe.transform(this.to_date, 'dd-MM-yyyy');
      const empsHstryData = {
        'from_dt': this.from_date,
        'to_dt': this.to_date,
        'ulb_id': this.ulbId,
        'mcrpt_id': this.mcrptId
      };
      this.misreportsService.gatesNtScndByMcrpt(empsHstryData, (err, gteStatus_res) => {
        this.rowData = gteStatus_res.data;
        if (this.rowData.length){
          this.noData = false;
        }else{
          this.noData = true;
        }
  
        this.shwLdr = false;
  
        for (let i = 0; i < this.rowData.length; i++){
          this.gatentscndExclData.push({'sno': this.rowData[i].sno,
                                      'strt_nm': this.rowData[i].strt_nm, 'gte_hse_nu': this.rowData[i].gte_hse_nu, 'tge_nm': this.rowData[i].tge_nm,
                                      'gte_ctgry_nm': this.rowData[i].gte_ctgry_nm, 'frm_dt': this.rowData[i].frm_dt, 'to_dt': this.rowData[i].to_dt,
                                      'mcrpt_nm': this.rowData[i].mcrpt_nm});
        }
        this.columnDefs = [
          {headerName: 'SNo.', field: 'sno', width: 80, cellStyle: {textAlign: 'center'}},
          {headerName: 'Street Name', field: 'strt_nm', sortable: true, filter: true, width: 370},
          {headerName: 'Gate Number', field: 'gte_hse_nu', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 170},
          {headerName: 'RFID Tag', field: 'tge_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 190},
          {headerName: 'Gate Category', field: 'gte_ctgry_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 210},
          {headerName: 'From Date', field: 'frm_dt', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 180},
          {headerName: 'To Date', field: 'to_dt', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 180},
          {headerName: 'Micropocket', field: 'mcrpt_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 180},     
      ];
      });
    }
  }

  downloadExcel($event): void{
    if (this.rowData.length > 0){
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['SNo', 'Street Name', 'Gate Number', 'RFID Tag' , 'Gate Category', 
       'From Date', 'To Date', 'Micropocket'];
      this.excelFileNm = 'gate_notscanned_summary_rpt';
    }
  }
}
