import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Http } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class Rtms2Service {

  constructor(public httpCtrl: Http, private http: HttpClient) { }  
  public getFeildUsrLst(cb) {
    this.http.get('/getAllUsrs').subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public getUserProfiles(cb) {
    this.http.get('/ulbWsePrfls').subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public addfeildUser(usrData,cb) {
    this.http.post('/AddfldUsrs',usrData).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public actiInactivFldUsr(usrData,cb) {
    this.http.post('/ActvOrInactveUsr',usrData).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public ulbGteFxtnStsData(data, cb) {
    this.http.post('/rtms/gates/fixation/status', data).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public getVehclAsgnmntData(postData, cb) {
    this.http.post('/getvehcleAsgnmentData',postData).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  
  public assgnTrckr(postData, cb) {
    this.http.post('/assignTracker',postData).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }

  //*************rfid tags search************************************************//
  public getTagInfo(tgno, scn_typ, cb) {
    this.http.get('/ops/tagGateInfo/' + scn_typ + '/' + tgno).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }

  public updtTagCode(data, cb) {
    this.http.post(`/updateRfidtagsCode`,data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
}
