import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HardwareCertificationService } from '../hardware-certification.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Scanners } from './scanners.model'

@Component({
  selector: 'app-scanners',
  templateUrl: './scanners.component.html',
  styleUrls: ['./scanners.component.scss']
})
export class ScannersComponent implements OnInit {
  @Input() ulb;
  displayedColumns: string[] = ['sno', 'scnr_cd', 'rfid_scnr_ctgry_nm', 'last_scans_ts'];
  dataSource = new MatTableDataSource<Scanners>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  shwLdr: boolean = false;
  data: any;
  excelData: any[];
  isExcelDwnLd: any;
  excelDataHeaders: string[];
  excelFileNm: string;
  noData;
  constructor(private hwCertification: HardwareCertificationService, ) { }

  ngOnInit() {
    this.getscnrsWrkngStatus();
  }
  getscnrsWrkngStatus() {
    this.shwLdr = true;
    this.hwCertification.scnrsWrkngStatus(this.ulb.ulb_id, (err, res) => {
      this.dataSource.filter = '';
      this.dataSource.data = [];
      this.data = res.data;
      this.dataSource.data = res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }
  ngOnChanges(changes: any) {
    if (changes && changes.ulb) {
      this.getscnrsWrkngStatus();
    }
  }
  downloadExcel($event): void {
    this.excelData = [];
    if (this.data.length > 0) {
      for (let i of this.data) {
        this.excelData.push({
          'sno': i.sno ? i.sno : '', 'Device ID': i.scnr_cd ? i.scnr_cd : '',
           'Category': (i.rfid_scnr_ctgry_nm ? i.rfid_scnr_ctgry_nm : '') + (i.mcrpt_nu ? '-' + i.mcrpt_nu : ''),
          'Last Scanning On': i.last_scans_ts ? i.last_scans_ts : ''});
      }
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['sno', 'Device ID', 'Category', 'Last Scanning On'];
        this.excelFileNm = 'scanners_status_' + this.ulb.ulb_nm;
    }
  }
}
