import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

export interface DialogData {
    [x: string]: any;
  animal: string;
  name: string;
}

@Component({
  selector: 'dialog-box',
  template: `<h1 mat-dialog-title>Session Expired</h1>
              <div mat-dialog-content>
                <p>You will be Redirected to Login Page</p>
              </div>
              <div mat-dialog-actions>
                <button mat-raised-button color="primary" (click)="goToLogin()">Login</button>
              </div>`,
})
export class SessionPopup {

  constructor(
    public dialogRef: MatDialogRef<SessionPopup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router) {
    localStorage.clear();
  }

  goToLogin(): void {
    this.dialogRef.close();
    this.router.navigate(['/']);
  }

}
