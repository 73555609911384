import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ColDef, GridApi, ColumnApi } from 'ag-grid-community';
@Component({
  selector: 'app-ds-ag-grid',
  templateUrl: './ds-ag-grid.component.html',
  styleUrls: ['./ds-ag-grid.component.scss']
})
export class DsAgGridComponent implements OnInit {
  @Input() columnDefs;
  @Input() rowData;
  @Input() pagination;
  @Input() paginationPageSize;
  @Output() aggridEvent = new EventEmitter<string>(); 
  @Output() gridReadyEvent = new EventEmitter<string>(); 
  @Input() rowSelection; 
  slctdRow;
  params;
  columnApi;
  public gridApi: GridApi;

  constructor() { 
    this.rowSelection = 'multiple';
    }

  ngOnInit() { 
  }

  onCellClick(data){
    this.slctdRow = data;
    this.aggridEvent.emit(this.slctdRow);
  }
  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.params = params;
    this.gridReadyEvent.emit(this.params);
  }
}
