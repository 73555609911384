import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MainServiceService } from '../../main-service.service';

@Component({
  selector: 'app-user-wise-report',
  templateUrl: './user-wise-report.component.html',
  styleUrls: ['./user-wise-report.component.scss']
})
export class UserWiseReportComponent implements OnInit {
  rowData = [];
  columnDefs = [];
  date = new Date();
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  shwLdr = true;
  frmevents;
  toevents;
  isExcelDwnLd;
  excelFileNm;
  excelDataHeaders;
  noData;
  userExcelData=[];
  constructor(public datepipe: DatePipe, private dataService: MainServiceService) { }

  ngOnInit(): void {
    this.getuserwise();
   }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
  }


  getuserwise(): void{

    if (this.frmevents === undefined){
      this.from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }else{
      this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    }

    if (this.toevents === undefined){
      this.to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }else{
      this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
    }

      const inspctOffcrData = {
        'frm_dt': this.from_date,
        'to_dt': this.to_date
      };
      
      const inspctOfcrsBnfsCnts_rte = 'beneficiary/counts/inspectionofficerwise';

      this.dataService.getByData(inspctOfcrsBnfsCnts_rte, inspctOffcrData, (err, res) => {
        this.rowData = res.data;
        this.shwLdr = false;

        this.columnDefs = [
          {
            headerName: 'District',
            field: 'dstrct_nm',
            sortable: true,
            filter: true,width:250
          },
          {
            headerName: 'Mandal',
            field: 'mndle_nm',
            sortable: true,
            filter: true,
            width:250
          },
          {
            headerName: 'Inspection Officer',
            field: 'user_nm',
            sortable: true,
            filter: true,width:250
          },
          {
            headerName: 'Total Inspections',
            field: 'tot',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'}
          },
          {
            headerName: 'In Progess',
            field: 'on_gng',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'}
          },
          {
            headerName: 'Completed',
            field: 'cmpltd',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'}
          },
          {
            headerName: 'Not Started',
            field: 'not_cmpltd',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'}
          },
        ];
      });
    }
    
    downloadExcel($event): void {
      // if (this.rowData.length > 0) {
        for (let i = 0; i < this.rowData.length; i++) {
     
          this.userExcelData.push({dstrct_nm:this.rowData[i].dstrct_nm, 
            mndle_nm:this.rowData[i].mndle_nm, 
            user_nm :this.rowData[i].user_nm,
            tot :this.rowData[i].tot, 
            on_gng:this.rowData[i].on_gng,
            cmpltd:this.rowData[i].cmpltd, 
            not_cmpltd:this.rowData[i].not_cmpltd });
  
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['District', 'Mandal', 'Inspection Officer', 'Total Inspections', 'In Progess', 'Completed', 'Not Started'];
        this.excelFileNm = 'Inspection_Officer_Wise_rpt';
      }
    }
}
