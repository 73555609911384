import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatSort } from '@angular/material';

import { ScoreCardService } from './scorecard.service';
@Component({
  selector: 'app-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.scss']
})
export class ScorecardComponent implements OnInit {
  ulbLst:any= [];
  srvPrvds = [];
  micro_pkts_res: any;
  month = [];
  crdData;
  dataSource = [];
  shwDtaCrd: boolean = false;
  @Input() ulbid: number;
  @Input() selectedTabIndex: number;
  displayedColumns: string[] = ['position', 'mn_ctgry_nm', 'wghtge', 'sb_cgry_nm', 'sbwghtge', 'trgt_bnchmrk', 'actl_achvmnt', 'failure', 'elgble_pymnt', 'pnlty_lvd', 'net_amnt_pybl'];
  // totdisplayedColumns:string[]=['Total'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  tot_sbwghtge:number;
  tot_trgt_bnchmrk:number;
  tot_actl_achvmnt:number;
  tot_failure:number;
  tot_elgble_pymnt:number;
  tot_pnlty_lvd:number;
  tot_net_amnt_pybl:number;
  tot_pnlty_prct:number;

  constructor(private scorecardService: ScoreCardService) {
  }

  ngOnInit() {
    // this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.scorecardService.getKpiUlbs((err, ulb_res) => {
      this.ulbLst = ulb_res.data;
    })

  }
  onUlbChange(ulb_id) {
    this.ulbid = ulb_id;
    this.scorecardService.getsrvPrvdrs(this.ulbid, (err, ulb_res) => {
      this.srvPrvds = ulb_res.data;
    })
  }
  getKpiDtls(kpData) {
    this.crdData = kpData;
   
  }
  getdata(){
    this.shwDtaCrd = true;
    this.scorecardService.getsbctgryWseData(this.crdData.kpi_id, (err, ulb_res) => {
      this.dataSource = ulb_res.data;
  this.tot_sbwghtge = 0;
  this.tot_trgt_bnchmrk = 0;
  this.tot_actl_achvmnt = 0;
  this.tot_failure = 0;
  this.tot_elgble_pymnt = 0;
  this.tot_pnlty_lvd = 0;
  this.tot_net_amnt_pybl = 0;
  this.tot_pnlty_prct = 0
    for (let d of this.dataSource) {
      d["isDisplay"] = "";
      d["pnlty_lvd"] = 0;
      d["pnlty_prct"] = 0;
      d["actl_achvmnt"] = 0;
      this.tot_sbwghtge = this.tot_sbwghtge+d["sbwghtge"];
      this.tot_trgt_bnchmrk = this.tot_trgt_bnchmrk+d["trgt_bnchmrk"];
      this.tot_actl_achvmnt = this.tot_actl_achvmnt+d["actl_achvmnt"];
      this.tot_pnlty_prct = this.tot_pnlty_prct+d["pnlty_prct"];
      this.tot_elgble_pymnt = this.tot_elgble_pymnt+d["elgble_pymnt"];
      this.tot_pnlty_lvd = this.tot_pnlty_lvd+d["pnlty_lvd"];
      this.tot_net_amnt_pybl = this.tot_net_amnt_pybl+d["net_amnt_pybl"];
    }
      this.tot_elgble_pymnt =  Math.floor(this.tot_elgble_pymnt);
      this.tot_net_amnt_pybl =  Math.floor(this.tot_net_amnt_pybl);
    })
  }
  getRowSpan(key, value) {
    var ct = 0;
    for (let i of this.dataSource) {

      if (value == i[key]) {
        ct = ct + 1;
        if (ct > 1) {
          i["isDisplay"] = "none";
        }
      }
    }
    return ct;
  }
  getTotalPymnt() {
    var total = 0;
    for (let i of this.dataSource) {
      total = total + i.elgble_pymnt;

    }
    return total;
  }
}
