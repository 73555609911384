import { Component, OnInit, ViewChild } from '@angular/core';
import { GeoLocationsService } from './../../geo-locations/geo-locations.service';
import { MatSort, MatPaginator, MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
import { Commercialgates } from './Commercialgates.model';
import { WasteGeneratorsService } from './../waste-generators.service';
import { ConfirmDialog } from '../waste-generator.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HardwareService } from './../../hardware/hardware.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { UserService } from 'app/providers/user/user.serivce';

@Component({
  selector: 'app-Commercialgates',
  templateUrl: './Commercialgates.component.html',
  styleUrls: ['./Commercialgates.component.scss']
})
export class CommercialgatesComponent implements OnInit {

  Commercialgates: Commercialgates[] = [];
  ulbLst: Commercialgates[] = [];
  mcrptLst: Commercialgates[] = [];
  wrkpckgsLst = [];
  clstrLst: Commercialgates[] = [];
  headerForm: FormGroup;
  rsGatesForm: FormGroup;
  cat_id: number = 2;
  ulb_id: number;
  mcrpt_id: number;
  mcrpt_nu: number;
  wrk_pckge_id: number = 0;
  clsrt_id: number = 0;
  rfid_tge_id: number;
  strt_id: number;
  gateOpLbl: string;
  displayedColumns: string[] = ['sno', 'mcrpt_nm', 'rfid_tge_nu', 'gte_hse_nu', 'strt_nm', 'hse_hlds_ct', 'clstr_nm', 'wrk_pckge_nm', 'actions'];
  dataSource = new MatTableDataSource<Commercialgates>();
  selectedMp = new FormControl();
  strtsLst:Commercialgates[] = [];
  Tags:Commercialgates[] = [];
  addTag:boolean;
  date = new Date();
  instl_ts = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  ulb_nm:string;
  showtagWrngmsg:boolean;
  old_rfid_tge_id: number;
  topmpFltrOptions: Observable<Commercialgates[]>;
  ulbFltrOptions: Observable<Commercialgates[]>;
  mpFltrOptions: Observable<Commercialgates[]>;
  clstrFltrOptions: Observable<Commercialgates[]>;
  strtFltrOptions: Observable<Commercialgates[]>;
  tgFltrOptions: Observable<Commercialgates[]>;

  
frmevents: string[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private dialogRef: MatDialogRef<string>;
  shwLdr: boolean = false;
  userPerm: { id: any; c_in: number; r_in: number; u_in: number; d_in: number; };
  constructor(private geoService: GeoLocationsService, 
              private wastegeneratorService: WasteGeneratorsService,
              private _fuseSidebarService: FuseSidebarService,
              private hardwareService: HardwareService,
              public dialog: MatDialog,
              public datepipe: DatePipe,private userService: UserService ) { 
              }

  ngOnInit() {
    this.ulb_id = 20;
    this.ulb_nm = 'Tirupathi'
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    
    this.geoService.getUlbs((err, ulbLst) => {
      this.ulbLst = ulbLst.data;
      this.geoLocationWorkPackage(this.ulb_id);
      this.geoLocationCluster(this.wrk_pckge_id);
    })
  
    this.headerForm.get("selectedUlb").setValue(20);
    this.topmpFltrOptions = this.selectedMp.valueChanges.pipe(
      startWith(''),
      map(value => this._topmpfilter(value))
    );
    this.geoLocationMicroPocket(this.ulb_id);
    
    this.rsGatesForm = new FormGroup({
      gte_id: new FormControl(),
      gte_hse_nu: new FormControl('', Validators.required),
      hse_hlds_ct: new FormControl('', Validators.required),
      ppltn_ct: new FormControl('', Validators.required),
      lat: new FormControl('', Validators.required),
      lng: new FormControl('', Validators.required),
      // addrs_tx: new FormControl('', Validators.required),
      // ulb_id: new FormControl('', Validators.required),
      mcrpt_id: new FormControl('', Validators.required),
      wrk_pckge_id: new FormControl('', Validators.required),
      clsrt_id: new FormControl('', Validators.required),
      strt_id: new FormControl('', Validators.required),
      rfid_tge_scn_cd: new FormControl(''),
      rfid_tge_id: new FormControl(''),
      rfid_tge_nu: new FormControl('', Validators.required),
      tag_sts: new FormControl('')
    });
    this.mpFltrOptions = this.rsGatesForm.get('mcrpt_id').valueChanges
    .pipe(
      startWith(''),
      map(value => this._mpfilter(value))
    );
    
    this.clstrFltrOptions = this.rsGatesForm.get('clsrt_id').valueChanges
    .pipe(
      startWith(''),
      map(value => this._clstrfilter(value))
    );
    
    this.strtFltrOptions = this.rsGatesForm.get('strt_id').valueChanges
    .pipe(
      startWith(''),
      map(value => this._strtfilter(value))
    );
    
    this.tgFltrOptions = this.rsGatesForm.get('rfid_tge_id').valueChanges
    .pipe(
      startWith(''),
      map(value => this._tgfilter(value))
    );
    this.userPerm = this.userService.CURRENT_PERM;
  }
  
autocompleteUDisplayFn(apprt?: Commercialgates): string  | undefined{
  return apprt ? apprt.ulb_nm : undefined;
}
autocompleteCDisplayFn(apprt?: Commercialgates): string  | undefined{
  return apprt ? apprt.clstr_nm : undefined;
}
autocompleteMDisplayFn(apprt?: Commercialgates): string  | undefined{
  return apprt ? apprt.mcrpt_nm : undefined;
}
autocompleteSDisplayFn(apprt?: Commercialgates): string  | undefined{
  return apprt ? apprt.strt_nm : undefined;
}
autocompleteTDisplayFn(apprt?: Commercialgates): string | undefined {
  return apprt ? apprt.rfid_tge_scn_cd : undefined;
}
mpSelected (evt: any) {
  this.mcrpt_id = evt.option.value.mcrpt_id;
}
slctdMp(evt: any){
  this.mcrpt_id = evt.option.value.mcrpt_id;
  this.mcrpt_nu = evt.option.value.mcrpt_nu;
  this.wrk_pckge_id = evt.option.value.wrk_pckge_id;
  this.clsrt_id = evt.option.value.clsrt_id;
  let clstr = {
    clsrt_id:evt.option.value.clsrt_id,
    clstr_nm:evt.option.value.clstr_nm} 
  this.getStreets();
  this.geoLocationWorkPackage(this.ulb_id);
  // this.geoLocationCluster(this.wrk_pckge_id);
  this.rsGatesForm.get('wrk_pckge_id').setValue(this.wrk_pckge_id);
  this.rsGatesForm.get('clsrt_id').setValue(clstr);
}
ulbSelected (evt: any) {
  this.ulb_id = evt.option.value.ulb_id; 
  this.ulb_nm = evt.option.value.ulb_nm; 
  this.geoLocationWorkPackage(this.ulb_id);
  this.geoLocationMicroPocket(this.ulb_id)
}
clstrSelected (evt: any) {
  this.clsrt_id = evt.option.value.clsrt_id; 
  this.geoLocationMicroPocket(this.ulb_id);
}
tgSelected (evt: any) {
  if(evt.option.value.gte_id && evt.option.value.gte_id != this.rsGatesForm.get('gte_id').value){
    this.showtagWrngmsg = true;
    return;
  }else{
    this.showtagWrngmsg = false;
    this.rfid_tge_id = evt.option.value.rfid_tge_id; 
  }
}
strtSelected(evt: any){
  this.strt_id = evt.option.value.strt_id; 
}
geoLocationMicroPocket(ulb_id) {
  this.ulb_id = ulb_id;
  this.geoService.getMicroPocket(this.ulb_id,this.wrk_pckge_id,this.clsrt_id, (err, mcrpt_res) => {
      this.mcrptLst = mcrpt_res.data;
      this.selectedMp.setValue("");
      if(this.gateOpLbl == 'Add')
      this.rsGatesForm.get('mcrpt_id').setValue("");
  });
}
private _ulbfilter(value): Commercialgates[] {
  if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                      item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
  }
  return this.ulbLst;
}
private _topmpfilter(value): Commercialgates[] {
  if (value) {
      return this.mcrptLst.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                      item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
  }
  return this.mcrptLst;
}
private _mpfilter(value): Commercialgates[] {
  if (value) {
      return this.mcrptLst.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                      item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
  }
  return this.mcrptLst;
}
private _clstrfilter(value): Commercialgates[]{
  if (value) {
      return this.clstrLst.filter(item => ((typeof value === 'string') ? item.clstr_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                      item.clstr_nm.toLowerCase().indexOf(value['clstr_nm'].toLowerCase()) === 0));
  }
  return this.clstrLst;
}
private _strtfilter(value): Commercialgates[] {
  if (value) {
      return this.strtsLst.filter(item => ((typeof value === 'string') ? item.strt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                      item.strt_nm.toLowerCase().indexOf(value['strt_nm'].toLowerCase()) === 0));
  }
  return this.strtsLst;
}
private _tgfilter(value): Commercialgates[] {
  if (value) {
      return this.Tags.filter(item => ((typeof value === 'string') ? item.rfid_tge_scn_cd.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                      item.rfid_tge_scn_cd.toLowerCase().indexOf(value['rfid_tge_scn_cd'].toLowerCase()) === 0));
  }
  return this.Tags;
}

geoLocationWorkPackage(ulb) {
  this.ulb_id = ulb;  
  this.geoService.workPackage(this.ulb_id, (err, wrkPckg_res) => {
    this.wrkpckgsLst = wrkPckg_res.data;
  });
}
geoLocationCluster(wrk_pckge_id) {
  this.wrk_pckge_id = wrk_pckge_id;
  this.geoService.getClusterDetails(this.ulb_id,this.wrk_pckge_id, (err, clstr_res) => {
    this.clstrLst = clstr_res.data;
    if(this.gateOpLbl == 'Add')
    this.rsGatesForm.get('clsrt_id').setValue('');
  });
}
  getCommercialgatesLst(): void {
    this.shwLdr = true;
    if(this.ulb_id>0 && this.mcrpt_id>0){
      this.wastegeneratorService.getGatesByCategory(this.ulb_id,this.mcrpt_id, this.cat_id, (err, hse_hld_res) => {
        this.dataSource.data = hse_hld_res.data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.shwLdr = false;
      })
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 
opensideBar(key, empUpdtData, event: any) {
  this.getunasgndTags();
  console.log(empUpdtData);
  if (empUpdtData) {
    this.gateOpLbl = 'Edit';
    this.addTag =  false;
    this.rsGatesForm.get('tag_sts').setValue("existing");
    this.rsGatesForm.get('gte_id').setValue(empUpdtData.gte_id);
    this.rsGatesForm.get('gte_hse_nu').setValue(empUpdtData.gte_hse_nu);
    this.rsGatesForm.get('hse_hlds_ct').setValue(empUpdtData.hse_hlds_ct);
    this.rsGatesForm.get('ppltn_ct').setValue(empUpdtData.ppltn_ct);
    this.rsGatesForm.get('lat').setValue(empUpdtData.lat);
    this.rsGatesForm.get('lng').setValue(empUpdtData.lng);
    this.ulb_id = empUpdtData.ulb_id;
    this.geoLocationMicroPocket(empUpdtData.ulb_id);
    
    let mcrpt = {
      mcrpt_id:empUpdtData.mcrpt_id,
      mcrpt_nm:empUpdtData.mcrpt_nm,
      mcrpt_nu:empUpdtData.mp_nu
    }
    this.mcrpt_id =  empUpdtData.mcrpt_id;
    this.getStreets();
    this.mcrpt_nu = empUpdtData.mp_nu;
    this.rsGatesForm.get('mcrpt_id').setValue(mcrpt);
    this.rsGatesForm.get('wrk_pckge_id').setValue(empUpdtData.wrk_pckge_id);
    let clstr = {
      clsrt_id:empUpdtData.clsrt_id,
      clstr_nm:empUpdtData.clstr_nm
    } 
    this.rsGatesForm.get('clsrt_id').setValue(clstr);
    let strt = {
      strt_id:empUpdtData.strt_id,
      strt_nm:empUpdtData.strt_nm
    } 
    this.strt_id = empUpdtData.strt_id;
    this.rsGatesForm.get('strt_id').setValue(strt);
    let tag = {
      rfid_tge_id:empUpdtData.rfid_tge_id,
      rfid_tge_scn_cd:empUpdtData.rfid_tge_scn_cd,
      rfid_tge_nu:empUpdtData.rfid_tge_nu
    }
    this.rfid_tge_id = empUpdtData.rfid_tge_id;
    this.old_rfid_tge_id = empUpdtData.rfid_tge_id;
    this.rsGatesForm.get('rfid_tge_id').setValue(tag);
    this.rsGatesForm.get('rfid_tge_scn_cd').setValue(empUpdtData.rfid_tge_scn_cd);
    this.rsGatesForm.get('rfid_tge_nu').setValue(empUpdtData.rfid_tge_nu);
  }
  else {
    this.gateOpLbl = 'Add';
    this.addTag =  true;
    this.rsGatesForm.get('tag_sts').setValue("new");
    this.rsGatesForm.get('gte_id').setValue("");
    this.rsGatesForm.get('gte_hse_nu').setValue("");
    this.rsGatesForm.get('hse_hlds_ct').setValue("");
    this.rsGatesForm.get('ppltn_ct').setValue("");
    this.rsGatesForm.get('lat').setValue("");
    this.rsGatesForm.get('lng').setValue("");
    this.rsGatesForm.get('mcrpt_id').setValue("");
    this.rsGatesForm.get('wrk_pckge_id').setValue("");
    this.rsGatesForm.get('clsrt_id').setValue("");
    this.rsGatesForm.get('strt_id').setValue("");
    this.rsGatesForm.get('rfid_tge_scn_cd').setValue("");
    this.rsGatesForm.get('rfid_tge_nu').setValue("");
    this.rsGatesForm.get('rfid_tge_id').setValue("");
  }
  this._fuseSidebarService.getSidebar(key).toggleOpen();
}
getStreets(){
  let postData = {ulb_id:this.ulb_id,wrk_pckge_id:this.wrk_pckge_id,clsrt_id:this.clsrt_id,mcrpt_id:this.mcrpt_id}
  this.geoService.streetLst(postData, (err, res) => {
    this.strtsLst = res.data;
    if(this.gateOpLbl == 'Add')
    this.rsGatesForm.get('strt_id').setValue("");
  })
}
getunasgndTags(){
this.Tags = [];
this.hardwareService.unasgnedTagsLst(this.ulb_id, (err, unasgnedTags) => {
  for (let t of unasgnedTags.data) {
    this.Tags.push(t); 
  }
  if(this.gateOpLbl == 'Add')
  this.rsGatesForm.get('rfid_tge_id').setValue("");
  else if(this.gateOpLbl == 'Edit')
  this.getasgndTags();
})
}
getasgndTags(){
this.hardwareService.getRfidTags(this.ulb_id, (err, asgnedTags) => {
  for (let t of asgnedTags.data) {
    this.Tags.push(t); 
  }
  if(this.gateOpLbl == 'Add')
  this.rsGatesForm.get('rfid_tge_id').setValue("");
})
}
gettagStatus(event: any) {
  if (event.value == "existing")
    this.addTag = false;
  else if(event.value == "new")
    this.addTag = true;
}

addinstlDtEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  this.frmevents.push(`${type}: ${event.value}`);
  this.instl_ts = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
}
onSubmitcmform(){
  console.log(this.rsGatesForm.value);
  this.rsGatesForm.value.mcrpt_id = this.mcrpt_id;
  this.rsGatesForm.value.mcrpt_nu = this.mcrpt_nu;
  this.rsGatesForm.value.gte_ctgry_id = this.cat_id;
  this.rsGatesForm.value.instl_ts = this.instl_ts;
  this.rsGatesForm.value.strt_id = this.strt_id;
  this.rsGatesForm.value.old_rfid_tge_id = this.old_rfid_tge_id;
  this.rsGatesForm.value.rfid_tge_id = this.rfid_tge_id;
  this.rsGatesForm.value.ulb_id = this.ulb_id
  if(!this.rsGatesForm.value.addrs_tx)
  this.rsGatesForm.value.addrs_tx = '';
  if (this.rsGatesForm.status == 'INVALID') {
    return false;
  }
  if (this.rsGatesForm.get("gte_id").value == '' || this.rsGatesForm.get("gte_id").value == null) {
    this.addGate(this.rsGatesForm.value);
  } else {
    if(!this.showtagWrngmsg)
    this.updtGate(this.rsGatesForm.value);
  }
}
addGate(data){
    this.wastegeneratorService.postGatesByCategory(data, (err, res) => {
      if(res.status == 200){
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation:"Created" }
        });
        this.getCommercialgatesLst();
      }
    })
}
updtGate(data){
  this.wastegeneratorService.updateGate(data, (err, res) => {
    if(res.status == 200){
      const dialogRef = this.dialog.open(ConfirmDialog, {
        width: '500px',
        data: { cnfrmflag: false, successFlag: true, operation:"Updated" }
      });
      this.getCommercialgatesLst();
    }
  })
}
deleteGate(gteData){
  const dialogRef = this.dialog.open(ConfirmDialog, {
    width: '500px',
    data: { id: gteData.gte_id, entityname: 'Gate', cnfrmflag: true, successFlag: false,  operation:"Deleted",dtls:gteData.rfid_tge_nu}
  });
  dialogRef.afterClosed().subscribe(() => {
    this.getCommercialgatesLst();
  })
}
removeTag(rfid_tge_id,rfid_tge_nu){
 const dialogRef = this.dialog.open(ConfirmDialog, {
  width: '500px',
  data: { id: rfid_tge_id, entityname: 'Tag', cnfrmflag: true, successFlag: false,  operation:"Removed",dtls:rfid_tge_nu}
});  
}
reserForm(){
  this.rsGatesForm.reset();
}
}
