import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Workpackage } from './workpackage/workpackage';
import { Http } from '@angular/http';


@Injectable()
export class GeoLocationsService {

  constructor(public httpCtrl: Http, private http: HttpClient) { }

  public createWorkpackage(wp: Workpackage): void { }

  public updateWorkpackage(wp: Workpackage): void { }

  public deleteWard(id: number, cb) {
    this.http.post('/geoLocations/delete/ward', id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }


  public getUlbs(cb) {
    this.http.get('/geoLocations/ulb').subscribe(data => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  public workPackage(id, cb) {
    this.http.get('/geoLocations/workPackages/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  public addWorkPackage(data, cb) {
    this.http.post('/geoLocations/workPackages', data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public updtWorkPackage(data, cb) {
    this.http.put('/geoLocations/workPackages/' + data.wrk_pckge_id, data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public dtlWorkPackage(id, cb) {
    this.http.delete('/geoLocations/workPackages/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public getClusterDetails(ulb_id, wp_id, cb) {
    this.http.get('/geoLocations/clusters/' + ulb_id + '/' + wp_id).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public addCluster(data, cb) {
    this.http.post('/geoLocations/clusters', data).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public updtCluster(data, cb) {
    this.http.put('/geoLocations/clusters/' + data.clsrt_id, data).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public dtlCluster(id, cb) {
    this.http.delete('/geoLocations/clusters/' + id).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  // get Wards for location in Master Data 
  public getWards(ulb_id, wp_id, clsrt_id, cb) {
    this.http.get('/geoLocations/Wards/' + ulb_id + '/' + wp_id + '/' + clsrt_id).subscribe(data => {
      // Read the result field from the JSON response.
      console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }



  // get Micropockets for location in Master Data 
  public getMicroPocket(ulb_id, wp_id, clsrt_id, cb) {
    this.http.get('/geoLocations/microPockets/' + ulb_id + '/' + wp_id + '/' + clsrt_id).subscribe(data => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  
  // get Micropockets for location in Master Data 
  public getMicroPocketMstr(postData, cb) {
    this.http.post('/geoLocations/microPocketsMstr/',postData).subscribe(data => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public addMicropocket(data, cb) {
    this.http.post('/geoLocations/microPockets', data).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public updtMicropocket(data, cb) {
    this.http.put('/geoLocations/microPockets/' + data.mcrpt_id, data).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  public dltMicropocket(id: number, cb) {
    this.http.delete('/geoLocations/microPockets/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  // get Streets for location in Master Data 
  public streetLst(postdata, cb) {
    this.http.post('/geoLocations/streets/', postdata).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public addStreet(data, cb) {
    this.http.post('/geoLocations/street', data).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public updtStreet(data, cb) {
    this.http.put('/geoLocations/street/' + data.strt_id, data).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  public dltStreet(id: number, cb) {
    this.http.delete('/geoLocations/street/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  // get Streets for location in Master Data 
  public srvcProviderLst(cb) {
    this.http.get('/geoLocations/serviceProviders').subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public addServiceProvider(data, cb) {
    this.http.post('/geoLocations/serviceProvider', data).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public updtServiceProvider(data, cb) {
    this.http.put('/geoLocations/serviceProvider/' + data.srvce_prvdr_id, data).subscribe(data => {
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  public dltServiceProvider(id: number, cb) {
    this.http.delete('/geoLocations/serviceProvider/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  // get Wards for location in Master Data 
   public wardsLst(postData, cb) {
    this.http.post('/geoLocations/wards/',postData).subscribe(data => {
      // Read the result field from the JSON response.
      // console.log(data);
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }


    // get Gates list for generators in Master Data 
    public getGatesByCategory(postdata, cb) {
      this.http.post('/wg/gatesList',postdata).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
  
    // post Gate for Waste generators in Master Data 
    public postGatesByCategory(gatedata, cb) {
      this.http.post('/wg/gates',gatedata).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
  
    // Delete Gate for Waste generators in Master Data 
    public deleteGate(gte_id, cb) {
      this.http.delete('/wg/gate/'+gte_id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
  
    // Delete Gate for Waste generators in Master Data 
    public updateGate(gatedata, cb) {
      this.http.put('/wg/updateGteTagDetails/'+gatedata.gte_id,gatedata).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    // Delete Gate for Waste generators in Master Data 
    public removeTagFrmGate(rfid_tge_id, cb) {
      this.http.delete('/wg/removeTagFrmGte/'+rfid_tge_id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    // get Gate Categories 
    public getGateCategories(cb) {
      this.http.get('/wg/gateCtgries/').subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    // ***************************vehicles Master Data**********************************
    
    public getVehicles(postData,cb) {
      this.http.post('/getVehicles',postData).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    } 
     public getVehicleCtgries(clnt_id,tnt_id,cb) {
      this.http.get('/vehicles/categories/'+clnt_id+'/'+tnt_id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public getvehiclStatuses(clnt_id,tnt_id,cb) {
      this.http.get('/vehicles/satuses/'+clnt_id+'/'+tnt_id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public addVehicle(postData,cb) {
      this.http.post('/AddVehicle',postData).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public updtVehcle(postData,cb) {
      this.http.post('/updateVehicle',postData).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public dltVehicle(id: number, cb) {
      this.http.delete('/dltVehicle/' + id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public getUnapprovedEmps(id: number, cb) {
      this.http.get('/notapproved/employee/list/ulb/' + id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public approvedUlbEmps(postdata, cb) {
      this.http.post('/approve/ulb/employees', postdata).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public getUnapprovedClstrs(id: number, cb) {
      this.http.get('/notapproved/cluster/list/ulb/' + id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public approvedUlbClstrs(postdata, cb) {
      this.http.post('/approve/ulb/clusters', postdata).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public getUnapprovedMcrpts(id: number, cb) {
      this.http.get('/notapproved/micropocket/list/ulb/' + id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public approvedUlbMcrpt(postdata, cb) {
      this.http.post('/approve/ulb/micropocket', postdata).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    getEmpGropCtgry(ulb_id,cb) {
      this.http.get('/getEmpGropCtgry/'+ulb_id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log("Backend returned code:" + err.status + ", error message: " + err.error);
          }
          cb(true, null);
        });
    }
    public getGropsData(ulb_id,clstr_id,cb) {
      this.http.get('/geoLocations/employeeGroup/'+ulb_id+'/'+clstr_id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.log('An error occurred:', err.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    
    public addEmployeeGroup(data, cb) {
      this.http.post('/geoLocations/employeeGroup', data).subscribe(data => {
        // console.log(data);
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public updtEmployeeGroup(data, cb) {
      this.http.put('/geoLocations/employeeGroup/' + data.emp_grp_id, data).subscribe(data => {
        // console.log(data);
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
    public dltemployeeGroup(id: number, cb) {
      this.http.delete('/geoLocations/employeeGroup/' + id).subscribe(data => {
        cb(false, data);
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('An error occurred:', err.error.message);
          } else {
            console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
          }
          cb(true, null);
        });
    }
}
