import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Scanners } from './scanners/scanners.model';

@Injectable({
  providedIn: 'root'
})
export class HardwareService {

  constructor(private http: HttpClient) { }

  getWorkPackages() {
  }
  // get Hardware Rfid Tags
  public getRfidTags(postData, cb) {
    this.http.post('/hardware/tags/', postData).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Rfid Tags
  public unasgnedTagsLst(id, cb) {
    this.http.get('/hardware/unasgnedTags/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public getScannerCategory(cb) {
    this.http.get('/hardware/scanner/categories').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public getScannerStatus(cb) {
    this.http.get('/hardware/scanner/status').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public getScanners(id, cb) {
    this.http.get('/hardware/scanners/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public getAsgndScanners(id, cb) {
    this.http.get('/hardware/scanners/assigned/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public getUnasgndScnMcprtLst(id, cb) {
    this.http.get('/hardware/scanners/unassigned/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public asgnScanner(data, cb) {
    this.http.post('/hardware/scanners/assigned', data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public addScanner(data, cb) {
    this.http.post('/hardware/scanners', data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public updtScanner(data, cb) {
    this.http.put('/hardware/scanner/' + data.id, data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public dltScanner(id, cb) {
    this.http.delete('/hardware/scanner/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }

  // get Hardware Scanners 
  public getUlbs(cb) {
    this.http.get('/hardware/tracker/ulbs').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public getTrackerCategory(cb) {
    this.http.get('/hardware/tracker/categories').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Scanners 
  public getTrackerTypes(cb) {
    this.http.get('/hardware/tracker/types').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Trackers list for Hardware in Master Data 
  public hardwareTrackers(id, cb) {
    this.http.get('/hardware/trackers/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }

  // get Hardware Trackers 
  public addTracker(data, cb) {
    this.http.post('/hardware/trackers', data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Trackers 
  public updtTracker(data, cb) {
    this.http.put('/hardware/tracker/' + data.dvce_id, data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware Trackers 
  public dltTracker(id, cb) {
    this.http.delete('/hardware/tracker/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
 // get Status of Trackers 
 public getTrackerStatus(id, cb) {
  this.http.get('/hardware/Trackerstatus/' + id).subscribe(data => {
    cb(false, data);
  },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log('An error occurred:', err.error.message);
      } else {
        console.log("Backend returned code:" + err.status + ", error message: " + err.error);
      }
      cb(true, null);
    });
}
  // get Weighing machines list for Hardware in Master Data 
  public hardwareWeighingMachines(id, cb) {
    this.http.get('/hardware/wghMchns/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware WeighingMachines 
  public addWeighingMachine(data, cb) {
    this.http.post('/hardware/wghMchns', data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware WeighingMachines 
  public updtWeighingMachine(data, cb) {
    this.http.put('/hardware/wghMchn', data).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware WeighingMachines 
  public dltWeighingMachine(id, cb) {
    this.http.delete('/hardware/wghMchn/' + id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // get Hardware WeighingMachines 
  public getTagCtgryLst( cb) {
    this.http.get('/hardware/tagCtgryLst/').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
   // get Status of WeighingMachines 
   public getWeighMchnStatus( cb) {
    this.http.get('/hardware/wghMchn/status').subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }

}
