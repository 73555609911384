import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HardwareService } from './../hardware.service';
import { rfidTags } from './rfidtags.model';
import { ActivatedRoute } from '@angular/router';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
// import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { UserService } from 'app/providers/user/user.serivce';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-rfidtags',
  templateUrl: './rfidtags.component.html',
  styleUrls: ['./rfidtags.component.scss']
})
export class RFIDTagsComponent implements OnInit {
  rfidtagLst: rfidTags[] = [];
  headerForm: FormGroup;
  tagForm: FormGroup;
  ulbLst: rfidTags[] = [];
  mcrptLst: rfidTags[] = [];
  userPerm: any = {};
  ulb_id: number;
  ulb_nm: string;
  wrk_pckge_id: number = 0;
  clsrt_id: number = 0;
  mcrpt_id: number;
  gateOpLbl: string;
  rfid_tge_id: number;
  rfid_tge_ctgry_id:number;
  tagCtgryLst = [];
  displayedColumns: string[] = ['sno','id', 'ulb_nm', 'gte_nm', 'number', 'code','strt_nm','gte_hse_nu','actions'];
  displayedColumns2: string[] = ['sno','rfid_tge_id', 'rfid_tge_scn_cd', 'mcrpt_nm', 'prvs_gte_id', 'p_gte_hse_nu', 'p_strt_nm', 'nxt_gte_id', 
                                  'n_gte_hse_nu', 'n_gte_nm', 'n_strt_nm', 'actions'];
                                  
  ulbtopFltrOptions: Observable<rfidTags[]>;

  dataSource = new MatTableDataSource<rfidTags>();
  dataSource2 = new MatTableDataSource<rfidTags>();
  ulb;
  topmpFltrOptions: Observable<rfidTags[]>;
  ulbFltrOptions: Observable<rfidTags[]>;
  mpFltrOptions: Observable<rfidTags[]>;
  selectedTabIndex: number;
  @ViewChild('tabGroup') tabGroup;
  assignedTagTab: boolean = true;
  shwLdr: boolean;
  frmevents: string[] = [];
  toevents: string[] = [];
  noData;
  assgnddata: any[];
  unassgnddata:any[];
  excelData: any[];
  isExcelDwnLd: any;
  excelDataHeaders: string[];
  excelFileNm: string;
  excelsData: any[];
  isExcelsDwnLd: any;
  excelsDataHeaders: string[];
  excelsFileNm: string;
  unassignedTagTab: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedMp = new FormControl()
  /**
    * @param {FuseSidebarService} _fuseSidebarService
    */
  constructor(public datepipe: DatePipe,private hardwareService: HardwareService, private _fuseSidebarService: FuseSidebarService, private geoService: GeoLocationsService,
    private userService: UserService) {
  }

  ngOnInit() {
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulb = {
      ulb_id: this.ulbLst[0].ulb_id,
      ulb_nm: this.ulbLst[0].ulb_nm
    };
    this.ulb_id = this.ulbLst[0].ulb_id;
    this.ulb_nm = this.ulbLst[0].ulb_nm;
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // })

    this.headerForm.get('selectedUlb').setValue(this.ulb);
    // this.rfidTagsLst();

    this.selectedTabIndex = this.tabGroup.selectedIndex;
    this.userPerm = this.userService.CURRENT_PERM;
    this.tagForm = new FormGroup({
      rfid_tge_id: new FormControl(),
      ulb_id: new FormControl('', Validators.required),
      mcrpt_id: new FormControl('', Validators.required),
      rfid_tge_scn_cd: new FormControl('', Validators.required),
      rfid_tge_nu: new FormControl('', Validators.required),
      rfid_tge_ctgry_id: new FormControl('', Validators.required)
    });
    
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
    .pipe(
      startWith(''),
      map(value => this._ulbfilter(value))
    );
    this.topmpFltrOptions = this.selectedMp.valueChanges.pipe(
      startWith(''),
      map(value => this._mpfilter(value))
    );
    this.ulbFltrOptions = this.tagForm.get('ulb_id').valueChanges
    .pipe(
      startWith(''),
      map(value => this._ulbfilter(value))
    );
    this.mpFltrOptions = this.tagForm.get('mcrpt_id').valueChanges
    .pipe(
      startWith(''),
      map(value => this._mpfilter(value))
    );
    this.geoLocationMicroPocket(this.ulb_id);
  }

  autocompletetpudisplayFn(ulb?: rfidTags): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  autocompleteUDisplayFn(tag?: rfidTags): string  | undefined{
    return tag ? tag.ulb_nm : undefined;
  }
  autocompleteMDisplayFn(tag?: rfidTags): string  | undefined{
    return tag ? tag.mcrpt_nm : undefined;
  }
  private _ulbfilter(value): rfidTags[] {
    if (value) {
        return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  private _mpfilter(value): rfidTags[] {
    if (value) {
        return this.mcrptLst.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
    }
    return this.mcrptLst;
  }

  ulbSelected (evt: any) {
    this.ulb_id = evt.option.value.ulb_id; 
    this.ulb_nm = evt.option.value.ulb_nm; 
    this.geoLocationMicroPocket(this.ulb_id);
  }
  
mpSelected (evt: any) {
  this.mcrpt_id = evt.option.value.mcrpt_id;
  this.rfidTagsLst();
}
  slctdMp(evt: any){
    this.mcrpt_id = evt.option.value.mcrpt_id;
  }
  
  onUlbChange(evt) {
    console.log("i am ulb");
    console.log(evt);
    this.ulb_id = evt.option.value.ulb_id;
    this.geoLocationMicroPocket(this.ulb_id);
    this.unasgnedTagsLst(this.ulb_id);
  }
  // onUlbChange(ulb_id) {
  //   this.ulb_id = ulb_id;
  //   // this.rfidTagsLst();
  // }
  rfidTagsLst() {
    this.dataSource.data = [];
    this.shwLdr = true;
    let postData = {
      ulb_id : this.ulb_id,
      mcrpt_id : this.selectedMp.value.mcrpt_id
    }
    this.hardwareService.getRfidTags(postData, (err, rfidtagLst) => {
      this.dataSource.data = rfidtagLst.data;
      this.assgnddata = rfidtagLst.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  unasgnedTagsLst(id) {
    // console.log("i came");
    // console.log(id);
    this.dataSource2.data = [];
    this.shwLdr = true;
    this.hardwareService.unasgnedTagsLst(id, (err, unasgnedTags) => {
      this.dataSource2.data = unasgnedTags.data;
      this.unassgnddata = unasgnedTags.data;
      this.dataSource2.paginator = this.paginator;
      this.dataSource2.sort = this.sort;
      this.shwLdr = false;
    });
  }
  ngOnChanges(changes: any) {
    this.ulb_id = changes.ulb_id.currentValue;
    // this.rfidTagsLst();
    this.unasgnedTagsLst(changes.ulb_id.currentValue);
  }
  
  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }

  applyUnasgndFilter(filterValue: string): void {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
    if (this.dataSource2.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }

  opensideBar(key, empUpdtData, event: any) {
    if (empUpdtData) {
      this.gateOpLbl = 'Edit';
      let ulb = {
        ulb_id: empUpdtData.ulb_id,
        ulb_nm: this.ulb_nm};
      this.ulb_id = empUpdtData.ulb_id;
      this.tagForm.get('ulb_id').setValue(ulb);
      let mcrpt = {
        mcrpt_id: empUpdtData.mcrpt_id,
        mcrpt_nm: empUpdtData.mcrpt_nm
      };
      this.tagForm.get('mcrpt_id').setValue(mcrpt);
      let tag = {
        rfid_tge_id: empUpdtData.rfid_tge_id,
        rfid_tge_scn_cd: empUpdtData.rfid_tge_scn_cd,
        rfid_tge_nu: empUpdtData.rfid_tge_nu
      };
      this.rfid_tge_id = empUpdtData.rfid_tge_id;
      this.tagForm.get('rfid_tge_id').setValue(tag);
      this.tagForm.get('rfid_tge_scn_cd').setValue(empUpdtData.rfid_tge_scn_cd);
      this.tagForm.get('rfid_tge_nu').setValue(empUpdtData.rfid_tge_nu);
      this.tagForm.get('rfid_tge_ctgry_id').setValue(empUpdtData.rfid_tge_ctgry_id);
    }
    else {
      this.gateOpLbl = 'Add';
      this.tagForm.get('ulb_id').setValue({ulb_id: this.ulb_id, ulb_nm: this.ulb_nm});
      this.tagForm.get('mcrpt_id').setValue('');
      this.tagForm.get('rfid_tge_scn_cd').setValue('');
      this.tagForm.get('rfid_tge_nu').setValue('');
      this.tagForm.get('rfid_tge_id').setValue('');
      this.tagForm.get('rfid_tge_ctgry_id').setValue('');
    }
    this.getTagCtgry();
    this.geoLocationMicroPocket(this.ulb_id);
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  public tabChanged(): void {
    if (this.tabGroup.selectedIndex === 0) {
      this.assignedTagTab = true; this.unassignedTagTab = false;
      this.rfidTagsLst();
    } else if (this.tabGroup.selectedIndex === 1) {
      this.assignedTagTab = false; this.unassignedTagTab = true;
      this.unasgnedTagsLst(this.ulb_id);
    }
  }

  geoLocationMicroPocket(ulb_id) {
    this.ulb_id = ulb_id;
    this.geoService.getMicroPocket(this.ulb_id, this.wrk_pckge_id, this.clsrt_id, (err, mcrpt_res) => {
        this.mcrptLst = mcrpt_res.data;
        if (this.gateOpLbl === 'Add')
          this.tagForm.get('mcrpt_id').setValue('');
        else 
        this.selectedMp.setValue('');
    });
  }
  getTagCtgry(){
    this.hardwareService.getTagCtgryLst((err, res) => {
      this.tagCtgryLst = res.data;
    });
  }
  onSubmitcmform(){
    // if (this.tagForm.status == 'INVALID') {
    //   return false;
    // }
    if (this.tagForm.get('gte_id').value === '' || this.tagForm.get('gte_id').value == null) {
      // this.addGate(this.tagForm.value);
    } else {
      // this.updtGate(this.tagForm.value);
    }
  }


  downloadExcel($event): void {
   
    console.log(this.assgnddata);
    if (this.assgnddata && this.assgnddata.length > 0) {
      this.excelData = [];
      for (let i of this.assgnddata) {
        this.excelData.push({
          'SNo': i.id ? i.id : '', 'ULB': i.ulb_nm ? i.ulb_nm : '', 'Gate': i.gte_nm ? i.gte_nm : '',
          'Number':i.number ? i.number : '',
          'Code': i.code ? i.code : ''});
      }
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['SNo', 'ULB', 'Gate', 'Number', 'Code'];
        this.excelFileNm = 'AssignedTags_data_' + this.ulb_nm;
    }
  }

  downloadExcels($event): void {
   
    console.log(this.unassgnddata);
    if (this.unassgnddata && this.unassgnddata.length > 0) {
      this.excelsData = [];
      for (let i of this.unassgnddata) {
        this.excelsData.push({
          'Number': i.rfid_tge_id ? i.rfid_tge_id : '', 'Code': i.rfid_tge_scn_cd ? i.rfid_tge_scn_cd : '', 'Micropocket Name': i.mcrpt_nm ? i.mcrpt_nm : '',
          'ULB':i.ulb_nm ? i.ulb_nm : '',
          'Previous Gate': i.prvs_gte_id ? i.prvs_gte_id : '',
          'Previous House': i.p_gte_hse_nu ? i.p_gte_hse_nu : '',
          'Previous Street': i.p_strt_nm ? i.p_strt_nm : '',
          'Next Gate Id': i.nxt_gte_id ? i.nxt_gte_id : '',
          'Next House': i.n_gte_hse_nu ? i.n_gte_hse_nu : '',
          'Next Gate': i.n_gte_nm ? i.n_gte_nm : '',
          'Next Street': i.n_strt_nm ? i.n_strt_nm : ''});
      }
        this.isExcelsDwnLd = $event;
        this.excelsDataHeaders = ['Number', 'Code', 'Micropocket Name', 'ULB', 'Previous Gate','Previous House', 'Previous Street', 'Next Gate Id', 'Next House',
         'Next Gate', 'Next Street'];
        this.excelsFileNm = 'UnAssignedTags_data_' + this.ulb_nm;
    }
  }
}
