import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { UserService } from 'app/providers/user/user.serivce';
import { confirmPasswordValidator } from '../reset-password-2/reset-password-2.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class UserProfileComponent implements OnInit {
    userProfileForm: FormGroup;
    resetPasswordForm: FormGroup;
    err_msg = null;
    userData: any = {};
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private userService: UserService
        , public router: Router
    ) {
        // Configure the layout
        this.userService.USER_DETAILS.subscribe(val => {
            this.userData = val;
            console.log(val);
        });
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.userProfileForm = this._formBuilder.group({
            frst_nm: ['', Validators.required],
            lst_nm: [''],
            mbl_nu: ['', Validators.required],
        })
        this.userProfileForm.get('frst_nm').setValue(this.userData.frst_nm);
        this.userProfileForm.get('lst_nm').setValue(this.userData.lst_nm);
        this.userProfileForm.get('mbl_nu').setValue(this.userData.mbl_nu);
        this.resetPasswordForm = this._formBuilder.group({
            // username: ['', [Validators.required]],
            nw_pswrd: ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('nw_pswrd').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    saveUsrDt() {
        console.log(this.userProfileForm);
        this.err_msg = null;
        this.userData.frst_nm = this.userProfileForm.value.frst_nm;
        this.userData.lst_nm = this.userProfileForm.value.lst_nm;
        this.userData.mbl_nu = this.userProfileForm.value.mbl_nu;
        this.authService.saveUsrDt(this.userData, (err, res) => {
            if (err) {
                return;
            }
            if (res && res.status == 200) {
                if (!res.data) {
                    this.err_msg = "Try again with a password that you haven't used before";
                } else {
                    this.router.navigate(['/']);
                }
            }
        })
    }
    submit() {
        console.log(this.resetPasswordForm);
        this.err_msg = null;
        this.resetPasswordForm.value.usr_id = this.userData.usr_id;
        this.authService.resetPassword(this.resetPasswordForm.value, (err, res) => {
            if (err) {
                return;
            }
            if (res && res.status == 200) {
                if (!res.data) {
                    this.err_msg = "Try again with a password that you haven't used before";
                } else {
                    this.router.navigate(['/']);
                }
            }
        })
    }
}
