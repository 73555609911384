import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {CdkTableModule} from '@angular/cdk/table';
import { FlexLayoutModule } from "@angular/flex-layout";
import { ScorecardComponent } from './scorecard/scorecard.component'
import { MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, 
  MatTableModule, MatPaginatorModule  , MatSortModule , MatButtonModule, MatIconModule, MatDatepickerModule } from '@angular/material';
  import { FuseWidgetModule } from '../../../@fuse/components/widget/widget.module';
  import {DatepickerViewsSelectionExample} from './Monthdatepicker/datepicker-views-selection-example';
  import {FormsModule, ReactiveFormsModule} from '@angular/forms';

const kpi_routes: Routes = [
  {
    path: 'kpiscorecard', component: ScorecardComponent
  }    
];

@NgModule({
  declarations: [
    ScorecardComponent,
    DatepickerViewsSelectionExample
  ],
  imports: [
    RouterModule.forChild(kpi_routes),
    CommonModule,
    FlexLayoutModule,
    CdkTableModule,
    CommonModule,
    MatTabsModule,
    MatCardModule, 
    MatInputModule, 
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule, 
    MatTableModule, 
    MatPaginatorModule  ,
    MatSortModule , 
    MatButtonModule, 
    MatIconModule,
    MatDatepickerModule,
    FuseWidgetModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ScorecardComponent,
    DatepickerViewsSelectionExample
  ],
  entryComponents: [DatepickerViewsSelectionExample],
  bootstrap: [DatepickerViewsSelectionExample],
})
export class KpiModule { }
