import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatRadioChange, MatDialog } from '@angular/material';
import { CrudService } from '../crud.service';
import { number } from '@amcharts/amcharts4/core';
import { TimelinePublicComponent } from '../timeline-public/timeline-public.component';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { TimelineDetailsComponent } from '../timeline-details/timeline-details.component';
let ELEMENT_DATA = []

@Component({
  selector: 'app-public-portal',
  templateUrl: './public-portal.component.html',
  styleUrls: ['./public-portal.component.scss']
})
export class PublicPortalComponent implements OnInit {
  filterdData: any;
  [x: string]: any;
  displayedColumns: string[] = ['position', 'Filenumber', 'Name', 'Date', 'Department', 'Category', 'Sub-category', 'description', 'Actions'];
  dataSourcePub = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  publicLst: any;
  // sort: any;
  filterby: any = number;
  name: string = '';
  searchDataRes: any;
  shwtbl = false;
  tblfalse = false;
  dialogRef: any;
  fltrdVal: string;
  status_nm:any;

  constructor(public crdsrv: CrudService, public _matDialog: MatDialog, ) { }

  ngOnInit() {
  }

  filterChange(event: MatRadioChange) {
    console.log(event.value)
    if (event.value == 1) {
      this.filterby = "Filenumber"
    }
    else if (event.value == 2) {
      this.filterby = "Filename"
    }
    else {
      this.filterby = "Filephone"
    }
    console.log(this.filterby)
  }
  //  filterResults(filterby){

  //  }
  // searchData() {
  //   let trackData = {
  //     trackby: this.filterby,
  //     inpt: name,
  //   }
  //   this.crdsrv.get('ticket-system/req-entry-list-public/'+trackData ).subscribe((res) => {
  //     this.searchDataRes = res['data'];
  //   }, (error) => {
  //     console.log(error)
  //   })
  // }

  getPublicLst() {
    let trackData = {
      trackby: this.filterby,
      inpt: this.name,
    }
    console.log(trackData)
    this.crdsrv.create(trackData, 'ticket-system/req-entry-list-public').subscribe((res) => {
      this.publicLst = res['data'];
      this.filterdData = this.publicLst;
      console.log(this.publicLst)
      if (this.publicLst.length > 0) {
        this.shwtbl = true;
        this.tblfalse = false;
      }
      else {
        this.tblfalse = true;
        this.shwtbl = false;
      }
      this.dataSourcePub = new MatTableDataSource(this.filterdData); //Table lo chupinchadaniki
      console.log(this.dataSourcePub)
      this.dataSourcePub.paginator = this.paginator;  //Pagination Kosam
      this.dataSourcePub.sort = this.sort;
    }, (error) => {
      console.log(error)
    })
  }
  

  viewPubtmln(element) {
    console.log(element);
    element['ind'] = 1
    this.dialogRef = this._matDialog.open(TimelineDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '98%',
      height: '99vh',
      panelClass: 'view-modal',
      data: element,
    });
    this.dialogRef.afterClosed()
      .subscribe((response) => {
        if (!response) {
          return;
        }
        console.log(response)

      });
  }

  applyFilter(filterValue: string) {
    console.log(filterValue);
    this.fltrdVal = filterValue;
    console.log(this.publicLst)
    if (filterValue.length > 0) {
      this.filterdData = this.publicLst.filter(function (v, i) {
        return ((v["req_qr_cd"].toLowerCase().includes(filterValue.toLowerCase())
          || v["req_usr_nm"].toLowerCase().includes(filterValue.toLowerCase())
          || v["dprmnt_nm"].toLowerCase().includes(filterValue.toLowerCase())
          || v["cat_nm"].toLowerCase().includes(filterValue.toLowerCase())
          || v["req_txt"].toLowerCase().includes(filterValue.toLowerCase())
          ));
      })
    } else {
      this.filterdData = this.publicLst
    }
    this.dataSourcePub = new MatTableDataSource(this.filterdData);
  }
}

cat_id: 2
cat_nm: "Town Maintanance"
dprmnt_id: 1
dprmnt_nm: "Building Development"
i_ts: "2019-08-30T06:33:03.000Z"
req_entry_id: 419
req_qr_cd: "RJY-20190830-3"
req_txt: "rgdh"
req_usr_nm: "rohit"
sub_cat_id: 1
sub_cat_nm: "Street Swpeer"