import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudService } from 'app/main/workflow/crud.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { TimelineDetailsComponent } from 'app/main/workflow/timeline-details/timeline-details.component';





@Component({
  selector: 'app-ticket-dashbaord',
  templateUrl: './ticket-dashbaord.component.html',
  styleUrls: ['./ticket-dashbaord.component.scss']
})
export class TicketDashbaordComponent implements OnInit {
  // frm_dt(arg0: any): any {
  //   throw new Error("Method not implemented.");
  // }
  // to_dt(arg0: any): any {
  //   throw new Error("Method not implemented.");
  // }
  // assigned(arg0: any): any {
  //   throw new Error("Method not implemented.");
  // }


  // public month: number = new Date().getMonth();
  // public fullYear: number = new Date().getFullYear();

  // date;
  // start
  deprtlst = [];
  userlst = [];
  columnDefs = [];
  columnDefs_userdata = [];
  dialogRef: any;
  spnrCtrl: boolean;
  udr_dtl: any;
  cat_id: any;
  sub_cat_id: any;
  sts_id: any;
  req_entry_id: any;
  opened: any;
  fltrdVal: string;
  dprmnt_id: any;
  asngd_to_me: any;
  tbllst: any;
  attachment: any;
  tblst: any;
  getData: { fnlData: any; usr_id: any; };
  rprtLst: any;
  usrlst: any;
  frm_dt;
  to_dt;
  assigned: any;
  lastassigned: any;
  pagination_userdata;
  paginationPageSize;
  paginationPageSize_userdata;
  pagination;
  public start: Date = new Date();
  public end: Date = new Date();
  // public start: Date = this.startDate;
  // public end: Date =this.endDate;


  overallCounts = [];
  displayedColumns: string[] = ['S.NO', 'Department Name', '1-5 Days', '6-15 days', '16-45 days', '46-90 days', 'Morethan90 days', 'Completed Files', 'Total'];
  // displayedColumnsuser: string[] = ['S.NO', 'User Name', '1-5 Days', '6-15 days', '16-45 days', '46-90 days', 'Morethan90 days', 'Completed Files', 'Total'];

  dataSource = new MatTableDataSource([]);

  dataSourceone = new MatTableDataSource([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  crdCnt: any;
  selecteddt: any;
  fromdate: string;
  todate: string;
  shwdprtmnt: boolean;
  shwusrs: boolean;
  showusernm: boolean;
  shownm: boolean;
  shwDshFltrs: boolean;
  get_ind: number;
  excelDeptDataHeaders = [];
  exceluserDataHeaders = [];
  userExcelData = [];
  fnluserExcelData;
  isuserExcelDwnLd;
  exceluserFileNm;
  deptExcelData = [];
  fnldeptExcelData;
  isdeptExcelDwnLd;
  exceldeptFileNm;
  lstassign: any;
  ind:any;


  getMinDate(): any { return moment().subtract(1, 'years').add(1, 'days'); }
  getMaxDate(): any { return moment(); }

  constructor(private apiCtrl: CrudService, public _matDialog: MatDialog, public dialog: MatDialog, ) {
    let dt = new Date()
    this.frm_dt = dt
    this.to_dt = dt;
  }

  ngOnInit() {
    this.dashbrddeptRprtLst();
    this.dashbrduserRprtLst();
    // this.dashbrdRprtLst();
    this.entryTktDtls();
  }

  // asgndBfrSixMnths(lastassigned) {
  //   console.log(lastassigned);
  //   this.lastassigned = lastassigned;
  //   this.entryTktDtls();
  //   this.dashbrddeptRprtLst();
  //   this.dashbrduserRprtLst();
  // }
  getDashBoardData() {
    console.log(this.lastassigned);
    this.lstassign = this.lastassigned;
    console.log(this.selecteddt);
    this.fromdate = '';
    this.todate = '';
    // this.get_ind = 1;
    this.entryTktDtls();
    this.dashbrddeptRprtLst();
    this.dashbrduserRprtLst();
  }




  dashbrddeptRprtLst() {
    console.log("Department List")
    var date = new Date();
    if (this.selecteddt == undefined) {
      this.fromdate = moment(date).format('YYYY-MM-DD');
      this.todate = moment(date).format('YYYY-MM-DD');
    } else {
      this.fromdate = moment(this.selecteddt[0]).format('YYYY-MM-DD');
      this.todate = moment(this.selecteddt[1]).format('YYYY-MM-DD');
      this.ind=1

    }

    let crntdata = {
      frm_dt: this.fromdate != undefined ? this.fromdate : '',
      to_dt: this.todate != undefined ? this.todate : '',
      lstasg: this.lstassign != undefined ? this.lstassign : '',
      ind: this.ind
    }
    crntdata['ind'] = this.ind;
    console.log(crntdata);
    // let postdata ={
    //   crntdata:crntdata,
    //   lastassigned: this.lastassigned != undefined ? this.lastassigned : '',
    // }

    this.apiCtrl.create(crntdata, 'ticket-system/dept-reprt').subscribe((res) => {
      // this.tbllst = res['data'];
      // console.log(this.tbllst);
      // if (res['data'].length > 0) {
      //   this.spnrCtrl = false;
      //   this.shwDshFltrs = true;
      if (res['status'] === 200) {
        this.rprtLst = res['data'];
        console.log(this.rprtLst);

        for (let i = 0; i < this.rprtLst.length; i++) {
          this.deptExcelData.push({
            dprmnt_nm: this.rprtLst[i].dprmnt_nm, one_to_five: this.rprtLst[i].one_to_five, six_to_fiftn: this.rprtLst[i].six_to_fiftn,
            sxtn_to_frtfv: this.rprtLst[i].sxtn_to_frtfv, frtsx_to_nty: this.rprtLst[i].frtsx_to_nty, abve_nty: this.rprtLst[i].abve_nty,
            completed: this.rprtLst[i].completed, total: this.rprtLst[i].total
          });
        }

        this.excelDeptDataHeaders.push('Department Name', '1 - 5 days', '6 - 15 days', ' 16 - 45 days ', '46 - 90 days', 'Morethan90 Days', 'Completed', 'Total')
        this.columnDefs = [
          {
            headerName: 'Department Name',
            field: 'dprmnt_nm',
            sortable: true,
            filter: true,
            width: 250,
            cellStyle: { textAlign: 'center',fontSize: '14px'},
          },
          {
            headerName: '1 - 5 days',
            field: 'one_to_five',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: { cursor: 'pointer', textAlign: 'center' },
          },
          {
            headerName: '6 - 15 days',
            field: 'six_to_fiftn',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: '16 - 45 days',
            field: 'sxtn_to_frtfv',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: '46 - 90',
            field: 'frtsx_to_nty',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: 'Morethan90 Days',
            field: 'abve_nty',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: 'Completed',
            field: 'completed',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: 'Total',
            field: 'total',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              textAlign: 'center'
            }
          },
        ];
      }
    }, (err) => {
      console.log(err);
    });
  }


  searchUsrnm(filterValue: string) {
    console.log(filterValue);
    this.fltrdVal = filterValue;
    this.dashbrduserRprtLst();
  }
  dashbrduserRprtLst() {
    this.shownm = false;
    this.showusernm = true;
    var date = new Date();
    if (this.selecteddt == undefined) {
      this.fromdate = moment(date).format('YYYY-MM-DD');
      this.todate = moment(date).format('YYYY-MM-DD');
    } else {
      this.fromdate = moment(this.selecteddt[0]).format('YYYY-MM-DD');
      this.todate = moment(this.selecteddt[1]).format('YYYY-MM-DD');
      this.ind=1
    }

    let crntdata = {
      frm_dt: this.fromdate != undefined ? this.fromdate : '',
      to_dt: this.todate != undefined ? this.todate : '',
      lstasg: this.lstassign != undefined ? this.lstassign : '',
      ind: this.ind
    }
    crntdata['ind'] = this.ind;
    console.log(crntdata);

    this.apiCtrl.create(crntdata, 'ticket-system/user-reprt').subscribe((res) => {
      this.deprtlst = [];
      if (res['status'] === 200) {
        this.usrlst = res['data'];
        console.log(this.usrlst);

        for (let i = 0; i < this.usrlst.length; i++) {
          this.userExcelData.push({
            usr_nm: this.usrlst[i].usr_nm, one_to_five: this.usrlst[i].one_to_five, six_to_fiftn: this.usrlst[i].six_to_fiftn,
            sxtn_to_frtfv: this.usrlst[i].sxtn_to_frtfv, frtsx_to_nty: this.usrlst[i].frtsx_to_nty, abve_nty: this.usrlst[i].abve_nty,
            completed: this.usrlst[i].completed, total: this.usrlst[i].total
          });
        }

        this.exceluserDataHeaders.push('User Name', '1 - 5 days', '6 - 15 days', ' 16 - 45 days ', '46 - 90 days', 'Morethan90 Days', 'Completed', 'Total')
        this.columnDefs_userdata = [
          {
            headerName: 'User Name',
            field: 'usr_nm',
            sortable: true,
            filter: true,
            width: 250,
            cellStyle: {
              textAlign: 'center',fontSize: '14px'
            }
          },
          {
            headerName: '1 - 5 days',
            field: 'one_to_five',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: '6 - 15 days',
            field: 'six_to_fiftn',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: '16 - 45 days',
            field: 'sxtn_to_frtfv',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: '46 - 90 Days',
            field: 'frtsx_to_nty',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: 'Morethan90 Days',
            field: 'abve_nty',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: 'Completed',
            field: 'completed',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              cursor: 'pointer',
              textAlign: 'center'
            }
          },
          {
            headerName: 'Total',
            field: 'total',
            sortable: true,
            filter: true,
            width: 210,
            cellStyle: {
              textAlign: 'center'
            }
          },
        ];
      }
    }, (err) => {
      console.log(err);
    });
  }
  downloaduserExcel($event, data): void {
    this.fnluserExcelData = [];
    this.isuserExcelDwnLd = $event;
    this.fnluserExcelData = data;
    this.exceluserDataHeaders = this.exceluserDataHeaders;
    this.exceluserFileNm = 'User_Wise_rpt';
  }

  downloaddeptExcel($event, data): void {
    this.fnldeptExcelData = [];
    this.isdeptExcelDwnLd = $event;
    this.fnldeptExcelData = data;
    this.excelDeptDataHeaders = this.excelDeptDataHeaders;
    this.exceldeptFileNm = 'Dept_Wise_rpt';
  }




  entryTktDtls() {
    console.log(this.lstassign);
    var date = new Date();
    if (this.selecteddt == undefined) {
      this.fromdate = moment(date).format('YYYY-MM-DD');
      this.todate = moment(date).format('YYYY-MM-DD');
    } else {
      this.fromdate = moment(this.selecteddt[0]).format('YYYY-MM-DD');
      this.todate = moment(this.selecteddt[1]).format('YYYY-MM-DD');
      this.ind=1
    }
    this.spnrCtrl = true;


    let EntryData = {
      // usr_id: this.udr_dtl.usr_id != undefined ? this.udr_dtl.usr_id : '',
      // cat_id: this.cat_id != undefined ? this.cat_id : '',
      // sub_cat_id: this.sub_cat_id != undefined ? this.sub_cat_id : '',
      // sts_id: this.sts_id != undefined ? this.sts_id : '',
      frm_dt: this.fromdate != undefined ? this.fromdate : '',
      to_dt: this.todate != undefined ? this.todate : '',
      // req_entry_id: this.req_entry_id != undefined ? this.req_entry_id : '',
      lstasg: this.lstassign != undefined ? this.lstassign : '',
      // opened: this.opened != undefined ? this.opened : '',
      // flte_txt: this.fltrdVal != undefined ? this.fltrdVal : '',
      // admn_in: this.udr_dtl.spr_admn_in != undefined ? this.udr_dtl.spr_admn_in : '',
      // dprmnt_id: this.dprmnt_id != undefined ? this.dprmnt_id : '',
      // asngd_to_me: this.asngd_to_me != undefined ? this.asngd_to_me : ''
      ind:this.ind
    }
    EntryData['ind'] = this.ind;
    console.log(EntryData);
    this.apiCtrl.create(EntryData, 'ticket-system/dashboard-req-entry-list').subscribe((res) => {
      this.tbllst = res['data'];
      console.log(this.tbllst);
      if (res['data'].length > 0) {
        this.spnrCtrl = false;
        this.shwDshFltrs = true;
        this.crdCnt = res['data'][0];
        console.log(this.crdCnt)
        if (this.crdCnt.total == null || this.crdCnt.closed == null || this.crdCnt.forward == null || this.crdCnt.opened == null) {
          this.crdCnt = {
            total: 0,
            closed: 0,
            forward: 0,
            opened: 0
          }
        }
        setTimeout(() => {
          this.dataSourceone = new MatTableDataSource(this.tblst);
          this.dataSourceone.paginator = this.paginator;
          this.dataSourceone.sort = this.sort;
        }, 2000);

      } else {
        setTimeout(() => {
          this.spnrCtrl = false;
        }, 50000);

      }
    });
  }


  viewtmln(element) {
    console.log(element);
    element['ind'] = 0;
    this.dialogRef = this._matDialog.open(TimelineDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '98%',
      height: '99vh',
      panelClass: 'view-modal',
      data: element
    });
    this.dialogRef.afterClosed().subscribe((response) => {
      console.log(response)
      console.log(`Dialog closed: ${response}`);
      this.entryTktDtls();
    });
  }

  getRowClicked(slctdData): void {
    console.log(slctdData);

    this.shwdprtmnt = true;
    this.columnDefs = [
      {
        headerName: 'File Num',
        field: 'req_qr_cd',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: { textAlign: 'center' ,fontSize: '14px'},
      },
      {
        headerName: 'Mobile',
        field: 'req_usr_mbl',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Request',
        field: 'req_txt',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: {
          textAlign: 'center'
        }
      },
      {
        headerName: 'Date',
        field: 'date',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Category',
        field: 'cat_nm',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Department',
        field: 'dprmnt_nm',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      }, {
        headerName: 'View Details',
        cellStyle: { textAlign: 'center' },
        width: 100,
        cellRenderer: function (param) {
          const eDiv = document.createElement('div');
          eDiv.innerHTML = `<span class="my-css-class">
            <button class="btn-simple-history" style="background-color: #3190a6;  
            box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
            color: #fff;cursor: pointer;border: none;line-height: 17px;border-radius: 3px;">View</button>
            </span>`;
          const eButton = eDiv.querySelectorAll('.btn-simple-history')[0];
          eButton.addEventListener('click', function (param) {
            // console.log(param.value);
          });
          return eDiv;
        },
      },

    ];

    if (slctdData.colDef.headerName === 'View Details') {
      console.log(slctdData.data);
      this.viewtmln(slctdData.data)
    }
    console.log(slctdData.data);
    // let usr_id = 0;
    this.apiCtrl.get('ticket-system/dprt-wse-rqst-mntr/' + slctdData.colDef.field + '/' + slctdData.data.dprmnt_id).subscribe((res: any) => {
      if (res['status'] === 200) {
        this.rprtLst = res['data'];
        console.log(this.rprtLst);
      }
    }, (err) => {
      console.log(err);
    });
  }

  getRowClick(slctdData): void {
    console.log(slctdData);
    this.showusernm = true;
    this.shownm = false;
    this.shwusrs = true;
    this.columnDefs_userdata = [
      {
        headerName: 'File Num',
        field: 'req_qr_cd',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: { textAlign: 'center',fontSize: '14px' },
      },
      {
        headerName: 'Mobile',
        field: 'req_usr_mbl',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Request',
        field: 'req_txt',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: {
          textAlign: 'center'
        }
      },
      {
        headerName: 'Date',
        field: 'date',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Category',
        field: 'cat_nm',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Department',
        field: 'dprmnt_nm',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      }, {
        headerName: 'View Details',
        cellStyle: { textAlign: 'center' },
        width: 100,
        cellRenderer: function (param) {
          const eDiv = document.createElement('div');
          eDiv.innerHTML = `<span class="my-css-class">
            <button class="btn-simple-history" style="background-color: #3190a6;  
            box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
            color: #fff;cursor: pointer;border: none;line-height: 17px;border-radius: 3px;">View</button>
            </span>`;
          const eButton = eDiv.querySelectorAll('.btn-simple-history')[0];
          eButton.addEventListener('click', function (param) {
            // console.log(param.value);
          });
          return eDiv;
        },
      },

    ];

    if (slctdData.colDef.headerName === 'View Details') {
      console.log(slctdData.data);
      this.viewtmln(slctdData.data)
    }

    this.apiCtrl.get('ticket-system/ofcr-wse-rqst-mntr/' + slctdData.colDef.field + '/' + slctdData.data.usr_id).subscribe((res: any) => {
      console.log(res['data'])
      if (res['status'] === 200) {
        this.usrlst = res['data'];
        console.log(this.usrlst);
      }
    }, (err) => {
      console.log(err);
    });
  }
  closeDprtTgl(): void {

    this.shwdprtmnt = false;
    this.dashbrddeptRprtLst();
  }
  clseUsrToggle() {
    this.shwusrs = false;
    this.dashbrduserRprtLst();
  }
  crntlyOpnd(sts): void {
    console.log(sts);
    this.shwusrs = true;
    this.showusernm = false;
    this.shownm = true;
    this.columnDefs_userdata = [
      {
        headerName: 'File Num',
        field: 'req_qr_cd',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: { textAlign: 'center' ,fontSize: '14px'},
      },
      {
        headerName: 'Mobile',
        field: 'req_usr_mbl',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Request',
        field: 'req_txt',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: {
          textAlign: 'center'
        }
      },
      {
        headerName: 'Request Date',
        field: 'dt',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Category',
        field: 'cat_nm',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Department',
        field: 'dprmnt_nm',
        sortable: true,
        filter: true,
        width: 210,
        cellStyle: { textAlign: 'center' },
      }, {
        headerName: 'View Details',
        cellStyle: { textAlign: 'center' },
        width: 100,
        cellRenderer: function (param) {
          const eDiv = document.createElement('div');
          eDiv.innerHTML = `<span class="my-css-class">
            <button class="btn-simple-history" style="background-color: #3190a6;  
            box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
            color: #fff;cursor: pointer;border: none;line-height: 17px;border-radius: 3px;">View</button>
            </span>`;
          const eButton = eDiv.querySelectorAll('.btn-simple-history')[0];
          eButton.addEventListener('click', function (param) {
          });
          return eDiv;
        },
      },

    ];

    var date = new Date();
    if (this.selecteddt == undefined) {
      this.fromdate = moment(date).format('YYYY-MM-DD');
      this.todate = moment(date).format('YYYY-MM-DD');
    } else {
      this.fromdate = moment(this.selecteddt[0]).format('YYYY-MM-DD');
      this.todate = moment(this.selecteddt[1]).format('YYYY-MM-DD');
    }
    let crntdata = {
      crntOpn: sts,
      frm_dt: this.fromdate != undefined ? this.fromdate : '',
      to_dt: this.todate != undefined ? this.todate : '',
      lstasg: this.lstassign != undefined ? this.lstassign : '',
    }
    crntdata['ind'] = this.get_ind;
    console.log(crntdata)
    this.apiCtrl.create(crntdata, '/ticket-system/overalldshbrd-list').subscribe((res: any) => {
      if (res['status'] === 200) {
        this.usrlst = res['data'];
        console.log(this.usrlst);
      }
    }, (err) => {
      console.log(err);
    });
  }

}
