import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { ulbFilterModel } from './ulb-level-filter.model';
import {map, startWith} from 'rxjs/operators';
// export interface workpackage {
//   // wrk_pckge_id: number;
//   wrk_pckge_nm: string;
// }
@Component({
  selector: 'app-ulb-level-filter',
  templateUrl: './ulb-level-filter.component.html',
  styleUrls: ['./ulb-level-filter.component.scss']
})
export class UlbLevelFilterComponent implements OnInit {
  @Output() fltrEvent = new EventEmitter<string>(); 
  @Output() excelEvent = new EventEmitter<boolean>();
  @Input() headers;
  @Input() rowData;
  @Input() isExcelDwnLd;
  @Input() excelFileNm;
  fltrForm: FormGroup;
  date = new Date();
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  frmevents: string[] = [];
  toevents: string[] = [];
  fltrdata;
  ulb_id:number = 0;
  wrk_pckge_id:number = 0;
  clsrt_id:number = 0;
  mcrpt_id:number = 0;
  asrt_id:number = 0;
  ulb_nm;
  wrk_pckge_nm;
  clsrt_nm;
  mcrpt_nm;
  ulbLst: ulbFilterModel[] = [];
  ulbFltrOptions: Observable<ulbFilterModel[]>;
  
  wrkpckgsLst: ulbFilterModel[] = [];
  wrkpckgeFltrOptions: Observable<ulbFilterModel[]>;

  clstrLst: ulbFilterModel[] = [];
  clstrFltrOptions: Observable<ulbFilterModel[]>;

  mcrptLst: ulbFilterModel[] = [];
  mpFltrOptions: Observable<ulbFilterModel[]>;

  vehLst: ulbFilterModel[] = [];
  vehFltrOptions: Observable<ulbFilterModel[]>;

  dataRecieve: boolean = true;

  @Input() fltrVsbleOptns;

  ulbfltr;
  wrkpckgeFltr;
  clstrFltr;
  mcrptFltr;
  vehFltr;
  frmDt;
  toDt;
  asrt_nm;
  ulbsLst;
  drpDwnWrkpckgeVluChngd; drpDwnClstrVluChngd; drpDwnMpVluChngd; drpDwnVehVluChngd;
  constructor(public datepipe: DatePipe, private misreportsService: MisreportsService) { }

  ngOnInit() {
    if (this.fltrVsbleOptns === undefined){
      this.ulbfltr = true;
      this.wrkpckgeFltr = true;
      this.clstrFltr = true;
      this.mcrptFltr = true;
      this.vehFltr = false;
      this.frmDt = true;
      this.toDt = true;
    }else{
      this.ulbfltr = this.fltrVsbleOptns.isUlb;
      this.wrkpckgeFltr = this.fltrVsbleOptns.isWrkpckge;
      this.clstrFltr = this.fltrVsbleOptns.isClstr;
      this.mcrptFltr = this.fltrVsbleOptns.isMcrpt;
      this.vehFltr = this.fltrVsbleOptns.isVeh;
      this.frmDt = this.fltrVsbleOptns.isFrmDt;
      this.toDt = this.fltrVsbleOptns.isToDt;
    }
    this.fltrForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required),
      selectedMp: new FormControl('', Validators.required),
      selectedWP: new FormControl('', Validators.required),
      selectedClstr: new FormControl('', Validators.required),
      selectedVeh: new FormControl('', Validators.required),
    });

    // this.wrkpckgsLst = [{sno: 0, wrk_pckge_id: 0, wrk_pckge_nm: "ALL"}];
    // this.clstrLst = [{sno: 0, clsrt_id: 0, clstr_nm: "ALL"}];
    // this.mcrptLst = [{sno: 0, mcrpt_id: 0, mcrpt_nm: "ALL"}];
    // this.ulbLst = [];

    this.ulbFltrOptions = this.fltrForm.get('selectedUlb').valueChanges
    .pipe(
      startWith(''),
      map(value => this._ulbfilter(value))
    );

    // this.misreportsService.getUlbs((err, ulbLst) => {
    //   for (let u of ulbLst.data) {
    //     this.ulbLst.push(u);
    //   }
    //   this.fltrForm.get('selectedUlb').setValue('');
    // });

    this.ulbsLst = JSON.parse(localStorage.getItem('ulbs'));
    for (let u of this.ulbsLst) {
          this.ulbLst.push(u);
        }
    this.fltrForm.get('selectedUlb').setValue('');


    this.wrkpckgeFltrOptions = this.fltrForm.get('selectedWP').valueChanges
    .pipe(
      startWith(''),
      map(value => this._wpfilter(value))
    );

    this.clstrFltrOptions = this.fltrForm.get('selectedClstr').valueChanges
    .pipe(
      startWith(''),
      map(value => this._clstrfilter(value))
    );

    this.mpFltrOptions = this.fltrForm.get('selectedMp').valueChanges
    .pipe(
      startWith(''),
      map(value => this._mpfilter(value))
    );

    this.vehFltrOptions = this.fltrForm.get('selectedVeh').valueChanges
    .pipe(
      startWith(''),
      map(value => this._vehfilter(value))
    );
  }

  getWorkPackages(ulb_id){
    this.wrkpckgsLst = [];
    this.ulb_id = ulb_id;
    this.misreportsService.getWorkPackages(this.ulb_id, (err, wrkPckgLst) => {
      for (let w of wrkPckgLst.data) {
        this.wrkpckgsLst.push(w);
      }
      this.fltrForm.get('selectedWP').setValue('');
    });
  }

  getClusters(wrk_pckge_id){
    this.clstrLst = [];
    this.wrk_pckge_id = wrk_pckge_id;
    this.misreportsService.getClusters(this.ulb_id, this.wrk_pckge_id, (err, clstrLst) => {
      for (let c of clstrLst.data) {
        this.clstrLst.push(c);
      }
      this.fltrForm.get('selectedClstr').setValue('');
    });
  }

  getVehicles(clsrt_id){
    this.vehLst = [];
    this.clsrt_id = clsrt_id;
    this.misreportsService.getclstrVehicles(this.clsrt_id, (err, vehLst) => {
      for (let m of vehLst.data) {
        this.vehLst.push(m);
      }
      this.fltrForm.get('selectedVeh').setValue('');
    });
  }

  getMicropockets(clsrt_id){
    this.mcrptLst = [];
    this.clsrt_id = clsrt_id;
    this.misreportsService.geMicroPockets(this.ulb_id, this.wrk_pckge_id, this.clsrt_id, (err, mcrptLst) => {
      for (let m of mcrptLst.data) {
        this.mcrptLst.push(m);
      }
      this.fltrForm.get('selectedMp').setValue('');
    });
  }
  // getMicropocketId(mcrpt_id){
  //   this.mcrpt_id = mcrpt_id;
  // }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
    this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
    this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
  }
  
  drpDwnWrkpckgeInptChnge(event): void{
    this.drpDwnWrkpckgeVluChngd = event;
  }
  drpDwnClstrInptChnge(event): void{
    this.drpDwnClstrVluChngd = event;
  }
  drpDwnMpInptChnge(event): void{
    this.drpDwnMpVluChngd = event;
  }
  drpDwnVehInptChnge(event): void{
    this.drpDwnVehVluChngd = event;
  }

  sendFltrData(){
    if (this.frmevents[0] === undefined){
      this.frmevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }
    if (this.toevents[0] === undefined){
      this.toevents[0] = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }
    this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');

    if (this.drpDwnWrkpckgeVluChngd === ''){
      this.wrk_pckge_id = 0;
    }
    if (this.drpDwnClstrVluChngd === ''){
      this.clsrt_id = 0;
    }
    if (this.drpDwnMpVluChngd === ''){
      this.mcrpt_id = 0;
    }
    if (this.drpDwnVehVluChngd === ''){
      this.asrt_id = 0;
    }

    this.fltrdata = { ulb_id: this.ulb_id, ulb_nm: this.ulb_nm, wrk_pckge_id: this.wrk_pckge_id, wrk_pckge_nm: this.wrk_pckge_nm, 
                      clsrt_id: this.clsrt_id, clsrt_nm: this.clsrt_nm, mcrpt_id: this.mcrpt_id, mcrpt_nm: this.mcrpt_nm, 
                      asrt_id: this.asrt_id, asrt_nm: this.asrt_nm, from_date: this.from_date, to_date: this.to_date};
    this.fltrEvent.emit(this.fltrdata);
  }

  getExcel(){
    this.isExcelDwnLd = true;
    this.excelEvent.emit(true);
  }

  ngOnChanges(changes: any) {
    if (this.isExcelDwnLd) {
       var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        useBom: true,
        headers: this.headers
      };
      new Angular5Csv(this.rowData, this.excelFileNm, options);
    }
  }

  ulbSelected (evt: any) {
    this.ulb_id = evt.option.value.ulb_id;
    this.ulb_nm = evt.option.value.ulb_nm;
    this.getWorkPackages(this.ulb_id);
  }

  private _ulbfilter(value): ulbFilterModel[]{
    if (value) {
        return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }

  wpSelected (evt: any) {
    this.wrk_pckge_id = evt.option.value.wrk_pckge_id;
    this.wrk_pckge_nm = evt.option.value.wrk_pckge_nm;
    this.getClusters(this.wrk_pckge_id);
  }

  private _wpfilter(value): ulbFilterModel[]{
    if (value) {
        return this.wrkpckgsLst.filter(item => ((typeof value === 'string') ? item.wrk_pckge_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.wrk_pckge_nm.toLowerCase().indexOf(value['wrk_pckge_nm'].toLowerCase()) === 0));
    }
    return this.wrkpckgsLst;
  }

  private _clstrfilter(value): ulbFilterModel[]{
    if (value) {
        return this.clstrLst.filter(item => ((typeof value === 'string') ? item.clstr_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.clstr_nm.toLowerCase().indexOf(value['clstr_nm'].toLowerCase()) === 0));
    }
    return this.clstrLst;
  }

  clstrSelected (evt: any) {
    this.clsrt_id = evt.option.value.clsrt_id;
    this.clsrt_nm = evt.option.value.clsrt_nm;
    this.getMicropockets(this.clsrt_id);
    this.getVehicles(this.clsrt_id);
  }

  private _mpfilter(value): ulbFilterModel[]{
    if (value) {
        return this.mcrptLst.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
    }
    return this.mcrptLst;
  }

  mpSelected (evt: any) {
    this.mcrpt_id = evt.option.value.mcrpt_id;
    this.mcrpt_nm = evt.option.value.mcrpt_nm;
  }

  private _vehfilter(value): ulbFilterModel[]{
    if (value) {
        return this.vehLst.filter(item => ((typeof value === 'string') ? item.asrt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :  
                        item.asrt_nm.toLowerCase().indexOf(value['asrt_nm'].toLowerCase()) === 0));
    }
    return this.vehLst;
  }

  vehSelected (evt: any) {
    this.asrt_id = evt.option.value.asrt_id;
    this.asrt_nm = evt.option.value.asrt_nm;
  }

  autocompleteUlbDisplayFn(apprt?: ulbFilterModel): string  | undefined{
    return apprt ? apprt.ulb_nm : undefined;
  }
  autocompleteWrkpckgeDisplayFn(apprt?: ulbFilterModel): string  | undefined{
    return apprt ? apprt.wrk_pckge_nm : undefined;
  }
  autocompleteClstrDisplayFn(apprt?: ulbFilterModel): string  | undefined{
    return apprt ? apprt.clstr_nm : undefined;
  }
  autocompleteMpDisplayFn(apprt?: ulbFilterModel): string  | undefined{
    return apprt ? apprt.mcrpt_nm : undefined;
  }
  autocompleteVehDisplayFn(apprt?: ulbFilterModel): string  | undefined{
    return apprt ? apprt.asrt_nm : undefined;
  }
}
