import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ScannersNotworkingComponent } from './scanners-notworking/scanners-notworking.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpModule } from '@angular/http';
import {
  MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatDatepickerModule, MatAutocompleteModule,
  MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatButtonModule, MatTooltipModule, MatSlideToggleModule,
  MatGridListModule, MatProgressSpinnerModule, MatListModule, MatCheckboxModule
} from '@angular/material';
import { UnassignedEmployeesReportComponent } from './unassigned-employees-report/unassigned-employees-report.component';
import { WrongScanReportComponent } from './wrong-scan-report/wrong-scan-report.component';
import { SharedModule } from 'app/shared/shared.module';
import { LongTermAbsentiesReportComponent } from './long-term-absenties-report/long-term-absenties-report.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LongTermGatesNotscannedReportComponent } from './long-term-gates-notscanned-report/long-term-gates-notscanned-report.component';
import { TrackersNotworkingComponent } from './trackers-notworking/trackers-notworking.component';
import { VehiclesNotcoveredtripsComponent } from './vehicles-notcoveredtrips/vehicles-notcoveredtrips.component';

import { LongTermTransperpointsNotcoveredReportComponent } from './long-term-transperpoints-notcovered-report/long-term-transperpoints-notcovered-report.component';
import { LongTermWeighmentNottakenReportComponent } from './long-term-weighment-nottaken-report/long-term-weighment-nottaken-report.component';

const excp_routes: Routes = [
  { path: 'unassignedemployee', component: UnassignedEmployeesReportComponent },
  { path: 'wronglyscnd', component: WrongScanReportComponent },
  { path: 'employeeabsentreport', component: LongTermAbsentiesReportComponent },
  { path: 'gatesnotscanned', component: LongTermGatesNotscannedReportComponent },
  { path: 'scannersnotworking', component: ScannersNotworkingComponent },
  { path: 'trackersnotworking', component: TrackersNotworkingComponent },
  { path: 'vehiclesnotcoveredtrips', component: VehiclesNotcoveredtripsComponent },
  { path: 'transperpointsnotscanned', component: LongTermTransperpointsNotcoveredReportComponent},
  { path: 'weighmentnottaken', component: LongTermWeighmentNottakenReportComponent} 
 
    
    
    
];


@NgModule({
  declarations: [
    UnassignedEmployeesReportComponent,
    WrongScanReportComponent,
    ScannersNotworkingComponent,
    LongTermAbsentiesReportComponent,
    LongTermGatesNotscannedReportComponent,
    TrackersNotworkingComponent,
    VehiclesNotcoveredtripsComponent,
    LongTermTransperpointsNotcoveredReportComponent,
    LongTermWeighmentNottakenReportComponent
  ],
  imports: [
    RouterModule.forChild(excp_routes),
    CommonModule,
    MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatDatepickerModule, MatAutocompleteModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatButtonModule, MatTooltipModule, MatSlideToggleModule,
    MatGridListModule, MatProgressSpinnerModule, MatListModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, 
    SharedModule, FlexLayoutModule
  ]
})
export class ExceptionalReportsModule { }
