import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { CrudService } from '../crud.service';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatSort, MatSidenav } from '@angular/material';
import { PrintDetailsComponent } from '../print-details/print-details.component';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
let ELEMENT_DATA = []
@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent implements OnInit {
  displayedColumns: string[] = ['position', 'Labelname', 'Filename', 'uploadBy', 'Date', 'Actions'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form: any;
  DepList: any;
  catList: any;
  @ViewChild('fileInput') el: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('usrLstSidenav') usrLstSidenav: MatSidenav;

  imageUrl: any = 'https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg';
  editFile: boolean = true;
  removeUpload: boolean = false;
  shwtbl = false;
  shwspnr = false;
  sub_cat_list: any;
  imagePath: any;
  Imgsrc: any;
  images: any = [];
  imagesPrv: any = [];
  elementType: 'url' | 'canvas' | 'img' = 'url';
  value: string = 'facebook.com';
  qrcd: string;
  // router: any;
  data = []
  reqdate: any;
  dialogRef: any;
  ShwQrcd: any;
  spnrCtrl = false;
  udr_dtl: any;
  curDate: Date;
  // dataSource: any;
  name: any;
  lbl_name: any;
  shwlbl: boolean;
  usrData: any;
  searchValue: string = '';
  slctdUsr_nm: any;
  slctdUsr_id: any;
  shwAsgnUsr: boolean;
  Slctd_usr;
  PstData: { form_data: any; image: any; asgnd_usr_id: any; usr_id: any; status_id: any; };
  stas_id: number;

  constructor(public crdsrv: CrudService, private _formBuilder: FormBuilder, private datePipe: DatePipe, public _matDialog: MatDialog, private cd: ChangeDetectorRef, private router: Router) {
    this.udr_dtl = JSON.parse(localStorage.getItem("usrDtls"));

  }

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
    this.reqdate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    //  this.curDt=new Date().toISOString();
    //  console.log(this.curDt);
    this.form = this._formBuilder.group({

      req_usr_nm: ['', Validators.required],
      req_usr_mbl: ['', Validators.required,],
      req_usr_adrs: ['', Validators.required],
      req_txt: ['', Validators.required],
      city: ['', Validators.required],
      dprmnt_id: ['', Validators.required],
      cat_id: ['', Validators.required],
      subcat_id: ['', Validators.required],
      date: [this.reqdate, Validators.required],
      //Date: ['', Validators.required],
      shrt_desc: ['', [Validators]],
      pn_cd: ['', [Validators]],
      // img_nm: ['', [Validators]],
      // cat_nm:['',[Validators]],
      // sub_cat_nm:['',[Validators]],
      // dprmnt_nm:['',[Validators]]

    });
    // this.form.get('date').disable()
    this.getDepList();
    this.getCatList();

  }

  getDepList() {

    this.crdsrv.get('ticket-system/Department-list').subscribe((res) => {
      this.DepList = res['data'];
      console.log(this.DepList)
    }, (error) => {
      console.log(error)
    })
  }


  getCatList() {
    console.log("getCats")
    this.crdsrv.get('ticket-system/category-list').subscribe((res) => {
      this.catList = res['data'];
      console.log(this.catList)
    }, (error) => {
      console.log(error)
    })
  }


  subcat(cat_id) {
    this.crdsrv.get('ticket-system/sub-category-list/' + cat_id).subscribe((res) => {
      this.sub_cat_list = res['data'];
      console.log(this.sub_cat_list)
    }, (error) => {
      console.log(error)
    })
  }


  getHome() {
    this.router.navigate(["/rqsten"]);
    // this.router.navigateByUrl('rqsten')
  }



  uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.form.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  sum(eve) {
    console.log(eve)
  }

  sveForm() {
    this.spnrCtrl = true;
    this.markFormGroupTouched(this.form)
    console.log(this.form.valid);
    console.log(this.imagesPrv);
    if (this.form.valid) {
      if (this.form.valid) {
        this.shwspnr = true;
      }
      else {
        this.shwspnr = false;
      }
      this.form['images'] = this.images;
      console.log(this.slctdUsr_id);
      if (this.slctdUsr_id == undefined || this.slctdUsr_id == null) {
        this.stas_id = 0
      } else {
        this.stas_id = 1
      }
      this.PstData = {
        form_data: this.form.getRawValue(),
        image: this.imagesPrv,
        asgnd_usr_id: this.slctdUsr_id,
        usr_id: this.udr_dtl.usr_id,
        status_id: this.stas_id
      }

      console.log(this.PstData)
      this.crdsrv.create(this.PstData, 'ticket-system/request-entry').subscribe((res: any) => {
        console.log(res)
        this.resData = res;
        this.shwspnr = false;
        console.log(this.resData)
        // this.qrcd ="Rohit"
        if (res.length > 0) {
          this.ShwQrcd = true;
        }
        this.spnrCtrl = false;
        this.openDialog(this.resData)
      }, (error) => {
        console.log(error)
        this.shwspnr = false;
      })
    }

  }
  resData(resData: any) {
    throw new Error("Method not implemented.");
  }



  resetForm() {
    this.form.reset();
  }



  preview(files) {
    console.log(files);
    this.curDate = new Date();
    this.shwtbl = true;
    if (files.length === 0) // files Emi Select avakapothe      	
      return;
    for (let i = 0; i < files.length; i++) {
      var mimeType = files[i].type; //file type kosam
      var reader = new FileReader();
      this.imagePath = files;
      reader.readAsDataURL(files[i]);
      reader.onload = (_event: any) => {
        let img = {}
        this.Imgsrc = _event.target.result;
        // if ((mimeType.match(/image\/*/) == null)) {
        //   this.Imgsrc = 'assets/images/file.png'
        // }
        let file_nm = files[i].name
        img = {
          base64: this.Imgsrc,
          lbl_name: this.lbl_name != undefined ? this.lbl_name : '',
          upldBy: this.udr_dtl.usr_id,
          upldusr: this.udr_dtl.usr_nm,
          upldDt: this.curDate,
          filenm: file_nm
        }
        this.imagesPrv.push(img); //Images ni push cheyadaniki
        if (this.imagesPrv) {
          this.shwlbl = true
        }
        console.log(this.imagesPrv);
        this.dataSource = new MatTableDataSource(this.imagesPrv); //Table lo chupinchadaniki
        console.log(this.dataSource)
        this.dataSource.paginator = this.paginator;  //Pagination Kosam
        this.dataSource.sort = this.sort;
        // img = {
        //   base64: _event.target.result,
        //   name: ''
        // }
        // this.images.push(img)
      }
    }

  }

  usersLst() {
    this.usrLstSidenav.toggle();
    this.crdsrv.get('ticket-system/users-lst').subscribe((res) => {
      if (res['status'] === 200) {
        console.log(res['data']);
        this.usrData = res['data'];
      }
    }, (err) => {
      console.log(err);
    });
  }

  closeUsrLstSidnav() {
    this.usrLstSidenav.toggle();
  }


  bindSlctdVlu(usrLst) {
    console.log(usrLst);
    this.shwAsgnUsr = true;
    this.slctdUsr_nm = usrLst.usr_nm;
    this.slctdUsr_id = usrLst.usr_id;
    this.usrLstSidenav.toggle();
  }
  // uploadFleData() {
  //   console.log(this.imagesPrv);
  //   console.log(this.dataSource)
  //   this.showTble = true;
  //   this.dataSource = new MatTableDataSource(this.imagesPrv);
  //   console.log(this.dataSource)
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;

  // }

  delImage(i: any) {
    console.log(i)
    this.imagesPrv.splice(i, 1)
    this.dataSource = new MatTableDataSource(this.imagesPrv);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  openDialog(data: any): void {
    this.dialogRef = this._matDialog.open(PrintDetailsComponent, {
      panelClass: 'print-modal',
      data: data

    });
    console.log(data)
    this.dialogRef.afterClosed()
      .subscribe((response) => {
        if (!response) {
          return;
        }
        console.log(response)

      });
  }

  /**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
// const ELEMENT_DATA: PeriodicElement[] = [
//   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
//   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
//   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
//   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
//   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
//   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
//   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
//   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
//   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
//   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
//   {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
//   {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
//   {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
//   {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
//   {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
//   {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
//   {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
//   {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
//   {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
//   {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
// ];
// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }
