import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecondaryVehicle } from './secondary.model';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { VehicleService } from '../vehicles.service';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-secondary',
  templateUrl: './secondary.component.html',
  styleUrls: ['./secondary.component.scss']
})
export class SecondaryComponent implements OnInit {

    Primary: SecondaryVehicle[] = [];
    ulbLst: Ulb[] = [];
    ulb_id;
    headerForm:  FormGroup;
    
    displayedColumns: string[] = ['sno', 'asrt_nm', 'clstr_nm', 'asrt_ctgry_nm','asrt_grp_nm','drvr_nm','drvr_phne_nu','vehstatus','actions'];
    dataSource = new MatTableDataSource<SecondaryVehicle>();
  
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;  
    ulbtopFltrOptions: Observable<Ulb[]>;
  constructor( private geoService: GeoLocationsService, private _fuseSidebarService: FuseSidebarService,private vehicleService: VehicleService) { 
    // this.ulb_id = 20;
  }

  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    
    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // })
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulb_id =this.ulbLst[0].ulb_id;
    this.headerForm.get('selectedUlb').setValue({ulb_id:this.ulbLst[0].ulb_id,ulb_nm:this.ulbLst[0].ulb_nm});
    this.getScndryVehicle();
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
  }
  
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  getScndryVehicle() {
    this.vehicleService.scndryVhcleLst(this.ulb_id, (err, scndryVeh_res) => {
      this.dataSource.data = scndryVeh_res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  
  onUlbChange(evt) {
    this.ulb_id = evt.option.value.ulb_id;
    // this.getScndryVehicle();
  }
  opensideBar(key) {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
