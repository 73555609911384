import { Component, OnInit } from '@angular/core';
import { MainServiceService } from '../../main-service.service';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
@Component({
  selector: 'app-login-details-report',
  templateUrl: './login-details-report.component.html',
  styleUrls: ['./login-details-report.component.scss']
})
export class LoginDetailsReportComponent implements OnInit {
  date = new Date();
   rowData = [];
  columnDefs = [];
  // dataSource=[];
  // from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  shwLdr = true;
  frmevents;
  toevents;
  isExcelDwnLd;
  excelFileNm;
  excelDataHeaders;
  noData;
  dataSource: any;
  loginExcelData=[];
  constructor(public datepipe: DatePipe, private dataService: MainServiceService) { }

  ngOnInit() {
    this.getlogindetails();
  }
  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
  }
  getlogindetails(): void{

    // if (this.frmevents === undefined){
    //   this.from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    // }else{
    //   this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    // }

    if (this.toevents === undefined){
      this.to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }else{
      this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
    }

      const inspctOffcrData = {
        // 'frm_dt': this.from_date,
        'to_dt': this.to_date
      };
      
      const inspctOfcrsBnfsCnts_rte = '/logindtls';

      this.dataService.getByData(inspctOfcrsBnfsCnts_rte, inspctOffcrData, (err, res) => {
        this.rowData = res.data;
        this.shwLdr = false;
      
        this.columnDefs = [
          {
            headerName: 'District',
            field: 'dstrct_nm',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'},
            width:300
          },
          {
            headerName: 'Mandal',
            field: 'usr_lgn_id',
            sortable: true,
            filter: true, 
            cellStyle: {textAlign: 'center'},
            width:300

          },
          {
            headerName: 'Inspection Officer',
            field: 'user_nm',
            sortable: true,
            filter: true,
             cellStyle: {textAlign: 'center'},
             width:300
          },
           {
            headerName: 'Mobile Number',
            field: 'mble_nu',
            sortable: true,
            filter: true,
             cellStyle: {textAlign: 'center'},
             width:300
          },
          {
            headerName: 'login time',
            field: 'lgn_ts',
            sortable: true,
            filter: true,
            cellStyle: {textAlign: 'center'},
            width:300
          }
         
        ];
      
      });
      console.log( this.rowData);
    }
    
        downloadExcel($event): void {
      // if (this.rowData.length > 0) {
        console.log( this.rowData);
        for (let i = 0; i < this.rowData.length; i++) {
     
        this.loginExcelData.push({dstrct_nm:this.rowData[i].dstrct_nm, usr_lgn_id:this.rowData[i].usr_lgn_id, user_nm :this.rowData[i].user_nm, mble_nu :this.rowData[i].mble_nu, lgn_ts:this.rowData[i].lgn_ts});

        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['District', 'Mandal', 'Inspection Officer', 'Mobile Number', 'Login Time'];
        this.excelFileNm = 'Inspection_Officer Login Details';
      }
    }
    
}
