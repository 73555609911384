import { Component, OnInit, ViewChild } from '@angular/core';
import { GeoLocationsService } from '../geo-locations/geo-locations.service';
import { Ulb } from '../geo-locations/ulb/ulb.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-hardware-certification',
  templateUrl: './hardware-certification.component.html',
  styleUrls: ['./hardware-certification.component.scss']
})
export class HardwareCertificationComponent implements OnInit {
  ulbLst: Ulb[] = [];
  headerForm: FormGroup;
  // ulbid: number;
  selectedTabIndex: number = 0;
  @ViewChild('tabGroup') tabGroup;
  wmstab: boolean = false;
  scannerstab: boolean = false;
  trackerstab: boolean = false;
  ulb;
  ulbtopFltrOptions: Observable<Ulb[]>;
  constructor(private geoService: GeoLocationsService, private locationsService: GeoLocationsService) {
  }

  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulb = {
      ulb_id: this.ulbLst[0].ulb_id,
      ulb_nm: this.ulbLst[0].ulb_nm
    };
    const toSelect = this.ulbLst.find(c => c.ulb_id == this.ulb.ulb_id);
    this.headerForm.get('selectedUlb').setValue({ulb_id:this.ulbLst[0].ulb_id,ulb_nm:this.ulbLst[0].ulb_nm});
   this.wmstab = true; this.scannerstab = false; this.trackerstab = false;
   this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
     .pipe(
       startWith(''),
       map(value => this._ulbfilter(value))
     );
  }

  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  onUlbChange(evt) {
    this.ulb = evt.option.value;
  }

  public tabChanged(): void {
    if (this.tabGroup.selectedIndex == 0) {
      this.wmstab = true; this.scannerstab = false; this.trackerstab = false;
    } else if (this.tabGroup.selectedIndex == 1) {
      this.wmstab = false; this.scannerstab = true; this.trackerstab = false;
    } else if (this.tabGroup.selectedIndex == 2) {
      this.wmstab = false; this.scannerstab = false; this.trackerstab = true; 
    } 
  }

}

