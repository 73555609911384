import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HardwareService } from './../hardware.service';
import { Scanners } from './scanners.model';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { GeoLocationsService } from './../../geo-locations/geo-locations.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { UserService } from 'app/providers/user/user.serivce';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-scanners',
  templateUrl: './scanners.component.html',
  styleUrls: ['./scanners.component.scss']
})

export class ScannersComponent implements OnInit {

  Scanners: Scanners[] = [];
  headerForm: FormGroup;
  scannerForm: FormGroup;
  unas_scannerForm: FormGroup;
  ulbLst: Ulb[] = [];
  scnrCtgryLst: any = [];
  scnrStsLst: any = [];
  ulbid;
  userPerm: any = {};
  mcrptList = [];
  scannerLst = [];
  displayedColumns: string[] = ['sno', 'name', 'ctgry_nm','rfid_scnr_sts_nm','lst_scn_ts','actions'];
  displayedColumns2: string[] = ['sno', 'mcrpt_nm', 'actions'];

  dataSource = new MatTableDataSource<Scanners>();
  dataSource2 = new MatTableDataSource<Scanners>();

  selectedTabIndex: number;
  @ViewChild('tabGroup') tabGroup;
  assignedTagTab: boolean = true;
  shwLdr: boolean;
  unassignedTagTab: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  errMsg = false;
  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  noData;
  ulbtopFltrOptions: Observable<Ulb[]>;
  constructor(private geoService: GeoLocationsService, private hardwareService: HardwareService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog,
    private userService: UserService) { }

  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // })
    this.ulbid = this.ulbLst[0].ulb_id;
    this.headerForm.get('selectedUlb').setValue({ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm});
    this.getAssgnedmicropocketsLst();
    // this.geoService.getMicroPocket(this.ulbid, null, null, (err, res) => {
    //   this.mcrptList = res.data;
    //   console.log(this.mcrptList);
    // });
    this.hardwareService.getScannerCategory((err, res) => {
      this.scnrCtgryLst = res.data;
    });
    this.hardwareService.getScannerStatus((err, res) => {
      this.scnrStsLst = res.data;
    });

    this.scannerForm = new FormGroup({
      name: new FormControl('', Validators.required),
      scnr_ctgry_id: new FormControl('', Validators.required),
      rfid_scnr_sts_id: new FormControl('', Validators.required),
      mcrpt_id: new FormControl('')
    });
    this.unas_scannerForm = new FormGroup({
      rfid_scnr_id: new FormControl('', Validators.required)
    });
    this.getAsgndScanners();
    this.getScanners();
    this.userPerm = this.userService.CURRENT_PERM;
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
  }

  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  getScanners() {
    this.hardwareService.getScanners(this.ulbid, (err, scannerLst) => {
      this.scannerLst = scannerLst.data;
    });
  }

  getAsgndScanners() {
    this.dataSource.data = [];
    this.shwLdr = true;
    this.hardwareService.getAsgndScanners(this.ulbid, (err, scannerLst) => {
      this.dataSource.data = scannerLst.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  unasgnedMcprtLst() {
    this.dataSource2.data = [];
    this.shwLdr = true;
    this.hardwareService.getUnasgndScnMcprtLst(this.ulbid, (err, scannerLst) => {
      this.dataSource2.data = scannerLst.data;
      this.dataSource2.paginator = this.paginator;
      this.dataSource2.sort = this.sort;
      this.shwLdr = false;
    });
  }
 

  onUlbChange(evt) {
    // this.ulbid = ulb_id;
    this.ulbid = evt.option.value.ulb_id;
    this.getScanners();
    this.getAsgndScanners();
    this.getAssgnedmicropocketsLst();
    // this.unasgnedMcprtLst();
  }
  getAssgnedmicropocketsLst() {
     this.geoService.getMicroPocket(this.ulbid, null, null, (err, res) => {
      this.mcrptList = res.data;
      console.log(this.mcrptList);
    });
  }

  // public tabChanged(): void {
  //   // console.log(this.tabGroup.selectedIndex);
  //   if (this.tabGroup.selectedIndex === 0) {
  //     this.assignedTagTab = true; this.unassignedTagTab = false;
  //     this.getAsgndScanners();
  //   } else if (this.tabGroup.selectedIndex === 1) {
  //     this.assignedTagTab = false; this.unassignedTagTab = true;
  //     this.unasgnedMcprtLst();
  //   }
  // }
  saveData() {
    this.errMsg = false;
    if (this.assignedTagTab) {
      if (this.scannerForm.status === 'INVALID') {
        return false;
      }
      this.updtDt = this.scannerForm.value;
      this.updtDt.ulb_id = this.ulbid;
      let flag = false;
      this.dataSource.data.forEach(val => {
        if (val.name === this.updtDt.name) {
          flag = true;
        }
      });
      if (flag) {
        this.errMsg = true;
        return;
      }
      this.hardwareService.addScanner(this.updtDt, (err, res) => {
        if (res.status == 200) {
         const dialogRef = this.dialog.open(ConfirmDialog, {
           width: '500px',
           data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
         });
        //  if (this.tabGroup.selectedIndex === 0) 
        //   {this.getAsgndScanners(); }
        //  else if (this.tabGroup.selectedIndex === 1)
        //    {this.unasgnedMcprtLst(); }
        this.getAsgndScanners();
     }
      });
    } else {
      if (this.unas_scannerForm.status === 'INVALID') {
        return false;
      }
      this.updtDt.rfid_scnr_id = this.unas_scannerForm.value.rfid_scnr_id;
      this.hardwareService.asgnScanner(this.updtDt, (err, res) => {
        // this.unasgnedMcprtLst();
        this.getAsgndScanners();
      });
    }
  }
  updateData() {
    if (this.scannerForm.status === 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt.name = this.scannerForm.value.name;
    this.updtDt.scnr_ctgry_id = this.scannerForm.value.scnr_ctgry_id;
    this.updtDt.rfid_scnr_sts_id = this.scannerForm.value.rfid_scnr_sts_id;
    this.updtDt.ulb_id = this.ulbid;
    this.updtDt.mcrpt_id = this.scannerForm.value.mcrpt_id;
    if (this.updtDt.scnr_ctgry_id === 3){
      this.scannerForm.value.mcrpt_id = 0;
    }
    if (this.updtDt.p_mcrpt_id !== this.scannerForm.value.mcrpt_id){ this.updtDt.mcrpt_chngd_in = true; }
      
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.name === this.updtDt.name) {
        if (val.id !== this.updtDt.id) {
          flag = true;
        }
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }
    this.hardwareService.updtScanner(this.updtDt, (err, res) => {  
      if (res.status === 200) {
       const dialogRef = this.dialog.open(ConfirmDialog, {
         width: '500px',
         data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
       });
       this.getAsgndScanners();
      //  if (this.tabGroup.selectedIndex === 0) { this.getAsgndScanners(); }
      //  else if (this.tabGroup.selectedIndex == 1) {this.unasgnedMcprtLst(); }
   }
    });
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'Scanner', cnfrmflag: true, successFlag: false,  operation: 'Deleted', dtls: nm}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getAsgndScanners();
      // if (this.tabGroup.selectedIndex === 0) {
      //   this.getAsgndScanners();
      // }
      // else if (this.tabGroup.selectedIndex === 1) { this.unasgnedMcprtLst(); }
    });
  }

  resetForm() {
    this.scannerForm.reset();
  }
  opensideBar(key, updtData) {
    // console.log(updtData);
    if (this.assignedTagTab) {
      if (updtData) {
        this.updtDt = updtData;
        this.updtDt.p_mcrpt_id = updtData.mcrpt_id;
        this.opLbl = 'Edit';
        this.scannerForm.get('name').setValue(updtData.name);
        this.scannerForm.get('mcrpt_id').setValue(updtData.mcrpt_id);
        this.scannerForm.get('scnr_ctgry_id').setValue(updtData.rfid_scnr_ctgry_id);
        this.scannerForm.get('rfid_scnr_sts_id').setValue(updtData.rfid_scnr_sts_id);
      }
      else {
        this.scannerForm.get('name').setValue('');
        this.scannerForm.get('mcrpt_id').setValue('');
        this.scannerForm.get('scnr_ctgry_id').setValue('');
        this.scannerForm.get('rfid_scnr_sts_id').setValue('');
        this.opLbl = 'Add';
      }
    } else {
      this.updtDt = updtData;
      this.unas_scannerForm.get('rfid_scnr_id').setValue('');
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }

}

