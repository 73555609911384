import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ds-widget-graph',
  templateUrl: './ds-widget-graph.component.html',
  styleUrls: ['./ds-widget-graph.component.scss']
})
export class DsWidgetGraphComponent implements OnInit {

  @Input() results;
  @Input() label: string;
  @Input() colorschema;
  @Input() maticon;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'ULB Names';
  showYAxisLabel = true;
  yAxisLabel = 'Percentages';
  yScaleMax = 100;

  colorScheme = { domain: [] };

  constructor() { }

  ngOnInit() {
    this.colorScheme.domain = this.colorschema;
  }

}
