import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MainServiceService } from 'app/main/main-service.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
@Component({
  selector: 'app-rejected-reports',
  templateUrl: './rejected-reports.component.html',
  styleUrls: ['./rejected-reports.component.scss']
})
export class RejectedReportsComponent implements OnInit {
  rowData = [];
  columnDefs = [];
  pagination: boolean = true;
  paginationPageSize = 10;
  date = new Date();
  from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  
  shwLdr = true;
  frmevents;
  toevents;
  isExcelDwnLd;
  excelFileNm;
  excelDataHeaders;
  noData;
  rejectExcelData=[];
  constructor(public datepipe: DatePipe, private dataService: MainServiceService,public router:Router) { }

  ngOnInit() {
this.getrejectedreports();
  }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
  }

  addToEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
  }
  
  getrejectedreports(): void{
    if (this.frmevents === undefined){
      this.from_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    }else{
      this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    }
      if (this.toevents === undefined){
        this.to_date = this.datepipe.transform(this.date, 'yyyy-MM-dd');
      }else{
        this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
      }
  
      const inspctOffcrData = {
        'frm_dt': this.from_date,
        'to_dt': this.to_date
      };

        const acceptbnfcryreport = '/beneficiary/rejectedlist';
  console.log(acceptbnfcryreport);
        this.dataService.getByData(acceptbnfcryreport, inspctOffcrData ,(err, res) => {
          this.rowData = res.data;
          this.shwLdr = false;
  
          this.columnDefs = [
            {
              headerName: 'District',
              field: 'dstrct_nm',
              sortable: true,
              filter: true,
              cellStyle: {textAlign: 'center'}
            },
            {
              headerName: 'Mandal',
              field: 'mndle_nm',
              sortable: true,
              filter: true,
              cellStyle: {textAlign: 'center'}
            },
            {
              headerName: 'Village',
              field: 'vlge_nm',
              sortable: true,
              filter: true,
              cellStyle: {textAlign: 'center'}
            },
            {
              headerName: 'Benificary Name',
              field: 'bnfcry_nm',
              sortable: true,
              filter: true,
              cellStyle: {textAlign: 'center'}
            },
            {
              headerName: ' Mobile number',
              field: 'mble_nu',
              sortable: true,
              filter: true,
              cellStyle: {textAlign: 'center'}
            },
            {
              headerName: 'Address',
              field: 'addrs_txt',
              sortable: true,
              filter: true,
              cellStyle: {textAlign: 'center'}
            },
            {
              headerName: ' Equipment Name',
              field: 'eqpmnt_nm',
              sortable: true,
              filter: true,
              cellStyle: {textAlign: 'center'}
            },
            {
              headerName: ' Status ',
              field: 'admn_sts',
              sortable: true,
              filter: true,
              cellStyle: {textAlign: 'center'}
            },
          ];
        });
      }
      
      downloadExcel($event): void {
        // if (this.rowData.length > 0) {
          for (let i = 0; i < this.rowData.length; i++) {
       
            this.rejectExcelData.push({dstrct_nm:this.rowData[i].dstrct_nm, 
              mndle_nm:this.rowData[i].mndle_nm, 
          vlge_nm:this.rowData[i].vlge_nm,
              bnfcry_nm:this.rowData[i].bnfcry_nm,
              mble_nu :this.rowData[i].mble_nu, 
              addrs_txt:this.rowData[i].addrs_txt, 
              eqpmnt_nm:this.rowData[i].eqpmnt_nm, 
          admn_sts:this.rowData[i].admn_sts});
    
          this.isExcelDwnLd = $event;
          this.excelDataHeaders = ['District', 'Mandal', 'village','Benificary Name', ' Mobile number','Address', ' Equipment Name',' Status '];
          this.excelFileNm = 'rejected equipent_list';
        }
      }
      onChange(deviceValue) {
        console.log(deviceValue);
        if(deviceValue=='accepted')
        this.router.navigate(['accepted']);
        if(deviceValue=='rejected')
        this.router.navigate(['rejected']);
        if(deviceValue=='differed')
        this.router.navigate(['differed'])
    }
  }
  

