import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { Router, NavigationExtras } from '@angular/router';


@Component({
  selector: 'app-scanners-notworking',
  templateUrl: './scanners-notworking.component.html',
  styleUrls: ['./scanners-notworking.component.scss']
})
export class ScannersNotworkingComponent implements OnInit {
  columnDefs = [];
  rowData =  [];
  pagination:boolean = true;
  paginationPageSize = 10;
  fltrdata;
  isExcelDwnLd:boolean = false;
  excelDataHeaders;
  excelFileNm;
  ulbId;
  ulbNm;
  shwLdr = true;
  frmevents: string[] = [];
  toevents: string[] = [];
  curdate = new Date();
  curdateFrmt = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
  // lst10DyDte = new Date().setDate(new Date().getDate() - 10);
  from_date = '';
  to_date = '';
  ulbGatesData = [];
  rpt_data_id;
  rpt_data_ky;
  rpt_nm;
  mcrptId; mcrptNm;
  noData;
  ulbsLst = [];
  ulb_id;
  wrk_pckge_id = 0;
  clsrt_id = 0;
  mcrpt_id;
  mcrpt_nm;
  mcrptGteLst = [];
  emp_id;
  fltrVsbleOptns;
  scnrsNtWrkngExclData = [];
  rptFrmDt;
  rptToDt;
  constructor(private misreportsService: MisreportsService, public datepipe: DatePipe, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.fltrVsbleOptns = {
      'isUlb':  true,
      'isMcrpt': false,
      'isGte': false,
      'isEmp': false,
      'isFrmDt': true,
      'isToDt': true
    };
    this.getUlbScnaedNotWorkingStatus();

  }

  receivefltrData(data): void{
    this.ulbId = data.ulb_id;
    this.ulbNm = data.ulb_nm;
    this.from_date = data.from_date;
    this.to_date = data.to_date;
    this.getUlbScnaedNotWorkingStatus();
  }

  getUlbScnaedNotWorkingStatus(): void{
    this.scnrsNtWrkngExclData=[];
    this.rptFrmDt = '';
    this.rptToDt = '';
    if (this.ulbId === undefined){
      this.noData = true;
    }

    else{
      this.noData = false;
      
      // if (this.from_date === this.curdateFrmt){
      //   this.from_date = this.datepipe.transform(this.lst10DyDte, 'yyyy-MM-dd');
      // }
      this.rptFrmDt = this.datepipe.transform(this.from_date, 'dd-MM-yyyy');
      this.rptToDt =  this.datepipe.transform(this.to_date, 'dd-MM-yyyy');
      const scnrsNtWrkngData = {
        'from_dt': this.from_date,
        'to_dt': this.to_date,
        'ulb_id': this.ulbId,
      };
      this.misreportsService.scnrsNtWrkngUlbWse(scnrsNtWrkngData, (err, scnrsNtWrkng_res) => {
        this.rowData = scnrsNtWrkng_res.data;
        if (this.rowData.length){
          this.noData = false;
        }else{
          this.noData = true;
        }
  
        this.shwLdr = false;
  
        for (let i = 0; i < this.rowData.length; i++){
          this.scnrsNtWrkngExclData.push({'sno': this.rowData[i].sno,
                                      'scnr_cd': this.rowData[i].scnr_cd, 'ulb_nm': this.rowData[i].ulb_nm, 'mcrpt_nm': this.rowData[i].mcrpt_nm,'lst_scn_ts':this.rowData[i].lst_scn_ts,
                                     });
        }
        this.columnDefs = [
          {headerName: 'SNo.', field: 'sno', width: 90, cellStyle: {textAlign: 'center'}},
          {headerName: 'Scanner Code', field: 'scnr_cd', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 390},
          {headerName: 'Ulb', field: 'ulb_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 390},
          {headerName: 'Micropocket ', field: 'mcrpt_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 390},
          {headerName: 'Last Scanned On ', field: 'lst_scn_ts', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 390},
      ];
      });
    }

    this.isExcelDwnLd = false;
    this.shwLdr = true;

  }
  downloadExcel($event): void{
    if (this.rowData.length > 0){
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['SNo', 'Scanner Code', 'Ulb', 'Micropocket' ,'Last Scanned On'
       ];
      this.excelFileNm = 'scanners_notworking_rpt';
    }
  }

}
