import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';

import { Login2Component } from 'app/main/auth/login-2/login-2.component';
import { ForgotPassword2Component } from 'app/main/auth/forgot-password-2/forgot-password-2.component';
import { ResetPassword2Component } from 'app/main/auth/reset-password-2/reset-password-2.component';

const routes = [
    {
        path     : '',
        component: Login2Component
    },
    {
        path     : 'forgot-password',
        component: ForgotPassword2Component
    },
    {
        path     : 'reset-password',
        component: ResetPassword2Component
    }
];

@NgModule({
    declarations: [
        Login2Component,
        ForgotPassword2Component,
        ResetPassword2Component
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,

        FuseSharedModule
    ]
})
export class AuthModule {

}
