import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HardwareCertificationService } from '../hardware-certification.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Trackers } from './trackers.model'

@Component({
  selector: 'app-trackers',
  templateUrl: './trackers.component.html',
  styleUrls: ['./trackers.component.scss']
})
export class TrackersComponent implements OnInit {
  @Input() ulb;
  displayedColumns: string[] = ['sno', 'dvce_nm', 'dev_imei_nu', 'lst_dta_rcvd', 'lat', 'lng'];
  dataSource = new MatTableDataSource<Trackers>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  shwLdr: boolean = false;
  data: any;
  excelData: any[];
  isExcelDwnLd: any;
  excelDataHeaders: string[];
  excelFileNm: string;
  noData;
  constructor(private hwCertification: HardwareCertificationService, ) { }

  ngOnInit() {
    this.gettrckrsWrkngStatus()
  }
  gettrckrsWrkngStatus() {
    this.shwLdr = true;
    this.hwCertification.trckrsWrkngStatus(this.ulb.ulb_id, (err, res) => {
      this.dataSource.filter = '';
      this.dataSource.data = [];
      this.data = res.data;
      this.dataSource.data = res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }
  ngOnChanges(changes: any) {
    if (changes && changes.ulb) {
      this.gettrckrsWrkngStatus();
    }
  }
  downloadExcel($event): void {
    this.excelData = [];
    if (this.data.length > 0) {
      for (let i of this.data) {
        this.excelData.push({
          'sno': i.sno ? i.sno : '', 'Device ID': i.dvce_nm ? i.dvce_nm : '', 'IMEI No.': i.dev_imei_nu ? i.dev_imei_nu : '',
          'Last Data Received On':i.lst_dta_rcvd ? i.lst_dta_rcvd:'', 'Latitude': i.lat ? i.lat : '', 'Longitude': i.lng ? i.lng : ''});
      }
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['sno', 'Device ID', 'IMEI No.', 'Last Data Received On','Latitude','Longitude'];
        this.excelFileNm = 'trackers_status_'+this.ulb.ulb_nm;
    }
  }
}
