import { Component, OnInit,ViewChild } from '@angular/core';
import { MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { UlbIncentiveReportService } from 'app/main/ulb-incentive-report/ulb-incentive-report.service';
import {  MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { UserService } from 'app/providers/user/user.serivce';

@Component({
  selector: 'app-ulb-incentive-report',
  templateUrl: './ulb-incentive-report.component.html',
  styleUrls: ['./ulb-incentive-report.component.scss']
})
export class UlbIncentiveReportComponent implements OnInit {

  date = new Date();
  ystrdy = this.date.setDate(this.date.getDate() - 1);
  curDateFrmt = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  from_date = this.datepipe.transform(this.ystrdy, 'yyyy-MM-dd');
  to_date = this.datepipe.transform(this.ystrdy, 'yyyy-MM-dd');
  frmmaxdate = this.datepipe.transform(this.ystrdy, 'yyyy-MM-dd');
  tomaxdate = this.datepipe.transform(this.ystrdy, 'yyyy-MM-dd');
  frmevents: string[] = [];
  toevents: string[] = [];
  displayedColumns: string[] = ['position', 'dstrct_nm', 'ulb_nm','total', 'frs_reg_per', 'frs_reg_achvd', 'frs_dly_per', 'frs_dly_achvd', 'scn_dly_per', 'scn_dly_achvd','wght_dly_per','wght_dly_achvd','fnce_dly_per','fnce_dly_achvd',];
  dataSource = new MatTableDataSource<[]>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  noData = true;tomindate: string;
  shwLdr: boolean;
  excelData: any[];
  data: any;
  isExcelDwnLd: any;
  excelDataHeaders: string[];
  excelFileNm: string;
  userPerm;
  constructor(public datepipe: DatePipe,public UlbIncentiveReportService:UlbIncentiveReportService, private userService: UserService) { }

  ngOnInit() {
    this.userPerm = this.userService.CURRENT_PERM;
  }

  addFrmEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.frmevents = [];
    this.frmevents.push(`${type}: ${event.value}`);
    this.tomindate = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd');
    // this.to_date = this.tomindate;
  }
  addToEvent(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.toevents = [];
    this.toevents.push(`${type}: ${event.value}`);
    this.frmmaxdate = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
  }

  getinsentivesData(){
    this.data = [];
    this.dataSource.data = [];
    this.shwLdr = true;
    if (this.frmevents[0]){
      this.from_date = this.datepipe.transform(this.frmevents[0], 'yyyy-MM-dd'); 
    }
    
    if (this.toevents[0]){
      this.to_date = this.datepipe.transform(this.toevents[0], 'yyyy-MM-dd');
    }
    else{
      this.toevents[0] = this.tomindate;
    }
    

    if (this.from_date === this.curDateFrmt){
      this.noData = true;
      this.shwLdr = false;
    }else{
      this.noData = false;
      let postdata = {
        strt_dt:this.from_date,
        end_dt:this.to_date,
        ulb_id:0
    }
    this.UlbIncentiveReportService.insentivesData(postdata, (err, res) => {
      this.dataSource.filter = '';
      let count = 0;
      for(let i of res.data){
        count++;
        i.sno = count;
        i.total = (parseFloat(i.frs_reg_achvd)+parseFloat(i.frs_dly_achvd)+parseFloat(i.scn_dly_achvd)+parseFloat(i.wght_dly_achvd)+parseFloat(i.fnce_dly_achvd)).toFixed(2)
      }
      this.data = res.data;
      this.dataSource.data = res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
    }
  }
  
  downloadExcel($event): void {
    this.excelData = [];
    if (this.data.length > 0) {
      this.excelData[0]={
        '      ': 'Sno', '       ': 'DISTRICT NAME', '        ':  'ULB NAME','         ': 'Total', 
        'FRS Registration':'(Monthly Average %)','':'(20)',
        'FRS Daily Attendance':'(Monthly Average %)',' ':'(20)',
        'Scanners Coverage':'(Monthly Average %)','  ':'(25)',
        'Weighing Scales':'(Monthly Average %)','    ':'(10)',
        'Coverage of TP':'(Monthly Average %)','     ':'(25)' }
      for (let i of this.data) {
        this.excelData.push({
          'sno': i.sno ? i.sno : '', 'DISTRICT NAME': i.dstrct_nm ? i.dstrct_nm : '', 'ULB NAME': i.ulb_nm ? i.ulb_nm : '',
          'Total': i.total == 'NaN' ? 0 : i.total, 'FRS Registration(Monthly Average %)': i.frs_reg_per ? i.frs_reg_per : '',
          'FRS Registration(20)': i.frs_reg_achvd ? i.frs_reg_achvd : '', 'FRS Daily Attendance(Monthly Average %)': i.frs_dly_per == 'NaN' ? 0 : i.frs_dly_per, 
          'FRS Daily Attendance(20)': i.frs_dly_achvd  == 'NaN' ? 0 : i.frs_dly_achvd,
          'Scanners Coverage(Monthly Average %)': i.scn_dly_per ? i.scn_dly_per : '', 'Scanners Coverage(20)': i.scn_dly_achvd ? i.scn_dly_achvd : '',
           'Weighing Scales(Monthly Average %)': i.wght_dly_per ? i.wght_dly_per : '', 
          'Weighing Scales(20)': i.wght_dly_achvd ? i.wght_dly_achvd : '',
          'Coverage of TP(Monthly Average %)': i.fnce_dly_per ? i.fnce_dly_per : '', 'Coverage of TP(20)': i.fnce_dly_achvd ? i.fnce_dly_achvd : ''
        });
      }
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['      ', '       ', '        ', '         ', 'FRS Registration', '',
         'FRS Daily Attendance', ' ', 'Scanners Coverage', '  ',
         'Weighing Scales','    ','Coverage of TP','     '];
        this.excelFileNm = 'incentive_report_'+this.datepipe.transform(this.frmevents[0], 'dd-MM-yyyy')+'_to_'+this.datepipe.transform(this.toevents[0], 'dd-MM-yyyy');
    }
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }
  getRowSpan(col, index) {
    return this.dataSource.data[index] && this.dataSource.data[index][col];
  //   var ct = 0;
  //   for (let i of this.dataSource.data) {

  //     if (value == i[key]) {
  //       ct = ct + 1;
  //       if (ct > 1) {
  //         i["isDisplay"] = "none";
  //       }
  //     }
  //   }
  //   return ct;
  }
}
