import { Component, OnInit, ViewChild, Input, Inject, ElementRef } from '@angular/core';
import { GeoLocationsService } from '../geo-locations.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'app/providers/user/user.serivce';
import { Vehicles } from '../vehicles/vehicles.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {
  vehicles: Vehicles[] = [];
  displayedColumns: string[] = ['sno', 'asrt_nm', 'wrk_pckge_nm', 'clstr_nm', 'asrt_ctgry_nm', 'dvce_nm', 'imei_nu', 'data_received_ts', 'actions'];
  dataSource = new MatTableDataSource<Vehicles>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('prev') prevInput: ElementRef;
  @ViewChild('next') nextInput: ElementRef;
  headerForm: FormGroup;
  topCtgryFltrForm: FormGroup;
  ulbLst: any;
  ulb_id: number;
  ulb_nm: string;
  wrkpckgsLst: any;
  wrk_pckge_id: number = 0;
  clstrLst: any;
  userPerm: { id: any; c_in: number; r_in: number; u_in: number; d_in: number; };
  vhclOpLbl: string;
  addVhclForm: FormGroup;
  tnt_id: any;
  clnt_id: any;
  vehiclCtgries: any;
  vehiclGrps: any;
  shwLdr: boolean;
  clstrs: any;
  vehiclCtgrylst: any;
  data: any;
  excelData: any;
  isExcelDwnLd: any;
  excelDataHeaders: string[];
  excelFileNm: string;
  ulbtopFltrOptions: Observable<Vehicles[]>;
  errMsg: boolean;
  vehnm: string;
  vehid: any;
  vehiclepattern = "^[A-Za-z]{2}[0-9]{2}[A-Za-z]{1,2}[0-9]{4}$";
  vehiclStatuses: any;
  vehiclStslst: any;
  constructor(private geoService: GeoLocationsService,
    private _fuseSidebarService: FuseSidebarService,
    public dialog: MatDialog, private userService: UserService) { }

  ngOnInit() {
    this.userPerm = this.userService.CURRENT_PERM;
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.topCtgryFltrForm = new FormGroup({
      selectedClstr: new FormControl(''),
      selectedwp: new FormControl(''),
      selectedCtgry: new FormControl(''),
      selectedSts: new FormControl('')

    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulb_id = this.ulbLst[0].ulb_id;
    this.ulb_nm = this.ulbLst[0].ulb_nm;
    this.clnt_id = this.ulbLst[0].clnt_id;
    this.tnt_id = this.ulbLst[0].tnt_id;
    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });
    this.topCtgryFltrForm.get('selectedwp').setValue(0);
    this.topCtgryFltrForm.get('selectedClstr').setValue(0);
    this.topCtgryFltrForm.get('selectedCtgry').setValue(0);
    this.topCtgryFltrForm.get('selectedSts').setValue(0);

    this.geoLocationWorkPackage(this.ulb_id);
    this.geoLocationCluster();
    this.addVhclForm = new FormGroup({
      // asrt_nm: new FormControl('', Validators.required)
      asrt_nm: new FormControl('', Validators.compose([Validators.required]))
      , input_1: new FormControl('')
      , input_2: new FormControl('')
      , input_3: new FormControl('')
      , input_4: new FormControl(''),
      asrt_ctgry_id: new FormControl(''),
      clsrt_id: new FormControl(''),
      asrt_id: new FormControl(''),
      asrt_sts_id: new FormControl(''),

    });
    this.getVehicleCtgries();
    this.getVehicleSts();
    this.wrkpckgsLst = [{ wrk_pckge_id: 0, wrk_pckge_nm: 'ALL' }];
    this.clstrLst = [{ clsrt_id: 0, clsrt_nm: 'ALL' }];
    this.vehiclCtgries = [{ asrt_ctgry_id: 0, asrt_ctgry_nm: 'ALL' }];
    this.getVehiclData();
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
  }

  autocompleteudisplayFn(ulb?: Vehicles): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Vehicles[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  ulbSelected(evt) {
    this.clnt_id = evt.option.value.clnt_id;
    this.tnt_id = evt.option.value.tnt_id;
    this.ulb_id = evt.option.value.ulb_id;
    this.ulb_nm = evt.option.value.ulb_nm;
    this.topCtgryFltrForm.get('selectedwp').setValue(0);
    this.geoLocationWorkPackage(this.ulb_id);
    this.geoLocationCluster();
    this.getVehicleCtgries();
    this.getVehicleSts();
  }
  geoLocationWorkPackage(ulb) {
    this.ulb_id = ulb;
    this.geoService.workPackage(this.ulb_id, (err, wrkPckg_res) => {
      this.wrkpckgsLst = [{ wrk_pckge_id: 0, wrk_pckge_nm: 'ALL' }];
      for (let w of wrkPckg_res.data) {
        this.wrkpckgsLst.push(w);
      }
      this.topCtgryFltrForm.get('selectedClstr').setValue(0);
    });
  }
  geoLocationCluster() {
    this.geoService.getClusterDetails(this.ulb_id, this.wrk_pckge_id, (err, res) => {
      this.clstrLst = [{ clsrt_id: 0, clstr_nm: 'ALL' }];
      for (let c of res.data) {
        this.clstrLst.push(c);
      }
      this.clstrs = res.data;
      if (this.vhclOpLbl !== 'Edit' && this.vhclOpLbl !== 'Add') { this.topCtgryFltrForm.get('selectedClstr').setValue(0); }
    });
  }
  getVehicleCtgries() {
    this.geoService.getVehicleCtgries(this.clnt_id, this.tnt_id, (err, res) => {
      this.vehiclCtgries = [{ asrt_ctgry_id: 0, asrt_ctgry_nm: 'ALL' }];
      for (let c of res.data) {
        this.vehiclCtgries.push(c);
      }
      this.vehiclCtgrylst = res.data;
      this.topCtgryFltrForm.get('selectedCtgry').setValue(0);
    });
  }
  getVehicleSts() {
    this.geoService.getvehiclStatuses(this.clnt_id, this.tnt_id, (err, res) => {
      this.vehiclStslst = res.data;
    });
  }
  getVehiclData() {
    this.shwLdr = true;
    var postData = {
      clnt_id: this.clnt_id,
      tnt_id: this.tnt_id,
      wrk_pckge_id: this.topCtgryFltrForm.value.selectedwp,
      clsrt_id: this.topCtgryFltrForm.value.selectedClstr,
      asrt_ctgry_id: this.topCtgryFltrForm.value.selectedCtgry
    };
    this.geoService.getVehicles(postData, (err, clstr_res) => {
      this.dataSource.data = [];
      this.dataSource.filter = '';
      this.dataSource.data = clstr_res.data;
      this.data = clstr_res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
      console.log(this.dataSource.data);
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  opensideBar(key, vehUpdtData, event: any) {
    if (vehUpdtData) {
      this.vhclOpLbl = 'Edit';
      console.log(vehUpdtData);
      let input1 = vehUpdtData.asrt_nm.toString().slice(0, 2);
      let input2 = vehUpdtData.asrt_nm.toString().slice(2, 4);
      let input3 = vehUpdtData.asrt_nm.toString().slice(4, 6);
      let input4 = vehUpdtData.asrt_nm.toString().slice(6, 10);
      this.addVhclForm.get('asrt_nm').setValue(vehUpdtData.asrt_nm);
      this.addVhclForm.get('input_1').setValue(input1);
      this.addVhclForm.get('input_2').setValue(input2);
      this.addVhclForm.get('input_3').setValue(input3);
      this.addVhclForm.get('input_4').setValue(input4);
      this.addVhclForm.get('asrt_ctgry_id').setValue(vehUpdtData.asrt_ctgry_id);
      this.addVhclForm.get('clsrt_id').setValue(vehUpdtData.clstr_id);
      this.addVhclForm.get('asrt_id').setValue(vehUpdtData.asrt_id);
      this.addVhclForm.get('asrt_sts_id').setValue(vehUpdtData.asrt_sts_id);

    }
    else {
      this.vhclOpLbl = 'Add';
      this.addVhclForm.get('asrt_nm').setValue('');
      this.addVhclForm.get('input_1').setValue('');
      this.addVhclForm.get('input_2').setValue('');
      this.addVhclForm.get('input_3').setValue('');
      this.addVhclForm.get('input_4').setValue('');
      this.addVhclForm.get('asrt_ctgry_id').setValue('');
      this.addVhclForm.get('clsrt_id').setValue('');
      this.addVhclForm.get('asrt_id').setValue('');
      this.addVhclForm.get('asrt_sts_id').setValue('');

    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
    this.geoLocationCluster();
    this.getVehicleCtgries();
  }
  onSubmitcmform() {
    console.log(this.addVhclForm)
    if (this.addVhclForm.status === 'INVALID') {
      return false;
    }
    this.onBlurMethod(this.addVhclForm.value.asrt_nm);
    this.addVhclForm.value.clnt_id = this.clnt_id;
    this.addVhclForm.value.tnt_id = this.tnt_id;
    if (this.addVhclForm.get('asrt_id').value === '' || this.addVhclForm.get('asrt_id').value == null) {
      this.addVehicle(this.addVhclForm.value);
    } else {
      this.updtVhcl(this.addVhclForm.value);
    }
  }
  addVehicle(data) {
    this.geoService.addVehicle(data, (err, res) => {
      if (res.status === 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
        });
        this.addVhclForm.reset();
        this.getVehiclData();
      }else{
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'exist' }
        });
        this.addVhclForm.reset();
        this.getVehiclData();
      }
    });
  }
  updtVhcl(data) {
    this.geoService.updtVehcle(data, (err, res) => {
      if (res.status === 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
        });
        this.getVehiclData();
      }
    });
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'Vehicle', cnfrmflag: true, successFlag: false, operation: 'Deleted', dtls: nm }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getVehiclData();
    });
  }
  downloadExcel($event): void {
    this.excelData = [];
    if (this.data.length > 0) {
      for (let i of this.data) {
        this.excelData.push({
          'sno': i.sno ? i.sno : '', 'Vehicle': i.asrt_nm ? i.asrt_nm : '', 'Cluster': i.clstr_nm ? i.clstr_nm : '',
          'Workpackage': i.wrk_pckge_nm ? i.wrk_pckge_nm : '',
          'Category': i.asrt_ctgry_nm ? i.asrt_ctgry_nm : ''
        });
      }
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['sno', 'Vehicle', 'Cluster', 'Workpackage', 'Category'];
      this.excelFileNm = 'vehicles_data_' + this.ulb_nm;
    }
  }
  onBlurMethod(asrt_nm) {
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.asrt_nm == asrt_nm && asrt_nm != null && asrt_nm != '') {
        flag = true;
        this.vehnm = val.asrt_nm;
        // this.vehid = val.asrt_id;
        console.log(val);
        console.log(this.vehnm);
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }
    else {
      this.errMsg = false;
    }
  }
  vehNameController(event, next, prev, place, edtplc) {
    let asrt_nm = `${this.addVhclForm.value.input_1}${this.addVhclForm.value.input_2}${this.addVhclForm.value.input_3}${this.addVhclForm.value.input_4}`;
    console.log(asrt_nm);
    console.log(place);
    console.log(event.target.value.length)
    if (asrt_nm.toString().length == 10) {
      this.addVhclForm.value.asrt_nm = asrt_nm;
      console.log(this.addVhclForm.value.asrt_nm)
      this.addVhclForm.get('asrt_nm').setValue(asrt_nm);

    } else {
      console.log(next);
      console.log(this.vhclOpLbl)
      if (this.vhclOpLbl == 'Add') {
        if (place == 'ip1' && event.target.value.length > 1) {
          next.focus();
        } else if (place == 'ip2' && event.target.value.length > 1) {
          next.focus();
        } else if (place == 'ip3' && event.target.value.length > 1) {
          next.focus();
        } else {
          return 0;
        }
      } else if (this.vhclOpLbl == 'Edit'){
// console.log(edtplc)
// event.target.blur();
        // edtplc.focusout();
        if(place == 'ip1'){
          edtplc.focus();
        }else if(place == 'ip2'){
          edtplc.focus();
        }else if(place == 'ip3'){
          edtplc.focus();
        }else if(place == 'ip4'){
          edtplc.focus();
        }
      }
       
    }

  }
}
