import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-hardware',
  templateUrl: './hardware.component.html',
  styleUrls: ['./hardware.component.scss']
})
export class HardwareComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }



}
