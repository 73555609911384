import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ulbdashboardService {

  constructor(private http: HttpClient) { }

  public PrtUlbGatesCrd(postdata, cb) {
   
    this.http.post('/gatesDashboard/ulb/gates/scanned', postdata).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public PrtUlbAttndnceCrd(postdata, cb) {
    this.http.post('/atndnceDashboard/ulb/emp/atndnce', postdata).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  public PrtUlbVhclCrd(postdata, cb) {
    this.http.post('/UlbDashboard/ulb/emp/trpsCouns', postdata).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  public PrtUlbScnrsCrd(postdata, cb) {
    this.http.post('/dashboard/scanners/count', postdata).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }

  public PrtUlbWgmntCrd(postdata, cb) {
    this.http.post('/UlbDashboard/ulb/emp/weighments', postdata).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  

  

  

  

  public getAttndnceChartData( ulbid, cb) {
    this.http.get('/ulbdashboard/attendence/allworkers/weekly/ulb/' + ulbid).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
        }
        cb(true, null);
      });
  }
  public getGateChartData(ulbid, cb) {
    this.http.get('/ulbdashboard/weekly/gates/scanned/ulb/' + ulbid).subscribe(data => {
    cb(false, data);
    },
    (err: HttpErrorResponse) => {
    if (err.error instanceof Error) {
    console.log('An error occurred:', err.error.message);
    } else {
    console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
    }
    cb(true, null);
    });
    }
    
    public getWtChartData(ulbid, cb) {
    console.log(ulbid);
    this.http.get('/ulbdashboard/weekly/weighment/allweights/ulb/' + ulbid).subscribe(data => {
    cb(false, data);
    },
    (err: HttpErrorResponse) => {
    if (err.error instanceof Error) {
    console.log('An error occurred:', err.error.message);
    } else {
    console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
    }
    cb(true, null);
    });
    }
    public getTranspChartData(ulbid, id, cb) {
    this.http.get('/ulbdashboard/vehicles/wpWiseTransperpointsLatSevenDaysCvrd/' + ulbid + '/' + id).subscribe(data => {
    cb(false, data);
    },
    (err: HttpErrorResponse) => {
    if (err.error instanceof Error) {
    console.log('An error occurred:', err.error.message);
    } else {
    console.log('Backend returned code:' + err.status + ', error message: ' + err.error);
    }
    cb(true, null);
    });
    }
  


} 
