import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { DsAgGridComponent } from '../main/ds-ag-grid/ds-ag-grid.component';
import { DownloadButtonComponent } from '../main/download-button/download-button.component';
import { PdfDownloadButtonComponent } from '../main/pdf-download-button/pdf-download-button.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MicropocketLevelFilterComponent } from '../main/micropocket-level-filter/micropocket-level-filter.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatDatepickerModule, MatAutocompleteModule,
  MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatButtonModule, MatTooltipModule, MatSlideToggleModule,
  MatGridListModule, MatProgressSpinnerModule, MatListModule, MatCheckboxModule } from '@angular/material';
import { UlbLevelFilterComponent } from '../main/ulb-level-filter/ulb-level-filter.component';
@NgModule({
  declarations: [DsAgGridComponent, DownloadButtonComponent, PdfDownloadButtonComponent,MicropocketLevelFilterComponent, UlbLevelFilterComponent],
  imports: [
    CommonModule,
    AgGridModule,
    FlexLayoutModule,
    MatExpansionModule,
    FormsModule, ReactiveFormsModule,
    MatTabsModule, MatCardModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatDatepickerModule, MatAutocompleteModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatButtonModule, MatTooltipModule, MatSlideToggleModule,
    MatGridListModule, MatProgressSpinnerModule, MatListModule, MatCheckboxModule
  ],
  exports: [DsAgGridComponent, DownloadButtonComponent,PdfDownloadButtonComponent,MicropocketLevelFilterComponent, UlbLevelFilterComponent]
})
export class SharedModule { }
