import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrudService } from 'app/main/workflow/crud.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
// import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { BehaviorSubject } from 'rxjs/Rx';
import { EditFormComponent } from 'app/main/workflow/edit-form/edit-form.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import html2canvas, { Options } from 'html2canvas';
//import * as jsPDF from 'jspdf';
import { PrintDetailsComponent } from 'app/main/workflow/print-details/print-details.component';
import { FormControl } from '@angular/forms';
import { TimelineDetailsComponent } from 'app/main/workflow/timeline-details/timeline-details.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { PdfDownloadComponent } from 'app/main/workflow/pdf-download/pdf-download.component';
// import { TimelineDetailsComponent } from 'app/main/workflow/timeline-details/timeline-details.component';
import { MatSidenav } from '@angular/material/sidenav'
import { ExcelService } from 'app/main/workflow/eexcelservice'
// import * as jsPDF from 'jspdf';
// import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { Column } from 'ag-grid-community';
import { MyDialogComponent } from '../my-dialog/my-dialog.component';
const ELEMENT_DATA = [];
// interface jsPDFWithPlugin extends jsPDF {
//   autoTable: (options: UserOptions) => jsPDF;
// }
declare const require: any;
const jsPDF = require('jspdf');

require('jspdf-autotable');
@Component({
  selector: 'app-request-entry-list',
  templateUrl: './request-entry-list.component.html',
  styleUrls: ['./request-entry-list.component.scss']
})


export class RequestEntryListComponent implements OnInit {

  /** control for the selected bank */
  public ctgryCtrl: FormControl = new FormControl();
  /** control for the MatSelect filter keyword */
  public ctgryFilterCtrl: FormControl = new FormControl();

  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  @ViewChild('usrLstSidenav') usrLstSidenav: MatSidenav;
  @ViewChild('deplstsidenav') deplstsidenav: MatSidenav;
  @ViewChild('infrmdSidenav') infrmdSidenav: MatSidenav;

  req_entry_id: string;
  frm_dt;
  to_dt;
  dprmnt_id;
  attachment: any;
  usrData: any;
  tmlneLst: any;
  searchValue: string = '';
  asgndBfr6Mnths: any;
  assigned: any;
  opened: any;
  fltrdVal: string;
  InfrmdUsrLst: any;
  spr_admn_in: any;
  asngd_to_me: any;
  crdCnt: any;
  ShwCrds: boolean;
  dialogResult: any;
  infrmd_id: any;
  rprtLst: any;
  shwBck: boolean;
  getData: { fnlData: any; usr_id: any; };
  excelDataHeaders: any = [];
  excelFileNm: string;
  isExcelDwnLd: any;
  fnlExcelData: any;
  ofcrExcelData: any = [];
  ShwrprtCrds: boolean;
  shwasgndUsrSdnav: boolean;



  data(arg0: any): any {
    throw new Error("Method not implemented.");
  }
  displayedColumns: string[] = ['select', 'request_id', 'name', 'mobile', 'address', 'issue', 'date', 'category', 'sub-category', 'department', 'assigned to', 'status', 'Actions'];

  @ViewChild('content') content: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSourceone = new MatTableDataSource([]);

  anlysDataSource = new MatTableDataSource([]);
  selectiontwo = new SelectionModel(true, []);
  PstData: { form_data: any; image: any; };
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  catgrylst = [];
  userlst = [];
  tbllst = [];
  sub_cat_list = [];
  usrid: any;
  spnrCtrl = false;
  statuslst = [];
  cat_id: number;
  sts_id: number;
  usr_id: number;
  usrLgnDtls: any;
  jsonData = [];
  udr_dtl: any;
  dialogRef: any;
  deplst = [];
  showfilter = true;
  filtermore = false;
  slctdlst: any
  sl: any = []
  tblst: any
  columnDefs = [];
  constructor(private excelService: ExcelService, private apiCtrl: CrudService, private datePipe: DatePipe, private router: Router, public dialog: MatDialog, private _fuseSidebarService: FuseSidebarService, public _matDialog: MatDialog, public snackBar: MatSnackBar) {
    this.udr_dtl = JSON.parse(localStorage.getItem("usrDtls"));
    this.spr_admn_in = this.udr_dtl.spr_admn_in
    this.entryTktDtls();

    let dt = new Date()
    this.frm_dt = dt;
    this.to_dt = dt;
    if (this.spr_admn_in == 0) {
      this.asngd_to_me = 1;
    } else {
      this.asngd_to_me = 0;
    }

    this.sts_id = 0;
    this.dprmnt_id = 0;
  }

  getnewentry() {
    this.router.navigateByUrl('rqstform');
  }


  ngOnInit() {
    this.dataSourceone.paginator = this.paginator;
    this.dataSourceone.sort = this.sort;
    // if (this.dataSourceone.paginator) {
    //   this.dataSourceone.paginator.firstPage();
    // }
    var fromdate = moment(this.frm_dt).format('YYYY-MM-DD');
    var todate = moment(this.to_dt).format('YYYY-MM-DD');
    this.apiCtrl.get('ticket-system/category-list').subscribe((res) => {
      this.catgrylst = res['data'];
    })

    this.apiCtrl.get('ticket-system/status-lst').subscribe((res) => {
      this.statuslst = [];
      this.statuslst = res['data'];
      this.statuslst.push({
        status_id: 0,
        status_nm: "ALL"
      });
      this.statuslst.reverse();
    })

    this.apiCtrl.get('ticket-system/users-lst/').subscribe((res) => {
      this.userlst = res['data'];
    })
    // this.InfrmdUsr();
    // this.deparmentlst();
    this.dashbrdRprtLst();

    this.apiCtrl.get('ticket-system/Department-list/').subscribe((res) => {
      this.deplst = [];
      if (res['status'] === 200) {
        this.deplst = res['data'];
        this.deplst.push({
          dprmnt_id: 0,
          dprmnt_nm: "ALL"
        });
        this.deplst.reverse();
      }
    }, (err) => {
      console.log(err);
    });

  }

  dashbrdRprtLst() {
    var fromdate = moment(this.frm_dt).format('YYYY-MM-DD');
    var todate = moment(this.to_dt).format('YYYY-MM-DD');
    let crntdata = {
      usr_id: this.udr_dtl.usr_id,
      frm_dt: fromdate != undefined ? fromdate : '',
      to_dt: todate != undefined ? todate : '',
      spr_admn_in: this.udr_dtl.spr_admn_in != undefined ? this.udr_dtl.spr_admn_in : ''
    }
    this.shwBck = false;
    this.apiCtrl.create(crntdata, 'ticket-system/analsys-request').subscribe((res) => {
      console.log(res);
      if (res['status'] === 200) {
        if (res['data'].length > 0) {
          this.ShwrprtCrds = true;
          this.rprtLst = res['data'][0];
          console.log(this.rprtLst);
          setTimeout(() => {
            this.anlysDataSource = new MatTableDataSource(this.rprtLst);
            this.anlysDataSource.paginator = this.paginator;
            this.anlysDataSource.sort = this.sort;
          }, 1000);
        }
      }
    }, (err) => {
      console.log(err);
    });
  }

  closedeplstsidenav() {
    this.deplstsidenav.toggle();
  }

  edata = []
  exportAsXLSX() {
    for (var i = 0; i < this.dataSourceone.filteredData.length; i++) {
      var edata = {
        file_id: this.dataSourceone.filteredData[i].req_qr_cd,
        name: this.dataSourceone.filteredData[i].req_usr_nm,
        Mobile: this.dataSourceone.filteredData[i].req_usr_mbl,
        Address: this.dataSourceone.filteredData[i].req_usr_adrs,
        Request: this.dataSourceone.filteredData[i].req_txt,
        Date: moment(this.dataSourceone.filteredData[i].i_ts).format("DD-MMM-YYYY HH:mm a "),
        Category: this.dataSourceone.filteredData[i].cat_nm,
        SubCategory: this.dataSourceone.filteredData[i].sub_cat_nm,
        Department: this.dataSourceone.filteredData[i].dprmnt_nm,
        AssignedTo: this.dataSourceone.filteredData[i].asgnd_usr_nm,
        Status: this.dataSourceone.filteredData[i].status_nm,
      }
      this.edata.push(edata)
    }
    this.excelService.exportAsExcelFile(this.edata, 'sample')
  }

  asgndBfrSixMnths(assigned) {
    console.log(assigned);
    this.assigned = assigned;
    this.entryTktDtls();
  }
  opndBfrSixMnths(opened) {
    console.log(opened);
    this.opened = opened;
    this.entryTktDtls();
  }
  generatePDF() {
    var count = 1
    this.slctdlst = this.dataSourceone.filteredData
    console.log(this.slctdlst)
    var columns = [
      { title: "s.no", dataKey: 'sno' },
      { title: "filename", dataKey: "req_qr_cd" },
      { title: "Name", dataKey: "req_usr_nm" },
      { title: "mobile", dataKey: "req_usr_mbl" },
      { title: "address", dataKey: "req_usr_adrs" },
      { title: "issue", dataKey: "req_txt" },
      { title: "date", dataKey: "i_ts" },
      { title: "category", dataKey: "cat_nm" },
      { title: "sub-category", dataKey: "sub_cat_nm" },
      { title: "department", dataKey: "dprmnt_nm" },
      { title: "assigned to", dataKey: "asgnd_usr_nm" },
      { title: "status", dataKey: "status_nm" },
    ]

    var doc = new jsPDF('p', 'mm', [1197, 1197]);
    this.dataSourceone.filteredData.filter((k) => {
      k['sno'] = count++;
      k['i_ts'] = moment(k.i_ts).format("DD-MMM-YYYY HH:mm a ");
    })
    var rows = [this.dataSourceone.filteredData]

    doc.autoTable(columns, rows[0], {
      theme: 'striped',
      styles: {
        overflow: 'linebreak',
        cellnWidth: 'wrap'
      },
      columnStyles: {
        1: { columnWidth: 'auto' }
      }
    });
    doc.save('table.pdf');
  }

  isAllSelected() {
    const numSelected = this.selectiontwo.selected.length;
    const numRows = this.dataSourceone.filteredData.length;
    let counter = 0;
    this.selectiontwo.selected.filter((k) => {
      this.dataSourceone.filteredData.filter((k2) => {
        if (k.req_entry_id == k2.req_entry_id) {
          ++counter;
        }
      })
    })
    return (numSelected == counter || (counter == numRows)) && (counter == numRows);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectiontwo.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  closeUsrLstSidnav() {
    this.usrLstSidenav.toggle();
  }
  closeinfmdSidnav() {
    this.infrmdSidenav.toggle();
  }
  masterToggle() {
    console.log(this.dataSourceone.filteredData);
    if (!this.isAllSelected()) {
      this.dataSourceone.filteredData.filter((k2) => {
        k2['data'] = true;
      })
    } else {
      this.dataSourceone.filteredData.filter((k2) => {
        k2['data'] = false;
      });
    }
    this.isAllSelected() ? this.dataSourceone.filteredData.forEach(row => this.selectiontwo.deselect(row)) : this.dataSourceone.filteredData.forEach(row => this.selectiontwo.select(row));

  }

  ckdTktAdjstData = (ckdIn, data) => {
    console.log(ckdIn, data);
    data['data'] = ckdIn.checked;
    console.log(data['data']);
  }

  subcat(cat_id) {
    console.log(cat_id)
    this.apiCtrl.get('ticket-system/sub-category-list/' + cat_id).subscribe((res) => {
      this.sub_cat_list = res['data'];
      console.log(this.sub_cat_list);
    }, (error) => {
      console.log(error);
    });
  }

  applyFilter(filterValue: string) {
    console.log(filterValue);
    this.fltrdVal = filterValue;
    this.entryTktDtls();
  }

  entryTktDtls() {
    this.dashbrdRprtLst();
    this.spnrCtrl = true;
    var fromdate = moment(this.frm_dt).format('YYYY-MM-DD');
    var todate = moment(this.to_dt).format('YYYY-MM-DD');
    const datadt = {
      frm_date: fromdate,
      to_date: todate,
    };
    console.log(this.asngd_to_me)
    if (this.asngd_to_me == undefined && this.spr_admn_in == 1) {
      this.asngd_to_me = 0;
    } else if (this.asngd_to_me == undefined && this.spr_admn_in == 0) {
      this.asngd_to_me = 1;
    } else {
      this.asngd_to_me = this.asngd_to_me;
    }
    let EntryData = {
      usr_id: this.udr_dtl.usr_id != undefined ? this.udr_dtl.usr_id : '',
      cat_id: this.cat_id != undefined ? this.cat_id : '',
      sub_cat_id: this.sub_cat_id != undefined ? this.sub_cat_id : '',
      sts_id: this.sts_id != undefined ? this.sts_id : '',
      frm_dt: fromdate != undefined ? fromdate : '',
      to_dt: todate != undefined ? todate : '',
      req_entry_id: this.req_entry_id != undefined ? this.req_entry_id : '',
      assigned: this.assigned != undefined ? this.assigned : '',
      opened: this.opened != undefined ? this.opened : '',
      flte_txt: this.fltrdVal != undefined ? this.fltrdVal : '',
      admn_in: this.udr_dtl.spr_admn_in != undefined ? this.udr_dtl.spr_admn_in : '',
      dprmnt_id: this.dprmnt_id != undefined ? this.dprmnt_id : '',
      asngd_to_me: this.asngd_to_me != undefined ? this.asngd_to_me : '',
      infrmd_id: this.infrmd_id != undefined ? this.infrmd_id : '',
      // crtlyOpnd: this.crntlyOpnd != undefined ? this.crntlyOpnd : ''
    }
    console.log(EntryData);
    this.apiCtrl.create(EntryData, 'ticket-system/req-entry-list/').subscribe((res) => {
      this.tbllst = res['data'];
      console.log(res['data']);
      if (res['data'].length > 0) {
        this.ShwCrds = true;
        this.attachment = res['data'][0].attachments;
        this.tblst = res['data'][0].enrty_dtls;
        this.spnrCtrl = false;
        this.crdCnt = res['data'][0].cardCnt[0];
        this.crdCnt = {
          all_req: this.crdCnt.all_req != null ? this.crdCnt.all_req : 0,
          closed: this.crdCnt.closed != null ? this.crdCnt.closed : 0,
          forward: this.crdCnt.Forward != null ? this.crdCnt.Forward : 0,
          opened: this.crdCnt.opened != null ? this.crdCnt.opened : 0,
        }
        console.log(this.crdCnt);
        console.log(this.tblst)
        // if (this.tblst.length > 0) {
        // setTimeout(() => {
          this.dataSourceone = new MatTableDataSource(this.tblst);
          this.dataSourceone.paginator = this.paginator;
          this.dataSourceone.sort = this.sort;
        // }, 1000);
        // }

      } else {
        setTimeout(() => {
          this.spnrCtrl = false;
        }, 5000);

      }
    });
  }
  sub_cat_id(cat_id: number, sub_cat_id: any, sts_id: number) {
    throw new Error('Method not implemented.');
  }
  udtAssignUser(usr_id) {
    console.log("Assign Request")
    const adjsrtSlctdData = [];
    const slctd_data = this.selectiontwo.selected;
    const unckd_data = this.dataSourceone.filteredData.filter((k) => {
      return k.data === true;
    });

    const mrcht_bnk_acnt_no = '';
    this.userlst.filter((k) => {
      if (k.usr_id === this.usrid) {
      }
    });
    let lgin_user_id = '';
    lgin_user_id = this.udr_dtl.usr_id;


    unckd_data.filter((k) => {
      if (k.data === true) {
        if(this.spr_admn_in == 0){
          k['status_id'] = 2;
          k['asgnd_ind'] = 0;
          k['frwd_in'] = 1;
          // k['frwd_usr_id'] = k.asgnd_usr_id;
          // k['asgnd_usr_id'] =0;
          adjsrtSlctdData.push(k);
        }else{
          k['status_id'] = 1;
          k['asgnd_ind'] = 1;
          adjsrtSlctdData.push(k);
        }
        
      }
    });

    const data = {
      chckd: adjsrtSlctdData,
      asgnd_usr_id: usr_id.usr_id,
      usr_id: lgin_user_id,
      infrmd_to: 0,
      asgnd_ind: 1,
      txt: 'Do you want to Forward request to ' + usr_id.usr_nm
    };

    if (data.asgnd_usr_id == undefined) {
      this.snackBar.open('Please Select user', 'End now', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (data.chckd.length == 0) {
      this.snackBar.open('Please Select atleast one request to assign', 'End now', {
        duration: 4000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else {
      if (adjsrtSlctdData.length > 0) {
        let dialogRef = this._matDialog.open(MyDialogComponent, {
          width: '400px',
          data: data
        });
        console.log(data);
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'Confirm') {
            this.apiCtrl.create(data, 'ticket-system/forward-chcek-list').subscribe((res) => {
              if (res['status'] === 200) {

                this.usrLstSidenav.toggle();
                this.entryTktDtls();
                this.snackBar.open('Request Assigned sucessfully', 'End now', {
                  duration: 3000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                });
              }
            }, (err) => {
              console.log(err);
            });
          } else {
            this.dialogResult = result;
          }

        })

        // this.apiCtrl.create(data, 'ticket-system/forward-chcek-list').subscribe((res) => {
        //   if (res['status'] === 200) {
        //     this.spnrCtrl = false;
        //     this.usrLstSidenav.toggle();
        //     this.entryTktDtls();
        //     this.snackBar.open('Request Forward to  sucessfully', 'End now', {
        //       duration: 3000,
        //       horizontalPosition: this.horizontalPosition,
        //       verticalPosition: this.verticalPosition,
        //     });
        //   }
        // }, (err) => {
        //   this.spnrCtrl = false;
        //   console.log(err);
        // });
      }
    }
  }

  crntlyOpnd(sts) {
    var fromdate = moment(this.frm_dt).format('YYYY-MM-DD');
    var todate = moment(this.to_dt).format('YYYY-MM-DD');
    let crntdata = {
      crntOpn: sts,
      usr_id: this.udr_dtl.usr_id,
      frm_dt: fromdate != undefined ? fromdate : '',
      to_dt: todate != undefined ? todate : '',
      spr_admn_in: this.udr_dtl.spr_admn_in != undefined ? this.udr_dtl.spr_admn_in : ''
    }
    this.apiCtrl.create(crntdata, 'ticket-system/dshbrd-list').subscribe((res) => {
      console.log(res);
      if (res['status'] === 200) {
        this.tblst = res['data'];;
        setTimeout(() => {
          this.dataSourceone = new MatTableDataSource(this.tblst);
          this.dataSourceone.paginator = this.paginator;
          this.dataSourceone.sort = this.sort;
        }, 2000);
      }
    }, (err) => {
      console.log(err);
    });
    // this.entryTktDtls();
  }

  usrWseDshbrdCnt(dshcnt) {
    var fromdate = moment(this.frm_dt).format('YYYY-MM-DD');
    var todate = moment(this.to_dt).format('YYYY-MM-DD');
    let crntdata = {
      dshbrdcnt: dshcnt,
      usr_id: this.udr_dtl.usr_id,
      frm_dt: fromdate != undefined ? fromdate : '',
      to_dt: todate != undefined ? todate : '',
      spr_admn_in: this.udr_dtl.spr_admn_in != undefined ? this.udr_dtl.spr_admn_in : ''
    }
    this.apiCtrl.create(crntdata, 'ticket-system/usr-dshbrd-rnge').subscribe((res) => {
      console.log(res);
      if (res['status'] === 200) {
        this.tblst = res['data'];;
        setTimeout(() => {
          this.dataSourceone = new MatTableDataSource(this.tblst);
          this.dataSourceone.paginator = this.paginator;
          this.dataSourceone.sort = this.sort;
        }, 2000);
      }
    }, (err) => {
      console.log(err);
    });
  }
  infrmdToUsr(usr_id) {
    console.log("Informed to")
    const adjsrtSlctdData = [];
    const slctd_data = this.selectiontwo.selected;
    const unckd_data = this.dataSourceone.filteredData.filter((k) => {
      return k.data === true;
    });

    const mrcht_bnk_acnt_no = '';
    this.userlst.filter((k) => {
      if (k.usr_id === this.usrid) {
      }
    });
    let lgin_user_id = '';
    lgin_user_id = this.udr_dtl.usr_id;


    unckd_data.filter((k) => {
      if (k.data === true) {
        adjsrtSlctdData.push(k);
      }
    });
    const data = {
      chckd: adjsrtSlctdData,
      infrmd_to: usr_id.usr_id,
      usr_id: lgin_user_id,
      asgnd_usr_id: '',
      txt: 'Do you want to Inform to ' + usr_id.usr_nm
    };

    if (data.infrmd_to == undefined) {
      this.snackBar.open('Please Select user', 'End now', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (data.chckd.length == 0) {
      this.snackBar.open('Please Select atleast one request to assign', 'End now', {
        duration: 4000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else {
      if (adjsrtSlctdData.length > 0) {
        let dialogRef = this._matDialog.open(MyDialogComponent, {
          width: '400px',
          data: data
        });
        console.log(data);
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'Confirm') {
            this.apiCtrl.create(data, 'ticket-system/forward-chcek-list').subscribe((res) => {
              if (res['status'] === 200) {

                this.usrLstSidenav.toggle();
                this.entryTktDtls();
                this.snackBar.open('Informed Request sucessfully', 'End now', {
                  duration: 3000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                });
              }
            }, (err) => {
              console.log(err);
            });
          } else {
            this.dialogResult = result;
          }

        })

      }
    }
  }
  udtdept(dprmnt) {
    this.dprmnt_id = dprmnt;
    this.entryTktDtls();
  }
  archiveFile(arcData) {
    console.log(arcData);
    this.apiCtrl.update(arcData, 'ticket-system/archive-request').subscribe((res) => {
      console.log(res['data']);
      if (res['status'] === 200) {
        this.spnrCtrl = false;
        this.snackBar.open('File Archived', 'End now', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.entryTktDtls();
      }
    }, (err) => {
      this.spnrCtrl = false;
      console.log(err);
    });
  }

  delete(data) {
    console.log('delete');
  }

  getpdf(element) {
    console.log(element);
    this.dialogRef = this._matDialog.open(PdfDownloadComponent, {
      panelClass: 'pdf-modal',
      data: element
    });

    this.dialogRef.afterClosed()
      .subscribe((response) => {
        if (!response) {
          return;
        }
        console.log(response)

      });
  }


  viewtmln(element) {
    console.log(element);
    element['ind'] = 0;
    this.dialogRef = this._matDialog.open(TimelineDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '98%',
      height: '99vh',
      panelClass: 'view-modal',
      data: element
    });
    this.dialogRef.afterClosed().subscribe((response) => {
      console.log(response)
      console.log(`Dialog closed: ${response}`);
      this.entryTktDtls();
    });
  }

  toggle() {
    this.filtermore = !this.filtermore;
    if (this.filtermore == true) {
      this.filtermore = true;
    }
    else {
      this.filtermore = false;
    }
  }

  InfrmdUsr() {
    this.infrmdSidenav.toggle();
    this.apiCtrl.get('ticket-system/users-lst').subscribe((res) => {
      if (res['status'] === 200) {
        this.InfrmdUsrLst = res['data'];
      }
    }, (err) => {
      console.log(err);
    });
  }

  usersLst() {
    this.usrLstSidenav.toggle();
    // this.shwasgndUsrSdnav = true;
    // this.shwasgndUsrSdnav = false;
    this.apiCtrl.get('ticket-system/users-lst').subscribe((res) => {
      if (res['status'] === 200) {
        this.usrData = res['data'];
      }
    }, (err) => {
      console.log(err);
    });
  }

  InformedtoUsr() {
    this.usr_id
  }

  forwardRequest(usr) {
    let frwd_data = [];
    let data = this.tmlneLst[this.tmlneLst.length - 1];
    if (data != undefined) {
      frwd_data.push({
        frwd_usr_id: usr.usr_id,
        status_id: data.status_id != undefined ? data.status_id : '',
        req_entry_id: data.req_entry_id,
        dt: data.isue_dt,
        asgnd_ind: 1
      })
    } else {
      frwd_data.push({
        frwd_usr_id: usr.usr_id,
        status_id: this.data['data'].status_id != undefined ? this.data['data'].status_id : '',
        req_entry_id: this.data['data'].req_entry_id != undefined ? this.data['data'].req_entry_id : '',
        dt: this.data['data'].isue_dt != undefined ? this.data['data'].isue_dt : '',
        asgnd_ind: 1
      })
    }

    let postData = {
      chckd: frwd_data,
      asgnd_usr_id: this.data['data'].asgnd_usr_id != undefined ? this.data['data'].asgnd_usr_id : '',
      usr_id: this.udr_dtl.usr_id,
    }

    this.apiCtrl.create(postData, 'ticket-system/forward-chcek-list').subscribe((res) => {
      if (res['status'] === 200) {
        console.log(res['data']);
        this.usrData = res['data'];
        this.usrLstSidenav.toggle();
        this.dialogRef.close();
        this.snackBar.open('Requst Forward sucessfully', 'End now', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }, (err) => {
      console.log(err);
    });
  }

  getRowClicked(slctdData): void {
    console.log(slctdData);
    this.shwBck = true;
    this.columnDefs = [
      {
        headerName: 'File Num',
        field: 'req_qr_cd',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Mobile',
        field: 'req_usr_mbl',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Request',
        field: 'req_txt',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: {
          cursor: 'pointer',
          textAlign: 'center'
        }
      },
      {
        headerName: 'Date',
        field: 'date',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Category',
        field: 'cat_nm',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Department',
        field: 'dprmnt_nm',
        sortable: true,
        filter: true,
        width: 250,
        cellStyle: { textAlign: 'center' },
      }, {
        headerName: 'View Details',
        cellStyle: { textAlign: 'center' },
        width: 100,
        cellRenderer: function (param) {
          const eDiv = document.createElement('div');
          eDiv.innerHTML = `<span class="my-css-class">
            <button class="btn-simple-history" style="background-color: #3190a6;  
            box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
            color: #fff;cursor: pointer;border: none;line-height: 17px;border-radius: 3px;">View</button>
            </span>`;
          const eButton = eDiv.querySelectorAll('.btn-simple-history')[0];
          eButton.addEventListener('click', function (param) {
            // console.log(param.value);
          });
          return eDiv;
        },
      },

    ];

    if (slctdData.colDef.headerName === 'View Details') {
      console.log(slctdData.data);
      this.viewtmln(slctdData.data)
    }

    this.getData = {
      fnlData: slctdData.colDef.field,
      usr_id: this.udr_dtl.usr_id
    }
    console.log(this.getData);
    this.apiCtrl.get('ticket-system/ofcr-wse-rqst-mntr/' + slctdData.colDef.field + '/' + this.udr_dtl.usr_id).subscribe((res: any) => {
      if (res['status'] === 200) {
        this.rprtLst = res['data'];
        console.log(this.rprtLst);
      }
    }, (err) => {
      console.log(err);
    });


    console.log(this.ofcrExcelData);
    this.excelDataHeaders.push('File Number', 'Mobile Number', 'Request Text', 'Date', 'Category', 'Department');

  }

  goBack(): void {
    this.shwBck = false;
    this.dashbrdRprtLst();
  }

  downloadExcel($event, data): void {
    console.log($event, data);
    for (let i = 0; i < this.rprtLst.length; i++) {
      this.ofcrExcelData.push({
        req_qr_cd: this.rprtLst[i].req_qr_cd, req_usr_mbl: this.rprtLst[i].req_usr_mbl, req_txt: this.rprtLst[i].req_txt,
        dt: this.rprtLst[i].dt, cat_nm: this.rprtLst[i].cat_nm, dprmnt_nm: this.rprtLst[i].dprmnt_nm
      });
    }
    this.fnlExcelData = [];
    this.isExcelDwnLd = $event;
    this.fnlExcelData = this.ofcrExcelData;
    this.excelDataHeaders = this.excelDataHeaders;
    this.excelFileNm = 'Officer_Wise_rpt';
  }
}




