import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class WasteGeneratorsService {


  constructor(private http: HttpClient) { }

  // get Gates list for generators in Master Data 
  public getGatesByCategory(ulb_id,mp_id, cat_id, cb) {
    this.http.get('/wg/gates/' + ulb_id + '/' + mp_id + '/' + cat_id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }

  // post Gate for Waste generators in Master Data 
  public postGatesByCategory(gatedata, cb) {
    this.http.post('/wg/gates',gatedata).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }

  // Delete Gate for Waste generators in Master Data 
  public deleteGate(gte_id, cb) {
    this.http.delete('/wg/gate/'+gte_id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }

  // Delete Gate for Waste generators in Master Data 
  public updateGate(gatedata, cb) {
    this.http.put('/wg/updateGteTagDetails/'+gatedata.gte_id,gatedata).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
  // Delete Gate for Waste generators in Master Data 
  public removeTagFrmGate(rfid_tge_id, cb) {
    this.http.delete('/wg/removeTagFrmGte/'+rfid_tge_id).subscribe(data => {
      cb(false, data);
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      });
  }
}

