import { Component, OnInit, Inject, ViewChild, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { CrudService } from 'app/main/workflow/crud.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatPaginator, MatSort, MatSidenav, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MyDialogComponent } from '../my-dialog/my-dialog.component';
import * as moment from 'moment';
import { PdfDownloadComponent } from '../pdf-download/pdf-download.component';
const jsPDF = require('jspdf');

// export interface DialogData {

// }
const ELEMENT_DATA = [];
@Component({
  selector: 'app-timeline-details',
  templateUrl: './timeline-details.component.html',
  styleUrls: ['./timeline-details.component.scss']
})
export class TimelineDetailsComponent implements OnInit {
  shwEdt: boolean;
  inEdtMde: boolean;
  dialogResult: any;
  archvdLst: any;
  dialogReff: MatDialogRef<PdfDownloadComponent, any>;
  arc_ind: number;
  openDialog(arg0: any): any {
    throw new Error("Method not implemented.");
  }
  printData: any;
  req_Entry_dtls = [];
  tmlneLst: any;
  ShowNav: boolean;
  searchValue: string = '';
  imagePath: any;
  Imgsrc: any;
  lbl_name: any;
  curDate: Date;
  imagesPrv = [];
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('usrLstSidenav') usrLstSidenav: MatSidenav;
  @ViewChild('fileInput') el: ElementRef;

  timelineColumns: string[] = [];
  atchmntColumns: string[] = ['position', 'lbl_nm', 'atc_nm', 'upld_by', 'upld_Date', 'Actions'];
  displayedColumns: string[] = ['position', 'Labelname', 'Filename', 'uploadBy', 'Date', 'Actions'];
  ArchivedColumns: string[] = ['A_position', 'A_lbl_nm', 'A_atc_nm', 'A_upld_by', 'A_upld_Date', 'A_Actions']
  timelineDataSource = new MatTableDataSource(ELEMENT_DATA);
  atchmntDataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  ArchivedataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) timelinepaginator: MatPaginator;
  @ViewChild(MatPaginator) atchmntpaginator: MatPaginator;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  udr_dtl: any;
  cmnts: any;
  attachment: any;
  usrData: any;
  userForm: FormGroup;
  userlst: any;
  catList: any;
  sub_cat_list: any;
  reqdate: any;
  form: any;
  submituser = false;
  shwtbl = false;
  showarchive = false;
  shwlbl: boolean;
  showcards = true;
  showsubmituser = false;

  filteredItems = [];
  @Input() searchText;

  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  DepList: any;
  udtData: { form_data: any; };
  spnrCtrl: boolean;
  showSpinner: boolean;
  images: any;
  crdsrv: any;
  resData: any;
  ShwQrcd: boolean;
  shwarchvtbl = false;
  public href: string = "";
  constructor(private apiCtrl: CrudService, @Inject(MAT_DIALOG_DATA) private _data: any, private router: Router, private datePipe: DatePipe, private _formBuilder: FormBuilder, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<TimelineDetailsComponent>, public _matDialog: MatDialog) {
    this.udr_dtl = JSON.parse(localStorage.getItem("usrDtls"));
    this.printData = _data;
    console.log(this.printData);
    this.reqEntryTmlne();
    this.curDate = new Date();
    this.shwEdt = true;
    this.href = this.router.url;

    if (this.href.match(/public/g) && this.href.match(/public/g).length > 0) {
      console.log(this.router.url);
      console.log("__________________public");
      console.log(this.href);
      this.timelineColumns = ['position', 'asgndby', 'frwdto', 'frwddt'];
    }
    else {
      this.timelineColumns = ['position', 'asgndby', 'frwdto', 'frwddt', 'comments', 'actions'];
    }
  }
  ngOnInit() {

    this.reqdate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.form = this._formBuilder.group({

      req_usr_nm: [this.printData.req_usr_nm, Validators.required],
      req_usr_mbl: [this.printData.req_usr_mbl, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      req_usr_adrs: [this.printData.req_usr_adrs, Validators.required],
      req_txt: [this.printData.req_txt, Validators.required],
      city: [this.printData.city, Validators.required],
      dprmnt_id: [this.printData.dprmnt_id, Validators.required],
      cat_id: [this.printData.cat_id, Validators.required],
      sub_cat_id: [this.printData.sub_cat_id, Validators.required],
      // date: ['', Validators.required],
      Date: [this.printData.i_ts, Validators.required],
      shrt_desc: [this.printData.shrt_desc, [Validators.required]],
      pincode: [this.printData.pn_cd, [Validators]],

    });
    // this.form.get('date').disable()
    this.getDepList();
    this.getCatList();
    this.form.disable();

  }
  edituser() {
    this.shwEdt = false;
    this.inEdtMde = true;
    this.form.enable();
    // this.submituser = true;
    this.showcards = false;
    this.showsubmituser = true;
  }

  getCatList() {
    this.apiCtrl.get('ticket-system/category-list').subscribe((res) => {
      this.catList = res['data'];
      this.subcat(this.printData.cat_id);
    }, (error) => {
      console.log(error)
    })
  }
  subcat(cat_id) {
    this.apiCtrl.get('ticket-system/sub-category-list/' + cat_id).subscribe((res) => {
      this.sub_cat_list = res['data'];
    }, (error) => {
      console.log(error)
    })
  }
  getDepList() {

    this.apiCtrl.get('ticket-system/Department-list').subscribe((res) => {
      this.DepList = res['data'];
    }, (error) => {
      console.log(error)
    })
  }

  archiveFile(arcData) {
    this.apiCtrl.update(arcData, 'ticket-system/archive-request').subscribe((res) => {
      if (res['status'] === 200) {
        this.spnrCtrl = false;
        this.snackBar.open('File Archived', 'End now', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.reqEntryTmlne();
      }
    }, (err) => {
      this.spnrCtrl = false;
      console.log(err);
    });
  }


  updateuser() {
    this.udtData = {
      form_data: this.form.getRawValue(),
    }
    this.udtData['req_entry_id'] = this.printData.req_entry_id;
    console.log(this.udtData)
    this.apiCtrl.create(this.udtData, 'ticket-system/update-request').subscribe((res: any) => {
      this.snackBar.open('Requst Updated sucessfully', 'End now', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
      this.onCloseClick();
    }, (error) => {
      console.log(error)
    })
  }

  timeline() {
    this.ShowNav = true;
  }

  reqEntryTmlne() {
    this.spnrCtrl = true;
    this.req_Entry_dtls = [];
    let EntrtData = {
      usr_id: this.udr_dtl.usr_id,
      req_entry_id: this.printData.req_entry_id,
      admn_in: this.udr_dtl.spr_admn_in != undefined ? this.udr_dtl.spr_admn_in : '',
      cat_id: '',
      sub_cat_id: '',
      stg_id: '',
      frm_dt: '',
      to_dt: '',
      assigned: '',
      opened: '',
      flte_txt: '',
      dprmnt_id: '',
    }

    console.log(EntrtData)
    this.apiCtrl.create(EntrtData, 'ticket-system/pending-timeline-list/').subscribe((res) => {
      console.log(res);
      if (res['status'] === 200) {
        console.log(res);
        this.req_Entry_dtls.push(res['data'][0].req_dtls);
        this.tmlneLst = res['data'][0].stg_dtls;
        this.attachment = res['data'][0].attachments;
        this.spnrCtrl = false;
        console.log(this.req_Entry_dtls);
        console.log(this.tmlneLst);
        console.log(this.attachment);
        this.tmlneLst.filter((data) => {
          for (var i = 0; i < data.cmnt.length; i++) {
            if (data.cmnt[i].cmnt_tx != null) {
              data.cmntlngth = data.cmnt.length;
            } else {
              data.cmntlngth = 0;
            }
          }
        })
        this.tmlneLst.filter((atcdata) => {
          atcdata.atchmnt = atcdata.atchmnt;
        })
        setTimeout(() => {
          this.timelineDataSource = new MatTableDataSource(this.tmlneLst);
          this.timelineDataSource.paginator = this.timelinepaginator;
          this.timelineDataSource.sort = this.sort;
        }, 2000);


        this.atchmntDataSource = new MatTableDataSource(this.attachment);
        this.atchmntDataSource.paginator = this.atchmntpaginator;
        this.atchmntDataSource.sort = this.sort;
      }

    })
  }
  addAtchmnt() {
    console.log(this.atchmntDataSource);
    console.log(this.printData);
  }
  commentsTmln(element) {
    console.log(element);
    this.cmnts = element.cmnt
    console.log(this.cmnts)
    this.sidenav.toggle();
  }
  onCloseClick() {
    this.dialogRef.close();
  }
  closeSidnav() {
    this.sidenav.toggle();
  }

  closeUsrLstSidnav() {
    this.usrLstSidenav.toggle();
  }

  usersLst() {
    this.usrLstSidenav.toggle();
    this.apiCtrl.get('ticket-system/users-lst').subscribe((res) => {
      if (res['status'] === 200) {
        console.log(res['data']);
        this.usrData = res['data'];
      }
    }, (err) => {
      console.log(err);
    });
  }

  forwardRequest(usr) {
    let frwd_data = [];
    let data = this.tmlneLst[this.tmlneLst.length - 1];
    console.log(data);
    if (data != undefined) {
      frwd_data.push({
        frwd_usr_id: usr.usr_id,
        status_id: 2,
        req_entry_id: data.req_entry_id,
        dt: data.isue_dt,
        frwd_in: 1
      })
    } else {
      frwd_data.push({
        frwd_usr_id: usr.usr_id,
        status_id: 1,
        req_entry_id: this.printData.req_entry_id != undefined ? this.printData.req_entry_id : '',
        dt: this.printData.isue_dt != undefined ? this.printData.isue_dt : '',
        frwd_in: 1
      })
    }

    let postData = {
      chckd: frwd_data,
      asgnd_usr_id: 0,
      usr_id: this.udr_dtl.usr_id,
      txt: 'Do you want to Forward the request to' + usr.usr_nm

    }

    let dialogRef = this._matDialog.open(MyDialogComponent, {
      width: '400px',
      data: postData
    });
    console.log(postData)
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result == 'Confirm') {
        this.apiCtrl.create(postData, 'ticket-system/forward-chcek-list').subscribe((res) => {
          if (res['status'] === 200) {
            console.log(res['data']);
            this.usrData = res['data'];
            this.usrLstSidenav.toggle();
            this.dialogRef.close();
            this.reqEntryTmlne();
            this.snackBar.open('Request Assigned sucessfully', 'End now', {
              duration: 3000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
        }, (err) => {
          console.log(err);
        });
      } else {
        this.dialogResult = result;
      }
    })
  }

  clseCmplnt() {
    var date = new Date();
    var curDate = moment(date).format('YYYY-MM-DD');
    let frwdData = {
      req_entry_id: this.printData.req_entry_id,
      asgnd_usr_id: this.printData.asgnd_usr_id,
      a_in: this.printData.a_in,
      frwd_usr_id: this.printData.usr_id,
      dt: curDate,
      usr_id: this.udr_dtl.usr_id,
      status_id: 3,
      txt: 'Do you want to close the '+ this.printData.req_qr_cd + 'request'

    }


    let dialogRef = this._matDialog.open(MyDialogComponent, {
      width: '400px',
      data: frwdData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Confirm') {
        this.apiCtrl.create(frwdData, 'ticket-system/close-request').subscribe((res: any) => {
          console.log("if condotion")
          console.log(res);
          // this.usrLstSidenav.toggle();
          this.dialogRef.close();
          this.reqEntryTmlne();
        }, (err) => {
          console.log(err)
        })
      } else {
        console.log("Else condition")
        this.dialogResult = result;
      }

    })

  }

  updateSearchModel(value) {
    this.searchText = value;
    this.searchModelChange.emit(this.searchText);
  }
  sendMessage(message) {
    const data = {
      msg: message,
      usr_id: this.udr_dtl.usr_id,
      stg_id: this.cmnts[0].stg_id,
      req_entry_id: this.cmnts[0].req_entry_id
    };
    console.log(data);
    this.apiCtrl.create(data, 'ticket-system/stage-chat').subscribe((res) => {
      console.log(res['data']);
      if (res['status'] === 200) {
        console.log(":::::data reload:::::");
        this.sidenav.toggle();
        this.reqEntryTmlne();
      }
    }, (err) => {
      console.log(err);
    });
  }

  preview(files) {
    console.log(files);
    this.curDate = new Date();
    this.shwtbl = true;
    if (files.length === 0)
      return;
    for (let i = 0; i < files.length; i++) {
      var mimeType = files[i].type;
      var reader = new FileReader();
      this.imagePath = files;
      reader.readAsDataURL(files[i]);
      reader.onload = (_event: any) => {
        let img = {}
        this.Imgsrc = _event.target.result;
        // if ((mimeType.match(/image\/*/) == null)) {
        //   this.Imgsrc = 'assets/images/file.png'
        // }
        let file_nm = files[i].name
        img = {
          base64: this.Imgsrc,
          lbl_name: this.lbl_name != undefined ? this.lbl_name : '',
          upldBy: this.udr_dtl.usr_id,
          upldusr: this.udr_dtl.usr_nm,
          upldDt: this.curDate,
          filenm: file_nm
        }
        this.imagesPrv.push(img);
        if (this.imagesPrv) {
          this.shwlbl = true
        }
        console.log(this.imagesPrv);
        this.dataSource = new MatTableDataSource(this.imagesPrv);
        console.log(this.dataSource)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // img = {
        //   base64: _event.target.result,
        //   name: ''
        // }
        // this.images.push(img)
      }
    }

  }
  delImage(i: any) {
    console.log(i)
    this.imagesPrv.splice(i, 1)
    this.dataSource = new MatTableDataSource(this.imagesPrv);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }
  getHome() {
    this.router.navigate(["/rqsten"]);
    // this.router.navigateByUrl('rqsten')
  }

  sveattach() {
    console.log(this.dataSource);

    let PstData = {
      image: this.imagesPrv,
      req_entry_id: this.printData.req_entry_id,
      usr_id: this.udr_dtl.usr_id
    }
    console.log(PstData)
    this.apiCtrl.create(PstData, 'ticket-system/atchmnt-lst').subscribe((res: any) => {
      console.log(res)
      this.resData = res;
      console.log(this.resData);
      this.shwtbl = false;
      this.reqEntryTmlne();
      this.snackBar.open('Requst Attachments Uploaded sucessfully', 'End now', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }, (error) => {
      console.log(error)
    })
  }
  closeAtchDoc() {
    this.shwtbl = false;
  }

  downloadFile(data) {
    // var file = new Blob([data], { type: 'application/pdf' });
    // saveAs(file, 'filename.pdf');
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  archievedRecords() {
    this.showarchive = !this.showarchive;
    console.log(this.showarchive)
    if (this.showarchive == true) {
      this.arc_ind = 0
    } else {
      this.arc_ind = 1
    }
    this.apiCtrl.get('ticket-system/archived-request-list/' + this.printData.req_entry_id + '/' + this.arc_ind).subscribe((res) => {
      this.archvdLst = res['data'];
      console.log(this.archvdLst);
      this.atchmntDataSource = new MatTableDataSource(this.archvdLst);
      this.atchmntDataSource.paginator = this.paginator;
      this.atchmntDataSource.sort = this.sort;
    }, (error) => {
      console.log(error)
    })
  }
  unArchive(arcData) {
    this.apiCtrl.update(arcData, 'ticket-system/unarchive-request').subscribe((res) => {
      if (res['status'] === 200) {
        // this.spnrCtrl = false;
        this.snackBar.open('File Unarchived', 'End now', {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.reqEntryTmlne();
        this.archievedRecords();
      }
    }, (err) => {
      this.spnrCtrl = false;
      console.log(err);
    });
  }
  closearchieve() {
    this.showarchive = false;
  }


}

