import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FinesService } from 'app/main/fines.service';

@Component({
  selector: 'app-mct-collectrpt',
  templateUrl: './mct-collectrpt.component.html',
  styleUrls: ['./mct-collectrpt.component.scss']
})
export class MctCollectrptComponent implements OnInit {
  headerForm: any;
  ulbLst: any;
  ulbid: any;
  ulbnm: any;
  ulbtopFltrOptions: any;
  ulbNM: any;
  rowData: any = [];
  usr_id;
  columnDefs: any = [];
  data: any = [];
  rowData1: any = [];
  dta: any = [];
  snvntdta: any = [];
  nrmldta: any = [];
  t: any;
  rowClicked: boolean = false;
  usrchrgsrpt: boolean = false;
  paginationPageSize = 10;
  pagination: boolean = true;
  columnDefs1: any = [];
  columnDefs2: any = [];
  sh_in: number;
  fns_ofcrs_ttl;
  usr_ofcrs_ttl;
  fns_ofcrs_ttl_amnt = 0;
  usr_ofcrs_ttl_amnt = 0;
  excelDataHeaders: any[];
  isExcelDwnLd: any;
  excelFileNm: string;
  usrchrgtmdata: any = [];
  excelFileNm_snglofcr: any = [];
  excelDataHeaders_snglofcr: any[];
  isExcelDwnLd_snglofcr: any;
  snglusrchrgtmdata: any = [];
  usr_name: string;
  rptnm: any;
  constructor(private FinesService: FinesService, private route: ActivatedRoute, public datepipe: DatePipe, private router: Router) { }

  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'))
    this.ulbid = this.ulbLst[0].ulb_id;
    this.ulbnm = this.ulbLst[0].ulb_nm;

    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
    this.getMCTtabledata();
  }
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  onUlbChange(evt) {
    this.ulbid = evt.option.value.ulb_id;
    this.ulbNM = evt.option.value.ulb_nm;
  }

  getMCTtabledata() {
    const data = [{

    }];
    this.usrchrgtmdata = [];
    this.isExcelDwnLd = false;
    this.FinesService.MCTcolctOfcrsData(this.data, (err, res) => {
      this.dta = res.data;

      for (var t = 0; t < this.dta.length; t++) {
        if (this.dta[t].dsgns_id == 71) {
          this.snvntdta[t] = this.dta[t];

          // filtering undefined data
          this.snvntdta = this.snvntdta.filter(function (element) {
            return element !== undefined;
          });

          // for sno
          var index = 1;
          for (var i = 0; i < this.snvntdta.length; i++) {
            this.snvntdta[i].IncVl = index++;
            this.snvntdta[i].src = `D`;

          }
          this.usr_ofcrs_ttl = index - 1;
        }
        else {
          this.nrmldta[t] = this.dta[t];
          this.nrmldta = this.nrmldta.filter(function (element) {
            return element !== undefined;
          });
          var index = 1;
          for (var i = 0; i < this.nrmldta.length; i++) {
            this.nrmldta[i].IncVl = index++;
            this.nrmldta[i].src = "D"
          }
          this.fns_ofcrs_ttl = index - 1;
        }

      }
      for (var j = 0; j < this.snvntdta.length; j++) {
        this.usr_ofcrs_ttl_amnt = this.usr_ofcrs_ttl_amnt + this.snvntdta[j].usr_ttl;
      }
      for (var l = 0; l < this.nrmldta.length; l++) {
        this.fns_ofcrs_ttl_amnt = this.fns_ofcrs_ttl_amnt + this.nrmldta[l].fn_ttl;
      }
      this.usrchrgtmdata = [];
      this.usrchrgtmdata.push({
        'Officer Name': "",
        'Phone Number': "",
        'Total Collection': "",
        'Treasury': "",
        'Pending': "",
      });
      this.usrchrgtmdata.push({
        'Officer Name': "",
        'Phone Number': "user Charges team (" + this.usr_ofcrs_ttl + ") collection - " + this.usr_ofcrs_ttl_amnt,
        'Total Collection': "",
        'Treasury': "",
        'Pending': "",
      });
      this.usrchrgtmdata.push({
        'Officer Name': 'Officer Name',
        'Phone Number': 'Phone Number',
        'Total Collection': 'Total Collection',
        'Treasury': 'Treasury',
        'Pending': 'Pending',
      });
      for (let i = 0; i < this.snvntdta.length; i++) {
        this.usrchrgtmdata.push({
          'Officer Name': this.snvntdta[i].usr_nm,
          'Phone Number': this.snvntdta[i].mobile_nu,
          'Total Collection': this.snvntdta[i].usr_ttl,
          'Treasury': this.snvntdta[i].usr_depst_ttl,
          'Pending': this.snvntdta[i].usr_pndg_ttl,
        });
      }
      this.usrchrgtmdata.push({
        'Officer Name': "",
        'Phone Number': "",
        'Total Collection': "",
        'Treasury': "",
        'Pending': "",
      });
      this.usrchrgtmdata.push({
        'Officer Name': "",
        'Phone Number': "Fines team (" + this.fns_ofcrs_ttl + ") collection - " + this.fns_ofcrs_ttl_amnt,
        'Total Collection': "",
        'Treasury': "",
        'Pending': "",
      });
      this.usrchrgtmdata.push({
        'Officer Name': 'Officer Name',
        'Phone Number': 'Phone Number',
        'Total Collection': 'Total Collection',
        'Treasury': 'Treasury',
        'Pending': 'Pending',
      });
      for (let i = 0; i < this.nrmldta.length; i++) {
        this.usrchrgtmdata.push({
          'Officer Name': this.nrmldta[i].usr_nm,
          'Phone Number': this.nrmldta[i].mobile_nu,
          'Total Collection': this.nrmldta[i].fn_ttl,
          'Treasury': this.nrmldta[i].fn_depst_ttl,
          'Pending': this.nrmldta[i].fn_pndg_ttl,
        });
      }
    });

    this.columnDefs = [{ headerName: 'Sno', field: 'IncVl', width: 50, cellStyle: { textAlign: 'center' } },
    { headerName: 'Officer Name', field: 'usr_nm', width: 200, cellStyle: { textAlign: 'center' } },
    { headerName: 'Phone Number', field: 'mobile_nu', width: 120, cellStyle: { textAlign: 'center' } },
    { headerName: 'Total Collection', field: 'usr_ttl', width: 120, cellStyle: { textAlign: 'center' } },
    { headerName: 'Treasury', field: 'usr_depst_ttl', width: 120, cellStyle: { textAlign: 'center' } },
    { headerName: 'Pending', field: 'usr_pndg_ttl', width: 100, cellStyle: { textAlign: 'center', } },
    { headerName: 'Details', field: 'src', width: 100, cellStyle: { textAlign: 'center', color: 'blue', cursor: 'pointer', font: 'bold' } },];

    this.columnDefs1 = [{ headerName: 'Sno', field: 'IncVl', width: 50, cellStyle: { textAlign: 'center' } },
    { headerName: 'Officer Name', field: 'usr_nm', width: 200, cellStyle: { textAlign: 'center' } },
    { headerName: 'Phone Number', field: 'mobile_nu', width: 120, cellStyle: { textAlign: 'center' } },
    { headerName: 'Total Collection', field: 'fn_ttl', width: 120, cellStyle: { textAlign: 'center' } },
    { headerName: 'Treasury', field: 'fn_depst_ttl', width: 120, cellStyle: { textAlign: 'center' } },
    { headerName: 'Pending', field: 'fn_pndg_ttl', width: 100, cellStyle: { textAlign: 'center' } },
    { headerName: '	Details', field: 'src', width: 100, cellStyle: { textAlign: 'center', color: 'blue', cursor: 'pointer', font: 'bold' } }];


  }
  downloadExcel($event): void {
    if (this.snvntdta.length > 0) {
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['Officer Name', 'Phone Number', 'Total Collection', 'Treasury', 'Pending'];
      this.excelFileNm = 'Fines and User collctn rpt';
    }
  }
  getRowClicked(evt, id): void {
    this.usrchrgsrpt = true;
    this.sh_in = id;
    if (id == 1) {
   this.rptnm="User Charges Report";
    }
    else {
      this.rptnm="Fines Details Report";
    }
    this.usr_id = evt.data.usr_id,
    this.FinesService.MCtsingleoffdta(this.usr_id, this.sh_in, (err, res) => {
      this.rowData = res.data;
      this.usr_name = evt.data.usr_nm;
      var index = 1;
      for (var i = 0; i < this.rowData.length; i++) {
        this.rowData[i].IncVl = index++;
      }
      this.snglusrchrgtmdata = [];
      this.isExcelDwnLd_snglofcr = false;
      if(this.sh_in == 1)
      {
      this.snglusrchrgtmdata.push({
        'Date': "",
        'Officer Name': "",
        'Ward No': "",
        'MicroPocketNo': "",
        'House no': "",
        'Receipt no': "",
        'Name of Waste Generator': "",
        'Category': "",
        'UserFeeAmount': "",
        'Phone Number': "",
        'Address': "",
        'Challan No': "",
        'Challan Date': "",
      });
      this.snglusrchrgtmdata.push({

        'Date': "",
        'Officer Name': "",
        'Ward No': "",
        'MicroPocketNo': "",
        'House no': "",
        'Receipt no': "",
        'Name of Waste Generator': this.usr_name + " - User Charges Details report",
        'Category': "",
        'UserFeeAmount': "",
        'Phone Number': "",
        'Address': "",
        'Challan No': "",
        'Challan Date': "",
      });
      this.snglusrchrgtmdata.push({
        'Date': "Date",
        'Officer Name': "Officer Name",
        'Ward No': "Ward No",
        'MicroPocketNo': "MicroPocketNo",
        'House no': "House no",
        'Receipt no': "Receipt no",
        'Name of Waste Generator': "Name of Waste Generator",
        'Category': "Category",
        'UserFeeAmount': "UserFeeAmount",
        'Phone Number': "Phone Number",
        'Address': "Address",
        'Challan No': "Challan No",
        'Challan Date': "Challan Date",
      });
      for (let i = 0; i < this.rowData.length; i++) {
        this.snglusrchrgtmdata.push({
          'Date': this.rowData[i].fn_date,
          'Officer Name': this.rowData[i].usr_nm,
          'Ward No': this.rowData[i].wrd_nu,
          'MicroPocketNo': this.rowData[i].micro_pkt,
          'House no': this.rowData[i].hse_nu,
          'Receipt no': this.rowData[i].id,
          'Name of Waste Generator': this.rowData[i].nme,
          'Category': this.rowData[i].ctgry_nm,
          'UserFeeAmount': this.rowData[i].amnt,
          'Phone Number': this.rowData[i].mble_ph,
          'Address': this.rowData[i].address,
          'Challan No': this.rowData[i].chalana_no,
          'Challan Date': this.rowData[i].chalana_dt,
        });
      }
    }else{
    this.snglusrchrgtmdata.push({
        'Date': "",
        // 'Officer Name': "",
        'Ward No': "",
        // 'Offender': "",
        'Receipt no': "",
        'Name of Offender': "",
        'Category Name': "",
        'Fine Amount:"",                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           Amount': "",
        'Phone Number': "",
        'Location': "",
        'Challan No': "",
        'Challan Date': "",
      });
      this.snglusrchrgtmdata.push({

        'Date': "",
        'Ward No': "",
        'Receipt no': "",
        'Name of Offender': this.usr_name + " - Fines Details report",
        'Category Name': "",
        'Fine Amount': "",
        'Phone Number': "",
        'Location': "",
        'Challan No': "",
        'Challan Date': "",
      });
      this.snglusrchrgtmdata.push({
        'Date': "Date",
        'Ward No': "Ward No",
        'Receipt no': "Receipt no",
        'Name of Offender': "Name of Offender",
        'Category Name': "Category Name",
        'Fine Amount': "Fine Amount",
        'Phone Number': "Phone Number",
        'Location': "Location",
        'Challan No': "Challan No",
        'Challan Date': "Challan Date",
      });
      for (let i = 0; i < this.rowData.length; i++) {
        this.snglusrchrgtmdata.push({
          'Date': this.rowData[i].fn_date,
          'Ward No': this.rowData[i].wrd_nu,
          'Receipt no': this.rowData[i].id,
          'Name of Offender': this.rowData[i].nme,
          'Category Name': this.rowData[i].ctgry_nm,
          'Fine Amount': this.rowData[i].amnt,
          'Phone Number': this.rowData[i].mble_ph,
          'Location': this.rowData[i].lctn_txt,
          'Challan No': this.rowData[i].chalana_no,
          'Challan Date': this.rowData[i].chalana_dt,
        });
      }

    }

    });
    if(id==1)
    {
      this.columnDefs2 = [{ headerName: 'Sno', field: 'IncVl', width: 50, cellStyle: { textAlign: 'center' } },
      { headerName: 'Date', field: 'fn_date', width: 100, cellStyle: { textAlign: 'center' } },
      { headerName: 'Ward No', field: 'wrd_nu', width: 80, cellStyle: { textAlign: 'center' } },
      { headerName: 'Micro Pocket No', field: 'micro_pkt', width: 120, cellStyle: { textAlign: 'center' } },
      { headerName: 'House No', field: 'hse_nu', width: 150, cellStyle: { textAlign: 'center' } },
      { headerName: 'Officer Name', field: 'usr_nm', width: 200, cellStyle: { textAlign: 'center' } },
      { headerName: 'Receipt No', field: 'id', width: 100, cellStyle: { textAlign: 'center' } },
      { headerName: 'Name of Waste Generator', field: 'nme', width: 200, cellStyle: { textAlign: 'center' } },
      { headerName: 'Category', field: 'ctgry_nm', width: 100, cellStyle: { textAlign: 'center' } },
      { headerName: 'UserFeeAmount', field: 'amnt', width: 80, cellStyle: { textAlign: 'center' } },
      { headerName: 'Phone Number', field: 'mble_ph', width: 200, cellStyle: { textAlign: 'center' } },
      { headerName: 'Address', field: 'address', width: 120, cellStyle: { textAlign: 'center' } },
      { headerName: 'Challan No', field: 'chalana_no', width: 90, cellStyle: { textAlign: 'center' } },
      { headerName: 'Challan Date', field: 'chalana_dt', width: 100, cellStyle: { textAlign: 'center' } },];
    }else{
      this.columnDefs2 = [{ headerName: 'Sno', field: 'IncVl', width: 50, cellStyle: { textAlign: 'center' } },
      { headerName: 'Date', field: 'fn_date', width: 100, cellStyle: { textAlign: 'center' } },
      { headerName: 'Ward No', field: 'wrd_nu', width: 80, cellStyle: { textAlign: 'center' } },
      // { headerName: 'Micro Pocket No', field: 'micro_pkt', width: 120, cellStyle: { textAlign: 'center' } },
      // { headerName: 'House No', field: 'hse_nu', width: 150, cellStyle: { textAlign: 'center' } },
      { headerName: 'Officer Name', field: 'usr_nm', width: 200, cellStyle: { textAlign: 'center' } },
      { headerName: 'Receipt No', field: 'id', width: 100, cellStyle: { textAlign: 'center' } },
      { headerName: 'Name of Waste Generator', field: 'nme', width: 200, cellStyle: { textAlign: 'center' } },
      { headerName: 'Category', field: 'ctgry_nm', width: 100, cellStyle: { textAlign: 'center' } },
      { headerName: 'Fine Amount', field: 'amnt', width: 80, cellStyle: { textAlign: 'center' } },
      { headerName: 'Phone Number', field: 'mble_ph', width: 200, cellStyle: { textAlign: 'center' } },
      { headerName: 'Location', field: 'lctn_txt', width: 120, cellStyle: { textAlign: 'center' } },
      { headerName: 'Challan No', field: 'chalana_no', width: 90, cellStyle: { textAlign: 'center' } },
      { headerName: 'Challan Date', field: 'chalana_dt', width: 100, cellStyle: { textAlign: 'center' } },];
    }


  }
  SingleofcrdownloadExcel($event): void {
    if (this.snglusrchrgtmdata.length > 0) {
      this.isExcelDwnLd_snglofcr = $event;
           if(this.snglusrchrgtmdata[4].MicroPocketNo != null){
        this.excelDataHeaders_snglofcr = ['Date', 'Officer Name', 'Ward No', 'MicroPocketNo', 'House no', 'Receipt no', 'Name of Waste Generator', 'Category', 'UserFeeAmount', 'Phone Number', 'Address', 'Challan No', 'Challan Date'];
        this.excelFileNm_snglofcr = this.usr_name + ' - User collection report';
      }
      else{
        this.excelDataHeaders_snglofcr = ['Date', 'Ward No', 'Receipt no', 'Name of Offender', 'Category Name', 'Fine Amount', 'Phone Number', 'Location', 'Challan No', 'Challan Date'];
        this.excelFileNm_snglofcr = this.usr_name + ' - Fines collection report';
      }
      
    }
  }
}