import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Ulb } from '../ulb/ulb.model';
import { Workpackage } from './workpackage';
import { WorkPackage } from './workpackage.model';
import { ConfirmDialog } from '../geo-locations.component';
import { UserService } from 'app/providers/user/user.serivce';

@Component({
  selector: 'app-workpackage',
  templateUrl: './workpackage.component.html',
  styleUrls: ['./workpackage.component.scss']
})
export class WorkpackageComponent implements OnInit {

  displayedColumns: string[] = ['sno', 'wrk_pckge_nm', 'wrk_pckg_Status', 'srvce_prvdr_nm', 'srvce_prvdr_cntct_ph',  'actions'];
  dataSource = new MatTableDataSource<WorkPackage>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() ulbid;
  wpkgs: Workpackage[];
  ulbs;
  // ulb_id;
  wpForm: FormGroup;
  ulbLst: Ulb[] = [];
  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  userPerm: any = {};
  errMsg = false;
  shwLdr: boolean = false;
  noData;
  constructor(private geoService: GeoLocationsService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog
    , private userService: UserService) {
    // this.ulb_id = 20;
  }

  ngOnInit() {
    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.wpForm = new FormGroup({
      wrk_pckge_nm: new FormControl('', Validators.required),
      ulb_id: new FormControl('', Validators.required)
    });
    this.wpForm.get('ulb_id').setValue(this.ulbid);
    this.userPerm = this.userService.CURRENT_PERM;
    this.workpackagelst();
  }

  ngOnChanges(changes: any) {
    if (changes && changes.ulbid){ 
      this.workpackagelst(); 
    }
  }

  workpackagelst() {
    this.dataSource.data = [];
    this.shwLdr = true;
    this.geoService.workPackage(this.ulbid, (err, res) => {
      this.wpkgs = res.data;
      this.dataSource.data = res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  saveData() {
    this.errMsg = false;
    if (this.wpForm.status === 'INVALID') {
      return false;
    }
    this.updtDt = this.wpForm.value;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.wrk_pckge_nm === this.updtDt.wrk_pckge_nm) {
        flag = true;
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }
    this.geoService.addWorkPackage(this.updtDt, (err, res) => {
      if (res.status === 200) {
       const dialogRef = this.dialog.open(ConfirmDialog, {
         width: '500px',
         data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
       });
       this.workpackagelst();
   }
    });
  }
  updateData() {
    if (this.wpForm.status === 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt.wrk_pckge_nm = this.wpForm.value.wrk_pckge_nm;
    this.updtDt.ulb_id = this.wpForm.value.ulb_id;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.wrk_pckge_nm === this.updtDt.wrk_pckge_nm) {
        if (val.wrk_pckge_id !== this.updtDt.wrk_pckge_id) {
          flag = true;
        }
      }
    });
    if (flag) {
      this.errMsg = true;
      return;
    }
    this.geoService.updtWorkPackage(this.updtDt, (err, res) => { 
      if (res.status === 200) {
       const dialogRef = this.dialog.open(ConfirmDialog, {
         width: '500px',
         data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
       });
       this.workpackagelst();
   }
    });
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'Workpackage', cnfrmflag: true, successFlag: false,  operation:"Deleted",dtls: nm}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.workpackagelst();
    })
  }

  resetForm() {
    this.wpForm.reset();
  }
  opensideBar(key, updtData) {
    console.log(key)
    if (updtData) {
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.wpForm.get('wrk_pckge_nm').setValue(updtData.wrk_pckge_nm);
      this.wpForm.get('ulb_id').setValue(updtData.ulb_id);
    }
    else {
      this.wpForm.get('ulb_id').setValue(this.ulbid);
      this.wpForm.get('wrk_pckge_nm').setValue('');
      this.opLbl = 'Add';
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }
}
