import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { Ulbgates } from './long-term-absenties-report.model';
import { Router, NavigationExtras } from '@angular/router';
import { map, startWith, filter } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import {MatDatepickerModule, MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-long-term-absenties-report',
  templateUrl: './long-term-absenties-report.component.html',
  styleUrls: ['./long-term-absenties-report.component.scss']
})
export class LongTermAbsentiesReportComponent implements OnInit {
  columnDefs = [];
  rowData =  [];
  pagination:boolean = true;
  paginationPageSize = 10;
  fltrdata;
  isExcelDwnLd:boolean = false;
  excelDataHeaders;
  excelFileNm;
  ulbId;
  ulbNm;
  shwLdr = true;
  frmevents: string[] = [];
  toevents: string[] = [];
  curdate = new Date();
  curdateFrmt = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
  lst10DyDte = new Date().setDate(new Date().getDate() - 10);
  from_date = '';
  to_date = '';
  ulbGatesData = [];
  rpt_data_id;
  rpt_data_ky;
  rpt_nm;
  mcrptId; mcrptNm;
  noData;
  ulbsLst = [];
  ulb_id;
  wrk_pckge_id = 0;
  clsrt_id = 0;
  mcrpt_id;
  mcrpt_nm;
  mcrptGteLst = [];
  emp_id;
  fltrVsbleOptns;
  emplyAtndExclData = [];
  rptFrmDt;
  rptToDt;
  constructor(private misreportsService: MisreportsService, public datepipe: DatePipe, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.fltrVsbleOptns = {
      'isUlb':  true,
      'isMcrpt': true,
      'isGte': false,
      'isEmp': false,
      'isFrmDt': true,
      'isToDt': true
    };
    this.getUlbGtehistoricalGateStatus();
  }

  receivefltrData(data): void{
    this.ulbId = data.ulb_id;
    this.mcrptId = data.mcrpt_id;
    this.ulbNm = data.ulb_nm;
    this.mcrptNm = data.mcrpt_nm;
    this.from_date = data.from_date;
    this.to_date = data.to_date;
    this.getUlbGtehistoricalGateStatus();
  }

  getUlbGtehistoricalGateStatus(): void{
    this.emplyAtndExclData=[];
    this.rptFrmDt = '';
    this.rptToDt = '';
    if (this.ulbId === undefined){
      this.noData = true;
    }else{
      this.noData = false;
    }
    this.isExcelDwnLd = false;
    this.shwLdr = true;

    if (this.from_date === ''){
      this.from_date = this.datepipe.transform(this.lst10DyDte, 'yyyy-MM-dd');
      this.to_date = this.datepipe.transform(this.curdate, 'yyyy-MM-dd');
    }
    this.rptFrmDt = this.datepipe.transform(this.from_date, 'dd-MM-yyyy');
    this.rptToDt =  this.datepipe.transform(this.to_date, 'dd-MM-yyyy');
    const empsHstryData = {
      'from_date': this.from_date,
      'to_date': this.to_date,
      'ulb_id': this.ulbId,
      'mcrpt_id': this.mcrptId,
      'rpt_nm': 'longterm'
    };

    this.misreportsService.getdayWiseEmployeeLst(empsHstryData, (err, empStatus_res) => {
      this.rowData = empStatus_res.data;
      if (this.rowData.length){
        this.noData = false;
      }else{
        this.noData = true;
      }

      this.shwLdr = false;

      for (let i = 0; i < this.rowData.length; i++){
        this.emplyAtndExclData.push({'sno': this.rowData[i].sno,
                                    'emp_nm': this.rowData[i].emp_nm, 'emp_nu': this.rowData[i].emp_nu, 'mble_nu': this.rowData[i].mble_nu,
                                    'dsgn_nm': this.rowData[i].dsgn_nm, 's1_status': this.rowData[i].s1_status, 's2_status': this.rowData[i].s2_status,
                                    'ulb_nm': this.rowData[i].ulb_nm, 'clstr_nm': this.rowData[i].clstr_nm, 'mcrpt_nm': this.rowData[i].mcrpt_nm});
      }
      this.columnDefs = [
        {headerName: 'SNo.', field: 'sno', width: 80},
        {headerName: 'Employee Name', field: 'emp_nm', sortable: true, filter: true},
        {headerName: 'Employee Number', field: 'emp_nu', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 150},
        {headerName: 'Mobile Number', field: 'mble_nu', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 150},
        {headerName: 'Designation', field: 'dsgn_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 150},
        {headerName: 'Shif-1 Time', field: 's1_status', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 150},
        {headerName: 'Shif-2 Time', field: 's2_status', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 150},
        {headerName: 'ULB', field: 'ulb_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 100},
        {headerName: 'Cluster', field: 'clstr_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 100},
        {headerName: 'Micropocket', field: 'mcrpt_nm', sortable: true, filter: true, cellStyle: {textAlign: 'center'}, width: 180},     
    ];
    });
  }

  downloadExcel($event): void{
    if (this.rowData.length > 0){
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['SNo', 'Employee Name', 'Employee Number', 'Mobile Number' , 'Designation', 
       'Shif-1 Time', 'Shif-2 Time', 'ULB', 'Cluster', 'Micropocket'];
      this.excelFileNm = 'employee_attendance_summary_rpt';
    }
  }

}
