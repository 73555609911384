import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { ServiceProvider } from './service-provider.model';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'app/providers/user/user.serivce';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss']
})
export class ServiceProviderComponent implements OnInit {

  displayedColumns: string[] = ['sno', 'srvce_prvdr_nm', 'srvce_prvdr_cntct_ph', 'wrk_pckge_nm', 'actions'];
  dataSource = new MatTableDataSource<ServiceProvider>();
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  SPForm: FormGroup;

  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  userPerm: any = {};
  errMsg = false;
  shwLdr: boolean;
  noData;
  constructor(private geoService: GeoLocationsService, private _fuseSidebarService: FuseSidebarService, public dialog: MatDialog, private userService: UserService) {
  }

  ngOnInit() {
    this.SPForm = new FormGroup({
      srvce_prvdr_nm: new FormControl('', Validators.required),
      srvce_prvdr_cntct_ph: new FormControl('', Validators.required),
      srvce_prvdr_cntct_nm: new FormControl('', Validators.required)
    });
    this.userPerm = this.userService.CURRENT_PERM;
    this.serviceProvider();
  }
  serviceProvider() {
    this.dataSource.data = [];
    this.shwLdr = true;
    this.geoService.srvcProviderLst((err, srvcPrvdrs_res) => {
      this.dataSource.data = srvcPrvdrs_res.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }
  saveData() {
    if (this.SPForm.status === 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt = this.SPForm.value;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.srvce_prvdr_nm === this.updtDt.srvce_prvdr_nm){
        flag = true;
      }
    });
    if (flag){
      this.errMsg = true;
      return;
    }
    this.geoService.addServiceProvider(this.updtDt, (err, res) => {
      if (res.status === 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
        });
        this.serviceProvider();
    }
    });
  }
  updateData() {
    if (this.SPForm.status === 'INVALID') {
      return false;
    }
    this.errMsg = false;
    this.updtDt.srvce_prvdr_nm = this.SPForm.value.srvce_prvdr_nm;
    this.updtDt.srvce_prvdr_cntct_ph = this.SPForm.value.srvce_prvdr_cntct_ph;
    this.updtDt.srvce_prvdr_cntct_nm = this.SPForm.value.srvce_prvdr_cntct_nm;
    let flag = false;
    this.dataSource.data.forEach(val => {
      if (val.srvce_prvdr_nm === this.updtDt.srvce_prvdr_nm){
        if (val.srvce_prvdr_id !== this.updtDt.srvce_prvdr_id){
          flag = true;
        }
      }
    });
    if (flag){
      this.errMsg = true;
      return;
    }
    this.geoService.updtServiceProvider(this.updtDt, (err, res) => {
      if (res.status === 200) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
        });
        this.serviceProvider();
    }
    });
  }
  deleteData(id, nm) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '500px',
      data: { id: id, entityname: 'Street', cnfrmflag: true, successFlag: false,  operation: 'Deleted', dtls: nm}
    });
    dialogRef.afterClosed().subscribe(() => {
      this.serviceProvider();
    });
  }

  resetForm() {
    this.SPForm.reset();
  }
  opensideBar(key, updtData, event: any) {
    if (updtData) {
      this.updtDt = updtData;
      this.opLbl = 'Edit';
      this.SPForm.get('srvce_prvdr_nm').setValue(updtData.srvce_prvdr_nm);
      this.SPForm.get('srvce_prvdr_cntct_ph').setValue(updtData.srvce_prvdr_cntct_ph);
      this.SPForm.get('srvce_prvdr_cntct_nm').setValue(updtData.srvce_prvdr_cntct_nm);
    }
    else {
      this.SPForm.get('srvce_prvdr_nm').setValue('');
      this.SPForm.get('srvce_prvdr_cntct_ph').setValue('');
      this.SPForm.get('srvce_prvdr_cntct_nm').setValue('');
      this.opLbl = 'Add';
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
