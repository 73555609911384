import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { GeoLocationsService } from '../geo-locations.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Ward } from './ward.model';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialog } from 'app/main/geo-locations/geo-locations.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ulb } from '../ulb/ulb.model';
import { Workpackage } from '../workpackage/workpackage';
import { UserService } from 'app/providers/user/user.serivce';


@Component({
  selector: 'app-wards',
  templateUrl: './wards.component.html',
  styleUrls: ['./wards.component.scss']
})
export class WardsComponent implements OnInit {
    public get fuseSidebarService(): FuseSidebarService {
        return this._fuseSidebarService;
    }
    public set fuseSidebarService(value: FuseSidebarService) {
        this._fuseSidebarService = value;
    }

  displayedColumns: string[] = ['sno', 'wrd_nm','ulb_nm','mcrpt_nm'];
  dataSource = new MatTableDataSource<Ward>();

  @Input() ulbid: number;
  @Input() selectedTabIndex: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  mpForm: FormGroup;


  ulb_id;
  wrk_pckge_id:number = 0;
  clsrt_id:number  = 0;
  ulbLst: Ulb[] = [];
  wrkpckgsLst: Workpackage[];
  clstrList = null;

  opLbl = '';
  updtDt: any = {};
  ConfirmDialog: ConfirmDialog;
  userPerm: any = {};
  errMsg = false;
  shwLdr: boolean = false;
  noData;


    /**
      * @param {FuseSidebarService} _fuseSidebarService
    */

  constructor(private geoService: GeoLocationsService,private _fuseSidebarService: FuseSidebarService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes && changes.ulbid){
      this.geoLocationWard();
    }
  }

  geoLocationWard() {
    this.dataSource.data = [];
    this.shwLdr = true;
    this.geoService.getWards(this.ulbid, this.wrk_pckge_id, this.clsrt_id, (err, wards_res) => {
      this.dataSource.data = wards_res.data;
      console.log(this.dataSource.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }

  // wardsLst(id) {
  //   this.locationService.wardsLst(id, (err, wards_res) => {
  //     this.dataSource = wards_res.data;
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //   })
  // }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  opensideBar(key) {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }


}
