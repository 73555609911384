import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FinesService } from 'app/main/fines.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { map, startWith } from 'rxjs/operators';
import { Ulb } from 'app/main/geo-locations/ulb/ulb.model';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-fines-dashbord',
  templateUrl: './fines-dashbord.component.html',
  styleUrls: ['./fines-dashbord.component.scss']
})
export class FinesDashbordComponent implements OnInit {
  multiYears = ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'];
  monthNames = [{ nm: 'January', id: '01' }, { nm: 'February', id: '02' }, { nm: 'March', id: '03' }, { nm: 'April', id: '04' },
  { nm: 'May', id: '05' }, { nm: 'June', id: '06' }, { nm: 'July', id: '07' }, { nm: 'August', id: '08' }, { nm: 'September', id: '09' },
  { nm: 'October', id: '10' }, { nm: 'November', id: '11' }, { nm: 'December', id: '12' }];
  numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  fltrForm: any;
  date = new Date();
  mnthId = this.datepipe.transform(this.date, 'MM');
  yrId = this.datepipe.transform(this.date, 'yyyy');
  day = this.datepipe.transform(this.date, 'dd');
  finecollect: any = [];
  columnDefs: any = [];
  rowData: any = [];
  columnDefs1: any = [];
  rowData1: any = [];
  PChrtdata: any;
  headerForm: FormGroup;
  ulbLst: any;
  ulbid: any;
  ulbname: any;
  ulbtopFltrOptions: Observable<Ulb[]>;
  overallCounts: any;
  // finesdtainMnth: any[];
  index: any;
  tenarray: any[];
  fivearray: any[];
  totalfines=0;
  totalamnt=0;
  mnthNM: string;
  totalsamnt: any[];
  totaldata: { lbl: string; tot: any; }[];
  constructor(private FinesService: FinesService, public datepipe: DatePipe, private router: Router) { }

  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    // this.geoService.getUlbs((err, ulbLst) => {
    //   this.ulbLst = ulbLst.data;
    // })
    this.ulbid = this.ulbLst[0].ulb_id;
    this.headerForm.get('selectedUlb').setValue({ ulb_id: this.ulbLst[0].ulb_id, ulb_nm: this.ulbLst[0].ulb_nm });

    this.fltrForm = new FormGroup({
      selectedMonth: new FormControl('', Validators.required),
      selectedYear: new FormControl('', Validators.required)
    });
    this.fltrForm.get('selectedMonth').setValue(this.mnthId);
    this.fltrForm.get('selectedYear').setValue(this.yrId);
    // console.log(this.mnthId);
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
    this.getcardsdata();
    this.getLineChartDta();
    this.TopFiveTbldta();
    this.Toptenoffcrsdta();
    this.getmnthNm();

  }

  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  onUlbChange(evt) {
    // this.ulbid = ulb_id;
    this.ulbid = evt.option.value.ulb_id;
    console.log(this.ulbid);
    this.getcardsdata();
    this.TopFiveTbldta();
    this.Toptenoffcrsdta();
    this.getmnthNm();

  }
  Toptenoffcrsdta() {
    this.tenarray = [];
    this.FinesService.fnsDBToptenoffcrsdta((err, res) => {
      var index = 1;
      for (var i = 0; i < 10; i++) {
        this.tenarray.push(res.data[i]);
        this.tenarray[i].IncVl = index++;
      }
      this.rowData1 = this.tenarray;
      this.columnDefs1 = [{ headerName: 'Sno', field: 'IncVl', width: 50, cellStyle: { textAlign: 'center' } },
      { headerName: 'Officer Name', field: 'usr_nm', width: 250, cellStyle: { textAlign: 'center' } },
      { headerName: 'Mobile No', field: 'mobile_nu', width: 200, cellStyle: { textAlign: 'center' } },
      { headerName: 'Collected Amount ₹', field: 'fns_ttl', width: 135, cellStyle: { textAlign: 'center' } },
      { headerName: 'Treasury Amount ₹', field: 'fns_depst_ttl', width: 135, cellStyle: { textAlign: 'center' } },
      { headerName: 'Pending Amount ₹', field: 'fns_pndg_ttl', width: 150, cellStyle: { textAlign: 'center' } },];
    });
  }
  onMnthChange() {
    this.getLineChartDta();
    // this.fineDBTopFiveTbldta();
    this.getmnthNm();
  }
  getmnthNm() {
    for (let z = 0; z < this.monthNames.length; z++) {
      if (this.mnthId === this.monthNames[z].id) {
        this.mnthNM = this.monthNames[z].nm
      }
    }
  }
  getcardsdata() {
    this.FinesService.finesDBcarddata((err, res) => {
      this.finecollect = res.data;
      // console.log(this.finecollect);

      this.overallCounts = [
        {
          lbl: 'TOTAL OFFICERS',
          cnt: this.finecollect[0].ct,
          img: 'http://wetrackon.com/client/public/assets/images/employee2.png',
        },
        {
          lbl: 'TOTAL COLLECTED AMOUNT',
          cnt: this.finecollect[0].fns_ttl + '₹',
          img: 'http://wetrackon.com/client/public/assets/images/dueamount.png',

        },
        {
          lbl: 'TOTAL TREASURY',
          cnt: this.finecollect[0].fns_depst_ttl + '₹',
          img: 'http://wetrackon.com/client/public/assets/images/clipboard.png',
        },
        {
          lbl: 'PENDING',
          cnt: this.finecollect[0].fns_pndg_ttl + '₹',
          img: 'http://wetrackon.com/client/public/assets/images/change.png',
        }
      ];
      //pie chart         
      var pchart = am4core.create("Pchartdiv", am4charts.PieChart);
      // Add data
      pchart.data = [{
        "country": "Treasury Amount",
        "litres": this.finecollect[0].fns_depst_ttl
      }, {
        "country": "Pending amount",
        "litres": this.finecollect[0].fns_pndg_ttl
      },];
      // console.log(pchart.data);
      // Add and configure Series
      var pieSeries = pchart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "country";

      // Let's cut a hole in our Pie chart the size of 40% the radius
      pchart.innerRadius = am4core.percent(40);

      // Put a thick white border around each Slice
      pieSeries.slices.template.stroke = am4core.color("#4a2abb");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      // Add a legend
      pchart.legend = new am4charts.Legend();

      this.PChrtdata = [{
        nm: 'Total Fines',
        tots: this.finecollect[0].ttl_fns,
      },
      {
        nm: 'Total Plastic',
        tots: this.finecollect[0].ttl_plstc,
      },
      {
        nm: 'Total Other Category',
        tots: this.finecollect[0].ttl_other,
      },]
    });
  }
  TopFiveTbldta() {
    this.fivearray = [];
    var index = 1;
    this.FinesService.fineDBTopFiveTbldta(this.yrId, this.mnthId, (err, res) => {
      if (res.data.length < 5) {
        for (var i = 0; i < res.data.length; i++) {
          this.fivearray.push(res.data[i]);
          this.fivearray[i].IncVl = index++;
        }
      }
      else {
        for (var i = 0; i < 5; i++) {
          this.fivearray.push(res.data[i]);
          this.fivearray[i].IncVl = index++;
        }
      }
      this.rowData = this.fivearray;
      this.columnDefs = [{ headerName: 'Sno', field: 'IncVl', width: 50, cellStyle: { textAlign: 'center' } },
      { headerName: 'Ward No', field: 'wrd_nu', width: 80, cellStyle: { textAlign: 'center' } },
      { headerName: 'No.of fines', field: 'ttl_fns', width: 200, cellStyle: { textAlign: 'center' } },
      { headerName: 'Amount ₹', field: 'amnt', width: 120, cellStyle: { textAlign: 'center' } }];
    });
  }
  getLineChartDta() {
    this.FinesService.fineDBLinegraphDta(this.yrId, this.mnthId, (err, res) => {
      chart.data = res.data;
      this.totalsamnt=chart.data
      for(var i=0;i< this.totalsamnt.length;i++){
        this.totalfines+=this.totalsamnt[i].ttl_fns;
        this.totalamnt +=this.totalsamnt[i].other_clctd_amnt;
        }
        this.totaldata = [{ lbl: 'Total User Fee in'+'('+this.mnthNM+')', tot: this.totalfines},
                          { lbl: 'Total Amount in '+'('+this.mnthNM+')', tot: this.totalamnt+'  ₹' }];
    });

    // Use themes
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create('chartdiv', am4charts.XYChart);
    // Create axes

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'dt';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    //categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.title.text = 'Dates';

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Collected amounts';
    valueAxis.calculateTotals = true;

    valueAxis.renderer.labels.template.adapter.add('text', function (text) {
      return text;
    });

    // Create series
    // plastic
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'plstc_clctd_amnt';
    // series.dataFields.valueYShow = 'total';
    series.dataFields.categoryX = 'dt';
    series.name = 'Plastic';
    series.strokeWidth = 3;
    series.tensionX = 0.8;
    series.bullets.push(new am4charts.CircleBullet());
    series.tooltipText = 'day{categoryX}:[bold]{valueY}[/]'

    //others
    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = 'other_clctd_amnt';
    series2.dataFields.categoryX = 'dt';
    series2.name = 'Other categories';
    series2.strokeWidth = 3;
    series2.tensionX = 0.8;
    series2.bullets.push(new am4charts.CircleBullet());
    //series2.stroke = am4core.color("#b0de09");
    //series2.fill = am4core.color("#5f56bf");
    series2.tooltipText = 'day{categoryX}:[bold]{valueY}[/]';

    // Add a legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }


}
