import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Http } from '@angular/http';


@Injectable()
export class VehicleService {

  constructor(public httpCtrl: Http, private http: HttpClient) { }

  // // get Streets for location in Master Data 
  public scndryVhcleLst(id, cb) {
    this.http.get('/transport/scndryvehicles/' + id).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 

  public clsrDtls(id, cb) {
    this.http.get('/rtms/scheudles/clsuters/' + id).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 
  public clsrWseRtDtls(id, cb) {
    this.http.get('/rtms/scheudles/routes/' + id).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 
  public PcrRslts(id, cb) {
    this.http.get('/trpfncewsepcrRnds/' + id).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 

  public trnsfptsDtls(id, cb) {
    this.http.get('/rtms/schedule/transferpoints/' + id).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 

  public getAllMstrsData(id, cb) {
    this.http.get('/rtms/schedule/dropdown/master/' + id).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 
  public getTripDtls(id, cb) {
    this.http.get('/rtms/schedule/trips/fnces/' + id).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 
  public addTrips(data, cb) {
    this.http.post('/rtms/schedule/trpRouteDtl', data).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 
  public addFence(data, cb) {
    this.http.post('/rtms/schedule/fnce', data).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 
  public getpcrLst(streets,tp_id, cb) {
    this.http.post('/TpWseAllPcrRounds/'+tp_id, streets).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 
  public addpcr(data, cb) {
    this.http.post('/AddpcrRnd/', data).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  } 
  public updtpcr(data, cb) {
    this.http.post('/updatepcrRnd/', data).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  }
  public dltpcr(data, cb) {
    this.http.post('/deletepcrRnd/', data).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  }
  public dltsndry(data, cb) {
    this.http.post('/deletescndry/', data).subscribe(data => {
        cb(false, data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        } else {
          console.log("Backend returned code:" + err.status + ", error message: " + err.error);
        }
        cb(true, null);
      })
  }
}
