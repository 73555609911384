import { Component, OnInit, ViewChild } from '@angular/core';
import { GeoLocationsService } from '../geo-locations/geo-locations.service';
import { Ulb } from '../geo-locations/ulb/ulb.model';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { EmployeesService } from 'app/main/employees/employees.service';
import { Inject } from '@angular/core';
import { Input } from '@angular/core';
import { HardwareService } from '../hardware/hardware.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-geo-locations',
  templateUrl: './geo-locations.component.html',
  styleUrls: ['./geo-locations.component.scss']
})
export class GeoLocationsComponent implements OnInit {

  idtxt: String = 'wrkpkg';
  ulbLst: Ulb[] = [];
  headerForm: FormGroup;
  // ulbid: number;
  selectedTabIndex: number = 0;
  @ViewChild('tabGroup') tabGroup;

  wrkpkgtab: boolean = true;
  clstrtab: boolean = false;
  wardstab: boolean = false;
  mpstab: boolean = false;
  streetstab: boolean = false;
  serviceProviderTab: boolean = false;
  ulbtopFltrOptions: Observable<Ulb[]>;
  ulb;
  empGrptab: boolean = false;
  constructor(private geoService: GeoLocationsService, private locationsService: GeoLocationsService) {
    // this.ulbid = 20;


  }

  ngOnInit() {
    this.headerForm = new FormGroup({
      selectedUlb: new FormControl('', Validators.required)
    });
    // this.geoService.getUlbs((err, ulbLst) => {
    // this.ulbLst = ulbLst.data;
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.ulb = {
      ulb_id: this.ulbLst[0].ulb_id,
      ulb_nm: this.ulbLst[0].ulb_nm
    };
    const toSelect = this.ulbLst.find(c => c.ulb_id == this.ulb.ulb_id);
    this.headerForm.get("selectedUlb").setValue(toSelect);
    // });
    this.wrkpkgtab = true; this.clstrtab = false; this.wardstab = false; this.mpstab = false; this.streetstab = false; this.serviceProviderTab = false;this.empGrptab=false;
    
    this.ulbtopFltrOptions = this.headerForm.get('selectedUlb').valueChanges
      .pipe(
        startWith(''),
        map(value => this._ulbfilter(value))
      );
  }
  autocompleteudisplayFn(ulb?: Ulb): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  private _ulbfilter(value): Ulb[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  
  onUlbChange(evt) {
    this.ulb = evt.option.value;
  }

  public tabChanged(): void {
    if (this.tabGroup.selectedIndex == 0) {
      this.wrkpkgtab = true; this.clstrtab = false; this.wardstab = false; this.mpstab = false; this.streetstab = false; this.serviceProviderTab = false;this.empGrptab=false;
    } else if (this.tabGroup.selectedIndex == 1) {
      this.wrkpkgtab = false; this.clstrtab = true; this.wardstab = false; this.mpstab = false; this.streetstab = false; this.serviceProviderTab = false;this.empGrptab=false;
    } else if (this.tabGroup.selectedIndex == 2) {
      this.wrkpkgtab = false; this.clstrtab = false; this.wardstab = true; this.mpstab = false; this.streetstab = false; this.serviceProviderTab = false;this.empGrptab=false;
    } else if (this.tabGroup.selectedIndex == 3) {
      this.wrkpkgtab = false; this.clstrtab = false; this.wardstab = false; this.mpstab = true; this.streetstab = false; this.serviceProviderTab = false;this.empGrptab=false;
    } else if (this.tabGroup.selectedIndex == 4) {
      this.wrkpkgtab = false; this.clstrtab = false; this.wardstab = false; this.mpstab = false; this.streetstab = true; this.serviceProviderTab = false;this.empGrptab=false;
    } else if (this.tabGroup.selectedIndex == 5) {
      this.wrkpkgtab = false; this.clstrtab = false; this.wardstab = false; this.mpstab = false; this.streetstab = false; this.serviceProviderTab = true;this.empGrptab=false;
    }else if (this.tabGroup.selectedIndex == 6) {
      this.wrkpkgtab = false; this.clstrtab = false; this.wardstab = false; this.mpstab = false; this.streetstab = false; this.serviceProviderTab = false;this.empGrptab=true;
    }
  }

}


@Component({
  selector: 'confirm-dialog',
  templateUrl: '../../main/confirm-dialog.html'
})
export class ConfirmDialog {

  @Input() flag: boolean = false;

  constructor(
    private geoService: GeoLocationsService,
    public dialogRef: MatDialogRef<ConfirmDialog>,
    public hardwareService: HardwareService,
    // public EmployeesService: EmployeesService,
    @Inject(MAT_DIALOG_DATA) public data: { id: '', entityname: '', cnfrmflag: false, successFlag: false, operation: '', dtls: '' }) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  onYesClick(id, entityname, cnfrmflag, successFlag, operation, dtls): void {
    console.log("YES , Delete:", id, entityname, cnfrmflag, successFlag, operation, dtls);

    this.flag = false;

    switch (entityname) {
      case 'Workpackage': {
        this.geoService.dtlWorkPackage(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'cluster': {
        this.geoService.dtlCluster(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'Micropocket': {
        this.geoService.dltMicropocket(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'Ward': {
        this.geoService.deleteWard(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'Street': {
        this.geoService.dltStreet(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'ServiceProvider': {
        this.geoService.dltServiceProvider(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'EmployeeGroup': {
        this.geoService.dltemployeeGroup(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'Scanner': {
        this.hardwareService.dltScanner(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'WeighingMachine': {
        this.hardwareService.dltWeighingMachine(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'Tracker': {
        this.hardwareService.dltTracker(id, (err, res) => {
          if(res.status == 200){
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
      case 'Gate': { 
        this.geoService.deleteGate(id, (err, res) => {
          if(res.status == 200){
            this.data.cnfrmflag = false;
          }
        });
        break; 
      } 
      case 'Tag': { 
        this.geoService.removeTagFrmGate(id, (err, res) => {
          if(res.status == 200){
            this.data.cnfrmflag = false;
          }
        });
        break; 
      } 
      case 'Vehicle': {
        this.geoService.dltVehicle(id, (err, res) => {
          if (res.status == 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }


    }

  }

  getColor(operation) {
    (2)
    switch (operation) {
      case 'Created':
        return 'green';
      case 'Assigned':
        return 'green';
      case 'Updated':
        return '#ffc107';
      case 'Disabled':
        return '#ffc107';
      case 'Removed':
        return '#red';
      case 'Deleted':
        return 'red';
        case 'Tracker assigned to new vehicle':
          return '#efa010';
          case 'exist':
            return 'red';
    }
  }
}
