import { Component, OnInit } from '@angular/core';
import { MisreportsService } from 'app/main/misreports/misreports.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vehicles-notcoveredtrips',
  templateUrl: './vehicles-notcoveredtrips.component.html',
  styleUrls: ['./vehicles-notcoveredtrips.component.scss']
})
export class VehiclesNotcoveredtripsComponent implements OnInit {
  columnDefs = [];
  data;
  rowData = [];
  pagination: boolean = true;
  paginationPageSize = 10;

  noData: boolean;
  ulbId: number;
  from_date: number;
  to_date: number;
  fltrVsbleOptns;
  shwLdr: boolean;
  isExcelDwnLd: any;
  excelFileNm: string;
  vehicntcvdExclData = [];
  vehiclenotcvrtr: any;
  excelDataHeaders: string[];
  frntEndFrmDt;
  frntEndToDt;

  constructor(private misreportsService: MisreportsService, public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.fltrVsbleOptns = {
      'isUlb': true,
      'isFrmDt': true,
      'isToDt': true
    };
    this.getUlbVehNoTripsStatus();
  }

  receivefltrData(data): void{
    this.ulbId = data.ulb_id;
    this.from_date = data.from_date;
    this.to_date = data.to_date;
    this.getUlbVehNoTripsStatus();
  }

  getUlbVehNoTripsStatus(): void {
    this.vehicntcvdExclData=[];
    this.isExcelDwnLd = false;
    if (this.ulbId === undefined){
      this.noData = true;
      this.isExcelDwnLd = false;
    }else{
      this.noData = false;
      this.frntEndFrmDt = this.datepipe.transform(this.from_date, 'dd-MM-yyyy');
      this.frntEndToDt = this.datepipe.transform(this.to_date, 'dd-MM-yyyy');
      this.data = {
        'ulb_id': this.ulbId,
        'start_dt': this.from_date,
        'end_dt': this.to_date
      };
      this.misreportsService.vehiclenotcvrtr(this.data, (err, data_res) => {
        this.rowData = data_res.data;
        this.shwLdr = false;
        if (this.rowData.length) {
          this.noData = false;
        } else { 
          this.noData = true; 
        }
        for (let i = 0; i < this.rowData.length; i++) {
          this.vehicntcvdExclData.push({
            'sno': this.rowData[i].sno,
            'dt': this.rowData[i].dt,
            'ulb_nm': this.rowData[i].ulb_nm, 'asrt_nm': this.rowData[i].asrt_nm, 'tot_trp_cnt': this.rowData[i].tot_trp_cnt, 
            'not_cvrd_trps': this.rowData[i].not_cvrd_trps
          });
        }
      
        this.columnDefs = [
          { headerName: 'SNo.', field: 'sno', width: 50, cellStyle: { textAlign: 'center' } },
          { headerName: 'Date', field: 'dt', sortable: true, filter: true, cellStyle: { textAlign: 'center' }, width: 200 },
          { headerName: 'ULB ', field: 'ulb_nm', sortable: true, filter: true, cellStyle: { textAlign: 'center' }, width: 200 },
          { headerName: 'Vehicle Number', field: 'asrt_nm', sortable: true, filter: true, cellStyle: { textAlign: 'center' }, width: 200 },
          { headerName: 'Total Trips', field: 'tot_trp_cnt', sortable: true, filter: true, cellStyle: { textAlign: 'center' }, width: 200 },
          { headerName: 'Vehicle Not Covered', field: 'not_cvrd_trps', sortable: true, filter: true, cellStyle: { textAlign: 'center' }, width: 200 },
        ];
      });
    }
  }
  downloadExcel($event): void {
    if (this.rowData.length > 0) {
      this.isExcelDwnLd = $event;
      this.excelDataHeaders = ['SNo', 'Date', 'ULB ', 'Vehicle Number', 'Total Trips', 'Vehicle Not Covered'];
      this.excelFileNm = 'vehiclenotcvrtr_rpt';
    }
  }
}
