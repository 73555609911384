import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HardwareCertificationService } from '../hardware-certification.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { WeighingMachines } from './weighing-machines.model'

@Component({
  selector: 'app-weighing-machines',
  templateUrl: './weighing-machines.component.html',
  styleUrls: ['./weighing-machines.component.scss']
})
export class WeighingMachinesComponent implements OnInit {
  @Input() ulb;
  displayedColumns: string[] = ['sno', 'wghng_mchne_nm', 'last_weights_ts'];
  dataSource = new MatTableDataSource<WeighingMachines>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  shwLdr: boolean = false;
  data: any;
  excelData: any[];
  isExcelDwnLd: any;
  excelDataHeaders: string[];
  excelFileNm: string;
  noData;
  constructor(private hwCertification: HardwareCertificationService, ) { }

  ngOnInit() {
    this.getwmsWrkngStatus()
  }
  getwmsWrkngStatus() {
    this.shwLdr = true;
    this.hwCertification.wmsWrkngStatus(this.ulb.ulb_id, (err, res) => {
      this.dataSource.filter = '';
      this.dataSource.data = [];
      this.data = res.data;
      this.dataSource.data = res.data;
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort;
      this.shwLdr = false;
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length === 0){
      this.noData = true;
    }else{
      this.noData = false;
    }
  }
  ngOnChanges(changes: any) {
    if (changes && changes.ulb) {
      this.getwmsWrkngStatus();
    }
  }
  downloadExcel($event): void {
    this.excelData = [];
    if (this.data.length > 0) {
      for (let i of this.data) {
        this.excelData.push({
          'sno': i.sno ? i.sno : '', 'Device ID': i.wghng_mchne_nm ? i.wghng_mchne_nm : '',
          'Last Weighment On':i.last_weights_ts ? i.last_weights_ts:''});
      }
        this.isExcelDwnLd = $event;
        this.excelDataHeaders = ['sno', ' Device ID', 'Last Weighment On'];
        this.excelFileNm = 'weighing_machines_status_'+this.ulb.ulb_nm;
    }
  }
}
