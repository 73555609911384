import { Component, OnInit, ViewChild, Inject, ɵConsole } from '@angular/core';
import { GeoLocationsService } from 'app/main/geo-locations/geo-locations.service';
import { VehicleService } from '../vehicles.service';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UserService } from 'app/providers/user/user.serivce';
import { PrimaryVehicle } from './primary.model';
import { MatSort, MatPaginator, MatTableDataSource, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-primary',
  templateUrl: './primary.component.html',
  styleUrls: ['./primary.component.scss']
})
export class PrimaryComponent implements OnInit {
  // public dialogRef: MatDialogRef<PrimaryConfirmDialog>
  
  dataSource = new MatTableDataSource();
  empOpLbl: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tabGroup') tabGroup;
  private dialogRef: MatDialogRef<string>;
  ulbLst: PrimaryVehicle[] = [];
  micro_pkts_res: any;
  selectedulb = new FormControl();
  selectedtp = new FormControl();
  userPerm: { id: any; c_in: number; r_in: number; u_in: number; d_in: number; };
  ulbFltrOptns: Observable<PrimaryVehicle[]>;
  toptpFltrOptns: Observable<PrimaryVehicle[]>;
  clstrFltrOptns: Observable<PrimaryVehicle[]>;
  mpFltrOptns: Observable<PrimaryVehicle[]>;
  sstrtFltrOptns: Observable<PrimaryVehicle[]>;
  estrtFltrOptns: Observable<PrimaryVehicle[]>;
  tnt_id: number;
  ulb_id: number;
  trnsfrPntslst:PrimaryVehicle[] = [];
  fnce_id: number;
  fnce_nm: string;
  addpcrform: FormGroup;
  micropockets: PrimaryVehicle[] = [];
  streets:  PrimaryVehicle[] = [];
  mcrpt_id: number;
  mcrpt_nm: string;
  dsbladdNwTrpBtn: boolean = true;
  dsblSvRtBtn : boolean = false;
  shwpcr: boolean = false;
  shwLdr: boolean;
  pcrlst: any = [];
  shwErrMsg: boolean;
  clstrs: any;
  clsrt_id: number;
  addFncForm: FormGroup;
  fncegrpFltrOptns: Observable<PrimaryVehicle[]>;
  tpGrpLst: any;
  dltdta: any;
  constructor(private _fuseSidebarService: FuseSidebarService,
     private userService: UserService,
      private vehicleService: VehicleService, 
     private geoService: GeoLocationsService,public dialog: MatDialog,
     public datepipe: DatePipe) { }
 
  ngOnInit() {
    this.ulbLst = JSON.parse(localStorage.getItem('ulbs'));
    this.selectedulb.setValue("");
    this.userPerm = this.userService.CURRENT_PERM;
    this.addpcrform = new FormGroup({
      mcrpt: new FormControl(new Date(), Validators.required),
      strt_strt: new FormControl('', Validators.required),
      end_strt: new FormControl('', Validators.required),
      tp_arvl_ts: new FormControl('', Validators.required),
      mrdn: new FormControl('', Validators.required),
      psh_crt_id: new FormControl('')
    });
    this.addFncForm = new FormGroup({
      fnce_nm: new FormControl('', Validators.required),
      lat: new FormControl('', Validators.required),
      lng: new FormControl('', Validators.required),
      fnce_grp: new FormControl('', Validators.required),
      clsrt_id: new FormControl('', Validators.required),
    });
    this.ulbFltrOptns = this.selectedulb.valueChanges
      .pipe(
        startWith(''),
        map(value => this._topUlbfilter(value))
      );
    this.clstrFltrOptns = this.addFncForm.get('clsrt_id').valueChanges
      .pipe(
        startWith(''),
        map(value => this._clsrtfilter(value))
      );
    this.toptpFltrOptns = this.selectedtp.valueChanges
      .pipe(
        startWith(''),
        map(value => this._toptpfilter(value))
      );
    this.mpFltrOptns = this.addpcrform.get('mcrpt').valueChanges
      .pipe(
        startWith(''),
        map(value => this._mpfilter(value))
      );
    this.sstrtFltrOptns = this.addpcrform.get('strt_strt').valueChanges
      .pipe(
        startWith(''),
        map(value => this._sstrtfilter(value))
      );
    this.estrtFltrOptns = this.addpcrform.get('end_strt').valueChanges
      .pipe(
        startWith(''),
        map(value => this._testrtfilter(value))
      );
      this.fncegrpFltrOptns = this.addFncForm.get('fnce_grp').valueChanges
        .pipe(
        startWith(''),
        map(value => this._fncegrpFncfilter(value))
        );
  }

  autocompleteUDisplayFn(ulb?: PrimaryVehicle): string | undefined {
    return ulb ? ulb.ulb_nm : undefined;
  }
  autocompleteCDisplayFn(clstr?: PrimaryVehicle): string | undefined {
    return clstr ? clstr.clstr_nm : undefined;
  }
  autocompletetpDisplayFn(tp?: PrimaryVehicle): string | undefined {
    return tp ? tp.fnce_nm : undefined;
  }
  autocompletemDisplayFn(mp?: PrimaryVehicle): string | undefined {
    return mp ? mp.mcrpt_nm : undefined;
  }
  autocompletesDisplayFn(strt?: PrimaryVehicle): string | undefined {
    return strt ? strt.strt_nm : undefined;
  }
  fnceGrpDisplayFn(fnce?: PrimaryVehicle): string | undefined {
    return fnce ? fnce.fnce_grp_nm : undefined;
  }
  ulbSelected(evt) {
    this.tnt_id = evt.option.value.tnt_id;
    this.ulb_id = evt.option.value.ulb_id;
    this.getCrstrs();
    this.gettplst();
    this.getstreetLst();
  }
  clstrSelected(evt) {
    this.clsrt_id = evt.option.value.tnt_id;
    this.gettplst();
  }
  tpSelected(evt) {
    this.fnce_id = evt.option.value.fnce_id;
    this.fnce_nm = evt.option.value.fnce_nm;
    this.dsbladdNwTrpBtn = false;
    this.shwpcr = false;
    this.pcrDtls()
  }
  mpSelected(evt) {
    this.mcrpt_id = evt.option.value.mcrpt_id;
    this.mcrpt_nm = evt.option.value.mcrpt_nm;
    this.getstreetLst();
  }
  private _topUlbfilter(value): PrimaryVehicle[] {
    if (value) {
      return this.ulbLst.filter(item => ((typeof value === 'string') ? item.ulb_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.ulb_nm.toLowerCase().indexOf(value['ulb_nm'].toLowerCase()) === 0));
    }
    return this.ulbLst;
  }
  private _clsrtfilter(value): PrimaryVehicle[] {
    if (value) {
      return this.clstrs.filter(item => ((typeof value === 'string') ? item.clstr_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.clstr_nm.toLowerCase().indexOf(value['clstr_nm'].toLowerCase()) === 0));
    }
    return this.clstrs;
  }
  private _toptpfilter(value): PrimaryVehicle[] {
    if (value) {
      return this.trnsfrPntslst.filter(item => ((typeof value === 'string') ? item.fnce_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.fnce_nm.toLowerCase().indexOf(value['fnce_nm'].toLowerCase()) === 0));
    }
    return this.trnsfrPntslst;
  }
  private _mpfilter(value): PrimaryVehicle[] {
    if (value) {
      return this.micropockets.filter(item => ((typeof value === 'string') ? item.mcrpt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.mcrpt_nm.toLowerCase().indexOf(value['mcrpt_nm'].toLowerCase()) === 0));
    }
    return this.micropockets;
  }
  private _sstrtfilter(value): PrimaryVehicle[] {
    if (value) {
      return this.streets.filter(item => ((typeof value === 'string') ? item.strt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.strt_nm.toLowerCase().indexOf(value['strt_nm'].toLowerCase()) === 0));
    }
    return this.streets;
  }
  private _testrtfilter(value): PrimaryVehicle[] {
    if (value) {
      return this.streets.filter(item => ((typeof value === 'string') ? item.strt_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.strt_nm.toLowerCase().indexOf(value['strt_nm'].toLowerCase()) === 0));
    }
    return this.streets;
  }
  private _fncegrpFncfilter(value): PrimaryVehicle[] {
    if (value) {
      return this.tpGrpLst.filter(item => ((typeof value === 'string') ? item.fnce_grp_nm.toLowerCase().indexOf(value.toLowerCase()) === 0 :
        item.fnce_grp_nm.toLowerCase().indexOf(value['fnce_grp_nm'].toLowerCase()) === 0));
    }
    return this.tpGrpLst;
  }
  pcrDtls() {
    this.mcrpt_id = 0;
    this.geoService.streetLst({ ulb_id: this.ulb_id, mcrpt_id: this.mcrpt_id }, (err, res) => {
      this.streets = res.data;
      this.addpcrform.get('strt_strt').setValue("");
      this.addpcrform.get('end_strt').setValue("");
      this.shwLdr = true;
      this.vehicleService.getpcrLst(this.streets, this.fnce_id, (err, res) => {
        this.shwLdr = false;
        // this.pcrlst = [];
        this.addpcrform.reset();
        this.pcrlst = res.data;
        console.log(this.pcrlst);
        for (let r of this.pcrlst) {
          r.shwRtLbl = "Show Round";
        }
      });
    });
  }
  addpcrDtls() {
    console.log("addpcrDtls");
    this.dsblSvRtBtn = false;
    this.addpcrform.get('mrdn').setValue('AM');
    this.getmpLst();
    this.shwpcr = true;
    this.shwLdr = false;
    for (let r of this.pcrlst) {
      r.shwRtLbl = "Show Round";
    }
  }
  getCrstrs() {
    this.geoService.getClusterDetails(this.ulb_id, 0, (err, res) => {
      this.clstrs = res.data;
      this.addFncForm.get('clsrt_id').setValue("");
    });
  }
  gettplst() {
    this.vehicleService.trnsfptsDtls(this.ulb_id, (err, trns_res) => {
      this.trnsfrPntslst = trns_res.data;
      this.selectedtp.setValue("");
    });
  }
  getmpLst() {
    this.geoService.getMicroPocket(this.ulb_id, 0, 0, (err, res) => {
      this.micropockets = res.data;
      this.addpcrform.get('mcrpt').setValue("");
    });
  }
  getstreetLst() {
    this.geoService.streetLst({ulb_id: this.ulb_id, mcrpt_id: this.mcrpt_id }, (err, res) => {
      this.streets = res.data;
      this.addpcrform.get('strt_strt').setValue("");
      this.addpcrform.get('end_strt').setValue("");
    });
  }
  opensideBar(key) {
    if(!this.selectedulb.value)
    return;
    this.shwErrMsg = false;
    this.getAllMstrData();
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  getAllMstrData() {
    this.vehicleService.getAllMstrsData(this.ulb_id, (err, res) => {
      this.tpGrpLst = res.data.transfrPointLst_rtms;
      this.addFncForm.get('fnce_grp').setValue('');
    });
  }
  getroundDtls(roud) {
    this.shwLdr = true;
    if (roud.shwRtLbl == "Show Round") {
      this.shwpcr = true;
    } else
      this.shwpcr = false;

    if (roud.shwRtLbl == "Show Round") {
      for (let r of this.pcrlst) {
        if (r.psh_crt_id == roud.psh_crt_id) {
          r.shwRtLbl = "Hide Round";
        } else {
          r.shwRtLbl = "Show Round";
        }
      }
      let tp_arvl_ts = (roud.tp_arvl_ts.split(' '))[0];
      let mrdn;
      if((roud.tp_arvl_ts.split(' '))[1])
      mrdn = (roud.tp_arvl_ts.split(' '))[1];
      this.addpcrform.get('mcrpt').setValue({ mcrpt_id: roud.mcrpt_id, mcrpt_nm: roud.mcrpt_nm, });
      this.addpcrform.get('strt_strt').setValue({ strt_id: roud.strt_strt_id, strt_nm: roud.strt_strt_nm, });
      this.addpcrform.get('end_strt').setValue({ strt_id: roud.end_strt_id, strt_nm: roud.end_strt_nm, });
      this.addpcrform.get('tp_arvl_ts').setValue(tp_arvl_ts);
      this.addpcrform.get('mrdn').setValue(mrdn);
      this.addpcrform.get('psh_crt_id').setValue(roud.psh_crt_id);
      this.shwpcr = true;
      this.shwLdr = false;
    } else {
      for (let r of this.pcrlst) {
        if (r.psh_crt_id == roud.psh_crt_id)
          r.shwRtLbl = "Show Round";
      }
      this.shwLdr = false;
    }
  }

  delete(p) {
  const dialogRef = this.dialog.open(PrimaryConfirmDialog, {
    width: '500px',
    data: { id: p, entityname: 'Pushcart', cnfrmflag: true, successFlag: false, operation: "Deleted", dtls: p.psh_crt_id }
  });
  dialogRef.afterClosed().subscribe(() => {
    this.pcrDtls();
  })
  }
  
  savepcr() {
    this.dsblSvRtBtn = true;
        this.shwErrMsg = false;
    if (this.addpcrform.status === 'INVALID') {
      this.shwErrMsg = true;
      return false;
    }
    let strt_hour = parseInt((this.addpcrform.value.tp_arvl_ts.split(':'))[0]);
    let strt_min = parseInt((this.addpcrform.value.tp_arvl_ts.split(':'))[1]);
    let data ={
      strt_strt_id:this.addpcrform.value.strt_strt.strt_id,
      end_strt_id:this.addpcrform.value.end_strt.strt_id,
      tp_arvl_ts:this.addpcrform.value.mrdn == "PM" ?(strt_hour+12+":"+((strt_min+'').length == 1 ? '0'+strt_min : strt_min)):this.addpcrform.value.tp_arvl_ts,
      // tp_arvl_ts:this.addpcrform.value.tp_arvl_ts+" "+this.addpcrform.value.mrdn,
      trnsfr_pnt_id:this.fnce_id,
      mcrpt_id:this.addpcrform.value.mcrpt.mcrpt_id,
      psh_crt_id:this.addpcrform.value.psh_crt_id
      }
    if (this.addpcrform.get('psh_crt_id').value === '' || this.addpcrform.get('psh_crt_id').value == null) {
      this.addpcr(data);
    } else { 
      this.updtPcr(data);
    }
  }
  addpcr(data) {
    this.vehicleService.addpcr(data, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(PrimaryConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
        });
        console.log("hiii");
        this.dsblSvRtBtn = false;
        this.pcrDtls();
      }
    });
  }
  updtPcr(data) {
    this.vehicleService.updtpcr(data, (err, res) => {
      if (res.status == 200) {
        const dialogRef = this.dialog.open(PrimaryConfirmDialog, {
          width: '500px',
          data: { cnfrmflag: false, successFlag: true, operation: 'Updated' }
        });
        this.pcrDtls();
      }
    });
  }
  
  validateTimeformate(time){
    let hour = (time.split(':'))[0];
    let min = (time.split(':'))[1];
    let part = hour > 12 ? 'PM' : 'AM';
    min = (min+'').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour+'').length == 1 ? `0${hour}` : hour;
    this.addpcrform.get('tp_arvl_ts').setValue(hour+":"+min);
    this.addpcrform.get('mrdn').setValue(part);
  }
  toggleMrdn(mrdn){
    let part = (mrdn == 'AM') ? 'PM' : 'AM'
    this.addpcrform.get('mrdn').setValue(part);
  }
  addNewFnc() {
    if (this.addFncForm.status == 'VALID') {
      let postData = {
        fncs_nm: this.addFncForm.value.fnce_nm,
        fncsGrpId: this.addFncForm.value.fnce_grp.fnce_grp_id,
        lat: this.addFncForm.value.lat,
        lng: this.addFncForm.value.lng,
        tnt_id: this.tnt_id
      }
      this.vehicleService.addFence(postData, (err, res) => {
        if (res.status == 200) {
          const dialogRef = this.dialog.open(PrimaryConfirmDialog, {
            width: '500px',
            data: { cnfrmflag: false, successFlag: true, operation: 'Created' }
          });
          this.gettplst();
        }
      })
    } else this.shwErrMsg = true;
  }
}


@Component({
  selector: 'confirm-dialog',
  templateUrl: '../../../main/confirm-dialog.html'
})
export class PrimaryConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<PrimaryConfirmDialog>,
    public vehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA) public data: { id: '', entityname: '', cnfrmflag: false, successFlag: false, operation: '', dtls: '' }) {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(id, entityname, cnfrmflag, successFlag, operation, dtls): void {
    switch (entityname) {
      case 'Pushcart': {
        console.log(id);
        this.vehicleService.dltpcr(id, (err, res) => {
          if (res.status === 200) {
            this.data.cnfrmflag = false;
          }
        });
        break;
      }
    }

  }
  getColor(operation) {
    (2)
    switch (operation) {
      case 'Created':
        return 'green';
      case 'Assigned':
        return 'green';
      case 'Updated':
        return '#ffc107';
      case 'Disabled':
        return '#ffc107';
      case 'Removed':
        return '#red';
      case 'Deleted':
        return 'red';
    }
  }
}

